import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'meu-select-container',
  templateUrl: './select-container.component.html',
  styleUrls: ['./select-container.component.scss'],
})
export class SelectContainerComponent implements OnInit {
  @Input('fcontrol') formControl: FormControl;
  @Input('errorMessageResource') errorMessageResource;
  @Input('label') labelContent: string;
  constructor() {}

  ngOnInit() {}
}
