<mat-form-field class="full-width-field custom-label" floatLabel="never">
  <mat-label>{{ placeholderLabel }}</mat-label>
  <div class="border-focus">
    <input
      [disabled]="disabled"
      matInput
      [matDatepicker]="datepicker"
      [formControl]="selectCtrl"
      [ngModel]="defaultID"
      [placeholder]="placeholder"
      autocomplete="off"
      (focus)="datepicker.open()"
      class="datepicker-custom"
    />
    <mat-datepicker-toggle matSuffix [for]="datepicker" class="icon-customer">
    </mat-datepicker-toggle>
  </div>
  <mat-datepicker #datepicker></mat-datepicker>
</mat-form-field>
