<!-- begin:: Footer -->
<div class="kt-footer kt-footer--extended kt-grid__item" id="kt_footer">
  <!-- <div class="kt-footer__top">
    <div class="kt-container" [ngClass]="{'kt-container--fluid': fluid}">
      <div class="row">
        <div class="col-lg-4">
          <div class="kt-footer__section">
            <h3 class="kt-footer__title">About</h3>
            <div class="kt-footer__content">
              Vẻ đẹp của một giải pháp thông minh không chỉ là giải quyết các
              vấn đề nhất định, mà còn làm cho tâm trí của chúng ta sáng sủa
              hơn. Chúng tôi đã sẵn sàng để mang đến sự xuất sắc của chúng tôi,
              các đội có hiệu suất cao và hồ sơ theo dõi trong thế giới thực cho
              bạn
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="kt-footer__section">
             <h3 class="kt-footer__title">Quick Links</h3>
						<div class="kt-footer__content">
							<div class="kt-footer__nav">
								<div class="kt-footer__nav-section">
									<a href="javascript:;">General Reports</a>
									<a href="javascript:;">Dashboart Widgets</a>
									<a href="javascript:;">Custom Pages</a>
								</div>
								<div class="kt-footer__nav-section">
									<a href="javascript:;">User Setting</a>
									<a href="javascript:;">Custom Pages</a>
									<a href="javascript:;">Intranet Settings</a>
								</div>
							</div>
						</div>
            <h3 class="kt-footer__title">Contact</h3>
            <div class="kt-footer__content">
              Office 1: 68 Nguyen Hue Street, District 1, Ho Chi Minh City,
              Vietnam. <br />

              Office 2: 03 Song Thao, Tan Binh, Ho Chi Minh City, Vietnam.<br />

              Office 3: 25 Street 2/9, Hai Chau Dist, Da Nang City, Vietnam.<br />

              Phone: (+84)28 7109 9879<br />

              Mail: contact@meu-solutions.com
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="kt-footer__section">
            <h3 class="kt-footer__title">Subscibe</h3>
            <div class="kt-footer__content">
              <label class="mb-4">
                Hãy cho chúng tôi email của bạn, chúng tôi sẽ gửi cho bạn tin
                tức của chúng tôi thường xuyên.
              </label>

              <form action="" class="kt-footer__subscribe">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Nhập Email của bạn"
                  />
                  <div class="input-group-append">
                    <button class="btn btn-info" type="button">Subscibe</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="kt-footer__bottom">
    <div class="kt-container" [ngClass]="{'kt-container--fluid': fluid}">
      <div class="kt-footer__wrapper">
        <div class="kt-footer__logo">
          <a href="/">
            <img
              class="logo-footer"
              alt="Logo"
              [src]="htmlClassService.logoPathSubject$ | async"
            />
          </a>
          <div class="kt-footer__copyright">
            {{ today | date: 'yyyy' }}&nbsp;©&nbsp;
            <a href="https://meu-solutions.com/" target="_blank" rel="noreferrer" 
              >MeU Solutions, Inc</a
            >
          </div>
        </div>
        <div class="kt-footer__menu">
          <!-- <a href="javascript:void(0);">Purchase Lisence</a>
          <a href="javascript:void(0);">Team</a>
          <a href="javascript:void(0);">Contact</a> -->
        </div>
      </div>
    </div>
  </div>
</div>
<!-- end:: Footer -->
