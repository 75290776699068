<div class="modal-content-container custom-dashboard">
  <div class="flex flex-col py-8px px-16px border-b border-tlr-lightest-blue">
    <div class="text-16px text-dark-grey font-roboto-medium">
      Chia sẻ thẻ '{{ dataInject.nameBoard }}'
      <img
        src="/assets/icons/u_times.svg"
        alt=""
        height="20px"
        (click)="closeModalClick(null)"
        class="cursor-pointer lg:block inline float-right"
      />
    </div>
  </div>

  <div
    class="grid grid-cols-2 gap-8px pt-8px px-16px orkitem-hashtag-container overflow-mobile-dcl"
  >
    <p class="text-14px text-grey col-span-2">Người được chia sẻ</p>
    <div class="col-span-2">
      <meu-hash-tag-dashboard
        (emitLstUser)="lstUser = $event"
        #hashtag
        [boardId]="dataInject.boardId"
        isEdit="true"
      >
      </meu-hash-tag-dashboard>
    </div>

    <p class="text-14px text-grey col-span-2">Quyền</p>
    <div class="col-span-2 hashtag-select-board">
      <meu-control-select-search
        [floatLabel]="false"
        [placeholderLabel]="'Chọn quyền'"
        [arrayInput]="role"
        [(ngModel)]="selectedRole"
      >
      </meu-control-select-search>
    </div>
  </div>

  <div class="p-16px grid grid-cols-2">
    <div class="w-180px text-12px"></div>
    <div class="text-right pt-2px">
      <button
        class="btn-text-sm mr-8px input-h-40px"
        (click)="closeModalClick(null)"
      >
        Hủy
      </button>
      <button
        class="btn-primary-sm input-h-40px"
        [disabled]="enabledButton()"
        (click)="save()"
      >
        Chia sẻ
      </button>
    </div>
  </div>
</div>
