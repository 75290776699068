<div id="kt_header_mobile" class="kt-header-mobile kt-header-mobile--fixed">
  <div class="kt-header-mobile__toolbar">
    <button
      class="kt-header-mobile__toolbar-toggler"
      id="kt_header_mobile_toggler"
    >
      <!-- <span></span> -->
      <!-- <img src="../../../../../assets/icons/svg/menu_open.svg" /> -->
      <img
        class="d-block"
        alt="icon"
        src="./assets/icons/svg/menu_open.svg"
      />
    </button>
  </div>
  <div class="kt-header-mobile__logo">
    <a href="/workflow/dashboard">
      <img
        class="logo-mobile"
        alt="logo"
        onerror="this.src='./assets/media/logos/logo_skhcn.png';"
        [src]="htmlClassService.logoPathSubject$ | async"
      />
    </a>
  </div>

  <div class="kt-header-mobile__toolbar">
    <div>
      <kt-notification
        data-placement="left"
        [bgImage]="'./assets/media/misc/bg-1.jpg'"
        [pulse]="true"
        [skin]="'dark'"
        [icon]="'./assets/icons/svg/bell_2.svg'"
        [useSVG]="true"
      ></kt-notification>
    </div>
    <button
      [hidden]="!asideDisplay"
      class="kt-header-mobile__toggler kt-header-mobile__toggler--left"
      id="kt_aside_mobile_toggler"
    >
      <span></span>
    </button>
    <!--begin: Quick actions -->
    <div class="quickAction-header">
      <kt-quick-action
        [bgImage]="'./assets/media/misc/bg-2.jpg'"
        [skin]="'dark'"
        [icon]="'./assets/media/icons/svg/Layout/Layout-4-blocks.svg'"
        [useSVG]="true"
      ></kt-quick-action>
    </div>
    <div class="user-header ml-2">
      <kt-user-profile2></kt-user-profile2>
    </div>
    <!-- <button
      ktToggle
      [options]="toggleOptions"
      class="kt-header-mobile__toolbar-topbar-toggler"
      id="kt_header_mobile_topbar_toggler"
    >
      <i class="flaticon-more-1"></i>
    </button> -->
  </div>
</div>
