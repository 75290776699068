<div class="grid" #radioRef>
  <div class="radio-wrapper" *ngFor="let item of radioOption">
    <label>
      <input
        type="radio"
        [value]="item.value"
        [attr.name]="item.label"
        [formControl]="radiobuttonControl"
        attr.disabled="{{ disabled ? true : null }}"
      />
      <span>
        {{ item.label }}
      </span>
    </label>
  </div>
</div>
