<kt-subheader1
  *ngIf="!layout || layout === 'subheader-v1'"
  [fluid]="fluid"
  [clear]="clear"
></kt-subheader1>
<kt-subheader2
  *ngIf="layout === 'subheader-v2'"
  [fluid]="fluid"
  [clear]="clear"
></kt-subheader2>
<kt-subheader3
  *ngIf="layout === 'subheader-v3'"
  [fluid]="fluid"
  [clear]="clear"
></kt-subheader3>
