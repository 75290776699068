import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import moment from 'moment';
import {ToastrService} from 'ngx-toastr';
import {DynamicDashboardComponent} from '../dynamic-dashboard.component';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DbdBoardShareService} from '@app/api/dbdBoardShare.service';

@Component({
  selector: 'meu-modal-share-widget',
  templateUrl: './modal-share-widget.component.html',
  styleUrls: ['./modal-share-widget.component.scss'],
})
export class ModalShareWidgetComponent implements OnInit {
  public keyUp = new Subject<KeyboardEvent>();

  dataSearchBasic: any = '';
  checkboxAll = false;
  listShare = [];
  filter = '';
  paging: any = {
    pageNo: 1,
    pageSize: 10,
    recordCount: 100,
  };
  constructor(
    public dialogRef: MatDialogRef<DynamicDashboardComponent>,
    @Inject(MAT_DIALOG_DATA) public dataInject,
    private toastr: ToastrService,
    public bdBoardShareService: DbdBoardShareService
  ) {}

  ngOnInit(): void {
    this.keyUp
      .pipe(
        map((event) => event),
        debounceTime(500)
      )
      .subscribe((value) => {
        this.dataSearchBasic = value;
        this.filter =
          'extend_board_name|extend_receiver_email_full_name@=*' +
          this.dataSearchBasic;
        this.paging.pageNo = 1;
        this.getWidgetCollection();
      });
    this.getWidgetCollection();
  }

  closeModalClick(data) {
    this.dialogRef.close(data);
  }

  async save() {
    var _this = this;
    for (let index = 0; index < this.listShare.length; index++) {
      const element = this.listShare[index];

      if (element.isaccepted != element.accepted) {
        let prom = new Promise((res, rej) => {
          let obj = {
            accepted: element.isaccepted,
          };
          _this.bdBoardShareService
            .apiDbdBoardShareIdPut(element.id, obj)
            .subscribe((response) => {
              if (!response.success) {
                _this.toastr.error(response.message, 'Thất bại');
              }
              res('Success');
            });
        });
        await prom.then((val) => {
          console.log('asynchronously executed: ' + val);
        });
      }
    }
    this.toastr.success('Thao tác thành công', 'Thành công');
    this.closeModalClick(null);
    setTimeout(() => {}, 1500);
  }

  paginatorChange(event?) {
    this.paging.pageNo = event;
    this.paging = {...this.paging};
    this.getWidgetCollection();
  }

  getWidgetCollection() {
    this.bdBoardShareService
      .apiDbdBoardShareToMeGet(
        this.filter,
        '',
        this.paging.pageNo,
        this.paging.pageSize
      )
      .subscribe((res) => {
        if (res.success) {
          this.listShare = res.data.collection;
          let isCheckAll = true;
          this.listShare.forEach((element) => {
            element.created_at = moment(element.created_at).format(
              'DD/MM/YYYY - HH:mm'
            );
            element.isaccepted = element.accepted;
            if (!element.accepted) isCheckAll = false;
          });
          this.checkboxAll = this.listShare.length == 0 ? false : isCheckAll;
          this.paging.pageNo = res.data.pageIndex;
          this.paging.recordCount = res.data.total;
          this.paging.pageSize = res.data.pagesize;
          this.paging = Object.assign({}, this.paging);
        }
      });
  }

  chooseAll(value) {
    this.listShare.forEach((element) => {
      element.isaccepted = value.target.checked;
    });
  }

  enabledButton() {
    return this.listShare.filter((x) => x.isaccepted == true).length == 0;
  }
}
