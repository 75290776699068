<div class="kt-portlet" style="overflow: hidden; margin-bottom: 0px">
  <div class="kt-portlet__head kt-portlet__head__custom meu-title-bg">
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title title-color font-roboto-medium">
        {{ dataInject.boardId ? 'Chỉnh sửa thẻ' : 'Thêm thẻ mới' }}
      </h3>
    </div>
    <div mat-dialog-close class="close_modal_btn meu-item-center">
      <mat-icon>close</mat-icon>
    </div>
  </div>

  <div class="meu-p-4 create-flow-body">
    <form
      [formGroup]="createBoardForm"
      class="mb-8px"
      [ngClass]="{invalid: isShowError(createBoardForm, 'name')}"
    >
      <meu-input
        [name]="'name'"
        [title]="'Tên thẻ'"
        [formControl]="nameControl"
        [invalidState]="
          createBoardForm.get('name').invalid &&
          (createBoardForm.get('name').dirty ||
            createBoardForm.get('name').touched)
        "
        ngDefaultControl
      >
        <ng-container errorMessage>
          <div class="flex items-center mt-1">
            <div class="input-error-label">Tên thẻ là bắt buộc nhập</div>
          </div>
        </ng-container>
      </meu-input>
    </form>
  </div>

  <div
    class="kt-portlet__foot kt-portlet__foot--fit kt-portlet__no-border"
    id="modal-footer"
  >
    <div class="kt-form__actions kt-form__actions--solid">
      <div class="meu-p-2-5 text-right">
        <button
          class="meu-basic-button"
          (click)="closeModalClick(null)"
          mat-raised-button
        >
          Hủy
        </button>
        <button
          class="meu-primary-button ml-2"
          (click)="save()"
          mat-raised-button
          color="primary"
        >
          Lưu
        </button>
      </div>
    </div>
  </div>
</div>
