import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  forwardRef,
  ChangeDetectorRef,
  OnChanges,
} from '@angular/core';
import {FormControl, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import * as _ from 'lodash';
import {finalize} from 'rxjs/operators';
import {environment} from 'environments/environment';
import {Configuration} from '@app/configuration';
import {CustomHttpUrlEncodingCodec} from '@app/encoder';

@Component({
  selector: 'meu-select-load-more-dynamic-dashboard',
  templateUrl: './select-load-more-dynamic-dashboard.component.html',
  styleUrls: ['./select-load-more-dynamic-dashboard.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectLoadMoreDynamicDashboardComponent),
      multi: true,
    },
  ],
})
export class SelectLoadMoreDynamicDashboardComponent
  implements OnInit, OnChanges
{
  @Input() placeholderLabel: string;
  @Input() noEntriesFoundLabel: string;
  @Input() defaultID: any = '';
  @Input() nameAPI: string;
  @Input() required: boolean;
  @Input() multiple: boolean;
  @Input() stringFilter: string;
  @Input() columnName: string;
  @Input() columnValue: string;
  @Input() disabled = false;
  @Input() invalid = false;
  @Input() options: any;
  @Output() change = new EventEmitter();
  @Output() changeItem = new EventEmitter();
  comboBoxLoading = false;
  fiteredInput: any[];
  selectCtrl: FormControl = new FormControl({
    value: this.defaultID,
    disabled: this.disabled,
  });
  searchControl: FormControl = new FormControl();
  page = 1;
  filters = null;
  totalPage = 0;
  private _value: any;
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  ispagination = true;
  notUpdate = false;
  get value() {
    return this._value;
  }
  set value(val) {
    this._value = val;
    this.propagateChange(this._value);
  }
  private propagateChange = (_: any) => {};

  constructor(
    protected httpClient: HttpClient,
    private changeDetectorRef: ChangeDetectorRef
  ) {}
  ngOnChanges(changes: SimpleChanges) {
    if (this.options && this.options.length > 0) {
      this.fiteredInput = [...this.options];
    } else if (changes.nameAPI) {
      this.fiteredInput = [];
      if (changes.nameAPI.currentValue.includes('{{')) return;
      if (changes.stringFilter) {
        this.filters = this.stringFilter;
        this.runAPI(this.nameAPI);
      } else {
        this.runAPI(this.nameAPI);
      }
    }
  }
  ngOnInit() {
    if (this.required) {
      this.selectCtrl.setValidators(Validators.required);
      this.selectCtrl.updateValueAndValidity();
    }
    this.selectCtrl.valueChanges.subscribe((res) => {
      if (!this.multiple && this.selectCtrl.value === '') {
        setTimeout(() => {
          this.selectCtrl.reset();
        }, 200);
      }
      this.emitChangeValue(this.selectCtrl.value);
    });
  }

  search(event) {
    this.page = 1;
    this.fiteredInput = [];
    this.filters = null;
    if (event) {
      if (this.stringFilter) {
        this.filters =
          this.stringFilter + ',' + this.columnName + '@=*' + event;
      } else {
        this.filters = this.columnName + '@=*' + event;
      }
    } else {
      if (this.stringFilter) {
        this.filters = this.stringFilter;
      }
    }
    this.runAPI(this.nameAPI);
  }
  loadMore() {
    if (this.page && this.page < this.totalPage) {
      this.page = this.page + 1;
      this.runAPI(this.nameAPI);
    }
  }
  runAPI(nameAPI) {
    if (nameAPI == null || nameAPI.trim() === '') {
      return;
    }
    this.notUpdate = false;
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (this.filters) {
      queryParameters = queryParameters.set('Filters', <any>this.filters);
    }
    queryParameters = queryParameters.set('PageNo', '1');
    if (this.page) {
      queryParameters = queryParameters.set('PageSize', <any>(this.page * 10));
    }
    let headers = this.defaultHeaders;
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    this.comboBoxLoading = true;
    this.httpClient
      .request<any>('get', `${environment.backendhost + '/' + nameAPI}`, {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
      })
      .pipe(finalize(() => (this.comboBoxLoading = false)))
      .subscribe((res) => {
        let array = [];
        if (res.data.collection) {
          this.ispagination = true;
          array = [...res.data.collection];
        } else {
          this.ispagination = false;
          array = [...res.data];
        }
        this.fiteredInput = array;
        if (this.multiple) {
          this.selectCtrl.value.forEach((element) => {
            const item = this.fiteredInput.find(
              (x) => x[this.columnValue] === element
            );
            if (!item) this.getDataByID(element);
          });
        }
        this.totalPage = Math.ceil(res.data.total / 10);
        this.changeDetectorRef.detectChanges();
        let index = null;
        try {
          if (this.fiteredInput.length % 10 === 0) {
            index = this.fiteredInput.length - 10;
          } else {
            index = Math.floor(this.fiteredInput.length / 10) * 10;
          }
        } catch (error) {}
        if (index != null && index !== 0) {
          if (this.fiteredInput[index]) {
            const elementID = this.fiteredInput[index][this.columnValue];
            if (document.getElementById(elementID)) {
              setTimeout(function () {
                document.getElementById(elementID)?.scrollIntoView(false);
              }, 0.5);
            }
          }
        }
      });
  }
  getDataByID(id) {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    const filter = ' ' + this.columnValue + '==' + id;
    queryParameters = queryParameters.set('Filters', <any>filter);
    let headers = this.defaultHeaders;
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    this.httpClient
      .request<any>('get', `${environment.backendhost + '/' + this.nameAPI}`, {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
      })
      .subscribe((res) => {
        let array = [];
        if (res.data.collection) {
          array = res.data.collection;
        } else {
          array = res.data;
        }
        if (array.length > 0) {
          this.fiteredInput = [...this.fiteredInput, ...array];
        } else {
          const i = this.value.findIndex((x) => x === id);
          if (i >= 0) {
            this.value.splice(i, 1);
            this.selectCtrl.setValue(this.value);
          }
        }
      });
  }
  writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
    }
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}

  emitChangeValue(event) {
    this.stringFilter = '';
    setTimeout(() => {
      this.value = event;
      this.change.emit(this.value);
    }, 200);
  }
}
