<div class="modal-content-container modal-share-list custom-dashboard">
  <div class="flex flex-col py-8px px-16px border-b border-tlr-lightest-blue">
    <div class="text-16px text-dark-grey font-roboto-medium">
      Danh sách chia sẻ
      <img
        src="/assets/icons/u_times.svg"
        alt=""
        height="20px"
        (click)="closeModalClick(null)"
        class="cursor-pointer lg:block inline float-right"
      />
    </div>
  </div>

  <div
    class="grid grid-cols-2 gap-8px pt-8px px-16px orkitem-hashtag-container"
  >
    <div class="col-span-2 md:col-span-1 mr-4px md:mr-8px mt-16px mb-8px">
      <div class="row padding-input-search">
        <div
          class="right-inner-addon input-container pointer input-search-event full-width-field"
        >
          <div class="input-group border-search">
            <div class="input-group-prepend">
              <div class="input-group-text icon-search none-border">
                <img
                  class="flex justify-content-center flex-column input-icon-search ico-wd"
                  src="./assets/icons/icon_search.svg"
                />
              </div>
            </div>
            <input
              type="text"
              class="m-h-40px form-control input-search none-border"
              id="inlineFormInputGroupUsername2"
              placeholder="Tìm kiếm"
              autocomplete="off"
              (keyup)="keyUp.next($event.target.value)"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="col-span-2 min-table-height">
      <table
        class="w-full table-fixed mb-4"
        [ngClass]="paging.recordCount > paging.pageSize ? 'table-nosps' : ''"
      >
        <thead class="bg-tlr-lightest-blue">
          <tr>
            <th class="tb-header rounded-tl-4px w-34px text-center">#</th>
            <th class="tb-header lg:w-6/12 w-6/12 text-left">Tên</th>
            <th class="tb-header lg:w-4/12 w-4/12 text-left">Phân quyền</th>
            <th class="tb-header rounded-tr-4px w-2/12 text-center"></th>
          </tr>
        </thead>

        <tbody>
          <tr *ngIf="lstSharering.length == 0">
            <td colspan="4">
              <div class="no-record-found">Chưa có dữ liệu</div>
            </td>
          </tr>
          <tr
            class="border-b border-silver-40"
            *ngFor="let item of lstSharering; let i = index"
          >
            <td class="tb-cell text-center">
              {{ paging.pageNo * paging.pageSize + i - paging.pageSize + 1 }}
            </td>
            <td class="tb-cell">
              <div class="flex items-center" *ngIf="!item.edit">
                <img
                  class="rounded-full w-24px h-24px border border-2px border-tlr-lightest-blue"
                  [src]="item.extend_receiver_avatar"
                  onerror="this.src = null;this.src='/assets/images/default.jpg'"
                />
                <span
                  class="truncate ml-8px"
                  [title]="item.extend_receiver_email_full_name"
                >
                  {{ item.extend_receiver_email_full_name }}
                </span>
              </div>
              <div *ngIf="item.edit">
                <ng-select
                  class="custom form-control text-14px"
                  [items]="lstUser"
                  [virtualScroll]="true"
                  [loading]="comboBoxLoading"
                  bindLabel="extend_user_name_email"
                  bindValue="id"
                  placeholder="Nhập người được chia sẽ"
                  appendTo="body"
                  (scrollToEnd)="onScrollToEnd()"
                  dropdownPosition="bottom"
                  [(ngModel)]="item.user_id"
                  (search)="uFilter.next($event.term)"
                  hideSelected="true"
                >
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    {{ item.extend_user_name_email }}
                  </ng-template>
                </ng-select>
              </div>
            </td>
            <td class="tb-cell">
              <div *ngIf="!item.edit">
                {{ item.editable ? 'Được chỉnh sửa' : 'Chỉ xem' }}
              </div>
              <div *ngIf="item.edit">
                <ng-select
                  [items]="role"
                  clearable="false"
                  bindLabel="name"
                  bindValue="id"
                  [(ngModel)]="item.editable"
                >
                </ng-select>
              </div>
            </td>
            <td class="tb-cell text-center">
              <div class="flex justify-center" *ngIf="!item.edit">
                <img
                  class="w-20px h-20px cursor-pointer mr-5px md:mr-12px"
                  title="Chỉnh sửa"
                  src="/assets/icons/icon-edit.svg"
                  alt="icon-edit"
                  (click)="item.edit = true"
                />
                <img
                  class="w-20px h-20px cursor-pointer"
                  title="Xoá"
                  src="/assets/icons/icon_trash.svg"
                  alt="icon_trash"
                  (click)="removeItem(item.id)"
                />
              </div>
              <div class="flex justify-center" *ngIf="item.edit">
                <img
                  class="w-20px h-20px cursor-pointer mr-5px md:mr-12px"
                  title="Lưu"
                  src="/assets/icons/save_black.svg"
                  alt="icon-edit"
                  (click)="saveItem(item)"
                />
                <img
                  class="w-20px h-20px cursor-pointer"
                  title="Huỷ"
                  src="/assets/icons/u_times.svg"
                  alt="icon_trash"
                  (click)="cancel(item)"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      class="col-span-2 -mt-23px"
      [ngClass]="paging.recordCount > paging.pageSize ? 'paging-shadow' : ''"
    >
      <mat-paginator
        class=""
        (page)="paginatorChange($event)"
        [pageSize]="paging.pageSize"
        [length]="paging.recordCount"
        [hidePageSize]="false"
        [pageSizeOptions]="[5, 10, 20, 100]"
        [showFirstLastButtons]="true"
      >
      </mat-paginator>
    </div>
  </div>

  <div class="pt-0 p-16px grid grid-cols-2">
    <div class="w-180px text-12px"></div>
    <div class="text-right pt-2px">
      <button
        class="btn-primary-sm input-h-40px"
        (click)="closeModalClick(null)"
      >
        Đóng
      </button>
    </div>
  </div>
</div>
