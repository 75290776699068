import {Directive, ElementRef, HostListener, Renderer2} from '@angular/core';

@Directive({
  selector: '[appMeuCurrency]',
})
export class MeuCurrencyDirective {
  constructor(private renderer: Renderer2, private el: ElementRef) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    const numberValue = +value.replace(/\D/g, '');
    let formattedValue = isNaN(numberValue)
      ? ''
      : new Intl.NumberFormat('vi-VN', {
          //   style: 'currency',
          //   currency: 'VND',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(numberValue);
    this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string) {
    const numberValue = +value.replace(/\D/g, '');
    let formattedValue = isNaN(numberValue)
      ? ''
      : new Intl.NumberFormat('vi-VN', {
          //   style: 'currency',
          //   currency: 'VND',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(numberValue);
    this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
  }
}
