import { Injectable } from '@angular/core';
import { FormGroup, AbstractControl, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormValidatorsService {
  constructor() {}

  isShowError(form: FormGroup, formControlName: string): boolean {
    const control = this.getControl(form, formControlName);
    return control.invalid && (control.dirty || control.touched);
  }

  errors(form: FormGroup, formControlName: string, formControlLabel: string = ''): string | null {
    const control = this.getControl(form, formControlName);
    const controlErrors = control.errors;
    if (controlErrors == null) {
      return null;
    }
    const errorMessages = this.errorMessageMap(controlErrors);
    return Object.keys(errorMessages)
      .map((val) => errorMessages[val])
      .map((val) => `<div class="text-error">${formControlLabel} ${val}</div>`)
      .join('');
  }

  makeAsTouched(form: FormGroup) {
    Object.keys(form.controls).forEach((controlName) => {
      form.controls[controlName].markAsTouched();
    });
  }

  private getControl(formGroup: FormGroup, formControlName: string): AbstractControl {
    return formGroup.get(formControlName);
  }

  private errorMessageMap(errors: ValidationErrors): {
    [key: string]: string;
  } {
    let composite = {};
    if (this.getProp('required', errors)) {
      composite = {
        ...composite,
        required: 'bắt buộc nhập.',
      };
    }

    const minLengthObj = this.getProp('minlength', errors);
    if (minLengthObj) {
      const min = this.getProp('requiredLength', minLengthObj);
      composite = {
        ...composite,
        minlength: `phải chứa ít nhất ${min} ký tự.`,
      };
    }

    const maxLengthObj = this.getProp('maxlength', errors);
    if (maxLengthObj) {
      const max = this.getProp('requiredLength', maxLengthObj);
      composite = {
        ...composite,
        maxlength: `chỉ chứa tối đa ${max} ký tự`,
      };
    }

    if (this.getProp('email', errors) || this.getProp('pattern', errors)) {
      composite = {
        ...composite,
        email: 'không hợp lệ.',
      };
    }

    const minObj = this.getProp('min', errors);
    if (minObj) {
      const min = this.getProp('min', minObj);
      composite = {
        ...composite,
        minlength: `phải lớn hơn ${min}.`,
      };
    }

    const maxObj = this.getProp('max', errors);
    if (maxObj) {
      const max = this.getProp('max', maxObj);
      composite = {
        ...composite,
        minlength: `phải nhỏ hơn ${max}.`,
      };
    }

    return composite;
  }

  private getProp(prop: string, object: Object): any | null {
    return object !== undefined ? object[prop] : null;
  }
}
