// Angular
import {Component, OnInit} from '@angular/core';
import {OffcanvasOptions} from '@app/core/_base/layout';
// Layout

@Component({
  selector: 'meu-motification-mobile',
  templateUrl: './motification-mobile.component.html',
  styleUrls: ['./motification-mobile.component.scss'],
})
export class MotificationMobileComponent {
  offcanvasOptions: OffcanvasOptions = {
    overlay: true,
    baseClass: 'kt-quick-panel',
    closeBy: 'kt_quick_panel_close_btn',
    toggleBy: 'kt_quick_panel_toggler_btn',
  };
  constructor() {}
}
