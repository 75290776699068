import {
  Component,
  OnInit,
  Output,
  Input,
  EventEmitter,
  Inject,
} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DynamicDashboardComponent} from '../dynamic-dashboard.component';
import {DbdBoardService} from '@app/api/dbdBoard.service';
import {FormValidatorsService} from '@app/shared/services/form-validators.service';

@Component({
  selector: 'meu-modal-edit-widget-name',
  templateUrl: './modal-edit-widget-name.component.html',
  styleUrls: ['./modal-edit-widget-name.component.scss'],
})
export class ModalEditWidgetNameComponent implements OnInit {
  hasFormErrors = false;
  editWidgetForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
  });
  nameControl = this.editWidgetForm.get('name') as FormControl;

  constructor(
    public dialogRef: MatDialogRef<DynamicDashboardComponent>,
    @Inject(MAT_DIALOG_DATA) public dataInject,
    private toastr: ToastrService,
    private dbdBoardService: DbdBoardService,
    private formValidators: FormValidatorsService
  ) {}

  ngOnInit(): void {
    this.editWidgetForm.patchValue({
      name: this.dataInject.board_widget.name,
    });
  }

  save() {
    if (this.editWidgetForm.invalid) {
      this.formValidators.makeAsTouched(this.editWidgetForm);
      return;
    }
    const controls = this.editWidgetForm.controls;
    this.dataInject.board_widget.name = controls.name.value;
    this.dbdBoardService
      .apiMyBoardsSectionsIdWidgetsPost(
        this.dataInject.board_widget.board_section_id,
        this.dataInject.board_widget
      )
      .subscribe((result) => {
        if (result.success) {
          this.toastr.success('Cập nhật tên thành công.');
          this.closeModalClick(result.data);
        } else {
          this.toastr.error('Cập nhật tên thất bại.');
          this.closeModalClick(null);
        }
      });
  }

  closeModalClick(data) {
    this.dialogRef.close(data);
  }

  isShowError(formGroup: FormGroup, formControlName: string) {
    return this.formValidators.isShowError(formGroup, formControlName);
  }

  errors(
    formGroup: FormGroup,
    formControlName: string,
    formControlLabel: string
  ) {
    return this.formValidators.errors(
      formGroup,
      formControlName,
      formControlLabel
    );
  }
}
