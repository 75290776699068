import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'meuCurrency',
})
@Injectable({
  providedIn: 'root',
})
export class MeuCurrencyPipe implements PipeTransform {
  transform(value: number|string): string {
    const isFormatted = /^\d{1,3}(\.\d{3})*$/.test(value.toString());
    if (isFormatted) {
      return value.toString(); // If already formatted, return as is
    }
    return new Intl.NumberFormat('vi-VN', {
      // open to have đ in back ex: 1.000 đ
      // style: 'currency',
      // currency: 'VND',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
      .format(Number(value))
      .replace(/,/g, '.'); // comment this line to change '.' to ',' ex: 1.000 => 1,000
  }
}
