<div
  ktHeader
  #ktHeader
  class="kt-header meu-header kt-grid__item"
  [ngClass]="htmlClassService.getClasses('header', true)"
  id="kt_header"
>
  <ngb-progressbar
    class="kt-loading-bar"
    *ngIf="(loader.progress$ | async) > 0"
    [value]="loader.progress$ | async"
    height="3px"
  ></ngb-progressbar>
  <div class="kt-header__top">
    <div class="kt-container" [ngClass]="{'kt-container--fluid': fluid}">
      <kt-brand></kt-brand>

      <kt-topbar></kt-topbar>
    </div>
  </div>
  <div class="kt-header__bottom">
    <div class="kt-container" [ngClass]="{'kt-container--fluid': fluid}">
      <kt-menu-horizontal *ngIf="menuHeaderDisplay"></kt-menu-horizontal>
    </div>
  </div>
</div>
