import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {UsersService} from '@app/api/api';
import {AttachmentService} from '@app/api/attachment.service';
@Component({
  selector: 'meu-app-common-element',
  templateUrl: './app-common-element.component.html',
  styleUrls: ['./app-common-element.component.scss'],
})
export class AppCommonElementComponent implements OnInit {
  //search load more
  filter = '';
  //avatar-card example
  allUsers = [];
  displayedColumns: string[] = ['name', 'email'];
  //--end
  arrayDefaultID = [14, 17];
  //arrayRequiredFile = [];
  arrayRequiredFile = [
    {
      name: 'Giấy phép lái xe',
      Description: 'Bản sao giấy phép lái xe',
      sub_code: 'giaypheplaixe',
      required: true,
    },
    {
      name: 'CMND',
      Description: 'Bản sao giấy CMND',
      sub_code: 'cmnd',
      required: true,
    },
    {
      name: 'Giấy khai sinh',
      Description: 'Bản sao giấy khai sinh',
      sub_code: 'giaykhaisinh',
      required: true,
    },

    {
      name: 'Giấy phép sử dụng đất',
      Description: 'Bản sao giấy phép sử dụng nhà',
      sub_code: 'giayphepsudungdat',
      required: true,
    },
  ];
  allManager: [
    {
      id: 6;
      first_name: 'Yến';
      middle_name: 'Thị Hải';
      last_name: 'Trần ';
      email: 'MEU0003@meu.com';
      cell_phone: '012365478';
      gender: false;
      birthday: '1996-02-12T17:00:00';
      phone_extension: null;
      avatar: '/public/avatar/user/1602202016082833977973V268VJGP90';
      department: 'Phòng IT';
      position: 'Trưởng phòng';
      employee_code: 'MEU0003';
      attendance_code: '1111113543';
      extend_user_full_name: 'Yến Trần';
    },
    {
      id: 5;
      first_name: 'Lan';
      middle_name: 'Thị';
      last_name: 'Hoàng';
      email: 'MEU0003@meu.com';
      cell_phone: '0123456789';
      gender: true;
      birthday: '1993-02-04T00:00:00';
      phone_extension: null;
      avatar: '/public/avatar/user/1602202016070169033328V7BL6LGLHV';
      department: 'Phòng kế toán';
      position: 'Kế toán trưởng';
      employee_code: 'MEU0002';
      attendance_code: '12345';
      extend_user_full_name: 'Lan Hoàng';
    }
  ];
  user: any;
  constructor(
    private attachmentService: AttachmentService,
    private usersService: UsersService
  ) {}

  ngOnInit() {
    this.user = {
      id: '28fcfd7a-ce37-4b88-827f-08468c3b805e',
      email: 'vodinhthien@gmail.com',
      cell_phone: '0356158341',
      phone_extension: null,
      avatar: '/public/avatars/users/1112202023451527556802R9CB1WJWQY.jpeg',
      employee_code: 'MEU000100',
      extend_user_full_name: 'Thiên Đình Võ (Gmail)',
    };
    this.getData();
  }

  getData() {
    this.usersService
      .apiUsersGet('employee_code@=MEU', '', 1, 5)
      .subscribe((res) => {
        this.allUsers = res.data.collection;
      });
  }

  searchLoadMoreRemove(value) {
    this.filter += 'id!=' + value + ',';
  }
}
