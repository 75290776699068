import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DbdBoardShareService} from '@app/api/dbdBoardShare.service';
import {UsersService} from '@app/api/users.service';
import {ConfirmService} from '@app/shared/services/confirm.service';
import {ToastrService} from 'ngx-toastr';
import {BehaviorSubject, Subject} from 'rxjs';
import {debounceTime, filter, map, take} from 'rxjs/operators';
import {DynamicDashboardComponent} from '../dynamic-dashboard.component';

@Component({
  selector: 'meu-share-list',
  templateUrl: './share-list.component.html',
  styleUrls: ['./share-list.component.scss'],
})
export class ShareListComponent implements OnInit {
  lstSharering = [];
  public keyUp = new Subject<KeyboardEvent>();

  paging: any = {
    pageNo: 1,
    pageSize: 10,
    recordCount: 100,
  };
  dataSearchBasic: any = '';
  filter = '';

  //User
  //
  lstUser = [];
  pageNo = 1;
  pageSize = 20;
  comboBoxLoading = false;
  uFilter = new BehaviorSubject<string>('');
  filterUser = 'extend_user_full_name|email@=';

  role = [
    {id: false, name: 'Chỉ xem'},
    {id: true, name: 'Được chỉnh sửa'},
  ];

  constructor(
    public dialogRef: MatDialogRef<DynamicDashboardComponent>,
    @Inject(MAT_DIALOG_DATA) public dataInject,
    private bdBoardShareService: DbdBoardShareService,
    private toastr: ToastrService,
    private userService: UsersService,
    private confirmService: ConfirmService
  ) {}

  ngOnInit(): void {
    this.keyUp
      .pipe(
        map((event) => event),
        debounceTime(500)
      )
      .subscribe((value) => {
        this.dataSearchBasic = value;
        this.filter =
          'extend_receiver_email_full_name@=' + this.dataSearchBasic;
        this.paging.pageNo = 1;
        this.getShare();
      });

    //todo fix lỗi filter
    this.uFilter
      .pipe(
        map((event) => event),
        debounceTime(300)
      )
      .subscribe((value) => {
        this.lstUser = [];
        this.filter += value;
        this.getListUser(this.filter);
      });

    this.getShare();

    this.getListUser();
  }

  onScrollToEnd() {
    this.pageNo++;
    this.getListUser();
  }

  getShare() {
    this.bdBoardShareService
      .apiDbdBoardShareOfBoardBoardIdGet(
        this.dataInject.boardId,
        this.filter,
        '',
        this.paging.pageNo,
        this.paging.pageSize
      )
      .subscribe((res) => {
        if (res.success) {
          this.lstSharering = res.data.collection;
          this.lstSharering.forEach((element) => {
            element.backup_user = element.user_id;
            element.backup_editable = element.editable;
          });
          this.paging.pageNo = res.data.pageIndex;
          this.paging.recordCount = res.data.total;
          this.paging.pageSize = res.data.pagesize;
          this.paging = Object.assign({}, this.paging);
        } else {
          this.toastr.error(res.message, 'Lỗi');
        }
      });
  }

  removeItem(id) {
    const title = 'Xoá người chia sẻ';
    const description = 'Bạn có chắc chắn muốn xóa người được chia sẻ này?';
    this.confirmService.deleteConfirm(title, description, true);
    this.confirmService.open$
      .pipe(
        filter((event) => !!event),
        filter((event: any) => {
          return event.type === 'delete' || event.type === 'close';
        }),
        take(1)
      )
      .subscribe((events) => {
        if (events.type === 'delete') {
          this.bdBoardShareService
            .apiDbdBoardShareIdDelete(id)
            .subscribe((res) => {
              if (res.success) {
                this.toastr.success('Thao tác xóa thành công');
                let index = this.lstSharering.findIndex((x) => x.id === id);
                this.lstSharering.splice(index, 1);
              } else {
                this.toastr.error(res.message, 'Thao tác xóa không thành công');
              }
            });
        }
        this.confirmService.close();
      });
  }

  closeModalClick(data) {
    this.dialogRef.close(data);
  }

  cancel(item) {
    item.user_id = item.backup_user;
    item.editable = item.backup_editable;
    item.edit = false;
  }

  saveItem(item) {
    let obj = {
      user_id: item.user_id,
      editable: item.editable,
    };
    this.bdBoardShareService
      .apiDbdBoardShareIdPut(item.id, obj)
      .subscribe((res) => {
        if (res.success) {
          this.toastr.success(res.message, 'Thành công');
          const index = this.lstUser.findIndex((x) => x.id === item.user_id);
          if (index > -1) {
            item.extend_receiver_email_full_name =
              this.lstUser[index].extend_user_full_name +
              '(' +
              this.lstUser[index].email +
              ')';
            item.extend_receiver_avatar = this.lstUser[index].avatar;
          }

          item.edit = false;
        } else {
          this.toastr.error(res.message, 'Thao tác không thành công');
        }
      });
  }

  paginatorChange(event?) {
    this.paging.pageNo = event;
    this.paging = {...this.paging};
    this.getShare();
  }

  getListUser(filter = '', pageNo = this.pageNo) {
    this.pageNo = pageNo;
    this.comboBoxLoading = true;
    this.userService
      .apiUsersGet(filter, '', this.pageNo, this.pageSize)
      .pipe(
        map((event) => event),
        debounceTime(1000)
      )
      .subscribe((res) => {
        if (res.success) {
          this.lstUser = this.lstUser.concat(res.data.collection);
        } else {
          this.toastr.error(res.message, 'Thao tác không thành công');
        }
        this.comboBoxLoading = false;
      });
  }
}
