<div perfectScrollbar [ngStyle]="{'max-height': '50vh'}" class="kt-timeline-v2">
	<div class="kt-timeline-v2__items kt-padding-top-25 kt-padding-bottom-30">
		<ng-container *ngFor="let item of data">
			<ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{item: item}"></ng-container>
		</ng-container>
	</div>
</div>

<ng-template #itemTemplate let-item="item">
	<div class="kt-timeline-v2__item">
		<span class="kt-timeline-v2__item-time">{{item.time}}</span>
		<div class="kt-timeline-v2__item-cricle">
			<i [ngClass]="item.icon ? item.icon :'fa fa-genderless'"></i>
		</div>
		<div class="kt-timeline-v2__item-text kt-padding-top-5" [innerHTML]="item.text"></div>

		<div class="kt-list-pics kt-list-pics--sm kt-padding-l-20" [innerHTML]="item.attachment"></div>
	</div>
</ng-template>
