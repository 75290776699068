import { NgModule, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { ApprovedStatusService } from './api/approvedStatus.service';
import { AttachmentService } from './api/attachment.service';
import { AuthenticateService } from './api/authenticate.service';
import { BackgroundJobService } from './api/backgroundJob.service';
import { CommentService } from './api/comment.service';
import { CompanyInfoService } from './api/companyInfo.service';
import { DbdBoardService } from './api/dbdBoard.service';
import { DbdBoardShareService } from './api/dbdBoardShare.service';
import { DbdSectionService } from './api/dbdSection.service';
import { DbdWidgetCollectionService } from './api/dbdWidgetCollection.service';
import { DebugService } from './api/debug.service';
import { EzLogService } from './api/ezLog.service';
import { FcmTokenService } from './api/fcmToken.service';
import { FileDownloadMngtService } from './api/fileDownloadMngt.service';
import { HrDegreeCategoryService } from './api/hrDegreeCategory.service';
import { HrMajorService } from './api/hrMajor.service';
import { MailMergeService } from './api/mailMerge.service';
import { NotificationService } from './api/notification.service';
import { OfficeService } from './api/office.service';
import { OfficeTypeService } from './api/officeType.service';
import { OfficeUsersService } from './api/officeUsers.service';
import { PRoleService } from './api/pRole.service';
import { ProductService } from './api/product.service';
import { QuotationService } from './api/quotation.service';
import { TestInstanceService } from './api/testInstance.service';
import { TimeUnitService } from './api/timeUnit.service';
import { UserBankService } from './api/userBank.service';
import { UserCertificationService } from './api/userCertification.service';
import { UserDegreeService } from './api/userDegree.service';
import { UsersService } from './api/users.service';
import { WorkflowService } from './api/workflow.service';
import { WorkflowBookmarkService } from './api/workflowBookmark.service';
import { WorkflowCouncilService } from './api/workflowCouncil.service';
import { WorkflowFilterService } from './api/workflowFilter.service';
import { WorkflowIndustryService } from './api/workflowIndustry.service';
import { WorkflowItemService } from './api/workflowItem.service';
import { WorkflowItemAnotherCostService } from './api/workflowItemAnotherCost.service';
import { WorkflowItemAttachmentDataService } from './api/workflowItemAttachmentData.service';
import { WorkflowItemCancelRequestService } from './api/workflowItemCancelRequest.service';
import { WorkflowItemCategoryService } from './api/workflowItemCategory.service';
import { WorkflowItemContractService } from './api/workflowItemContract.service';
import { WorkflowItemContractAddendunService } from './api/workflowItemContractAddendun.service';
import { WorkflowItemContractAttachmentService } from './api/workflowItemContractAttachment.service';
import { WorkflowItemContractBiddingPlanService } from './api/workflowItemContractBiddingPlan.service';
import { WorkflowItemContractBiddingPlanAttachmentService } from './api/workflowItemContractBiddingPlanAttachment.service';
import { WorkflowItemContractExpenseWaveService } from './api/workflowItemContractExpenseWave.service';
import { WorkflowItemContractModificationRequestService } from './api/workflowItemContractModificationRequest.service';
import { WorkflowItemContractProResService } from './api/workflowItemContractProRes.service';
import { WorkflowItemContractProductService } from './api/workflowItemContractProduct.service';
import { WorkflowItemContractProgressReportService } from './api/workflowItemContractProgressReport.service';
import { WorkflowItemContractProgressReportAssetService } from './api/workflowItemContractProgressReportAsset.service';
import { WorkflowItemContractResultService } from './api/workflowItemContractResult.service';
import { WorkflowItemContractSettlementWaveService } from './api/workflowItemContractSettlementWave.service';
import { WorkflowItemCouncilService } from './api/workflowItemCouncil.service';
import { WorkflowItemFieldDataService } from './api/workflowItemFieldData.service';
import { WorkflowItemSettlementFoundingRoundService } from './api/workflowItemSettlementFoundingRound.service';
import { WorkflowStatisticsService } from './api/workflowStatistics.service';
import { WorkflowTreeService } from './api/workflowTree.service';
import { WorkflowUserCouncilPositionService } from './api/workflowUserCouncilPosition.service';
import { WorkingShiftService } from './api/workingShift.service';
import { UsersSsoService } from './api/usersSso.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    ApprovedStatusService,
    AttachmentService,
    AuthenticateService,
    BackgroundJobService,
    CommentService,
    CompanyInfoService,
    DbdBoardService,
    DbdBoardShareService,
    DbdSectionService,
    DbdWidgetCollectionService,
    DebugService,
    EzLogService,
    FcmTokenService,
    FileDownloadMngtService,
    HrDegreeCategoryService,
    HrMajorService,
    MailMergeService,
    NotificationService,
    OfficeService,
    OfficeTypeService,
    OfficeUsersService,
    PRoleService,
    ProductService,
    QuotationService,
    TestInstanceService,
    TimeUnitService,
    UserBankService,
    UserCertificationService,
    UserDegreeService,
    UsersService,
    UsersSsoService,
    WorkflowService,
    WorkflowBookmarkService,
    WorkflowCouncilService,
    WorkflowFilterService,
    WorkflowIndustryService,
    WorkflowItemService,
    WorkflowItemAnotherCostService,
    WorkflowItemAttachmentDataService,
    WorkflowItemCancelRequestService,
    WorkflowItemCategoryService,
    WorkflowItemContractService,
    WorkflowItemContractAddendunService,
    WorkflowItemContractAttachmentService,
    WorkflowItemContractBiddingPlanService,
    WorkflowItemContractBiddingPlanAttachmentService,
    WorkflowItemContractExpenseWaveService,
    WorkflowItemContractModificationRequestService,
    WorkflowItemContractProResService,
    WorkflowItemContractProductService,
    WorkflowItemContractProgressReportService,
    WorkflowItemContractProgressReportAssetService,
    WorkflowItemContractResultService,
    WorkflowItemContractSettlementWaveService,
    WorkflowItemCouncilService,
    WorkflowItemFieldDataService,
    WorkflowItemSettlementFoundingRoundService,
    WorkflowStatisticsService,
    WorkflowTreeService,
    WorkflowUserCouncilPositionService,
    WorkingShiftService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): any {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
