<div class="meu-year-picker-component">
  <meu-year-picker-component
    *ngIf="mode === 'YEAR'"
    [formControl]="_yearPickerCtrl"
    [jpCustomFormFieldClass]="jpCustomFormFieldClass"
    [label]="label"
    [max]="max"
    [min]="min"
    [touchUi]="touchUi"
  >
  </meu-year-picker-component>
</div>
