import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';

@Component({
  selector: 'meu-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit, AfterViewInit {
  @Input() name: string;
  @Input() title: string;
  @Input() placeholderText: string;
  @Input() formControl: FormControl;
  @Input() invalidState: boolean;
  @Input() iconClass: string;
  @Input() maximumLength: number;
  @Input() minimumLength: number;
  @Input() numberKeypadOn: boolean;
  @Input() classInput: string;
  @Input() disabled: boolean;
  @Input() type: string = 'text';
  @Input() currency: boolean = false;
  @Input() readonly: boolean = false;
  @Output() iconClick = new EventEmitter();
  @Input() adjustFont: boolean;
  @Input() textarea: boolean;
  @Input() minRows: number = 2;
  pattern: string;
  inputType: string;

  @Input() isRequiredField: boolean = false;

  @ViewChild('focusName') focusName: ElementRef;
  ngOnInit(): void {
    if (this.numberKeypadOn) {
      this.pattern = '[0-9]*';
      this.inputType = 'numeric';
    } else {
      this.pattern = '';
      this.inputType = 'text';
    }
  }
  ngAfterViewInit(): void {
    const validator = this.formControl?.validator({} as AbstractControl);
    this.isRequiredField = validator && validator?.required;
  }
  focusInput() {
    this.focusName.nativeElement.focus();
  }
}
