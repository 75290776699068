import {Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfirmService {
  // private modalConfirmComponent: ModalConfirmComponent = new ModalConfirmComponent;
  open$ = new ReplaySubject<any>(1);
  constructor() {}
  deleteConfirm(title, content, confirmOption?, option?) {
    this.open$.next({
      title,
      content,
      confirmOption,
      option,
    });
  }
  readMeConfirm(title, content, confirmOption?, option?) {
    this.open$.next({
      title,
      content,
      confirmOption,
      option,
    });
  }
  close() {
    this.open$.next(null);
  }
}
