/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

 import { Inject, Injectable, Optional }                      from '@angular/core';
 import { HttpClient, HttpHeaders, HttpParams,
          HttpResponse, HttpEvent }                           from '@angular/common/http';
 import { CustomHttpUrlEncodingCodec }                        from '../encoder';
 
 import { Observable }                                        from 'rxjs';
 
 
 import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
 import { Configuration }                                     from '../configuration';
 
 
 @Injectable()
 export class WorkflowItemContractProgressReportAssetService {
 
     protected basePath = 'https://gateway.dev.meu-solutions.com/sciencetechnology';
     public defaultHeaders = new HttpHeaders();
     public configuration = new Configuration();
 
     constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
         if (basePath) {
             this.basePath = basePath;
         }
         if (configuration) {
             this.configuration = configuration;
             this.basePath = basePath || configuration.basePath || this.basePath;
         }
     }
 
     /**
      * @param consumes string[] mime-types
      * @return true: consumes contains 'multipart/form-data', false: otherwise
      */
     private canConsumeForm(consumes: string[]): boolean {
         const form = 'multipart/form-data';
         for (const consume of consumes) {
             if (form === consume) {
                 return true;
             }
         }
         return false;
     }
 
 
     /**
      * 
      * 
      * @param contractId 
      * @param file 
      * @param code 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiWorkflowItemContractAssetsContractIdApproveLiquidationPut(contractId: string, file?: Blob, code?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public apiWorkflowItemContractAssetsContractIdApproveLiquidationPut(contractId: string, file?: Blob, code?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public apiWorkflowItemContractAssetsContractIdApproveLiquidationPut(contractId: string, file?: Blob, code?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public apiWorkflowItemContractAssetsContractIdApproveLiquidationPut(contractId: string, file?: Blob, code?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (contractId === null || contractId === undefined) {
             throw new Error('Required parameter contractId was null or undefined when calling apiWorkflowItemContractAssetsContractIdApproveLiquidationPut.');
         }
 
 
 
         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (code !== undefined && code !== null) {
             queryParameters = queryParameters.set('code', <any>code);
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'multipart/form-data'
         ];
 
         const canConsumeForm = this.canConsumeForm(consumes);
 
         let formParams: { append(param: string, value: any): void; };
         let useForm = false;
         let convertFormParamsToString = false;
         // use FormData to transmit files using content-type "multipart/form-data"
         // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
         useForm = canConsumeForm;
         if (useForm) {
             formParams = new FormData();
         } else {
             formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         }
 
         if (file !== undefined) {
             formParams = formParams.append('file', <any>file) as any || formParams;
         }
 
         return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractAssets/${encodeURIComponent(String(contractId))}/approveLiquidation`,
             {
                 body: convertFormParamsToString ? formParams.toString() : formParams,
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param contractId 
      * @param filename 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiWorkflowItemContractAssetsContractIdFilenameDownloadPhysicalTemplateGet(contractId: string, filename: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
     public apiWorkflowItemContractAssetsContractIdFilenameDownloadPhysicalTemplateGet(contractId: string, filename: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
     public apiWorkflowItemContractAssetsContractIdFilenameDownloadPhysicalTemplateGet(contractId: string, filename: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
     public apiWorkflowItemContractAssetsContractIdFilenameDownloadPhysicalTemplateGet(contractId: string, filename: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (contractId === null || contractId === undefined) {
             throw new Error('Required parameter contractId was null or undefined when calling apiWorkflowItemContractAssetsContractIdFilenameDownloadPhysicalTemplateGet.');
         }
 
         if (filename === null || filename === undefined) {
             throw new Error('Required parameter filename was null or undefined when calling apiWorkflowItemContractAssetsContractIdFilenameDownloadPhysicalTemplateGet.');
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflowItemContractAssets/${encodeURIComponent(String(contractId))}/${encodeURIComponent(String(filename))}/downloadPhysicalTemplate`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
         responseType: 'blob' as 'json',
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param contractId 
      * @param filename 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiWorkflowItemContractAssetsContractIdFilenamePrintPhysicalTemplateGet(contractId: string, filename: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
     public apiWorkflowItemContractAssetsContractIdFilenamePrintPhysicalTemplateGet(contractId: string, filename: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
     public apiWorkflowItemContractAssetsContractIdFilenamePrintPhysicalTemplateGet(contractId: string, filename: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
     public apiWorkflowItemContractAssetsContractIdFilenamePrintPhysicalTemplateGet(contractId: string, filename: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (contractId === null || contractId === undefined) {
             throw new Error('Required parameter contractId was null or undefined when calling apiWorkflowItemContractAssetsContractIdFilenamePrintPhysicalTemplateGet.');
         }
 
         if (filename === null || filename === undefined) {
             throw new Error('Required parameter filename was null or undefined when calling apiWorkflowItemContractAssetsContractIdFilenamePrintPhysicalTemplateGet.');
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflowItemContractAssets/${encodeURIComponent(String(contractId))}/${encodeURIComponent(String(filename))}/printPhysicalTemplate`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
         responseType: 'blob' as 'json',
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param contractId 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiWorkflowItemContractAssetsContractIdGetAllGet(contractId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public apiWorkflowItemContractAssetsContractIdGetAllGet(contractId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public apiWorkflowItemContractAssetsContractIdGetAllGet(contractId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public apiWorkflowItemContractAssetsContractIdGetAllGet(contractId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (contractId === null || contractId === undefined) {
             throw new Error('Required parameter contractId was null or undefined when calling apiWorkflowItemContractAssetsContractIdGetAllGet.');
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItemContractAssets/${encodeURIComponent(String(contractId))}/getAll`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param contractId 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiWorkflowItemContractAssetsContractIdSendNotificationGet(contractId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public apiWorkflowItemContractAssetsContractIdSendNotificationGet(contractId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public apiWorkflowItemContractAssetsContractIdSendNotificationGet(contractId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public apiWorkflowItemContractAssetsContractIdSendNotificationGet(contractId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (contractId === null || contractId === undefined) {
             throw new Error('Required parameter contractId was null or undefined when calling apiWorkflowItemContractAssetsContractIdSendNotificationGet.');
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItemContractAssets/${encodeURIComponent(String(contractId))}/sendNotification`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param contractId 
      * @param file 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiWorkflowItemContractAssetsContractIdSubmitLiquidationPut(contractId: string, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public apiWorkflowItemContractAssetsContractIdSubmitLiquidationPut(contractId: string, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public apiWorkflowItemContractAssetsContractIdSubmitLiquidationPut(contractId: string, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public apiWorkflowItemContractAssetsContractIdSubmitLiquidationPut(contractId: string, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (contractId === null || contractId === undefined) {
             throw new Error('Required parameter contractId was null or undefined when calling apiWorkflowItemContractAssetsContractIdSubmitLiquidationPut.');
         }
 
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'multipart/form-data'
         ];
 
         const canConsumeForm = this.canConsumeForm(consumes);
 
         let formParams: { append(param: string, value: any): void; };
         let useForm = false;
         let convertFormParamsToString = false;
         // use FormData to transmit files using content-type "multipart/form-data"
         // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
         useForm = canConsumeForm;
         if (useForm) {
             formParams = new FormData();
         } else {
             formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         }
 
         if (file !== undefined) {
             formParams = formParams.append('file', <any>file) as any || formParams;
         }
 
         return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractAssets/${encodeURIComponent(String(contractId))}/submitLiquidation`,
             {
                 body: convertFormParamsToString ? formParams.toString() : formParams,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param contractId 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiWorkflowItemContractAssetsContractIdSubmitProcessAssetPut(contractId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public apiWorkflowItemContractAssetsContractIdSubmitProcessAssetPut(contractId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public apiWorkflowItemContractAssetsContractIdSubmitProcessAssetPut(contractId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public apiWorkflowItemContractAssetsContractIdSubmitProcessAssetPut(contractId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (contractId === null || contractId === undefined) {
             throw new Error('Required parameter contractId was null or undefined when calling apiWorkflowItemContractAssetsContractIdSubmitProcessAssetPut.');
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractAssets/${encodeURIComponent(String(contractId))}/submitProcessAsset`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param contractId 
      * @param userId 
      * @param file 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiWorkflowItemContractAssetsContractIdUploadFileLiquidationDocumentUserIdPut(contractId: string, userId: string, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public apiWorkflowItemContractAssetsContractIdUploadFileLiquidationDocumentUserIdPut(contractId: string, userId: string, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public apiWorkflowItemContractAssetsContractIdUploadFileLiquidationDocumentUserIdPut(contractId: string, userId: string, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public apiWorkflowItemContractAssetsContractIdUploadFileLiquidationDocumentUserIdPut(contractId: string, userId: string, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (contractId === null || contractId === undefined) {
             throw new Error('Required parameter contractId was null or undefined when calling apiWorkflowItemContractAssetsContractIdUploadFileLiquidationDocumentUserIdPut.');
         }
 
         if (userId === null || userId === undefined) {
             throw new Error('Required parameter userId was null or undefined when calling apiWorkflowItemContractAssetsContractIdUploadFileLiquidationDocumentUserIdPut.');
         }
 
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'multipart/form-data'
         ];
 
         const canConsumeForm = this.canConsumeForm(consumes);
 
         let formParams: { append(param: string, value: any): void; };
         let useForm = false;
         let convertFormParamsToString = false;
         // use FormData to transmit files using content-type "multipart/form-data"
         // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
         useForm = canConsumeForm;
         if (useForm) {
             formParams = new FormData();
         } else {
             formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         }
 
         if (file !== undefined) {
             formParams = formParams.append('file', <any>file) as any || formParams;
         }
 
         return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractAssets/${encodeURIComponent(String(contractId))}/uploadFileLiquidationDocument/${encodeURIComponent(String(userId))}`,
             {
                 body: convertFormParamsToString ? formParams.toString() : formParams,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param id 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiWorkflowItemContractAssetsIdDownloadGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
     public apiWorkflowItemContractAssetsIdDownloadGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
     public apiWorkflowItemContractAssetsIdDownloadGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
     public apiWorkflowItemContractAssetsIdDownloadGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (id === null || id === undefined) {
             throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractAssetsIdDownloadGet.');
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflowItemContractAssets/${encodeURIComponent(String(id))}/download`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
         responseType: 'blob' as 'json',
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param id 
      * @param file 
      * @param liquidation_report_number 
      * @param liquidation_report_signed_time 
      * @param budget_funding 
      * @param other_cost 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiWorkflowItemContractAssetsIdUploadFilePut(id: string, file?: Blob, liquidation_report_number?: string, liquidation_report_signed_time?: string, budget_funding?: number, other_cost?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public apiWorkflowItemContractAssetsIdUploadFilePut(id: string, file?: Blob, liquidation_report_number?: string, liquidation_report_signed_time?: string, budget_funding?: number, other_cost?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public apiWorkflowItemContractAssetsIdUploadFilePut(id: string, file?: Blob, liquidation_report_number?: string, liquidation_report_signed_time?: string, budget_funding?: number, other_cost?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public apiWorkflowItemContractAssetsIdUploadFilePut(id: string, file?: Blob, liquidation_report_number?: string, liquidation_report_signed_time?: string, budget_funding?: number, other_cost?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (id === null || id === undefined) {
             throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractAssetsIdUploadFilePut.');
         }
 
 
 
 
 
 
         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (liquidation_report_number !== undefined && liquidation_report_number !== null) {
             queryParameters = queryParameters.set('liquidation_report_number', <any>liquidation_report_number);
         }
         if (liquidation_report_signed_time !== undefined && liquidation_report_signed_time !== null) {
             queryParameters = queryParameters.set('liquidation_report_signed_time', <any>liquidation_report_signed_time);
         }
         if (budget_funding !== undefined && budget_funding !== null) {
             queryParameters = queryParameters.set('budget_funding', <any>budget_funding);
         }
         if (other_cost !== undefined && other_cost !== null) {
             queryParameters = queryParameters.set('other_cost', <any>other_cost);
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'multipart/form-data'
         ];
 
         const canConsumeForm = this.canConsumeForm(consumes);
 
         let formParams: { append(param: string, value: any): void; };
         let useForm = false;
         let convertFormParamsToString = false;
         // use FormData to transmit files using content-type "multipart/form-data"
         // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
         useForm = canConsumeForm;
         if (useForm) {
             formParams = new FormData();
         } else {
             formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         }
 
         if (file !== undefined) {
             formParams = formParams.append('file', <any>file) as any || formParams;
         }
 
         return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractAssets/${encodeURIComponent(String(id))}/uploadFile`,
             {
                 body: convertFormParamsToString ? formParams.toString() : formParams,
                 params: queryParameters,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
 } 