import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { User } from '../model/user';
import { UserDegree } from '../model/userDegree';
import { UserStatusHistory } from '../model/userStatusHistory';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UsersSsoService {

    protected basePath = 'https://gateway.dev.meu-solutions.com/sciencetechnologysso';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
    }

    /**
     * 
     * 
     * @param username 
     * @param oldPassword 
     * @param newPassword 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersChangePasswordPost(username?: string, oldPassword?: string, newPassword?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiUsersChangePasswordPost(username?: string, oldPassword?: string, newPassword?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiUsersChangePasswordPost(username?: string, oldPassword?: string, newPassword?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiUsersChangePasswordPost(username?: string, oldPassword?: string, newPassword?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (username !== undefined && username !== null) {
            queryParameters = queryParameters.set('username', <any>username);
        }
        if (oldPassword !== undefined && oldPassword !== null) {
            queryParameters = queryParameters.set('oldPassword', <any>oldPassword);
        }
        if (newPassword !== undefined && newPassword !== null) {
            queryParameters = queryParameters.set('newPassword', <any>newPassword);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/users/changePassword`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param username 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersForgotPasswordPost(username?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiUsersForgotPasswordPost(username?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiUsersForgotPasswordPost(username?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiUsersForgotPasswordPost(username?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (username !== undefined && username !== null) {
            queryParameters = queryParameters.set('username', <any>username);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/users/forgotPassword`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersPost(body?: User, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiUsersPost(body?: User, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiUsersPost(body?: User, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiUsersPost(body?: User, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/users`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param username 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersRequestSetNewPasswordPost(username?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiUsersRequestSetNewPasswordPost(username?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiUsersRequestSetNewPasswordPost(username?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiUsersRequestSetNewPasswordPost(username?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (username !== undefined && username !== null) {
            queryParameters = queryParameters.set('username', <any>username);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/users/requestSetNewPassword`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param newPassword 
     * @param secretkey 
     * @param sendEmail 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersSetPasswordPut(newPassword?: string, secretkey?: string, sendEmail?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiUsersSetPasswordPut(newPassword?: string, secretkey?: string, sendEmail?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiUsersSetPasswordPut(newPassword?: string, secretkey?: string, sendEmail?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiUsersSetPasswordPut(newPassword?: string, secretkey?: string, sendEmail?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (newPassword !== undefined && newPassword !== null) {
            queryParameters = queryParameters.set('newPassword', <any>newPassword);
        }
        if (secretkey !== undefined && secretkey !== null) {
            queryParameters = queryParameters.set('secretkey', <any>secretkey);
        }
        if (sendEmail !== undefined && sendEmail !== null) {
            queryParameters = queryParameters.set('sendEmail', <any>sendEmail);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/users/setPassword`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUsersIdPut(id: string, body?: any, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiUsersIdPut(id: string, body?: any, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiUsersIdPut(id: string, body?: any, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiUsersIdPut(id: string, body?: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiUsersIdPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/users/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
}