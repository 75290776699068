/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import {CustomHttpUrlEncodingCodec} from '../encoder';

import {Observable} from 'rxjs';

import {Inspection} from '../model/inspection';
import {InspectionLiquid} from '../model/inspectionLiquid';
import {Signature} from '../model/signature';
import {SummaryAssessment} from '../model/summaryAssessment';
import {SummaryCommercial} from '../model/summaryCommercial';
import {WorkflowItemContractProgressReport} from '../model/workflowItemContractProgressReport';

import {BASE_PATH, COLLECTION_FORMATS} from '../variables';
import {Configuration} from '../configuration';

@Injectable()
export class WorkflowItemContractProgressReportService {
    protected basePath = 'https://gateway.dev.meu-solutions.com/sciencetechnology';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
    * @param consumes string[] mime-types
    * @return true: consumes contains 'multipart/form-data', false: otherwise
    */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractProgressReportAndCouncilAttachmentIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportAndCouncilAttachmentIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportAndCouncilAttachmentIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportAndCouncilAttachmentIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractProgressReportAndCouncilAttachmentIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItemContractProgressReportAndCouncilAttachment/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param contractID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractProgressReportContractIDGetByContractGet(contractID: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportContractIDGetByContractGet(contractID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportContractIDGetByContractGet(contractID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportContractIDGetByContractGet(contractID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (contractID === null || contractID === undefined) {
            throw new Error('Required parameter contractID was null or undefined when calling apiWorkflowItemContractProgressReportContractIDGetByContractGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(contractID))}/getByContract`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param contractID
    * @param id
    * @param getDocx
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportContractIDIdFormsPrintTemplateGet(contractID: string, id: string, getDocx?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowItemContractProgressReportContractIDIdFormsPrintTemplateGet(contractID: string, id: string, getDocx?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowItemContractProgressReportContractIDIdFormsPrintTemplateGet(contractID: string, id: string, getDocx?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowItemContractProgressReportContractIDIdFormsPrintTemplateGet(contractID: string, id: string, getDocx?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (contractID === null || contractID === undefined) {
            throw new Error('Required parameter contractID was null or undefined when calling apiWorkflowItemContractProgressReportContractIDIdFormsPrintTemplateGet.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractProgressReportContractIDIdFormsPrintTemplateGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (getDocx !== undefined && getDocx !== null) {
            queryParameters = queryParameters.set('getDocx', <any>getDocx);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(contractID))}/${encodeURIComponent(String(id))}/forms/printTemplate`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
    responseType: 'blob' as 'json',
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param type
    * @param isLiquid
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportDownloadReportTypeGet(type: string, isLiquid?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowItemContractProgressReportDownloadReportTypeGet(type: string, isLiquid?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowItemContractProgressReportDownloadReportTypeGet(type: string, isLiquid?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowItemContractProgressReportDownloadReportTypeGet(type: string, isLiquid?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling apiWorkflowItemContractProgressReportDownloadReportTypeGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (isLiquid !== undefined && isLiquid !== null) {
            queryParameters = queryParameters.set('isLiquid', <any>isLiquid);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflowItemContractProgressReport/downloadReport/${encodeURIComponent(String(type))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
    responseType: 'blob' as 'json',
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param finalApproveID
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportFinalApproveIDManageRejectPut(finalApproveID: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportFinalApproveIDManageRejectPut(finalApproveID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportFinalApproveIDManageRejectPut(finalApproveID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportFinalApproveIDManageRejectPut(finalApproveID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (finalApproveID === null || finalApproveID === undefined) {
            throw new Error('Required parameter finalApproveID was null or undefined when calling apiWorkflowItemContractProgressReportFinalApproveIDManageRejectPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(finalApproveID))}/manageReject`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param finalApproveID
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportFinalApproveIDManageSignLiquidationPut(finalApproveID: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportFinalApproveIDManageSignLiquidationPut(finalApproveID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportFinalApproveIDManageSignLiquidationPut(finalApproveID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportFinalApproveIDManageSignLiquidationPut(finalApproveID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (finalApproveID === null || finalApproveID === undefined) {
            throw new Error('Required parameter finalApproveID was null or undefined when calling apiWorkflowItemContractProgressReportFinalApproveIDManageSignLiquidationPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(finalApproveID))}/manageSign/liquidation`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param finalApproveID
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportFinalApproveIDManageSignPut(finalApproveID: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportFinalApproveIDManageSignPut(finalApproveID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportFinalApproveIDManageSignPut(finalApproveID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportFinalApproveIDManageSignPut(finalApproveID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (finalApproveID === null || finalApproveID === undefined) {
            throw new Error('Required parameter finalApproveID was null or undefined when calling apiWorkflowItemContractProgressReportFinalApproveIDManageSignPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(finalApproveID))}/manageSign`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param id
    * @param cousilID
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportIdChooseCousilPut(id: string, cousilID?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportIdChooseCousilPut(id: string, cousilID?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportIdChooseCousilPut(id: string, cousilID?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportIdChooseCousilPut(id: string, cousilID?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractProgressReportIdChooseCousilPut.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (cousilID !== undefined && cousilID !== null) {
            queryParameters = queryParameters.set('cousilID', <any>cousilID);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(id))}/chooseCousil`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param id
    * @param cousilID
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportIdChooseLiquidationCousilPut(id: string, cousilID?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportIdChooseLiquidationCousilPut(id: string, cousilID?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportIdChooseLiquidationCousilPut(id: string, cousilID?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportIdChooseLiquidationCousilPut(id: string, cousilID?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractProgressReportIdChooseLiquidationCousilPut.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (cousilID !== undefined && cousilID !== null) {
            queryParameters = queryParameters.set('cousilID', <any>cousilID);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(id))}/chooseLiquidationCousil`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param id
    * @param atachment
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportIdCompletedLiquidationPut(id: string, atachment?: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportIdCompletedLiquidationPut(id: string, atachment?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportIdCompletedLiquidationPut(id: string, atachment?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportIdCompletedLiquidationPut(id: string, atachment?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractProgressReportIdCompletedLiquidationPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (atachment !== undefined) {
            formParams = formParams.append('atachment', <any>atachment) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(id))}/completed/liquidation`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param id
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportIdCompletedPut(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportIdCompletedPut(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportIdCompletedPut(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportIdCompletedPut(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractProgressReportIdCompletedPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(id))}/completed`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param id
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportIdDelete(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportIdDelete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportIdDelete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportIdDelete(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractProgressReportIdDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param id
    * @param expectedSubmitDate
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportIdDoneEditPut(id: string, expectedSubmitDate?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportIdDoneEditPut(id: string, expectedSubmitDate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportIdDoneEditPut(id: string, expectedSubmitDate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportIdDoneEditPut(id: string, expectedSubmitDate?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractProgressReportIdDoneEditPut.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (expectedSubmitDate !== undefined && expectedSubmitDate !== null) {
            queryParameters = queryParameters.set('expectedSubmitDate', <any>expectedSubmitDate.toISOString());
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(id))}/doneEdit`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param id
    * @param isConfirmAttachment
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportIdDownloadGet(id: string, isConfirmAttachment?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowItemContractProgressReportIdDownloadGet(id: string, isConfirmAttachment?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowItemContractProgressReportIdDownloadGet(id: string, isConfirmAttachment?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowItemContractProgressReportIdDownloadGet(id: string, isConfirmAttachment?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractProgressReportIdDownloadGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (isConfirmAttachment !== undefined && isConfirmAttachment !== null) {
            queryParameters = queryParameters.set('isConfirmAttachment', <any>isConfirmAttachment);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(id))}/download`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
    responseType: 'blob' as 'json',
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param id
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportIdDownloadSubfileGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowItemContractProgressReportIdDownloadSubfileGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowItemContractProgressReportIdDownloadSubfileGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowItemContractProgressReportIdDownloadSubfileGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractProgressReportIdDownloadSubfileGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(id))}/downloadSubfile`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
    responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param id
    * @param body
    * @param isDraft
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportIdEditPut(id: string, body?: WorkflowItemContractProgressReport, isDraft?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportIdEditPut(id: string, body?: WorkflowItemContractProgressReport, isDraft?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportIdEditPut(id: string, body?: WorkflowItemContractProgressReport, isDraft?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportIdEditPut(id: string, body?: WorkflowItemContractProgressReport, isDraft?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractProgressReportIdEditPut.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (isDraft !== undefined && isDraft !== null) {
            queryParameters = queryParameters.set('isDraft', <any>isDraft);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(id))}/edit`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param id
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractProgressReportIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractProgressReportIdPut(id: string, body?: WorkflowItemContractProgressReport, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportIdPut(id: string, body?: WorkflowItemContractProgressReport, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportIdPut(id: string, body?: WorkflowItemContractProgressReport, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportIdPut(id: string, body?: WorkflowItemContractProgressReport, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractProgressReportIdPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param reason 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractProgressReportIdRejectedPut(id: string, reason?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportIdRejectedPut(id: string, reason?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportIdRejectedPut(id: string, reason?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportIdRejectedPut(id: string, reason?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractProgressReportIdRejectedPut.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (reason !== undefined && reason !== null) {
            queryParameters = queryParameters.set('reason', <any>reason);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(id))}/rejected`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param id
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportIdRemoveSubfileDelete(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportIdRemoveSubfileDelete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportIdRemoveSubfileDelete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportIdRemoveSubfileDelete(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractProgressReportIdRemoveSubfileDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(id))}/removeSubfile`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param id
    * @param expectedSubmitDate
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportIdRequestEditPut(id: string, expectedSubmitDate?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportIdRequestEditPut(id: string, expectedSubmitDate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportIdRequestEditPut(id: string, expectedSubmitDate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportIdRequestEditPut(id: string, expectedSubmitDate?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractProgressReportIdRequestEditPut.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (expectedSubmitDate !== undefined && expectedSubmitDate !== null) {
            queryParameters = queryParameters.set('expectedSubmitDate', <any>expectedSubmitDate.toISOString());
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(id))}/requestEdit`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param id
    * @param isCompleted
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportIdSignPut(id: string, isCompleted?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportIdSignPut(id: string, isCompleted?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportIdSignPut(id: string, isCompleted?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportIdSignPut(id: string, isCompleted?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractProgressReportIdSignPut.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (isCompleted !== undefined && isCompleted !== null) {
            queryParameters = queryParameters.set('isCompleted', <any>isCompleted);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(id))}/sign`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param id
    * @param file
    * @param title
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportIdUpdateSubfilePut(id: string, file?: Blob, title?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportIdUpdateSubfilePut(id: string, file?: Blob, title?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportIdUpdateSubfilePut(id: string, file?: Blob, title?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportIdUpdateSubfilePut(id: string, file?: Blob, title?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractProgressReportIdUpdateSubfilePut.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (title !== undefined && title !== null) {
            queryParameters = queryParameters.set('title', <any>title);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(id))}/updateSubfile`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param id
    * @param file
    * @param isConfirmAttachment
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportIdUploadPost(id: string, file?: Blob, isConfirmAttachment?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportIdUploadPost(id: string, file?: Blob, isConfirmAttachment?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportIdUploadPost(id: string, file?: Blob, isConfirmAttachment?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportIdUploadPost(id: string, file?: Blob, isConfirmAttachment?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractProgressReportIdUploadPost.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (isConfirmAttachment !== undefined && isConfirmAttachment !== null) {
            queryParameters = queryParameters.set('isConfirmAttachment', <any>isConfirmAttachment);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(id))}/upload`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param progressReportID
    * @param receiverMail
    * @param resultNoti
    * @param reportDateIfSuccess
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportNotificationhubNotifyReportResultProgressReportIDReceiverMailPost(progressReportID: string, receiverMail: string, resultNoti?: Array<Blob>, reportDateIfSuccess?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportNotificationhubNotifyReportResultProgressReportIDReceiverMailPost(progressReportID: string, receiverMail: string, resultNoti?: Array<Blob>, reportDateIfSuccess?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportNotificationhubNotifyReportResultProgressReportIDReceiverMailPost(progressReportID: string, receiverMail: string, resultNoti?: Array<Blob>, reportDateIfSuccess?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportNotificationhubNotifyReportResultProgressReportIDReceiverMailPost(progressReportID: string, receiverMail: string, resultNoti?: Array<Blob>, reportDateIfSuccess?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (progressReportID === null || progressReportID === undefined) {
            throw new Error('Required parameter progressReportID was null or undefined when calling apiWorkflowItemContractProgressReportNotificationhubNotifyReportResultProgressReportIDReceiverMailPost.');
        }

        if (receiverMail === null || receiverMail === undefined) {
            throw new Error('Required parameter receiverMail was null or undefined when calling apiWorkflowItemContractProgressReportNotificationhubNotifyReportResultProgressReportIDReceiverMailPost.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (reportDateIfSuccess !== undefined && reportDateIfSuccess !== null) {
            queryParameters = queryParameters.set('reportDateIfSuccess', <any>reportDateIfSuccess.toISOString());
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        const formData = new FormData();

        if (resultNoti) {
            resultNoti.forEach((element) => {
            formData.append('resultNoti', element);
        });
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflowItemContractProgressReport/notificationhub/notifyReportResult/${encodeURIComponent(String(progressReportID))}/${encodeURIComponent(String(receiverMail))}`,
            {
                body: formData,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param progressReportID
    * @param receiverMail
    * @param remindFile
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportNotificationhubRemindToSubmitReportProgressReportIDReceiverMailPost(progressReportID: string, receiverMail: string, remindFile?: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportNotificationhubRemindToSubmitReportProgressReportIDReceiverMailPost(progressReportID: string, receiverMail: string, remindFile?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportNotificationhubRemindToSubmitReportProgressReportIDReceiverMailPost(progressReportID: string, receiverMail: string, remindFile?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportNotificationhubRemindToSubmitReportProgressReportIDReceiverMailPost(progressReportID: string, receiverMail: string, remindFile?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (progressReportID === null || progressReportID === undefined) {
            throw new Error('Required parameter progressReportID was null or undefined when calling apiWorkflowItemContractProgressReportNotificationhubRemindToSubmitReportProgressReportIDReceiverMailPost.');
        }

        if (receiverMail === null || receiverMail === undefined) {
            throw new Error('Required parameter receiverMail was null or undefined when calling apiWorkflowItemContractProgressReportNotificationhubRemindToSubmitReportProgressReportIDReceiverMailPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (remindFile !== undefined) {
            formParams = formParams.append('remindFile', <any>remindFile) as any || formParams;
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflowItemContractProgressReport/notificationhub/remindToSubmitReport/${encodeURIComponent(String(progressReportID))}/${encodeURIComponent(String(receiverMail))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param body
    * @param isDraff
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportPost(body?: WorkflowItemContractProgressReport, isDraff?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportPost(body?: WorkflowItemContractProgressReport, isDraff?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportPost(body?: WorkflowItemContractProgressReport, isDraff?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportPost(body?: WorkflowItemContractProgressReport, isDraff?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (isDraff !== undefined && isDraff !== null) {
            queryParameters = queryParameters.set('isDraff', <any>isDraff);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflowItemContractProgressReport`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param progresReportID
    * @param body
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportProgresReportIDEditReportLiquidPut(progresReportID: string, body?: InspectionLiquid, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportProgresReportIDEditReportLiquidPut(progresReportID: string, body?: InspectionLiquid, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportProgresReportIDEditReportLiquidPut(progresReportID: string, body?: InspectionLiquid, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportProgresReportIDEditReportLiquidPut(progresReportID: string, body?: InspectionLiquid, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (progresReportID === null || progresReportID === undefined) {
            throw new Error('Required parameter progresReportID was null or undefined when calling apiWorkflowItemContractProgressReportProgresReportIDEditReportLiquidPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(progresReportID))}/editReport/liquid`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param progresReportID
    * @param body
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportProgresReportIDEditReportPut(progresReportID: string, body?: Inspection, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportProgresReportIDEditReportPut(progresReportID: string, body?: Inspection, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportProgresReportIDEditReportPut(progresReportID: string, body?: Inspection, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportProgresReportIDEditReportPut(progresReportID: string, body?: Inspection, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (progresReportID === null || progresReportID === undefined) {
            throw new Error('Required parameter progresReportID was null or undefined when calling apiWorkflowItemContractProgressReportProgresReportIDEditReportPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(progresReportID))}/editReport`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param progresReportID
    * @param body
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportProgresReportIDEditReportSummaryAssessmentPut(progresReportID: string, body?: SummaryAssessment, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportProgresReportIDEditReportSummaryAssessmentPut(progresReportID: string, body?: SummaryAssessment, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportProgresReportIDEditReportSummaryAssessmentPut(progresReportID: string, body?: SummaryAssessment, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportProgresReportIDEditReportSummaryAssessmentPut(progresReportID: string, body?: SummaryAssessment, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (progresReportID === null || progresReportID === undefined) {
            throw new Error('Required parameter progresReportID was null or undefined when calling apiWorkflowItemContractProgressReportProgresReportIDEditReportSummaryAssessmentPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(progresReportID))}/editReport/summaryAssessment`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param progresReportID
    * @param body
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportProgresReportIDEditReportSummaryCommercialPut(progresReportID: string, body?: SummaryCommercial, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportProgresReportIDEditReportSummaryCommercialPut(progresReportID: string, body?: SummaryCommercial, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportProgresReportIDEditReportSummaryCommercialPut(progresReportID: string, body?: SummaryCommercial, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportProgresReportIDEditReportSummaryCommercialPut(progresReportID: string, body?: SummaryCommercial, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (progresReportID === null || progresReportID === undefined) {
            throw new Error('Required parameter progresReportID was null or undefined when calling apiWorkflowItemContractProgressReportProgresReportIDEditReportSummaryCommercialPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(progresReportID))}/editReport/summaryCommercial`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param progressReportID
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportProgressReportIDCompletedSubfilePut(progressReportID: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportProgressReportIDCompletedSubfilePut(progressReportID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportProgressReportIDCompletedSubfilePut(progressReportID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportProgressReportIDCompletedSubfilePut(progressReportID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (progressReportID === null || progressReportID === undefined) {
            throw new Error('Required parameter progressReportID was null or undefined when calling apiWorkflowItemContractProgressReportProgressReportIDCompletedSubfilePut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(progressReportID))}/completedSubfile`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param progressReportID
    * @param fileDanhgia
    * @param score
    * @param reason
    * @param userID
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportProgressReportIDCouncilLiquidationScorePut(progressReportID: string, fileDanhgia?: Blob, score?: number, reason?: string, userID?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportProgressReportIDCouncilLiquidationScorePut(progressReportID: string, fileDanhgia?: Blob, score?: number, reason?: string, userID?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportProgressReportIDCouncilLiquidationScorePut(progressReportID: string, fileDanhgia?: Blob, score?: number, reason?: string, userID?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportProgressReportIDCouncilLiquidationScorePut(progressReportID: string, fileDanhgia?: Blob, score?: number, reason?: string, userID?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (progressReportID === null || progressReportID === undefined) {
            throw new Error('Required parameter progressReportID was null or undefined when calling apiWorkflowItemContractProgressReportProgressReportIDCouncilLiquidationScorePut.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (score !== undefined && score !== null) {
            queryParameters = queryParameters.set('score', <any>score);
        }
        if (reason !== undefined && reason !== null) {
            queryParameters = queryParameters.set('reason', <any>reason);
        }
        if (userID !== undefined && userID !== null) {
            queryParameters = queryParameters.set('userID', <any>userID);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (fileDanhgia !== undefined) {
            formParams = formParams.append('fileDanhgia', <any>fileDanhgia) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(progressReportID))}/councilLiquidationScore`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param progressReportID
    * @param reason
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportProgressReportIDCouncilRejectPut(progressReportID: string, reason?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportProgressReportIDCouncilRejectPut(progressReportID: string, reason?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportProgressReportIDCouncilRejectPut(progressReportID: string, reason?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportProgressReportIDCouncilRejectPut(progressReportID: string, reason?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (progressReportID === null || progressReportID === undefined) {
            throw new Error('Required parameter progressReportID was null or undefined when calling apiWorkflowItemContractProgressReportProgressReportIDCouncilRejectPut.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (reason !== undefined && reason !== null) {
            queryParameters = queryParameters.set('reason', <any>reason);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(progressReportID))}/councilReject`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param progressReportID
    * @param fileDanhgia
    * @param score
    * @param userID
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportProgressReportIDCouncilScorePut(progressReportID: string, fileDanhgia?: Array<Blob>, score?: number, userID?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportProgressReportIDCouncilScorePut(progressReportID: string, fileDanhgia?: Array<Blob>, score?: number, userID?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportProgressReportIDCouncilScorePut(progressReportID: string, fileDanhgia?: Array<Blob>, score?: number, userID?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportProgressReportIDCouncilScorePut(progressReportID: string, fileDanhgia?: Array<Blob>, score?: number, userID?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (progressReportID === null || progressReportID === undefined) {
            throw new Error('Required parameter progressReportID was null or undefined when calling apiWorkflowItemContractProgressReportProgressReportIDCouncilScorePut.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (score !== undefined && score !== null) {
            queryParameters = queryParameters.set('score', <any>score);
        }
        if (userID !== undefined && userID !== null) {
            queryParameters = queryParameters.set('userID', <any>userID);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        const formData = new FormData();

        if (fileDanhgia) {
          fileDanhgia.forEach((element) => {
            formData.append('fileDanhgia', element);
          });
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(progressReportID))}/councilScore`,
            {
                body: formData,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param progressReportID
    * @param isLiquid
    * @param getDocx
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportProgressReportIDPrintReportGet(progressReportID: string, isLiquid?: boolean, getDocx?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowItemContractProgressReportProgressReportIDPrintReportGet(progressReportID: string, isLiquid?: boolean, getDocx?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowItemContractProgressReportProgressReportIDPrintReportGet(progressReportID: string, isLiquid?: boolean, getDocx?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowItemContractProgressReportProgressReportIDPrintReportGet(progressReportID: string, isLiquid?: boolean, getDocx?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (progressReportID === null || progressReportID === undefined) {
            throw new Error('Required parameter progressReportID was null or undefined when calling apiWorkflowItemContractProgressReportProgressReportIDPrintReportGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (isLiquid !== undefined && isLiquid !== null) {
            queryParameters = queryParameters.set('isLiquid', <any>isLiquid);
        }
        if (getDocx !== undefined && getDocx !== null) {
            queryParameters = queryParameters.set('getDocx', <any>getDocx);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(progressReportID))}/printReport`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
    responseType: 'blob' as 'json',
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param progressReportID
    * @param type
    * @param getDocx
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportProgressReportIDPrintReportTypeGet(progressReportID: string, type: string, getDocx?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowItemContractProgressReportProgressReportIDPrintReportTypeGet(progressReportID: string, type: string, getDocx?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowItemContractProgressReportProgressReportIDPrintReportTypeGet(progressReportID: string, type: string, getDocx?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowItemContractProgressReportProgressReportIDPrintReportTypeGet(progressReportID: string, type: string, getDocx?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (progressReportID === null || progressReportID === undefined) {
            throw new Error('Required parameter progressReportID was null or undefined when calling apiWorkflowItemContractProgressReportProgressReportIDPrintReportTypeGet.');
        }

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling apiWorkflowItemContractProgressReportProgressReportIDPrintReportTypeGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (getDocx !== undefined && getDocx !== null) {
            queryParameters = queryParameters.set('getDocx', <any>getDocx);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(progressReportID))}/printReport/${encodeURIComponent(String(type))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
    responseType: 'blob' as 'json',
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param progressReportID
    * @param file
    * @param title
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportProgressReportIDUploadSubfileLiquidationPost(progressReportID: string, file?: Blob, title?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportProgressReportIDUploadSubfileLiquidationPost(progressReportID: string, file?: Blob, title?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportProgressReportIDUploadSubfileLiquidationPost(progressReportID: string, file?: Blob, title?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportProgressReportIDUploadSubfileLiquidationPost(progressReportID: string, file?: Blob, title?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (progressReportID === null || progressReportID === undefined) {
            throw new Error('Required parameter progressReportID was null or undefined when calling apiWorkflowItemContractProgressReportProgressReportIDUploadSubfileLiquidationPost.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (title !== undefined && title !== null) {
            queryParameters = queryParameters.set('title', <any>title);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(progressReportID))}/uploadSubfile/liquidation`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param progressReportID
    * @param file
    * @param title
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportProgressReportIDUploadSubfilePost(progressReportID: string, file?: Blob, title?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportProgressReportIDUploadSubfilePost(progressReportID: string, file?: Blob, title?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportProgressReportIDUploadSubfilePost(progressReportID: string, file?: Blob, title?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportProgressReportIDUploadSubfilePost(progressReportID: string, file?: Blob, title?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (progressReportID === null || progressReportID === undefined) {
            throw new Error('Required parameter progressReportID was null or undefined when calling apiWorkflowItemContractProgressReportProgressReportIDUploadSubfilePost.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (title !== undefined && title !== null) {
            queryParameters = queryParameters.set('title', <any>title);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(progressReportID))}/uploadSubfile`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param id
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportSignaturesIdImageGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowItemContractProgressReportSignaturesIdImageGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowItemContractProgressReportSignaturesIdImageGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowItemContractProgressReportSignaturesIdImageGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractProgressReportSignaturesIdImageGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflowItemContractProgressReport/signatures/${encodeURIComponent(String(id))}/image`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
    responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param body
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportUploadNeedEditPost(body?: WorkflowItemContractProgressReport, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportUploadNeedEditPost(body?: WorkflowItemContractProgressReport, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportUploadNeedEditPost(body?: WorkflowItemContractProgressReport, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportUploadNeedEditPost(body?: WorkflowItemContractProgressReport, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflowItemContractProgressReport/UploadNeedEdit`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param userCounsilID
    * @param body
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportUserCounsilIDSignaturePut(userCounsilID: string, body?: Signature, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportUserCounsilIDSignaturePut(userCounsilID: string, body?: Signature, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportUserCounsilIDSignaturePut(userCounsilID: string, body?: Signature, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportUserCounsilIDSignaturePut(userCounsilID: string, body?: Signature, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userCounsilID === null || userCounsilID === undefined) {
            throw new Error('Required parameter userCounsilID was null or undefined when calling apiWorkflowItemContractProgressReportUserCounsilIDSignaturePut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(userCounsilID))}/signature`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
    *
    *
    * @param workitemID
    * @param Filters
    * @param Sorts
    * @param Page
    * @param PageSize
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
    public apiWorkflowItemContractProgressReportWorkitemIDCouncilGet(workitemID: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractProgressReportWorkitemIDCouncilGet(workitemID: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractProgressReportWorkitemIDCouncilGet(workitemID: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractProgressReportWorkitemIDCouncilGet(workitemID: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workitemID === null || workitemID === undefined) {
            throw new Error('Required parameter workitemID was null or undefined when calling apiWorkflowItemContractProgressReportWorkitemIDCouncilGet.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (Filters !== undefined && Filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>Filters);
        }
        if (Sorts !== undefined && Sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>Sorts);
        }
        if (Page !== undefined && Page !== null) {
            queryParameters = queryParameters.set('Page', <any>Page);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItemContractProgressReport/${encodeURIComponent(String(workitemID))}/council`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
}
