import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DbdBoardService} from '@app/api/dbdBoard.service';
import {ToastrService} from 'ngx-toastr';
import {DynamicDashboardComponent} from '../dynamic-dashboard.component';
import * as moment from 'moment';

@Component({
  selector: 'meu-modal-config-widget',
  templateUrl: './modal-config-widget.component.html',
  styleUrls: ['./modal-config-widget.component.scss'],
})
export class ModalConfigWidgetComponent implements OnInit {
  board_widget: any = {};
  constructor(
    public dialogRef: MatDialogRef<DynamicDashboardComponent>,
    @Inject(MAT_DIALOG_DATA) public dataInject,
    private dbdBoardService: DbdBoardService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.board_widget = this.dataInject.board_widget;
    this.board_widget.array_configuration.forEach((element) => {
      if (element.Control.Type === 'DATE_PICKER') {
        element.Values = moment(element.Values, 'YYYY-MM-DD');
      } else if (element.Control.Type === 'YEAR_PICKER') {
        element.Values = moment(element.Values, 'YYYY').toDate();
      }
    });
  }

  getDependNameApi(itemConfig: any, e?: any): void {
    if (
      itemConfig.Control.Type === 'DATE_PICKER' &&
      moment.utc(e).format() === 'Invalid date'
    ) {
      return;
    }
    if (itemConfig.Values) {
      this.board_widget.array_configuration.forEach(
        (element: any, i: number) => {
          if (!element.Control.ApiUrlPure) {
            element.Control.ApiUrlPure = element.Control.ApiUrl;
          }
          if (element.Control.ApiUrlPure.split('{{').length > 2) {
            const oldVal =
              itemConfig.Control.Type === 'DATE_PICKER'
                ? moment.utc(itemConfig.Values).format()
                : itemConfig.Values;
            if (element.Control.ApiUrlPure?.includes('{{' + itemConfig.Name)) {
              itemConfig.Values = e;
              element.Control.ApiUrl = element.Control.ApiUrl.replace(/}}/g, '')
                .replace(
                  new RegExp('{{' + itemConfig.Name, 'g'),
                  itemConfig.Control.Type === 'DATE_PICKER'
                    ? moment.utc(itemConfig.Values).format()
                    : itemConfig.Values
                )
                .replace(
                  new RegExp(oldVal, 'g'),
                  itemConfig.Control.Type === 'DATE_PICKER'
                    ? moment.utc(itemConfig.Values).format()
                    : itemConfig.Values
                );
            }
          } else if (
            element.Control.ApiUrlPure?.includes('{{' + itemConfig.Name)
          ) {
            if (e) {
              itemConfig.Values = e;
            }
            const a = element.Control.ApiUrlPure;
            element.Control.ApiUrl = a
              .replace(/}}/g, '')
              .replace(
                new RegExp('{{' + itemConfig.Name, 'g'),
                itemConfig.Values
              );
            this.board_widget.array_configuration[i].Control = element.Control;
          } else {
            if (e) {
              itemConfig.Values = e;
            }
          }
        }
      );
    }
  }

  getIdDefault(item) {
    if (Array.isArray(item)) {
      return item.length > 0 ? item[0] : '';
    } else {
      return item;
    }
  }
  cancelConfig() {
    this.dialogRef.close();
  }

  saveConfig() {
    this.board_widget.array_configuration.forEach((element) => {
      element.Key = element.Key || element.Name;
      if (element.Control.Type === 'DATE_PICKER') {
        element.Values = moment(element.Values).format('YYYY-MM-DD');
      } else if (element.Control.Type === 'YEAR_PICKER') {
        element.Values = moment(element.Values).format('YYYY');
      }
    });
    this.board_widget.configuration = JSON.stringify(
      this.board_widget.array_configuration
    );
    //return;
    this.dbdBoardService
      .apiMyBoardsSectionsIdWidgetsPost(
        this.board_widget.board_section_id,
        this.board_widget
      )
      .subscribe((res) => {
        if (res.success) {
          this.dialogRef.close(true);
          this.toastr.success('Cập nhật cấu hình widget thành công');
        } else {
          this.toastr.error(res.message, 'Thao tác không thành công');
        }
      });
  }
}
