// Angular
import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
// RxJS
import {Observable, of} from 'rxjs';
import {tap, delay, map} from 'rxjs/operators';
// NGRX
import {select, Store} from '@ngrx/store';
// Auth reducers and selectors
import {AppState} from '../../../core/reducers/';
import {isLoggedIn} from '../_selectors/auth.selectors';
import {environment} from 'environments/environment';
import {SharedService} from '@app/core/services/shared.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private sharedService: SharedService
  ) {}

  canActivate(): Observable<boolean> {
    return this.delayForAppTokenHandle().pipe(
      tap(()=>{
        const isAuthenticated = this.isAuthenticated();
        if (!isAuthenticated) {
          this.sharedService.openSSO();
        }}),
      map(()=>this.isAuthenticated())
    )
  }

  isAuthenticated() {
    const token = localStorage.getItem(environment.authTokenKey);
    const infoUserLogged = localStorage.getItem(environment.userInfo);
    if (token && token.trim() != '') {
      // && infoUserLogged && infoUserLogged.trim() != ''
      return true;
    }
    return false;
  }

  delayForAppTokenHandle():Observable<any>{
    return of('').pipe(delay(600));
  }
}
