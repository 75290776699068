import {NgModule} from '@angular/core';
import {MeuCurrencyPipe} from './meu-currency.pipe';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [MeuCurrencyPipe],
  imports: [CommonModule],
  exports: [MeuCurrencyPipe],
})
export class CurrencyModule {}
