<div class="row m-0">
  <div class="col-12 p-0">
    <ngx-dropzone
      #commonDropzone
      (change)="onSelect($event)"
      id="common-dropzone"
      [disableClick]="disableClick"
    >
      <ngx-dropzone-label *ngIf="!disabled"
        >Kéo và thả tệp vào đây</ngx-dropzone-label
      >
      <ngx-dropzone-label *ngIf="disabled"
        >Không có tệp đính kèm</ngx-dropzone-label
      >
      <ngx-dropzone-preview
        *ngFor="let f of files"
        [removable]="disabled ? false : true"
        (removed)="onRemove(f)"
        (click)="downloadFile(f.id, f.physical_path, f.file_name)"
        (mouseover)="onMouseOver()"
        (mouseout)="onMouseOut()"
        [ngClass]="disabled ? 'cursor' : ''"
        [title]="disabled ? 'Xem chi tiết' : ''"
      >
        <button>dowload</button>
        <ngx-dropzone-label class="ellipsis"
          ><i [class]="getIconFile(f.name)" aria-hidden="true"></i>
          {{ f.name }}</ngx-dropzone-label
        >
      </ngx-dropzone-preview>
    </ngx-dropzone>
  </div>
</div>
