import {Observable, Subscription, of} from 'rxjs';
// Angular
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
// Layout
import {
  LayoutConfigService,
  SplashScreenService,
  TranslationService,
} from './core/_base/layout';
const crypto = require('asymmetric-crypto');
// language list
import {locale as enLang} from './core/_config/i18n/en';
import {locale as chLang} from './core/_config/i18n/ch';
import {locale as esLang} from './core/_config/i18n/es';
import {locale as jpLang} from './core/_config/i18n/jp';
import {locale as deLang} from './core/_config/i18n/de';
import {locale as frLang} from './core/_config/i18n/fr';

import {NgxSpinnerService} from 'ngx-spinner';
import {filter, map, mergeMap, delay} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';

// Modal
import {BsModalService} from 'ngx-bootstrap/modal';
import {HtmlClassService} from './views/theme/html-class.service';
import {environment} from 'environments/environment';
import {WorkflowItemService} from './api/workflowItem.service';
import {RootSpinnerService} from './services/root-spinner.service';
import {NgSelectConfig} from '@ng-select/ng-select';
import {ConfirmService} from './shared/services/confirm.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[kt-root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  // Public properties
  title = 'Sở KHCN';
  buttonCollection = [];
  loader: boolean;
  favIcon: HTMLLinkElement = document.querySelector('#annaWorkIcon');
  objectModal: any;
  private subscription: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  /**
   * Component constructor
   *
   * @param translationService: TranslationService
   * @param router: Router
   * @param layoutConfigService: LayoutCongifService
   * @param splashScreenService: SplashScreenService
   */
  constructor(
    private translationService: TranslationService,
    private router: Router,
    private layoutConfigService: LayoutConfigService,
    private splashScreenService: SplashScreenService,
    private spinner: NgxSpinnerService,
    public rootSpinner: RootSpinnerService,
    private route: ActivatedRoute,
    private titleService: Title,
    private modalService: BsModalService,
    public htmlClassService: HtmlClassService,
    private workflowItemService: WorkflowItemService,
    private confirmService: ConfirmService,
    private ngSelectConfig: NgSelectConfig
  ) {
    localStorage.setItem('backend-host', environment.backendhost);
    this.ngSelectConfig.notFoundText = 'Chưa có dữ liệu';
    this.subscription.push(
      RootSpinnerService.statusChanged.subscribe((item) =>
        this.spinnerStatusChanged(item)
      )
    );
    setTimeout(() => {
      this.tokenHandler();
    }, 300);

    // register translations
    this.translationService.loadTranslations(
      enLang,
      chLang,
      esLang,
      jpLang,
      deLang,
      frLang
    );
    this.htmlClassService.getCompanyInfo();
    this.confirmService.open$.subscribe((x) => {
      this.objectModal = x;
    });
  }
  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  spinnerStatusChanged(item: boolean) {
    if (item) {
      this.htmlClassService.loader$.next(true);
    } else {
      this.htmlClassService.loader$.next(false);
    }
  }

  /**
   * On init
   */
  ngOnInit(): void {
    this.htmlClassService.loader$.subscribe((res) => {
      if (res) {
        this.spinner.show();
      } else {
        this.spinner.hide();
      }
    });
    // enable/disable loader
    //this.layoutConfigService.getConfig('loader.enabled')
    //this.htmlClassService.loader$.next(true);
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.htmlClassService.loader$.next(true);
      }
      if (event instanceof NavigationEnd) {
        // hide splash screen
        this.htmlClassService.loader$.next(false);

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('kt-page--loaded');
        }, 500);

        // close all open modals
        for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
          document.querySelector('body').classList.remove('modal-open');
          this.modalService.hide(i);
        }
      }
    });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.rootRoute(this.route)),
        filter((route: ActivatedRoute) => route.outlet === 'primary'),
        mergeMap((route: ActivatedRoute) => route.data)
      )
      .subscribe((event: {[name: string]: any}) => {
        this.titleService.setTitle(event['title']);
      });

    this.subscription.push(routerSubscription);
    this.changeIcon();
  }

  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  changeIcon() {
    this.htmlClassService.logoPathSubject$.subscribe((res) => {
      this.favIcon.href = res;
    });
  }

  private getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  private tokenHandler() {
    let token = this.getParameterByName('token');
    if (!!!token) {
      token = localStorage.getItem(environment.authTokenKey);
    } else {
      const keyPair = JSON.parse(
        localStorage.getItem(environment.privateKeyToken)
      );
      const dt = JSON.parse(atob(token));
      token = crypto.decrypt(
        dt.data,
        dt.nonce,
        dt.SSOServerPublicKey,
        keyPair.secretKey
      );
      //REMOVE TOKEN ON URL
      let currentURL = window.location.href;
      currentURL = window.location.origin + window.location.pathname; // currentURL.replace('?token=' + token, '');
      window.history.replaceState({}, '', currentURL);
      localStorage.setItem(environment.authTokenKey, token);
      window.location.href = window.location.origin + window.location.pathname;
    }
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscription.forEach((sb) => sb.unsubscribe());
  }
}
