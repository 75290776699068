import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';

@Component({
  selector: 'meu-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent implements OnInit, AfterViewInit {
  @Input() formControl: FormControl;
  @Input() title: string;
  @Input() placeholderText: string;
  @Input() invalidState: boolean;
  @Input() name: string;

  isRequiredField: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const validator = this.formControl?.validator({} as AbstractControl);
    this.isRequiredField = validator && validator?.required;
  }
}
