import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Input,
  ViewContainerRef,
  ViewChild,
  Compiler,
  ViewChildren,
  ElementRef,
  QueryList,
  AfterViewChecked,
  HostListener,
} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {Store, select} from '@ngrx/store';
import {PluginLoaderService} from '../../services/plugin-loader/plugin-loader.service';
import {filter, take} from 'rxjs/operators';
import {dropdownOptions} from '../dropdown-with-dots/dropdown-with-dots.component';
import {ConfirmService} from '@app/shared/services/confirm.service';
import {MatDialog} from '@angular/material/dialog';
import {ModalChooseLayoutComponent} from './modal-choose-layout/modal-choose-layout.component';
import {ModalAddWidgetComponent} from './modal-add-widget/modal-add-widget.component';
import {ModalShareComponent} from './modal-share/modal-share.component';
import {ModalEditWidgetNameComponent} from './modal-edit-widget-name/modal-edit-widget-name.component';
import {ShareListComponent} from './share-list/share-list.component';
import {ModalConfigWidgetComponent} from './modal-config-widget/modal-config-widget.component';
import {AppState} from '@app/core/reducers';
import {currentUser, User} from '@app/core/auth';
import {ModalSettingSectionComponent} from './modal-setting-section/modal-setting-section.component';
import {DbdBoardService} from '@app/api/dbdBoard.service';
import {DbdBoardShareService} from '@app/api/dbdBoardShare.service';
import {ModalShareWidgetComponent} from './modal-share-widget/modal-share-widget.component';
import {ModalCreateBoardComponent} from './modal-create-board/modal-create-board.component';
import {Md5} from 'ts-md5/dist/md5';

@Component({
  selector: 'meu-dynamic-dashboard',
  templateUrl: './dynamic-dashboard.component.html',
  styleUrls: ['./dynamic-dashboard.component.scss'],
})
export class DynamicDashboardComponent implements OnInit, AfterViewChecked {
  listMyBoard = [];
  boardContent = [];
  indexSelect = 0;
  boardSelected;
  idBoard;
  nameBoard;
  isEdit = false;
  openAction = false;
  openViewMore = false;
  modalRef: BsModalRef;
  options: any[] = [
    {
      id: 'editLayout',
      icon: './assets/icons/svg/u_web-grid-alt.svg',
      name: 'Chỉnh sửa bố cục',
      iconUrl: './assets/icons/svg/u_web-grid-alt.svg',
      label: 'Chỉnh sửa bố cục',
    },
    {
      id: 'editWidgetName',
      icon: './assets/icons/svg/u_file-edit-alt.svg',
      name: 'Đổi tên thẻ hiện tại',
      iconUrl: './assets/icons/svg/u_file-edit-alt.svg',
      label: 'Đổi tên thẻ hiện tại',
    },
    {
      id: 'deleteBoard',
      icon: './assets/icons/svg/u_trash-alt.svg',
      name: 'Xóa thẻ hiện tại',
      iconUrl: './assets/icons/svg/u_trash-alt.svg',
      label: 'Xóa thẻ hiện tại',
    },
  ];

  optionsEditWidget: dropdownOptions[] = [
    {
      id: 'edit',
      iconUrl: './assets/icons/svg/u_edit-alt.svg',
      label: 'Đổi tên widget',
    },
    {
      id: 'setting',
      iconUrl: './assets/icons/svg/u_setting.svg',
      label: 'Cấu hình widget',
    },
    {
      id: 'remove',
      iconUrl: './assets/icons/svg/u_trash-alt.svg',
      label: 'Xóa widget',
    },
  ];

  mobileScreen = false;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mobileScreen = window.innerWidth < 768;
  }

  // modal add widget
  sort_order_add_widget;
  board_section_id;
  category_add_widget;

  @Input() categoryDashboard;

  // modal edit widget name
  board_widget;
  section;

  public controlSearch: FormControl = new FormControl();

  isInit = true;

  totalShareWithMe = 0;
  dialogRef;
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dbdBoardService: DbdBoardService,
    private toastr: ToastrService,
    private pluginLoader: PluginLoaderService,
    private compiler: Compiler,
    private ref: ChangeDetectorRef,
    private confirmService: ConfirmService,
    public bdBoardShareService: DbdBoardShareService,
    public dialog: MatDialog,
    private store: Store<AppState>
  ) {}

  @ViewChildren('pluginReference', {read: ViewContainerRef})
  pluginReferences: QueryList<ViewContainerRef>;
  @ViewChild('widgetsContent', {read: ElementRef})
  public widgetsContent: ElementRef<any>;
  ngOnInit(): void {
    this.mobileScreen = window.innerWidth < 768;
    this.getListMyBoard();
    this.getWidgetCollection();
  }

  getWidgetCollection() {
    this.bdBoardShareService.apiDbdBoardShareToMeGet().subscribe((res) => {
      if (res.success) {
        this.totalShareWithMe = res.data.total;
      }
    });
  }

  openShareWithMe() {
    const dialogRef = this.dialog.open(ModalShareWidgetComponent);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getListMyBoard();
      }
    });
  }

  onSelectAction(e) {
    if (e === 'addWidget') {
      this.openModalCreateBoard(false);
    } else if (e === 'editWidgetName') {
      this.openModalCreateBoard(true);
    } else if (e === 'deleteBoard') {
      this.deleteBoard();
    } else if (e === 'editLayout') {
      this.isEdit = !this.isEdit;
    } else if (e === 'share') {
      const dialogRef = this.dialog.open(ModalShareComponent, {
        data: {
          boardId: this.boardSelected?.idBoard,
          nameBoard: this.boardSelected?.name,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
        }
      });
    } else if (e === 'shareList') {
      const dialogRef = this.dialog.open(ShareListComponent);
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
        }
      });
    }
  }

  onSelectWidgetAction(e, board_widget, indexSection?, i?) {
    if (e === 'edit') {
      this.openModalEditWidgetName(board_widget);
    } else if (e === 'remove') {
      this.deleteBoardWidget(board_widget.id);
    } else if (e === 'setting') {
      this.openModalConfigWidget(board_widget);
    }
  }

  convertToNumber(str) {
    return Number(str);
  }

  ngAfterViewChecked() {}
  loadCurrentPlugin() {
    const self = this;
    setTimeout(function () {
      self.boardContent.forEach((section) => {
        section.board_widget.forEach((element) => {
          if (element.widget_name !== undefined) {
            let chartType = 'bar-chart';
            switch (element.widget_name) {
              case 'Biểu đồ cột':
                chartType = 'bar-chart';
                break;
              case 'Biểu đồ bánh':
                chartType = 'donut-chart';
                break;
              case 'Biểu đồ tròn':
                chartType = 'pie-chart';
                break;
              case 'Biểu đồ chồng cột':
                chartType = 'stack-bar-chart';
                break;
              default:
                break;
            }
            //self.loadPlugin(chartType, element.id);
            self.loadPlugin(element.widget_name, element.id);
          }
        });
      });
    }, 10);
  }
  loadPlugin(pluginName: string, id: any) {
    this.pluginReferences.forEach((pluginItem) => {
      if (pluginItem['_lContainer'][0].id === id) {
        pluginItem.clear();
        setTimeout(() => {
          this.pluginLoader?.load(pluginName).then((moduleType: any) => {
            const ngModuleFactory = this.compiler.compileModuleSync(moduleType);
            const ngModule = ngModuleFactory.create(pluginItem.injector);
            const factory =
              ngModule.componentFactoryResolver.resolveComponentFactory(
                moduleType.entry
              );
            pluginItem.createComponent(factory);
            this.ref.detectChanges();
          });
        }, 50);
      }
    });
  }
  getListMyBoard() {
    const list = [];
    this.dbdBoardService
      .apiMyBoardsGet(this.categoryDashboard.code)
      .subscribe((res) => {
        if (res.success) {
          const data_sort = res.data.sort((a, b) =>
            a.sort_order > b.sort_order ? 1 : -1
          );
          for (let i = 0; i < data_sort.length; i++) {
            list.push(data_sort[i]);
          }
          this.listMyBoard = list;

          if (this.listMyBoard.length === 0 && this.isInit) {
            this.isInit = false;
            const objCreateBoard = {
              name: 'Thẻ mặc định',
              code: this.categoryDashboard.code,
              sort_order: 0,
              is_public: false,
            };
            this.dbdBoardService
              .apiMyBoardsPost(objCreateBoard)
              .subscribe((result) => {
                if (result.success) {
                  this.getListMyBoard();
                }
              });
          }
          this.indexSelect = 0;
          this.boardSelected =
            this.listMyBoard.length > 0 ? this.listMyBoard[0] : null;
          if (this.listMyBoard.length > 0) {
            this.getListBoardSection();
          }
        }
      });
  }

  getListBoardSection() {
    this.dbdBoardService
      .apiMyBoardsIdGet(this.boardSelected.id)
      .subscribe((res) => {
        if (res.success) {
          const list = [];
          for (let i = 0; i < res.data.extend_sections.length; i++) {
            const array_section_layout =
              res.data.extend_sections[i].section.section_layout.split(':');

            const list_board_widget = res.data.extend_sections[i].widget.sort(
              (a, b) => (a.sort_order > b.sort_order ? 1 : -1)
            );

            const board_widget = [];
            let index_widget = 0;
            for (let j = 0; j < array_section_layout.length; j++) {
              if (
                list_board_widget[index_widget] &&
                list_board_widget[index_widget].sort_order === j
              ) {
                board_widget.push({
                  id: list_board_widget[index_widget].id,
                  board_section_id:
                    list_board_widget[index_widget].board_section_id,
                  name: list_board_widget[index_widget].name,
                  widget_code: list_board_widget[index_widget].widget_code,
                  widget_name: list_board_widget[index_widget].widget_name,
                  sort_order: list_board_widget[index_widget].sort_order,
                  configuration: list_board_widget[index_widget].configuration,
                  is_edit_config: false,
                  array_configuration: JSON.parse(
                    list_board_widget[index_widget].configuration
                  ),
                });
                index_widget++;
              } else {
                board_widget.push({});
              }
            }

            list.push({
              id: res.data.extend_sections[i].section.id,
              sort_order: res.data.extend_sections[i].section.sort_order,
              board_section_id: res.data.extend_sections[i].section.id,
              board_id: this.boardSelected.id,
              section_layout:
                res.data.extend_sections[i].section.section_layout,
              array_section_layout: array_section_layout,
              board_widget: board_widget,
              height: res.data.extend_sections[i].section.height,
            });
          }
          this.boardContent = list.sort((a, b) =>
            a.sort_order > b.sort_order ? 1 : -1
          );
          this.changeDetectorRef.detectChanges();
        }
        this.loadCurrentPlugin();
      });
  }

  checkBoardWidgetEmpty(i) {
    for (let j = 0; j < this.boardContent[i].board_widget.length; j++) {
      if (!this.checkObjEmpty(this.boardContent[i].board_widget[j])) {
        return false;
      }
    }
    return true;
  }

  checkObjEmpty(obj) {
    return Object.keys(obj).length === 0 ? true : false;
  }

  getCurrentBoard(item, index) {
    if (item.id !== this.boardSelected.id) {
      this.indexSelect = index;
      this.boardSelected = item;
      this.getListBoardSection();
    }
  }

  openModalCreateBoard(isEdit) {
    this.idBoard = isEdit ? this.boardSelected.id : null;
    this.dialogRef = this.dialog.open(ModalCreateBoardComponent, {
      data: {
        boardId: this.idBoard,
        code: this.categoryDashboard.code,
        sort_order: this.listMyBoard.length,
        is_public: false,
      },
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      if (data && !data.isEdit) {
        this.listMyBoard.push(data.response);
        this.indexSelect = this.listMyBoard.length - 1;
        this.boardSelected = this.listMyBoard[this.indexSelect];
        this.boardContent = [];
      } else if (data && data.isEdit) {
        this.listMyBoard[this.indexSelect].name = data.response.name;
      }
    });
  }

  openModalChooseLayout() {
    this.dialogRef = this.dialog.open(ModalChooseLayoutComponent);
    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        const objSection: any = {
          board_id: this.boardSelected.id,
          sort_order: this.boardContent.length,
          section_layout: data.section_layout,
        };
        this.dbdBoardService
          .apiMyBoardsIdSectionsPost(this.boardSelected.id, objSection)
          .subscribe((res) => {
            if (res.success) {
              const array_section_layout = [];
              const listObjEmpty = [];
              for (let i = 0; i < data.col_layout.length; i++) {
                array_section_layout.push(data.col_layout[i]);
                listObjEmpty.push({});
              }

              this.boardContent.push({
                id: res.data.id,
                sort_order: res.data.sort_order,
                board_section_id: res.data.id,
                board_id: this.boardSelected.id,
                section_layout: res.data.section_layout,
                array_section_layout: array_section_layout,
                board_widget: listObjEmpty,
                height: res.data.height,
              });
              this.changeDetectorRef.detectChanges();
              this.toastr.success('Thêm mới section thành công');
            } else {
              this.toastr.error(
                res.message,
                'Thêm mới section không thành công'
              );
            }
          });
      }
    });
  }

  openModalChooseWidget(board_section_id, index) {
    this.sort_order_add_widget = index;
    this.board_section_id = board_section_id;
    this.category_add_widget = this.categoryDashboard.categories;
    this.idBoard = this.boardSelected.id;
    this.dialogRef = this.dialog.open(ModalAddWidgetComponent, {
      data: {
        boardId: this.idBoard,
        sort_order: this.sort_order_add_widget,
        board_section_id: this.board_section_id,
        category: this.category_add_widget,
      },
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.getListBoardSection();
      }
    });
  }

  openModalConfigWidget(board_widget) {
    this.board_widget = board_widget;
    this.dialogRef = this.dialog.open(ModalConfigWidgetComponent, {
      data: {
        board_widget: this.board_widget,
      },
    });
    this.dialogRef.afterClosed().subscribe((reset) => {
      if (reset) {
        this.board_widget.array_configuration = JSON.parse(
          this.board_widget.configuration
        );
        this.getListBoardSection();
      }
    });
  }

  openModalSettingSection(section) {
    this.section = section;
    this.dialogRef = this.dialog.open(ModalSettingSectionComponent, {
      data: {
        section: this.section,
      },
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.section.board_widget.forEach((element) => {
          this.onResetAfterSavedConfigWidget(element);
        });
      }
    });
  }

  onResetAfterSavedConfigWidget(board_widget) {
    if (board_widget.widget_name !== undefined) {
      let chartType = 'bar-chart';
      switch (board_widget.widget_name) {
        case 'Biểu đồ cột':
          chartType = 'bar-chart';
          break;
        case 'Biểu đồ bánh':
          chartType = 'donut-chart';
          break;
        case 'Biểu đồ tròn':
          chartType = 'pie-chart';
          break;
        case 'Biểu đồ chồng cột':
          chartType = 'stack-bar-chart';
          break;
        default:
          break;
      }
      //this.loadPlugin(chartType, board_widget.id);
      this.loadPlugin(board_widget.widget_name, board_widget.id);
    }
  }

  openModalEditWidgetName(board_widget) {
    this.board_widget = board_widget;
    this.dialogRef = this.dialog.open(ModalEditWidgetNameComponent, {
      data: {
        board_widget: this.board_widget,
      },
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) {
      }
    });
  }

  deleteBoard() {
    const title = 'Xóa thẻ đang mở';
    const description = 'Bạn có chắc chắn muốn xóa thẻ này?';
    this.confirmService.deleteConfirm(title, description, true);
    this.confirmService.open$
      .pipe(
        filter((event) => !!event),
        filter((event: any) => {
          return event.type === 'delete' || event.type === 'close';
        }),
        take(1)
      )
      .subscribe((events) => {
        if (events.type === 'delete') {
          const id = this.boardSelected.id;
          this.dbdBoardService
            .apiMyBoardsIdDelete(this.boardSelected.id)
            .subscribe((res) => {
              if (res.success) {
                this.toastr.success('Thao tác xóa thành công');
                let index = this.listMyBoard.findIndex((x) => x.id === id);
                this.listMyBoard.splice(index, 1);

                for (let i = 0; i < this.listMyBoard.length; i++) {
                  this.listMyBoard[i].sort_order = i;
                  const obj: any = {
                    sort_order: i,
                  };
                  this.dbdBoardService
                    .apiMyBoardsIdPut(this.listMyBoard[i].id, obj)
                    .subscribe((result) => {});
                  index++;
                }

                this.indexSelect = 0;
                this.boardSelected =
                  this.listMyBoard.length > 0 ? this.listMyBoard[0] : null;

                if (this.listMyBoard.length > 0) {
                  this.getListBoardSection();
                }
              } else {
                this.toastr.error(res.message, 'Thao tác xóa không thành công');
              }
            });
        }
        this.confirmService.close();
      });
  }

  deleteSection(boardSectionId) {
    const title = 'Xóa section';
    const description = 'Bạn có chắc chắn muốn xóa section này?';
    this.confirmService.deleteConfirm(title, description, true);
    this.confirmService.open$
      .pipe(
        filter((event) => !!event),
        filter((event: any) => {
          return event.type === 'delete' || event.type === 'close';
        }),
        take(1)
      )
      .subscribe((events) => {
        if (events.type === 'delete') {
          this.dbdBoardService
            .apiMyBoardsSectionsIdDelete(boardSectionId)
            .subscribe((res) => {
              if (res.success) {
                this.toastr.success('Thao tác xóa thành công');
                const index = this.boardContent.findIndex(
                  (x) => x.board_section_id === boardSectionId
                );
                this.boardContent.splice(index, 1);
                for (let i = 0; i < this.boardContent.length; i++) {
                  this.boardContent[i].sort_order = i;
                  const obj: any = {
                    sort_order: i,
                    section_layout: this.boardContent[i].section_layout,
                  };
                  this.dbdBoardService
                    .apiMyBoardsSectionsIdPut(
                      this.boardContent[i].board_section_id,
                      obj
                    )
                    .subscribe((res) => {});
                }
              } else {
                this.toastr.error(res.message, 'Thao tác xóa không thành công');
              }
            });
        }
        this.confirmService.close();
      });
  }

  deleteBoardWidget(boardWidgetId) {
    const title = 'Xóa widget';
    const description = 'Bạn có chắc chắn muốn xóa widget này?';
    this.confirmService.deleteConfirm(title, description, true);
    this.confirmService.open$
      .pipe(
        filter((event) => !!event),
        filter((event: any) => {
          return event.type === 'delete' || event.type === 'close';
        }),
        take(1)
      )
      .subscribe((events) => {
        if (events.type === 'delete') {
          this.dbdBoardService
            .apiMyBoardsWidgetsIdDelete(boardWidgetId)
            .subscribe((res) => {
              if (res.success) {
                this.toastr.success('Thao tác xóa thành công');
                this.getListBoardSection();
              } else {
                this.toastr.error(res.message, 'Thao tác xóa không thành công');
              }
            });
        }
        this.confirmService.close();
      });
  }

  moveUpSection(section, indexSection) {
    let b = this.boardContent;
    if (indexSection - 1 >= 0) {
      section.sort_order--;
      b[indexSection - 1].sort_order++;

      let obj: any = {
        sort_order: section.sort_order,
        section_layout: section.section_layout,
      };
      this.dbdBoardService
        .apiMyBoardsSectionsIdPut(section.board_section_id, obj)
        .subscribe((res) => {});

      obj = {
        sort_order: b[indexSection - 1].sort_order,
        section_layout: b[indexSection - 1].section_layout,
      };
      this.dbdBoardService
        .apiMyBoardsSectionsIdPut(b[indexSection - 1].board_section_id, obj)
        .subscribe((res) => {});
    } else {
      return;
    }

    b = b.sort(function (a, b) {
      return a.sort_order - b.sort_order;
    });
    this.boardContent = [...b];
  }

  moveDownSection(section, indexSection) {
    let b = this.boardContent;
    if (indexSection + 1 < b.length) {
      section.sort_order++;
      b[indexSection + 1].sort_order--;

      let obj: any = {
        sort_order: section.sort_order,
        section_layout: section.section_layout,
      };
      this.dbdBoardService
        .apiMyBoardsSectionsIdPut(section.board_section_id, obj)
        .subscribe((res) => {});

      obj = {
        sort_order: b[indexSection + 1].sort_order,
        section_layout: b[indexSection + 1].section_layout,
      };
      this.dbdBoardService
        .apiMyBoardsSectionsIdPut(b[indexSection + 1].board_section_id, obj)
        .subscribe((res) => {});
    } else {
      return;
    }

    b = b.sort(function (a, b) {
      return a.sort_order - b.sort_order;
    });
    this.boardContent = [...b];
  }

  search(event) {}

  checkBoardEmpty() {
    for (let i = 0; i < this.boardContent.length; i++) {
      for (let j = 0; j < this.boardContent[i].board_widget.length; j++) {
        if (!this.checkObjEmpty(this.boardContent[i].board_widget[j])) {
          return false;
        }
      }
    }
    return true;
  }

  scrollRight() {
    this.widgetsContent.nativeElement.scrollTo({
      left: this.widgetsContent.nativeElement.scrollLeft + 150,
      behavior: 'smooth',
    });
  }

  scrollLeft() {
    this.widgetsContent.nativeElement.scrollTo({
      left: this.widgetsContent.nativeElement.scrollLeft - 150,
      behavior: 'smooth',
    });
  }
}
