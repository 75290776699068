<div class="mx-select-container">
  <label class="title" *ngIf="labelContent">{{ labelContent }}</label>
  <div
    class="input-group"
    [ngClass]="{'form-error': formControl.invalid && formControl.touched}"
  >
    <ng-content></ng-content>
  </div>
  <ul class="error-list" *ngIf="formControl.invalid && formControl.touched">
    <ng-container *ngFor="let item of errorMessageResource | keyvalue">
      <li *ngIf="formControl.errors[item.key]">{{ item.value }}</li>
    </ng-container>
  </ul>
</div>
