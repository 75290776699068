export class MenuConfig {
  public defaults: any = {
    header: {
      self: {},
      items: [
        {
          title: 'Thống kê',
          root: true,
          alignment: 'left',
          toggle: 'click',
          permissionsArray: ['WORKFLOW_PROCESSING'],
          category: 'overall',
          cateIcon: 'dashboard.svg',
          submenu: [
            {
              title: 'Tổng quan',
              bullet: 'dot',
              page: '/workflow/dashboard',
              icon: 'flaticon-analytics',
              permissions: 'WORKFLOW_PROCESSING',
            },
            {
              title: 'Cá nhân',
              bullet: 'dot',
              page: '/workflow/custom-dashboard',
              icon: 'flaticon-graphic-1',
              permissions: 'WORKFLOW_PROCESSING',
            },
          ],
        },
        {
          title: 'Nộp hồ sơ',
          root: true,
          alignment: 'left',
          toggle: 'click',
          page: '/workflow/workitem',
          notAllowPermissions: ['WORKFLOW_PROCESSING', 'WORKFLOW_DASHBOARD'],
          category: 'overall',
          hideOnMobile: true,
        },
        {
          title: 'Xử lý hồ sơ',
          root: true,
          alignment: 'left',
          toggle: 'click',
          permissions: 'WORKFLOW_PROCESSING',
          category: 'overall',
          badge: {
            value: '0',
          },
          cateIcon: 'fact_check.svg',
          submenu: [
            {
              title: 'Hồ sơ thủ tục hành chính',
              bullet: 'dot',
              page: '/workflow/dynamicreceiveitems',
              icon: 'flaticon-analytics',
              badge: {
                value: '0',
              },
            },
            {
              title: 'Hồ sơ điều chỉnh',
              bullet: 'dot',
              page: '/workflow/adjustmentItems',
              icon: 'flaticon-interface-11',
              // badge: {
              //   value: '0',
              // },
            },
            {
              title: 'Danh mục hồ sơ',
              bullet: 'dot',
              page: '/workflow/category',
              icon: 'flaticon-list-2',
            },
          ],
        },
        {
          title: 'Hồ sơ của tôi',
          root: true,
          alignment: 'left',
          toggle: 'click',
          badge: {
            value: '0',
          },
          notAllowPermissions: ['WORKFLOW_PROCESSING', 'WORKFLOW_DASHBOARD'],
          page: '/workflow/myrequestitems',
          category: 'overall',
          cateIcon: 'list_alt.svg',
        },
        {
          title: 'Thẩm định',
          root: true,
          alignment: 'left',
          toggle: 'click',
          badge: {
            value: '0',
          },
          page: '/workflow/internal-approve',
          permissions: 'COUNCIL_MEMBER',
          category: 'overall',
          cateIcon: 'approval-menu.svg',
        },
        {
          title: 'Hợp đồng',
          root: false,
          alignment: 'left',
          toggle: 'click',
          page: '/workflow/contractlist',
          category: 'overall',
          cateIcon: 'small_request_quote.svg',
        },
        {
          title: 'Hội đồng',
          root: true,
          alignment: 'left',
          toggle: 'click',
          page: '/workflow/councillist',
          permissionsArray: ['WORKFLOW_PROCESSING', 'COUNCIL_APPROVING'],
          category: 'overall',
          cateIcon: 'list_alt.svg',
        },
        {
          title: 'Quản lý chữ ký',
          root: true,
          alignment: 'left',
          toggle: 'click',
          page: '/workflow/signature-management',
          category: 'overall',
          cateIcon: 'gesture.svg',
        },
        {
          title: 'Thiết lập',
          root: true,
          alignment: 'left',
          toggle: 'click',
          page: '/workflow/workflowmanagement',
          permissions: 'WORKFLOW_ADMIN',
          category: 'system',
          cateIcon: 'settings.svg',
          hideOnMobile: true,
          submenu: [
            {
              title: 'Quản lý quy trình',
              bullet: 'dot',
              page: '/workflow/workflowmanagement',
              icon: 'flaticon-file-2',
              permissions: 'WORKFLOW_ADMIN',
            },
            {
              title: 'Dạng và loại sản phẩm nghiệm thu',
              bullet: 'dot',
              page: '/workflow/product-type',
              icon: 'flaticon-file-2',
              permissions: 'WORKFLOW_PROCESSING',
            },
            {
              title: 'Nguồn tài trợ kinh phí',
              bullet: 'dot',
              page: '/workflow/funding-sources',
              icon: 'flaticon-file-2',
              permissions: 'WORKFLOW_ADMIN',
            },
            {
              title: 'Danh sách ngành',
              bullet: 'dot',
              page: '/workflow/industry-management',
              icon: 'flaticon-file-2',
              permissions: 'WORKFLOW_ADMIN',
            },
            {
              title: 'Quản lý danh sách dạng nhiệm vụ',
              bullet: 'dot',
              page: '/workflow/mission-type-management',
              icon: 'flaticon-file-2',
              permissions: 'WORKFLOW_ADMIN',
            },
          ],
        },
      ],
    },
  };

  private _MyWorkitemBadge = this.defaults.header.items.find(
    (c) => c.title === 'Hồ sơ của tôi'
  );
  private _MyProcessBadge = this.defaults.header.items.find(
    (c) => c.title === 'Xử lý hồ sơ'
  );
  private _WaitApproveBadge = this.defaults.header.items.find(
    (c) => c.title === 'Thẩm định'
  );

  public get configs(): any {
    return this.defaults;
  }
  public get MyProcessBadge(): any {
    if (!this._MyProcessBadge) return 0;
    return this._MyProcessBadge.badge.value;
  }
  public get WaitApproveBadge(): any {
    if (!this._WaitApproveBadge) return 0;
    return this._WaitApproveBadge.badge.value;
  }
  public get MyWorkitemBadge(): any {
    if (!this._MyWorkitemBadge) return 0;
    return this._MyWorkitemBadge.badge.value;
  }
  public set setConfigMyProcessBadge(val) {
    const menuItem = this._MyProcessBadge;
    if (!menuItem) return;
    menuItem.submenu[0].badge.value = val;
    menuItem.badge.value = val;
  }
  public set setConfigWaitApproveBadge(val) {
    const menuItem = this._WaitApproveBadge;
    if (!menuItem) return;
    menuItem.badge.value = val;
  }
  public set setConfigMyWorkitemBadge(val) {
    const menuItem = this._MyWorkitemBadge;
    if (!menuItem) return;
    menuItem.badge.value = val;
  }
}
