import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'time',
})
export class TimePipe implements PipeTransform {
  transform(value: String): String {
    let result = '';
    for (var i = 0; i < value?.length; i++) {
      result += value[i];
      if (value.length == 2 && !value.includes(':') && i == 1) {
        result += ':';
      } else {
        if (value.length == 2 && value.includes(':') && i == 1) {
          result = '0' + result;
        }
      }
    }
    return result;
  }
}
