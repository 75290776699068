<ng-container *ngIf="title">
  <label for="{{ name }}" attr.data-name="{{ name }}" class="input-label"
  >{{ title }}
  <span class="required-input-label" *ngIf="isRequiredField">*</span></label
>
</ng-container>

<span class="meu-datepicker-input">
  <mat-icon class="icon-calendar" (click)="founding_datepicker.open()"
    >date_range</mat-icon
  >
  <input
    [matDatepicker]="founding_datepicker"
    (click)="founding_datepicker.open()"
    class="input-box"
    placeholder="{{ placeholderText }}"
    [formControl]="formControl"
  />
</span>

<mat-datepicker
  #founding_datepicker
  (monthSelected)="setMonthAndYear(formControl, $event, founding_datepicker)"
  startView="multi-year"
></mat-datepicker>

<div *ngIf="invalidState" class="input-error-label">
  <ng-content select="[errorMessage]"></ng-content>
</div>
