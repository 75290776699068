<span
  [matTooltip]="content"
  [ngClass]="{
    'ellipsis-two-lines': showMore,
    'show-more': !showMore,
    'subcell-30px': showMore
  }"
  #spanElement
  *ngIf="content"
>
  <a href="javascript:void(0)" (click)="toggleEllipsis()">Ý kiến:</a>
  {{ content }}
</span>
<div *ngIf="isShowMore">
  <a href="javascript:void(0)" *ngIf="showMore" (click)="toggleEllipsis()"
    >Xem thêm</a
  >
  <a href="javascript:void(0)" *ngIf="!showMore" (click)="toggleEllipsis()"
    >Thu gọn</a
  >
</div>
