<div [ngClass]="!isQuickview ? '' : 'mw-150px'" class="dashboard-hashtag">
  <div *ngIf="isEdit">
    <div
      class="relative border border-grayscale-light-grey hover:border-primary-light focus:border-dark-blue rounded cursor-text"
      [ngClass]="
        !isQuickview
          ? 'mh-40px pt-2px pl-3px pr-25px pb-0px '
          : 'mh-30px w-150px pr-20px pt-0 pl-3px'
      "
    >
      <div class="input-hashtag flex">
        <div
          *ngFor="let tag of arrHashtag; index as i"
          class="flex rounded-full"
          [ngClass]="!isQuickview ? 'mt-5px' : 'mt-2px'"
        >
          <label
            class="flex tag-item rounded py-3px mb-0 text-gray text-12px font-roboto-regular h-24px"
            [title]="
              tag.extend_receiver_email_full_name
                ? tag.extend_receiver_email_full_name
                : tag?.extend_user_full_name + ' (' + tag?.email + ')'
            "
            [ngClass]="
              !isQuickview
                ? ' mx-5px  px-10px'
                : ' mx-3px mw-100px ellipsis  px-7px'
            "
          >
            <span [ngClass]="!isQuickview ? '' : 'ellipsis'">{{
              tag.extend_receiver_email_full_name
                ? tag.extend_receiver_email_full_name
                : tag?.extend_user_full_name + ' (' + tag?.email + ')'
            }}</span>
            <img
              class="cursor-pointer ml-5px"
              src="/assets/icons/icon_close.svg"
              alt=""
              height="14px"
              width="14px"
              (click)="removeTag(i)"
            />
          </label>
        </div>
        <meu-control-select-search-load-more
          [placeholderLabel]="'Chọn người được chia sẻ'"
          [noEntriesFoundLabel]="'Không có lựa chọn phù hợp'"
          [nameAPI]="'/api/users'"
          [columnName]="'extend_user_name_email'"
          [floatLabel]="false"
          (change)="changeLeagueOwner($event)"
        >
        </meu-control-select-search-load-more>
      </div>

      <img
        *ngIf="arrHashtag.length > 0"
        tooltip="Xóa tất cả"
        class="cursor-pointer absolute right-5px"
        src="/assets/icons/icon_close.svg"
        alt=""
        (click)="removeAll()"
        [ngClass]="
          !isQuickview
            ? 'h-20px w-20px icon-remove-all'
            : 'h-15px w-15px quickview-icon-remove-all'
        "
      />
    </div>
  </div>
</div>
