<!--begin: Quick actions -->
<div
  ngbDropdown
  placement="bottom-right"
  autoClose="outside"
  class="kt-header__topbar-item"
>
  <div ngbDropdownToggle class="kt-header__topbar-wrapper">
    <span
      class="kt-header__topbar-icon"
      [ngClass]="{'kt-header__topbar-icon--warning': iconType === 'warning'}"
    >
      <i *ngIf="!useSVG" [ngClass]="icon"></i>
      <span
        *ngIf="useSVG"
        class="kt-svg-icon"
        [inlineSVG]="icon"
        [ngClass]="{'kt-svg-icon--warning': iconType === 'warning'}"
      ></span>
    </span>
  </div>
  <div
    ngbDropdownMenu
    style="width: 200px"
    class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl quick-action-dropdown"
  >
    <form>
      <!--begin: Head -->
      <!-- [ngStyle]="{'background-image': 'url(' + bgImage + ')'}" -->
      <div class="kt-head kt-head--skin-{{ skin }}">
        <h3 class="kt-head__title">
          Phân hệ tính năng
          <!-- <span class="kt-space-15"></span>
					<span class="btn btn-success btn-sm btn-bold btn-font-md">23 tasks pending</span> -->
        </h3>
      </div>
      <!--end: Head -->
      <!--begin: Grid Nav -->
      <div class="kt-grid-nav kt-grid-nav--skin-{{ gridNavSkin }}">
        <div class="kt-grid-nav__row">
          <a [href]="ssoFeUrl" class="kt-grid-nav__item">
            <span
              class="kt-grid-nav__icon"
              [inlineSVG]="'./assets/media/icons/svg/Communication/Group.svg'"
              (onSVGInserted)="onSVGInserted($event)"
            ></span>
            <span class="kt-grid-nav__title">SSO</span>
            <span class="kt-grid-nav__desc">Nhân sự</span>
          </a>
        </div>
        <!--end: Grid Nav -->
      </div>
    </form>
  </div>
</div>
<!--end: Quick actions -->
