<label>Upload File By Dropzone</label>
<meu-control-upload-file-dropzone
  [code]="'testDropzone'"
  [disabled]="true"
></meu-control-upload-file-dropzone>

<label>Upload File</label>
<meu-control-upload-file
  [requiredFile]="arrayRequiredFile"
  [code]="'test'"
  [addMore]="true"
  [edit]="true"
>
</meu-control-upload-file>

<label>Select search load more</label>
<meu-control-select-search-load-more
  [nameAPI]="'/api/users'"
  [multiple]="true"
  [placeholderLabel]="'Cấp trên duyệt yêu cầu'"
  [columnName]="'extend_user_name_email'"
  [required]="true"
  [errorLabel]="'Chọn cấp trên phù hợp'"
>
</meu-control-select-search-load-more>
<div class="d-flex-row">
  <meu-control-select-search-load-more
    [nameAPI]="'/api/users'"
    [placeholderLabel]="'Cấp trên duyệt yêu cầu'"
    [columnName]="'extend_user_name_email'"
    [stringFilter]="filter"
    [required]="true"
    [errorLabel]="'Chọn cấp trên phù hợp'"
    #inputRemove
  ></meu-control-select-search-load-more>
  <button
    class="btn btn-success"
    (click)="searchLoadMoreRemove(inputRemove.value)"
  >
    Remove
  </button>
</div>
<label>Select search</label>
<meu-control-select-search
  [placeholderLabel]="'Cấp trên duyệt yêu cầu'"
  [noEntriesFoundLabel]="'Không có lựa chọn phù hợp'"
  [arrayInput]="allManager"
>
</meu-control-select-search>

<label>Daterangepicker</label>
<meu-date-range-picker [placeholderLabel]="'Choose Date'">
</meu-date-range-picker>

<p>Avatar card</p>
<p>Popover (hover)</p>
<mat-table class="m-5" #table [dataSource]="allUsers">
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef> Tên CBNV </mat-header-cell>
    <mat-cell *matCellDef="let element" class="cell-element">
      <span class="col-12 non-padding fw-600 show-on-mobile"> Tên CBNV </span>
      <span class="ellipsis">
        <meu-avatar-card [userId]="element.id" [path]="element.avatar">
        </meu-avatar-card>
        {{ element.extend_user_full_name }}
      </span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="email">
    <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
    <mat-cell
      *matCellDef="let element"
      [matTooltip]="element.email"
      class="cell-element"
    >
      <span class="col-12 non-padding fw-600 show-on-mobile"> Email </span>
      <span class="ellipsis">
        {{ element.email }}
      </span>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
