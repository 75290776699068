<div class="modal-content-container custom-dashboard">
  <div
    class="flex flex-col pb-8px pt-16px px-16px border-b border-tlr-lightest-blue"
  >
    <div class="text-16px text-dark-grey font-roboto-medium">
      Chọn bố cục hiển thị
      <img
        src="/assets/icons/u_times.svg"
        alt=""
        height="20px"
        (click)="closeModalClick(null)"
        class="cursor-pointer lg:block inline float-right"
      />
    </div>
  </div>

  <div class="kt-portlet__body kt-padding-l-10 kt-padding-r-10 max-height-body">
    <div class="grid grid-cols-12 gap-8px overflow-mobile-dcl">
      <div
        *ngFor="let item of templateLayout; index as i"
        class="col-span-12 md:col-span-4"
      >
        <div
          class="grid grid-cols-12 gap-8px br-4 m-10px cursor-pointer py-8px px-12px rounded border"
          (click)="getCurrentLayout(item, i)"
          [ngClass]="
            layoutSelected != null && indexSelect === i
              ? 'border-blue'
              : 'border-tlr-greynest border-dashed'
          "
        >
          <div
            *ngFor="let subItem of item.col_layout"
            class="p-0 h-84px bg-tlr-grey"
            [ngClass]="'col-span-' + subItem"
          >
            <div class="br-4 mr-5px item-layout"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-end p-16px">
    <button
      class="btn btn-outline-primary mr-2"
      (click)="closeModalClick(null)"
    >
      Hủy
    </button>
    <button class="btn btn-primary" (click)="save()">Lưu</button>
  </div>
</div>
