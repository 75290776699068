<div class="kt-portlet" style="overflow: hidden; margin-bottom: 0px">
  <div class="kt-portlet__head kt-portlet__head__custom meu-title-bg">
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title title-color font-roboto-medium">
        Cấu hình widget: {{ board_widget.name }}
      </h3>
    </div>
    <div mat-dialog-close class="close_modal_btn meu-item-center">
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div class="meu-p-4 meu-grid meu-grid-cols-1 create-flow-body">
    <div
      *ngFor="let itemConfig of board_widget.array_configuration; index as i"
      class=""
    >
      <div class="form-group mb-0">
        <div class="text-12px text-grey mb-5px">{{ itemConfig.Label }}</div>
        <div *ngIf="itemConfig.Control.Type == 'TEXTBOX'">
          <input
            type="text"
            placeholder="{{ itemConfig.Control.PlaceHolder }}"
            class="form-control input-field"
            autocomplete="off"
            [(ngModel)]="itemConfig.Values"
          />
        </div>

        <div *ngIf="itemConfig.Control.Type == 'DATE_PICKER'">
          <meu-control-datepicker
            [placeholderLabel]="itemConfig.Control.PlaceHolder"
            [placeholder]="itemConfig.Control.PlaceHolder"
            (ngModelChange)="getDependNameApi(itemConfig, $event)"
            [ngModel]="itemConfig.Values"
            [defaultID]="itemConfig.Values"
          ></meu-control-datepicker>
        </div>

        <div
          style="height: 50px"
          class="select-search-more"
          *ngIf="itemConfig.Control.Type == 'YEAR_PICKER'"
        >
          <meu-multidatepicker
            jpCustomFormFieldClass="input-custom"
            tabindex="-1"
            mode="YEAR"
            [label]="itemConfig.Control.PlaceHolder"
            [(ngModel)]="itemConfig.Values"
            #ngModelFrom="ngModel"
          ></meu-multidatepicker>
        </div>

        <div *ngIf="itemConfig.Control.Type == 'NUMBER'">
          <input
            min="0"
            type="number"
            placeholder="{{ itemConfig.Control.PlaceHolder }}"
            class="form-control input-field"
            autocomplete="off"
            [(ngModel)]="itemConfig.Values"
          />
        </div>
        <div
          class="cls-selectbox"
          *ngIf="itemConfig.Control.Type == 'SELECT_SINGLE'"
        >
          <meu-control-select-search
            [floatLabel]="false"
            [placeholderLabel]="itemConfig.Control.PlaceHolder"
            [noEntriesFoundLabel]="'Không có lựa chọn phù hợp'"
            [columnName]="itemConfig.Control.Label"
            [columnValue]="itemConfig.Control.Value"
            [nameAPI]="itemConfig.Control.ApiUrl"
            [defaultID]="getIdDefault(itemConfig.Values)"
            [required]="itemConfig.Required"
            [(ngModel)]="itemConfig.Values"
            (change)="getDependNameApi(itemConfig)"
          >
          </meu-control-select-search>
        </div>
        <div
          class="cls-selectbox"
          *ngIf="itemConfig.Control.Type == 'SELECT_SINGLE_LOADMORE'"
        >
          <meu-control-select-search-load-more
            [floatLabel]="false"
            [placeholderLabel]="itemConfig.Control.PlaceHolder"
            [noEntriesFoundLabel]="'Không có lựa chọn phù hợp'"
            [columnName]="itemConfig.Control.Label"
            [columnValue]="itemConfig.Control.Value"
            [nameAPI]="itemConfig.Control.ApiUrl"
            [defaultID]="getIdDefault(itemConfig.Values)"
            [multiple]="false"
            [(ngModel)]="itemConfig.Values"
            (change)="getDependNameApi(itemConfig)"
          >
          </meu-control-select-search-load-more>
        </div>

        <div *ngIf="itemConfig.Control.Type == 'SELECT_MULTIPLE'">
          <meu-control-select-search-load-more
            [floatLabel]="false"
            [placeholderLabel]="itemConfig.Control.PlaceHolder"
            [noEntriesFoundLabel]="'Không có lựa chọn phù hợp'"
            [columnName]="itemConfig.Control.Label"
            [columnValue]="itemConfig.Control.Value"
            [nameAPI]="itemConfig.Control.ApiUrl"
            [defaultID]="itemConfig.Values"
            [multiple]="true"
            [(ngModel)]="itemConfig.MultiValues"
            (change)="getDependNameApi(itemConfig)"
          >
          </meu-control-select-search-load-more>
        </div>

        <div
          *ngIf="itemConfig.Control.Type == 'COLOR_PICKER'"
          class="flex items-center"
        >
          <input type="color" [(ngModel)]="itemConfig.Values" />
          <input
            class="form-control input-field ml-5px"
            type="text"
            [(ngModel)]="itemConfig.Values"
          />
        </div>
      </div>
    </div>
    <div
      class="col-12 text-center"
      *ngIf="board_widget.array_configuration.length === 0"
    >
      Không có cấu hình
    </div>
  </div>

  <div
    class="kt-portlet__foot kt-portlet__foot--fit kt-portlet__no-border"
    id="modal-footer"
  >
    <div class="kt-form__actions kt-form__actions--solid">
      <div class="meu-p-2-5 text-right">
        <button
          class="meu-basic-button"
          (click)="cancelConfig()"
          mat-raised-button
        >
          Hủy
        </button>
        <button
          class="meu-primary-button ml-2"
          (click)="saveConfig()"
          mat-raised-button
          color="primary"
        >
          Lưu
        </button>
      </div>
    </div>
  </div>
</div>
