import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
export interface RadioButtonOptions {
  label: string;
  value: string;
}

@Component({
  selector: 'meu-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
})
export class RadioButtonComponent implements OnInit {
  @ViewChild('radioRef') radioRef: ElementRef<HTMLInputElement>;
  @Input() radioOption: RadioButtonOptions;
  @Input() radiobuttonControl: FormControl;
  @Input() disabled: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
