<span (mouseleave)="closePopover(popover)">
  <img
    class="pointer main-image"
    [src]="path"
    [style.width]="width"
    (click)="openCard(cardContent, popover)"
    (mouseenter)="popoverOpen(popover)"
    [ngbPopover]="cardContent"
    [autoClose]="'outside'"
    triggers="manual"
    [placement]="popPlacement"
    popoverClass="custom-popover"
    [disablePopover]="type == 'click' ? true : false"
    #popover="ngbPopover"
  />
</span>
<ng-template #cardContent centered>
  <div
    class="m-0"
    [class.flex-card]="type == 'hover'"
    [class.flex-modal]="type == 'click'"
  >
    <div class="image-card d-flex justify-content-between">
      <img class="image-profile" [src]="path" />
    </div>
    <div class="content-card">
      <div class="d-flex justify-content-between">
        <span
          class="avatar-label-name ellipsis"
          [ngClass]="{'label-italic': !userInfo?.extend_user_full_name}"
          [matTooltip]="userInfo?.extend_user_full_name"
        >
          {{
            !!userInfo?.extend_user_full_name
              ? userInfo.extend_user_full_name
              : '---'
          }}</span
        >
        <div
          *ngIf="type == 'click'"
          (click)="closeModalCard()"
          class="close_modal_btn pointer"
          [matTooltip]="'Đóng'"
        >
          <mat-icon>close</mat-icon>
        </div>
      </div>
      <div class="d-flex flex-column label-info">
        <span
          class="ellipsis"
          [matTooltip]="userInfo?.employee_code"
          [ngClass]="{'label-italic': !userInfo?.employee_code}"
        >
          ID:
          {{ !!userInfo?.employee_code ? userInfo.employee_code : '---' }}</span
        >
        <span
          class="ellipsis"
          [matTooltip]="userInfo?.email"
          [ngClass]="{'label-italic': !userInfo?.email}"
        >
          {{ !!userInfo?.email ? userInfo.email : 'Email: ---' }}</span
        >
        <span
          class="ellipsis"
          [matTooltip]="userInfo?.company_phone_number"
          [ngClass]="{'label-italic': !userInfo?.company_phone_number}"
        >
          EXT:
          {{
            !!userInfo?.company_phone_number
              ? userInfo.company_phone_number
              : '---'
          }}</span
        >
      </div>
    </div>
  </div>
</ng-template>
