import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import {AttachmentService} from '@app/api/attachment.service';
import {LayoutUtilsService} from '@app/core/_base/crud';
import {FileInput} from 'ngx-material-file-input';
import {FileInputComponent} from 'ngx-material-file-input';
import * as $ from 'jquery';
import {
  HttpClient,
  HttpEvent,
  HttpErrorResponse,
  HttpEventType,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import {environment} from 'environments/environment';
import {Configuration} from '@app/configuration';
import {CustomHttpUrlEncodingCodec} from '@app/encoder';
import {ToastrService} from 'ngx-toastr';
import {saveAs} from 'file-saver';
import {AuthService} from '@app/core/auth/_services';
import {FormControl, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'meu-control-upload-file',
  templateUrl: './control-upload-file.component.html',
  styleUrls: ['./control-upload-file.component.scss'],
})
export class ControlUploadFileComponent implements OnInit {
  @Input() requiredFile: any;
  @Input() code: string;
  @Input() addMore: boolean = true;
  @Input() edit: boolean = true;
  @Input() permissionStr: string = '';
  @Output() checkFile = new EventEmitter();

  optionsA = {
    percent: 85,
    radius: 60,
    showBackground: false,
    outerStrokeWidth: 10,
    innerStrokeWidth: 5,
    subtitleFormat: false, // clear subtitleFormat coming from other options, because Angular does not assign if variable is undefined.
    startFromZero: false,
  };
  focus: any;
  arrDataTale = new MatTableDataSource<any>();
  arrRequired: any;
  arrFileCancel: any;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  displayedColumns: string[] = [
    'index',
    'fileDescription',
    'fileName',
    'fileSize',
    'action',
  ];
  isHasPermission: boolean;
  file: any;
  oldName: any = '';

  constructor(
    private attachmentService: AttachmentService,
    private layoutUtilsService: LayoutUtilsService,
    private httpClient: HttpClient,
    private changeDetectorRef: ChangeDetectorRef,
    private toastr: ToastrService,
    private authService: AuthService
  ) {}
  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.code != undefined &&
      changes.code != null &&
      changes.code.previousValue != changes.code.currentValue
    ) {
      this.getAttachmentByCode(this.code);
    }
    if (this.permissionStr != '') {
      this.authService.getMyPermissions().subscribe((lstPermission) => {
        this.isHasPermission = lstPermission.includes(this.permissionStr);
        this.edit = this.isHasPermission;
        this.addMore = this.isHasPermission;
      });
    }
  }
  ngOnInit() {}
  getAttachmentByCode(code) {
    this.attachmentService.apiAttachmentsCodeGet(code).subscribe((res) => {
      this.arrFileCancel = res.data.collection;
      this.arrFileCancel = [...this.arrFileCancel];
      let arrRes = res.data.collection;
      if (arrRes.length > 0) {
        let arrNotRequired = [];
        this.arrRequired = [];
        if (this.requiredFile.length == 0) {
          arrNotRequired = arrRes;
        }
        for (let j = 0; j < this.requiredFile.length; j++) {
          this.requiredFile[j].isDisable = false;
          for (let i = 0; i < arrRes.length; i++) {
            if (this.requiredFile[j].sub_code == arrRes[i].sub_code) {
              this.arrRequired.push(arrRes[i]);
            } else {
              if (
                this.arrRequired.find(
                  (x) => x.sub_code == arrRes[i].sub_code
                ) == null
              ) {
                arrNotRequired.push(arrRes[i]);
              }
            }
          }
          if (
            this.arrRequired.find(
              (x) => x.sub_code == this.requiredFile[j].sub_code
            ) == null
          ) {
            this.arrRequired.push(this.requiredFile[j]);
          }
        }
        arrNotRequired.forEach((element) => {
          if (
            this.arrRequired.find((x) => x.sub_code == element.sub_code) == null
          ) {
            this.arrRequired.push(element);
          }
        });
        this.arrRequired.forEach(function (element) {
          if (element.file_name != undefined) {
            element.editFile = false;
            element.isChange = false;
          }
        });
      } else {
        this.arrRequired = this.requiredFile;
        this.arrRequired = [...this.arrRequired];
      }
      //this.arrDataTale.data = this.arrRequired;
      this.changeDetectorRef.detectChanges();
    });
  }
  cancelEdit(i, item) {
    let checkFileServer = this.arrFileCancel.find((x) => x.id == item.id);
    if (!!item.progress) {
      this.toastr.warning('Tài liệu đang được xử lý, xin vui lòng chờ', '');
      return;
    }
    if (item.editFile) {
      if (!!item.old_file_name) {
        item.file_name = item.old_file_name;
      } else {
        item.file_name = '';
      }
      item.name = this.oldName;
    } else {
      item.file_name = '';
    }
    if (!!checkFileServer) {
      this.arrRequired.splice(
        i,
        1,
        this.arrFileCancel.find((x) => x.id == item.id)
      );
    }
    item.editFile = false;
    item.choseFile = false;

    if (!item.file_name) {
      item.file_name = '';
      item.file_size = '';
    }
    if (
      this.requiredFile.length > 0 &&
      this.checkExistRequired(item.sub_code) == false &&
      item.more
    ) {
      this.arrRequired.splice(i, 1);
      this.arrRequired = [...this.arrRequired];
    }
  }
  viewFile(url, file_name) {
    url = url.replace('/api/attachments/download?url=', '');
    this.attachmentService.apiAttachmentsDownloadGet(url).subscribe((res) => {
      var downloadURL = URL.createObjectURL(res);
      if (
        file_name.includes('png') ||
        file_name.includes('jpg') ||
        file_name.includes('jpeg') ||
        file_name.includes('pdf')
      ) {
        window.open(downloadURL);
      } else {
        var blob = new Blob([res]);
        saveAs(blob, file_name);
      }
    });
  }
  deleteFile(item, i) {
    if (
      this.requiredFile.find((x) => x.sub_code == item.sub_code) == null &&
      item.file_name == ''
    ) {
      this.arrRequired.splice(i, 1);
      this.arrRequired = [...this.arrRequired];
    } else {
      if (item.file_name != '') {
        const title = 'Xóa tài liệu';
        const description = 'Bạn có chắc muốn xóa tài liệu này ?';
        const waitDesciption = 'Đang xử lý...';

        const dialogRef = this.layoutUtilsService.deleteElement(
          title,
          description,
          waitDesciption
        );
        dialogRef.afterClosed().subscribe((res) => {
          if (!res) {
            return;
          }
          this.attachmentService
            .apiAttachmentsIdDelete(item.id)
            .subscribe((res) => {
              if (res.success == true) {
                this.toastr.success('Xóa tệp thành công', '', {});
                item.showUploadButton = true;
                //document.getElementById().val ;
                $('#file' + i).val('');
                item.editFile = true;
                item.file_size = null;
                item.file_name = null;
                item.id = null;
                if (
                  this.requiredFile.find((x) => x.sub_code == item.sub_code) ==
                  null
                ) {
                  this.arrRequired.splice(i, 1);
                  this.arrRequired = [...this.arrRequired];
                } else {
                  this.arrRequired.splice(
                    i,
                    1,
                    this.requiredFile.find((x) => x.sub_code == item.sub_code)
                  );
                  this.checkValidationFile();
                  this.arrRequired = [...this.arrRequired];
                }
                item.progress = 0;
                this.arrRequired = [...this.arrRequired];
                this.changeDetectorRef.detectChanges();
              }
            });
        });
      } else {
        if (
          this.requiredFile.find((x) => x.sub_code == item.sub_code) == null
        ) {
          this.arrRequired.splice(i, 1);
          this.arrRequired = [...this.arrRequired];
        } else {
          this.arrRequired.splice(
            i,
            1,
            this.requiredFile.find((x) => x.sub_code == item.sub_code)
          );
          this.arrRequired = [...this.arrRequired];
        }
      }
    }
  }
  dowloadFile(url) {
    url = url.replace('/api/attachments/download?url=', '');
    this.attachmentService.apiAttachmentsDownloadGet(url).subscribe((res) => {
      // Use this code to open pdf on new tab
      var downloadURL = URL.createObjectURL(res);
      window.open(downloadURL);
    });
  }
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }
  checkExistRequired(sub_code) {
    if (this.requiredFile.find((x) => x.sub_code == sub_code) != null) {
      return true;
    } else {
      return false;
    }
  }
  chooseFile(event, item) {
    item.old_file_name = item.file_name;
    item.progress = 0;
    item.event = event;
    item.choseFile = true;
    var _this = this;
    let fileList: FileList = event.target.files;
    let file: File = fileList[0];
    item.file_size = fileList[0].size + ' ' + 'KB';
    item.file_name = fileList[0].name;
  }
  checkValidationFile() {
    var numCheck = 0;
    this.arrRequired.forEach((element) => {
      if (
        element?.file_name != null &&
        this.checkExistRequired(element?.sub_code)
      ) {
        numCheck++;
        if (numCheck == this.requiredFile.length) {
          this.checkFile.emit(true);
        } else {
          this.checkFile.emit(false);
        }
      }
    });
  }
  uploadFile(event, item, i) {
    if (!!item.progress) {
      this.toastr.warning('Tài liệu đang được xử lý, xin vui lòng chờ', '');
      return;
    }
    if ((!item.id && item.more && !item.choseFile) || !item.file_name) {
      return;
    }
    if (!item.choseFile && !item.isChange && !item.progress) {
      this.cancelEdit(i, item);
      return;
    }
    if (!item.choseFile) {
      item.choseFile = false;
      item.isChange = false;
      item.editFile = false;
      let attachment = {
        id: item.id,
        name: item.name,
      };
      this.attachmentService
        .apiAttachmentsIdPut(item.id, attachment)
        .subscribe((res) => {
          this.toastr.success('Đã cập nhật thành công', '');
        });
      return;
    }
    item.choseFile = false;
    if (item.id != undefined) {
      this.attachmentService
        .apiAttachmentsIdDelete(item.id)
        .subscribe((res) => {
          if (res.success == true) {
            let fileList: FileList = event.target.files;
            let file: File = fileList[0];
            let attachment = {
              name: item.name,
              code: this.code,
              sub_code: item.sub_code,
              file_name: file.name,
              file_size: item.file_size,
            };
            this.attachmentService
              .apiAttachmentsPost(attachment)
              .subscribe((res) => {
                if (res.success == true) {
                  item.id = res.data.id;
                  let id = item.id;
                  if (id === null || id === undefined) {
                    throw new Error(
                      'Required parameter id was null or undefined when calling apiAttachmentsIdUploadPut.'
                    );
                  }
                  let headers = this.defaultHeaders;

                  // to determine the Accept header
                  let httpHeaderAccepts: string[] = [];
                  const httpHeaderAcceptSelected:
                    | string
                    | undefined = this.configuration.selectHeaderAccept(
                    httpHeaderAccepts
                  );
                  if (httpHeaderAcceptSelected != undefined) {
                    headers = headers.set('Accept', httpHeaderAcceptSelected);
                  }
                  // to determine the Content-Type header
                  const consumes: string[] = ['multipart/form-data'];

                  const canConsumeForm = this.canConsumeForm(consumes);

                  let formParams: {append(param: string, value: any): void};
                  let useForm = false;
                  let convertFormParamsToString = false;
                  // use FormData to transmit files using content-type "multipart/form-data"
                  // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
                  useForm = canConsumeForm;
                  if (useForm) {
                    formParams = new FormData();
                  } else {
                    formParams = new HttpParams({
                      encoder: new CustomHttpUrlEncodingCodec(),
                    });
                  }

                  if (file !== undefined) {
                    formParams =
                      (formParams.append('file', <any>file) as any) ||
                      formParams;
                  }

                  return this.httpClient
                    .request<any>(
                      'put',
                      `${
                        environment.backendhost
                      }/api/attachments/${encodeURIComponent(
                        String(id)
                      )}/upload`,
                      {
                        body: convertFormParamsToString
                          ? formParams.toString()
                          : formParams,
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: 'events',
                        reportProgress: true,
                      }
                    )
                    .subscribe((event: HttpEvent<any>) => {
                      switch (event.type) {
                        case HttpEventType.UploadProgress:
                          item.progress = Math.round(
                            (100 * event.loaded) / event.total
                          );
                          this.changeDetectorRef.detectChanges();
                          return {status: 'progress', message: item.progress};

                        case HttpEventType.Response:
                          if (event.body.success == true) {
                            if (
                              this.requiredFile.find(
                                (x) => x.sub_code == item.sub_code
                              ) != null
                            ) {
                              this.checkValidationFile();
                            }
                            item.id = event.body.data.id;
                            item.showUploadButton = false;
                            item.file_name = event.body.data.file_name;
                            item.old_file_name = event.body.data.file_name;
                            item.editFile = false;
                            item.uploadDone = true;
                            item.physical_path = event.body.data.physical_path;
                            item.progress = 0;
                            item.choseFile = false;
                            // setTimeout(function () {
                            //   item.uploadDone = false;;
                            // }, 50);
                            this.toastr.success(
                              'Đã lưu tệp ' + item.file_name + ' thành công',
                              ''
                            );
                            this.changeDetectorRef.detectChanges();
                          }

                          return event.body;
                        default:
                          return `Unhandled event: ${event.type}`;
                      }
                    });
                }
              });
          }
        });
    } else {
      let fileList: FileList = event.target.files;
      let file: File = fileList[0];
      let attachment = {
        name: item.name,
        code: this.code,
        sub_code: item.sub_code,
        file_name: file.name,
        file_size: item.file_size,
      };
      this.attachmentService.apiAttachmentsPost(attachment).subscribe((res) => {
        if (res.success == true) {
          item.id = res.data.id;
          let id = item.id;
          if (id === null || id === undefined) {
            throw new Error(
              'Required parameter id was null or undefined when calling apiAttachmentsIdUploadPut.'
            );
          }

          let headers = this.defaultHeaders;

          // to determine the Accept header
          let httpHeaderAccepts: string[] = [];
          const httpHeaderAcceptSelected:
            | string
            | undefined = this.configuration.selectHeaderAccept(
            httpHeaderAccepts
          );
          if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
          }

          // to determine the Content-Type header
          const consumes: string[] = ['multipart/form-data'];

          const canConsumeForm = this.canConsumeForm(consumes);

          let formParams: {append(param: string, value: any): void};
          let useForm = false;
          let convertFormParamsToString = false;
          // use FormData to transmit files using content-type "multipart/form-data"
          // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
          useForm = canConsumeForm;
          if (useForm) {
            formParams = new FormData();
          } else {
            formParams = new HttpParams({
              encoder: new CustomHttpUrlEncodingCodec(),
            });
          }

          if (file !== undefined) {
            formParams =
              (formParams.append('file', <any>file) as any) || formParams;
          }

          return this.httpClient
            .request<any>(
              'put',
              `${environment.backendhost}/api/attachments/${encodeURIComponent(
                String(id)
              )}/upload`,
              {
                body: convertFormParamsToString
                  ? formParams.toString()
                  : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: 'events',
                reportProgress: true,
              }
            )
            .subscribe((event: HttpEvent<any>) => {
              switch (event.type) {
                case HttpEventType.UploadProgress:
                  item.progress = Math.round(
                    (100 * event.loaded) / event.total
                  );
                  this.changeDetectorRef.detectChanges();
                  return {status: 'progress', message: item.progress};

                case HttpEventType.Response:
                  if (event.body.success == true) {
                    item.id = event.body.data.id;
                    item.showUploadButton = false;
                    item.file_name = event.body.data.file_name;
                    item.old_file_name = event.body.data.file_name;
                    item.editFile = false;
                    item.physical_path = event.body.data.physical_path;
                    this.changeDetectorRef.detectChanges();
                    item.progress = undefined;
                    item.choseFile = false;
                    this.toastr.success(
                      'Đã lưu tệp ' + item.file_name + ' thành công',
                      ''
                    );
                    if (
                      this.requiredFile.find(
                        (x) => x.sub_code == item.sub_code
                      ) != null
                    ) {
                      this.checkValidationFile();
                    }
                    this.arrFileCancel.push(event.body.data);
                  }
                  return event.body;
                default:
                  return `Unhandled event: ${event.type}`;
              }
            });
        }
      });
    }
  }
  addRowFile() {
    this.arrRequired.push({
      name: '',
      sub_code: this.arrRequired.length + 1,
      file_name: 'Chọn tệp',
      showUploadButton: true,
      editFile: true,
      more: true,
    });
    this.arrRequired = [...this.arrRequired];
    var idTag = 'name' + (this.arrRequired.length - 1);
    this.changeDetectorRef.detectChanges();
  }
}
