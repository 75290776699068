/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { GuidListOperatorItemListTuple } from '../model/guidListOperatorItemListTuple';
import { OperatorItem } from '../model/operatorItem';
import { WorkflowItem } from '../model/workflowItem';
import { WorkflowItemHistory } from '../model/workflowItemHistory';
import { WorkflowItemManager } from '../model/workflowItemManager';
import { WorkflowItemSignature } from '../model/workflowItemSignature';
import { WorkflowUserCouncilPosition } from '../model/workflowUserCouncilPosition';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class WorkflowItemService {

    protected basePath = 'https://gateway.dev.meu-solutions.com/sciencetechnology';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param Filters 
     * @param Sorts 
     * @param Page 
     * @param PageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAllworkflowitemsCompletedGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAllworkflowitemsCompletedGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAllworkflowitemsCompletedGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAllworkflowitemsCompletedGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (Filters !== undefined && Filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>Filters);
        }
        if (Sorts !== undefined && Sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>Sorts);
        }
        if (Page !== undefined && Page !== null) {
            queryParameters = queryParameters.set('Page', <any>Page);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/allworkflowitems/completed`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param Filters 
     * @param Sorts 
     * @param Page 
     * @param PageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAllworkflowitemsProcessingGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAllworkflowitemsProcessingGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAllworkflowitemsProcessingGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAllworkflowitemsProcessingGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (Filters !== undefined && Filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>Filters);
        }
        if (Sorts !== undefined && Sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>Sorts);
        }
        if (Page !== undefined && Page !== null) {
            queryParameters = queryParameters.set('Page', <any>Page);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/allworkflowitems/processing`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param code 
     * @param updateTableURL 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiEditTableWorkItemByCodeCodePut(code: string, updateTableURL?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiEditTableWorkItemByCodeCodePut(code: string, updateTableURL?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiEditTableWorkItemByCodeCodePut(code: string, updateTableURL?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiEditTableWorkItemByCodeCodePut(code: string, updateTableURL?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling apiEditTableWorkItemByCodeCodePut.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (updateTableURL !== undefined && updateTableURL !== null) {
            queryParameters = queryParameters.set('updateTableURL', <any>updateTableURL);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/editTableWorkItemByCode/${encodeURIComponent(String(code))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param Filters 
     * @param Sorts 
     * @param Page 
     * @param PageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMyRepresentWorkflowItemsGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiMyRepresentWorkflowItemsGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiMyRepresentWorkflowItemsGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiMyRepresentWorkflowItemsGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (Filters !== undefined && Filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>Filters);
        }
        if (Sorts !== undefined && Sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>Sorts);
        }
        if (Page !== undefined && Page !== null) {
            queryParameters = queryParameters.set('Page', <any>Page);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/myRepresentWorkflowItems`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param Filters 
     * @param Sorts 
     * @param Page 
     * @param PageSize 
     * @param workflowTreeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMyWorkflowItemsGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, workflowTreeId?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiMyWorkflowItemsGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, workflowTreeId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiMyWorkflowItemsGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, workflowTreeId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiMyWorkflowItemsGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, workflowTreeId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (Filters !== undefined && Filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>Filters);
        }
        if (Sorts !== undefined && Sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>Sorts);
        }
        if (Page !== undefined && Page !== null) {
            queryParameters = queryParameters.set('Page', <any>Page);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }
        if (workflowTreeId !== undefined && workflowTreeId !== null) {
            queryParameters = queryParameters.set('workflowTreeId', <any>workflowTreeId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/myWorkflowItems`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param dataType 
     * @param Filters 
     * @param Sorts 
     * @param Page 
     * @param PageSize 
     * @param workflowTreeId 
     * @param isInternalApprove 
     * @param isFinanceApprove 
     * @param waitForUpdateConfirm 
     * @param waitForCouncil 
     * @param cancelRequested 
     * @param forStatistic 
     * @param isAdjustment 
     * @param isInspectionApprove 
     * @param isAdjustmentApprove 
     * @param isExpectsApprove 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsAssignedToMeGet(dataType?: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, workflowTreeId?: string, isInternalApprove?: boolean, isFinanceApprove?: boolean, waitForUpdateConfirm?: boolean, waitForCouncil?: boolean, cancelRequested?: boolean, forStatistic?: boolean, isAdjustment?: boolean, isInspectionApprove?: boolean, isAdjustmentApprove?: boolean, isExpectsApprove?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsAssignedToMeGet(dataType?: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, workflowTreeId?: string, isInternalApprove?: boolean, isFinanceApprove?: boolean, waitForUpdateConfirm?: boolean, waitForCouncil?: boolean, cancelRequested?: boolean, forStatistic?: boolean, isAdjustment?: boolean, isInspectionApprove?: boolean, isAdjustmentApprove?: boolean, isExpectsApprove?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsAssignedToMeGet(dataType?: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, workflowTreeId?: string, isInternalApprove?: boolean, isFinanceApprove?: boolean, waitForUpdateConfirm?: boolean, waitForCouncil?: boolean, cancelRequested?: boolean, forStatistic?: boolean, isAdjustment?: boolean, isInspectionApprove?: boolean, isAdjustmentApprove?: boolean, isExpectsApprove?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsAssignedToMeGet(dataType?: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, workflowTreeId?: string, isInternalApprove?: boolean, isFinanceApprove?: boolean, waitForUpdateConfirm?: boolean, waitForCouncil?: boolean, cancelRequested?: boolean, forStatistic?: boolean, isAdjustment?: boolean, isInspectionApprove?: boolean, isAdjustmentApprove?: boolean, isExpectsApprove?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

















        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (dataType !== undefined && dataType !== null) {
            queryParameters = queryParameters.set('dataType', <any>dataType);
        }
        if (Filters !== undefined && Filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>Filters);
        }
        if (Sorts !== undefined && Sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>Sorts);
        }
        if (Page !== undefined && Page !== null) {
            queryParameters = queryParameters.set('Page', <any>Page);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }
        if (workflowTreeId !== undefined && workflowTreeId !== null) {
            queryParameters = queryParameters.set('workflowTreeId', <any>workflowTreeId);
        }
        if (isInternalApprove !== undefined && isInternalApprove !== null) {
            queryParameters = queryParameters.set('isInternalApprove', <any>isInternalApprove);
        }
        if (isFinanceApprove !== undefined && isFinanceApprove !== null) {
            queryParameters = queryParameters.set('isFinanceApprove', <any>isFinanceApprove);
        }
        if (waitForUpdateConfirm !== undefined && waitForUpdateConfirm !== null) {
            queryParameters = queryParameters.set('waitForUpdateConfirm', <any>waitForUpdateConfirm);
        }
        if (waitForCouncil !== undefined && waitForCouncil !== null) {
            queryParameters = queryParameters.set('waitForCouncil', <any>waitForCouncil);
        }
        if (cancelRequested !== undefined && cancelRequested !== null) {
            queryParameters = queryParameters.set('cancelRequested', <any>cancelRequested);
        }
        if (forStatistic !== undefined && forStatistic !== null) {
            queryParameters = queryParameters.set('forStatistic', <any>forStatistic);
        }
        if (isAdjustment !== undefined && isAdjustment !== null) {
            queryParameters = queryParameters.set('isAdjustment', <any>isAdjustment);
        }
        if (isInspectionApprove !== undefined && isInspectionApprove !== null) {
            queryParameters = queryParameters.set('isInspectionApprove', <any>isInspectionApprove);
        }
        if (isAdjustmentApprove !== undefined && isAdjustmentApprove !== null) {
            queryParameters = queryParameters.set('isAdjustmentApprove', <any>isAdjustmentApprove);
        }
        if (isExpectsApprove !== undefined && isExpectsApprove !== null) {
            queryParameters = queryParameters.set('isExpectsApprove', <any>isExpectsApprove);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/assignedToMe`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowTreeId 
     * @param isInternalApprove 
     * @param isFinanceApprove 
     * @param isExpired 
     * @param forStatistic 
     * @param isInspectionApprove 
     * @param isAdjustmentApprove 
     * @param isExpectsApprove 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsAssignedToMeTabsCountGet(workflowTreeId?: string, isInternalApprove?: boolean, isFinanceApprove?: boolean, isExpired?: boolean, forStatistic?: boolean, isInspectionApprove?: boolean, isAdjustmentApprove?: boolean, isExpectsApprove?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsAssignedToMeTabsCountGet(workflowTreeId?: string, isInternalApprove?: boolean, isFinanceApprove?: boolean, isExpired?: boolean, forStatistic?: boolean, isInspectionApprove?: boolean, isAdjustmentApprove?: boolean, isExpectsApprove?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsAssignedToMeTabsCountGet(workflowTreeId?: string, isInternalApprove?: boolean, isFinanceApprove?: boolean, isExpired?: boolean, forStatistic?: boolean, isInspectionApprove?: boolean, isAdjustmentApprove?: boolean, isExpectsApprove?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsAssignedToMeTabsCountGet(workflowTreeId?: string, isInternalApprove?: boolean, isFinanceApprove?: boolean, isExpired?: boolean, forStatistic?: boolean, isInspectionApprove?: boolean, isAdjustmentApprove?: boolean, isExpectsApprove?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workflowTreeId !== undefined && workflowTreeId !== null) {
            queryParameters = queryParameters.set('workflowTreeId', <any>workflowTreeId);
        }
        if (isInternalApprove !== undefined && isInternalApprove !== null) {
            queryParameters = queryParameters.set('isInternalApprove', <any>isInternalApprove);
        }
        if (isFinanceApprove !== undefined && isFinanceApprove !== null) {
            queryParameters = queryParameters.set('isFinanceApprove', <any>isFinanceApprove);
        }
        if (isExpired !== undefined && isExpired !== null) {
            queryParameters = queryParameters.set('isExpired', <any>isExpired);
        }
        if (forStatistic !== undefined && forStatistic !== null) {
            queryParameters = queryParameters.set('forStatistic', <any>forStatistic);
        }
        if (isInspectionApprove !== undefined && isInspectionApprove !== null) {
            queryParameters = queryParameters.set('isInspectionApprove', <any>isInspectionApprove);
        }
        if (isAdjustmentApprove !== undefined && isAdjustmentApprove !== null) {
            queryParameters = queryParameters.set('isAdjustmentApprove', <any>isAdjustmentApprove);
        }
        if (isExpectsApprove !== undefined && isExpectsApprove !== null) {
            queryParameters = queryParameters.set('isExpectsApprove', <any>isExpectsApprove);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/assignedToMe/tabsCount`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param body 
     * @param reason 
     * @param password 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsCancelRequestIdPut(id: string, body?: Array<string>, reason?: string, password?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsCancelRequestIdPut(id: string, body?: Array<string>, reason?: string, password?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsCancelRequestIdPut(id: string, body?: Array<string>, reason?: string, password?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsCancelRequestIdPut(id: string, body?: Array<string>, reason?: string, password?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemsCancelRequestIdPut.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (reason !== undefined && reason !== null) {
            queryParameters = queryParameters.set('reason', <any>reason);
        }
        if (password !== undefined && password !== null) {
            queryParameters = queryParameters.set('password', <any>password);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItems/cancelRequest/${encodeURIComponent(String(id))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param code 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsCodesCodeGet(code: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsCodesCodeGet(code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsCodesCodeGet(code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsCodesCodeGet(code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling apiWorkflowItemsCodesCodeGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/codes/${encodeURIComponent(String(code))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsCountAssignedToMeGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsCountAssignedToMeGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsCountAssignedToMeGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsCountAssignedToMeGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/count/assignedToMe`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsForDevUpdateAllComingTimeAndExpireTimeGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsForDevUpdateAllComingTimeAndExpireTimeGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsForDevUpdateAllComingTimeAndExpireTimeGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsForDevUpdateAllComingTimeAndExpireTimeGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/forDev/UpdateAllComingTimeAndExpireTime`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsForDevUpdateExpireTimeForItemsByListIdPost(body?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsForDevUpdateExpireTimeForItemsByListIdPost(body?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsForDevUpdateExpireTimeForItemsByListIdPost(body?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsForDevUpdateExpireTimeForItemsByListIdPost(body?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflowItems/forDev/UpdateExpireTimeForItemsByListId`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsForDevUpdateNotFinishedItemComingTimeAndExpireTimeGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsForDevUpdateNotFinishedItemComingTimeAndExpireTimeGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsForDevUpdateNotFinishedItemComingTimeAndExpireTimeGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsForDevUpdateNotFinishedItemComingTimeAndExpireTimeGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/forDev/UpdateNotFinishedItemComingTimeAndExpireTime`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param activityId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsIdActivitiesActivityIdGet(id: string, activityId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsIdActivitiesActivityIdGet(id: string, activityId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsIdActivitiesActivityIdGet(id: string, activityId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsIdActivitiesActivityIdGet(id: string, activityId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemsIdActivitiesActivityIdGet.');
        }

        if (activityId === null || activityId === undefined) {
            throw new Error('Required parameter activityId was null or undefined when calling apiWorkflowItemsIdActivitiesActivityIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(id))}/activities/${encodeURIComponent(String(activityId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsIdAvailableHashtagsGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsIdAvailableHashtagsGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsIdAvailableHashtagsGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsIdAvailableHashtagsGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemsIdAvailableHashtagsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(id))}/availableHashtags`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsIdBudgetHistoryGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsIdBudgetHistoryGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsIdBudgetHistoryGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsIdBudgetHistoryGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemsIdBudgetHistoryGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(id))}/budgetHistory`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsIdBudgetPut(id: string, body?: WorkflowItem, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsIdBudgetPut(id: string, body?: WorkflowItem, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsIdBudgetPut(id: string, body?: WorkflowItem, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsIdBudgetPut(id: string, body?: WorkflowItem, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemsIdBudgetPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(id))}/budget`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param code 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsIdCodeDelete(id: string, code?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsIdCodeDelete(id: string, code?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsIdCodeDelete(id: string, code?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsIdCodeDelete(id: string, code?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemsIdCodeDelete.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (code !== undefined && code !== null) {
            queryParameters = queryParameters.set('code', <any>code);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(id))}/code`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsIdCompletePut(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsIdCompletePut(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsIdCompletePut(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsIdCompletePut(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemsIdCompletePut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(id))}/complete`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param body 
     * @param status 
     * @param rejectReason 
     * @param representive 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsIdCouncilApprovePut(id: string, body?: WorkflowItemSignature, status?: string, rejectReason?: string, representive?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsIdCouncilApprovePut(id: string, body?: WorkflowItemSignature, status?: string, rejectReason?: string, representive?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsIdCouncilApprovePut(id: string, body?: WorkflowItemSignature, status?: string, rejectReason?: string, representive?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsIdCouncilApprovePut(id: string, body?: WorkflowItemSignature, status?: string, rejectReason?: string, representive?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemsIdCouncilApprovePut.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (rejectReason !== undefined && rejectReason !== null) {
            queryParameters = queryParameters.set('rejectReason', <any>rejectReason);
        }
        if (representive !== undefined && representive !== null) {
            queryParameters = queryParameters.set('representive', <any>representive);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(id))}/councilApprove`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param file 
     * @param cmtFile 
     * @param summaryFile 
     * @param score 
     * @param reason 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsIdCouncilScorePut(id: string, file?: Blob, cmtFile?: Blob, summaryFile?: Blob, score?: number, reason?: string, userId?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsIdCouncilScorePut(id: string, file?: Blob, cmtFile?: Blob, summaryFile?: Blob, score?: number, reason?: string, userId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsIdCouncilScorePut(id: string, file?: Blob, cmtFile?: Blob, summaryFile?: Blob, score?: number, reason?: string, userId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsIdCouncilScorePut(id: string, file?: Blob, cmtFile?: Blob, summaryFile?: Blob, score?: number, reason?: string, userId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemsIdCouncilScorePut.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (score !== undefined && score !== null) {
            queryParameters = queryParameters.set('score', <any>score);
        }
        if (reason !== undefined && reason !== null) {
            queryParameters = queryParameters.set('reason', <any>reason);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }
        if (cmtFile !== undefined) {
            formParams = formParams.append('cmtFile', <any>cmtFile) as any || formParams;
        }
        if (summaryFile !== undefined) {
            formParams = formParams.append('summaryFile', <any>summaryFile) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(id))}/councilScore`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsIdCurrentActivityAvailableOperatorsGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsIdCurrentActivityAvailableOperatorsGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsIdCurrentActivityAvailableOperatorsGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsIdCurrentActivityAvailableOperatorsGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemsIdCurrentActivityAvailableOperatorsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(id))}/currentActivity/availableOperators`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsIdCurrentActivityIsValidToMoveGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsIdCurrentActivityIsValidToMoveGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsIdCurrentActivityIsValidToMoveGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsIdCurrentActivityIsValidToMoveGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemsIdCurrentActivityIsValidToMoveGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(id))}/currentActivity/isValidToMove`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsIdCurrentActivityOperatorsGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsIdCurrentActivityOperatorsGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsIdCurrentActivityOperatorsGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsIdCurrentActivityOperatorsGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemsIdCurrentActivityOperatorsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(id))}/currentActivity/operators`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param body 
     * @param comment 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsIdCurrentActivityOperatorsPut(id: string, body?: Array<OperatorItem>, comment?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsIdCurrentActivityOperatorsPut(id: string, body?: Array<OperatorItem>, comment?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsIdCurrentActivityOperatorsPut(id: string, body?: Array<OperatorItem>, comment?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsIdCurrentActivityOperatorsPut(id: string, body?: Array<OperatorItem>, comment?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemsIdCurrentActivityOperatorsPut.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (comment !== undefined && comment !== null) {
            queryParameters = queryParameters.set('comment', <any>comment);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(id))}/currentActivity/operators`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsIdDelete(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsIdDelete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsIdDelete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsIdDelete(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemsIdDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsIdExtendTimeHistoryGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsIdExtendTimeHistoryGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsIdExtendTimeHistoryGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsIdExtendTimeHistoryGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemsIdExtendTimeHistoryGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(id))}/extendTimeHistory`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsIdExtendTimeHistoryPost(id: string, body?: WorkflowItemHistory, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsIdExtendTimeHistoryPost(id: string, body?: WorkflowItemHistory, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsIdExtendTimeHistoryPost(id: string, body?: WorkflowItemHistory, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsIdExtendTimeHistoryPost(id: string, body?: WorkflowItemHistory, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemsIdExtendTimeHistoryPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(id))}/extendTimeHistory`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemsIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param body 
     * @param firstUpdated 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsIdPut(id: string, body?: WorkflowItem, firstUpdated?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsIdPut(id: string, body?: WorkflowItem, firstUpdated?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsIdPut(id: string, body?: WorkflowItem, firstUpdated?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsIdPut(id: string, body?: WorkflowItem, firstUpdated?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemsIdPut.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (firstUpdated !== undefined && firstUpdated !== null) {
            queryParameters = queryParameters.set('firstUpdated', <any>firstUpdated);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(id))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param currentActivityId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsIdResubmitCurrentActivityIdPut(id: string, currentActivityId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsIdResubmitCurrentActivityIdPut(id: string, currentActivityId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsIdResubmitCurrentActivityIdPut(id: string, currentActivityId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsIdResubmitCurrentActivityIdPut(id: string, currentActivityId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemsIdResubmitCurrentActivityIdPut.');
        }

        if (currentActivityId === null || currentActivityId === undefined) {
            throw new Error('Required parameter currentActivityId was null or undefined when calling apiWorkflowItemsIdResubmitCurrentActivityIdPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(id))}/resubmit/${encodeURIComponent(String(currentActivityId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsIdStartCouncilReviewPut(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsIdStartCouncilReviewPut(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsIdStartCouncilReviewPut(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsIdStartCouncilReviewPut(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemsIdStartCouncilReviewPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(id))}/startCouncilReview`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param base64Data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsNewWorkItemFromBase64Post(base64Data?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsNewWorkItemFromBase64Post(base64Data?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsNewWorkItemFromBase64Post(base64Data?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsNewWorkItemFromBase64Post(base64Data?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (base64Data !== undefined && base64Data !== null) {
            queryParameters = queryParameters.set('base64Data', <any>base64Data);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflowItems/newWorkItemFromBase64`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsPost(body?: WorkflowItem, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsPost(body?: WorkflowItem, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsPost(body?: WorkflowItem, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsPost(body?: WorkflowItem, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflowItems`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param Filters 
     * @param Sorts 
     * @param Page 
     * @param PageSize 
     * @param isInternalApprove 
     * @param isFinanceApprove 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsProcessedByMeGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, isInternalApprove?: boolean, isFinanceApprove?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsProcessedByMeGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, isInternalApprove?: boolean, isFinanceApprove?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsProcessedByMeGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, isInternalApprove?: boolean, isFinanceApprove?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsProcessedByMeGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, isInternalApprove?: boolean, isFinanceApprove?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (Filters !== undefined && Filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>Filters);
        }
        if (Sorts !== undefined && Sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>Sorts);
        }
        if (Page !== undefined && Page !== null) {
            queryParameters = queryParameters.set('Page', <any>Page);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }
        if (isInternalApprove !== undefined && isInternalApprove !== null) {
            queryParameters = queryParameters.set('isInternalApprove', <any>isInternalApprove);
        }
        if (isFinanceApprove !== undefined && isFinanceApprove !== null) {
            queryParameters = queryParameters.set('isFinanceApprove', <any>isFinanceApprove);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/processedByMe`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param workflowTreeId 
     * @param excludeWorkitemList 
     * @param workitemList 
     * @param Filters 
     * @param Sorts 
     * @param Page 
     * @param PageSize 
     * @param comment 
     * @param nextActivityID 
     * @param lstUserNext 
     * @param isInternalApprove 
     * @param isFinanceApprove 
     * @param isAdjustment 
     * @param isInspectionApprove 
     * @param isAdjustmentApprove 
     * @param isExpectsApprove 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsSignAndTransitionWorkflowItemsPost(body?: WorkflowItemSignature, workflowTreeId?: string, excludeWorkitemList?: Array<string>, workitemList?: Array<string>, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, comment?: string, nextActivityID?: string, lstUserNext?: Array<string>, isInternalApprove?: boolean, isFinanceApprove?: boolean, isAdjustment?: boolean, isInspectionApprove?: boolean, isAdjustmentApprove?: boolean, isExpectsApprove?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsSignAndTransitionWorkflowItemsPost(body?: WorkflowItemSignature, workflowTreeId?: string, excludeWorkitemList?: Array<string>, workitemList?: Array<string>, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, comment?: string, nextActivityID?: string, lstUserNext?: Array<string>, isInternalApprove?: boolean, isFinanceApprove?: boolean, isAdjustment?: boolean, isInspectionApprove?: boolean, isAdjustmentApprove?: boolean, isExpectsApprove?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsSignAndTransitionWorkflowItemsPost(body?: WorkflowItemSignature, workflowTreeId?: string, excludeWorkitemList?: Array<string>, workitemList?: Array<string>, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, comment?: string, nextActivityID?: string, lstUserNext?: Array<string>, isInternalApprove?: boolean, isFinanceApprove?: boolean, isAdjustment?: boolean, isInspectionApprove?: boolean, isAdjustmentApprove?: boolean, isExpectsApprove?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsSignAndTransitionWorkflowItemsPost(body?: WorkflowItemSignature, workflowTreeId?: string, excludeWorkitemList?: Array<string>, workitemList?: Array<string>, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, comment?: string, nextActivityID?: string, lstUserNext?: Array<string>, isInternalApprove?: boolean, isFinanceApprove?: boolean, isAdjustment?: boolean, isInspectionApprove?: boolean, isAdjustmentApprove?: boolean, isExpectsApprove?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


















        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workflowTreeId !== undefined && workflowTreeId !== null) {
            queryParameters = queryParameters.set('workflowTreeId', <any>workflowTreeId);
        }
        if (excludeWorkitemList) {
            excludeWorkitemList.forEach((element) => {
                queryParameters = queryParameters.append('excludeWorkitemList', <any>element);
            })
        }
        if (workitemList) {
            workitemList.forEach((element) => {
                queryParameters = queryParameters.append('workitemList', <any>element);
            })
        }
        if (Filters !== undefined && Filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>Filters);
        }
        if (Sorts !== undefined && Sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>Sorts);
        }
        if (Page !== undefined && Page !== null) {
            queryParameters = queryParameters.set('Page', <any>Page);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }
        if (comment !== undefined && comment !== null) {
            queryParameters = queryParameters.set('comment', <any>comment);
        }
        if (nextActivityID !== undefined && nextActivityID !== null) {
            queryParameters = queryParameters.set('nextActivityID', <any>nextActivityID);
        }
        if (lstUserNext) {
            lstUserNext.forEach((element) => {
                queryParameters = queryParameters.append('lstUserNext', <any>element);
            })
        }
        if (isInternalApprove !== undefined && isInternalApprove !== null) {
            queryParameters = queryParameters.set('isInternalApprove', <any>isInternalApprove);
        }
        if (isFinanceApprove !== undefined && isFinanceApprove !== null) {
            queryParameters = queryParameters.set('isFinanceApprove', <any>isFinanceApprove);
        }
        if (isAdjustment !== undefined && isAdjustment !== null) {
            queryParameters = queryParameters.set('isAdjustment', <any>isAdjustment);
        }
        if (isInspectionApprove !== undefined && isInspectionApprove !== null) {
            queryParameters = queryParameters.set('isInspectionApprove', <any>isInspectionApprove);
        }
        if (isAdjustmentApprove !== undefined && isAdjustmentApprove !== null) {
            queryParameters = queryParameters.set('isAdjustmentApprove', <any>isAdjustmentApprove);
        }
        if (isExpectsApprove !== undefined && isExpectsApprove !== null) {
            queryParameters = queryParameters.set('isExpectsApprove', <any>isExpectsApprove);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflowItems/signAndTransitionWorkflowItems`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsSignaturesIdImageGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowItemsSignaturesIdImageGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowItemsSignaturesIdImageGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowItemsSignaturesIdImageGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemsSignaturesIdImageGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflowItems/signatures/${encodeURIComponent(String(id))}/image`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
		responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsUpdateSignCodePut(body?: Array<WorkflowUserCouncilPosition>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsUpdateSignCodePut(body?: Array<WorkflowUserCouncilPosition>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsUpdateSignCodePut(body?: Array<WorkflowUserCouncilPosition>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsUpdateSignCodePut(body?: Array<WorkflowUserCouncilPosition>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItems/updateSignCode`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param activityID 
     * @param nextDirection 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdActivitiesActivityIDAvailableTrasitionsGet(workflowItemId: string, activityID: string, nextDirection?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdActivitiesActivityIDAvailableTrasitionsGet(workflowItemId: string, activityID: string, nextDirection?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdActivitiesActivityIDAvailableTrasitionsGet(workflowItemId: string, activityID: string, nextDirection?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdActivitiesActivityIDAvailableTrasitionsGet(workflowItemId: string, activityID: string, nextDirection?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdActivitiesActivityIDAvailableTrasitionsGet.');
        }

        if (activityID === null || activityID === undefined) {
            throw new Error('Required parameter activityID was null or undefined when calling apiWorkflowItemsWorkflowItemIdActivitiesActivityIDAvailableTrasitionsGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (nextDirection !== undefined && nextDirection !== null) {
            queryParameters = queryParameters.set('nextDirection', <any>nextDirection);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/activities/${encodeURIComponent(String(activityID))}/availableTrasitions`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param activityId 
     * @param body 
     * @param signType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdActivitiesActivityIdSignedPost(workflowItemId: string, activityId: string, body?: WorkflowItemSignature, signType?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdActivitiesActivityIdSignedPost(workflowItemId: string, activityId: string, body?: WorkflowItemSignature, signType?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdActivitiesActivityIdSignedPost(workflowItemId: string, activityId: string, body?: WorkflowItemSignature, signType?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdActivitiesActivityIdSignedPost(workflowItemId: string, activityId: string, body?: WorkflowItemSignature, signType?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdActivitiesActivityIdSignedPost.');
        }

        if (activityId === null || activityId === undefined) {
            throw new Error('Required parameter activityId was null or undefined when calling apiWorkflowItemsWorkflowItemIdActivitiesActivityIdSignedPost.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (signType !== undefined && signType !== null) {
            queryParameters = queryParameters.set('signType', <any>signType);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/activities/${encodeURIComponent(String(activityId))}/signed`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param password 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdCancelApprovePost(workflowItemId: string, password?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdCancelApprovePost(workflowItemId: string, password?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdCancelApprovePost(workflowItemId: string, password?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdCancelApprovePost(workflowItemId: string, password?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdCancelApprovePost.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (password !== undefined && password !== null) {
            queryParameters = queryParameters.set('password', <any>password);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/cancel/approve`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param reason 
     * @param password 
     * @param approverIdList 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdCancelPost(workflowItemId: string, reason?: string, password?: string, approverIdList?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdCancelPost(workflowItemId: string, reason?: string, password?: string, approverIdList?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdCancelPost(workflowItemId: string, reason?: string, password?: string, approverIdList?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdCancelPost(workflowItemId: string, reason?: string, password?: string, approverIdList?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdCancelPost.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (reason !== undefined && reason !== null) {
            queryParameters = queryParameters.set('reason', <any>reason);
        }
        if (password !== undefined && password !== null) {
            queryParameters = queryParameters.set('password', <any>password);
        }
        if (approverIdList) {
            approverIdList.forEach((element) => {
                queryParameters = queryParameters.append('approverIdList', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/cancel`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param reason 
     * @param password 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdCancelRejectPost(workflowItemId: string, reason?: string, password?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdCancelRejectPost(workflowItemId: string, reason?: string, password?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdCancelRejectPost(workflowItemId: string, reason?: string, password?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdCancelRejectPost(workflowItemId: string, reason?: string, password?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdCancelRejectPost.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (reason !== undefined && reason !== null) {
            queryParameters = queryParameters.set('reason', <any>reason);
        }
        if (password !== undefined && password !== null) {
            queryParameters = queryParameters.set('password', <any>password);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/cancel/reject`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param comment 
     * @param finishDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdDonePost(workflowItemId: string, comment?: string, finishDate?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdDonePost(workflowItemId: string, comment?: string, finishDate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdDonePost(workflowItemId: string, comment?: string, finishDate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdDonePost(workflowItemId: string, comment?: string, finishDate?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdDonePost.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (comment !== undefined && comment !== null) {
            queryParameters = queryParameters.set('comment', <any>comment);
        }
        if (finishDate !== undefined && finishDate !== null) {
            queryParameters = queryParameters.set('finishDate', <any>finishDate.toISOString());
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/done`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param file 
     * @param comment 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdInvalidPost(workflowItemId: string, file?: Blob, comment?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdInvalidPost(workflowItemId: string, file?: Blob, comment?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdInvalidPost(workflowItemId: string, file?: Blob, comment?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdInvalidPost(workflowItemId: string, file?: Blob, comment?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdInvalidPost.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (comment !== undefined && comment !== null) {
            queryParameters = queryParameters.set('comment', <any>comment);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/invalid`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflow_item_id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdManagersDelete(workflow_item_id: string, body?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdManagersDelete(workflow_item_id: string, body?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdManagersDelete(workflow_item_id: string, body?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdManagersDelete(workflow_item_id: string, body?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflow_item_id === null || workflow_item_id === undefined) {
            throw new Error('Required parameter workflow_item_id was null or undefined when calling apiWorkflowItemsWorkflowItemIdManagersDelete.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('delete',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflow_item_id))}/managers`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflow_item_id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdManagersPost(workflow_item_id: string, body?: Array<WorkflowItemManager>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdManagersPost(workflow_item_id: string, body?: Array<WorkflowItemManager>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdManagersPost(workflow_item_id: string, body?: Array<WorkflowItemManager>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdManagersPost(workflow_item_id: string, body?: Array<WorkflowItemManager>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflow_item_id === null || workflow_item_id === undefined) {
            throw new Error('Required parameter workflow_item_id was null or undefined when calling apiWorkflowItemsWorkflowItemIdManagersPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflow_item_id))}/managers`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdModifiedLogsGet(workflowItemId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdModifiedLogsGet(workflowItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdModifiedLogsGet(workflowItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdModifiedLogsGet(workflowItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdModifiedLogsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/modifiedLogs`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param body 
     * @param transitionId 
     * @param comment 
     * @param councilId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdMoveToTransitionPost(workflowItemId: string, body?: GuidListOperatorItemListTuple, transitionId?: string, comment?: string, councilId?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdMoveToTransitionPost(workflowItemId: string, body?: GuidListOperatorItemListTuple, transitionId?: string, comment?: string, councilId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdMoveToTransitionPost(workflowItemId: string, body?: GuidListOperatorItemListTuple, transitionId?: string, comment?: string, councilId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdMoveToTransitionPost(workflowItemId: string, body?: GuidListOperatorItemListTuple, transitionId?: string, comment?: string, councilId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdMoveToTransitionPost.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (transitionId !== undefined && transitionId !== null) {
            queryParameters = queryParameters.set('transitionId', <any>transitionId);
        }
        if (comment !== undefined && comment !== null) {
            queryParameters = queryParameters.set('comment', <any>comment);
        }
        if (councilId !== undefined && councilId !== null) {
            queryParameters = queryParameters.set('councilId', <any>councilId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/moveToTransition`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdReceiptsGet(workflowItemId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdReceiptsGet(workflowItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdReceiptsGet(workflowItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdReceiptsGet(workflowItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdReceiptsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/receipts`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param receiptID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdReceiptsReceiptIDGet(workflowItemId: string, receiptID: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowItemsWorkflowItemIdReceiptsReceiptIDGet(workflowItemId: string, receiptID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowItemsWorkflowItemIdReceiptsReceiptIDGet(workflowItemId: string, receiptID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowItemsWorkflowItemIdReceiptsReceiptIDGet(workflowItemId: string, receiptID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdReceiptsReceiptIDGet.');
        }

        if (receiptID === null || receiptID === undefined) {
            throw new Error('Required parameter receiptID was null or undefined when calling apiWorkflowItemsWorkflowItemIdReceiptsReceiptIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/receipts/${encodeURIComponent(String(receiptID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
		responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param receiptID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdReceiptsReceiptIDPdfGet(workflowItemId: string, receiptID: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowItemsWorkflowItemIdReceiptsReceiptIDPdfGet(workflowItemId: string, receiptID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowItemsWorkflowItemIdReceiptsReceiptIDPdfGet(workflowItemId: string, receiptID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowItemsWorkflowItemIdReceiptsReceiptIDPdfGet(workflowItemId: string, receiptID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdReceiptsReceiptIDPdfGet.');
        }

        if (receiptID === null || receiptID === undefined) {
            throw new Error('Required parameter receiptID was null or undefined when calling apiWorkflowItemsWorkflowItemIdReceiptsReceiptIDPdfGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/receipts/${encodeURIComponent(String(receiptID))}/pdf`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
		responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdResultsAttachmentsGet(workflowItemId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdResultsAttachmentsGet(workflowItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdResultsAttachmentsGet(workflowItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdResultsAttachmentsGet(workflowItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdResultsAttachmentsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/results/attachments`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdResultsFormsGet(workflowItemId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdResultsFormsGet(workflowItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdResultsFormsGet(workflowItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdResultsFormsGet(workflowItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdResultsFormsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/results/forms`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdSignaturesGet(workflowItemId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdSignaturesGet(workflowItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdSignaturesGet(workflowItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdSignaturesGet(workflowItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdSignaturesGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/signatures`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdSignaturesIdGet(workflowItemId: string, id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdSignaturesIdGet(workflowItemId: string, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdSignaturesIdGet(workflowItemId: string, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdSignaturesIdGet(workflowItemId: string, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdSignaturesIdGet.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemsWorkflowItemIdSignaturesIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/signatures/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdTransitionHistoriesGet(workflowItemId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdTransitionHistoriesGet(workflowItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdTransitionHistoriesGet(workflowItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdTransitionHistoriesGet(workflowItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdTransitionHistoriesGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/transitionHistories`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflow_item_manager_id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemManagersWorkflowItemManagerIdPut(workflow_item_manager_id: string, body?: WorkflowItemManager, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemManagersWorkflowItemManagerIdPut(workflow_item_manager_id: string, body?: WorkflowItemManager, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemManagersWorkflowItemManagerIdPut(workflow_item_manager_id: string, body?: WorkflowItemManager, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemManagersWorkflowItemManagerIdPut(workflow_item_manager_id: string, body?: WorkflowItemManager, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflow_item_manager_id === null || workflow_item_manager_id === undefined) {
            throw new Error('Required parameter workflow_item_manager_id was null or undefined when calling apiWorkflowItemsWorkflowItemManagersWorkflowItemManagerIdPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItems/workflowItemManagers/${encodeURIComponent(String(workflow_item_manager_id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemsReceiveAtGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemsReceiveAtGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemsReceiveAtGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemsReceiveAtGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/workflowItemsReceiveAt`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
