// Angular
import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
// RxJS
import {Subscription} from 'rxjs';
// Layout
import {SubheaderService} from '../../../../../core/_base/layout';
import {Breadcrumb} from '../../../../../core/_base/layout/services/subheader.service';
import {SubHeaderButtonService} from '../../../../../services/sub-header-button.service';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
@Component({
  selector: 'kt-subheader2',
  templateUrl: './subheader2.component.html',
  styleUrls: ['./subheader2.component.scss'],
})
export class Subheader2Component implements OnInit, OnDestroy, AfterViewInit {
  // Public properties
  @Input() fluid: boolean;
  @Input() clear: boolean;
  buttonCollection = [];
  today: number = Date.now();
  title = '';
  desc = '';
  url: any;
  breadcrumbs: Breadcrumb[] = [];

  // Private properties
  private subscriptions: Subscription[] = [];

  /**
   * Component constructor
   *
   * @param subheaderService: SubheaderService
   */
  constructor(
    public subheaderService: SubheaderService,
    public subHeaderButtonService: SubHeaderButtonService,
    private _location: Location,
    private router: Router
  ) {
    this.buttonCollection = this.subHeaderButtonService.buttonCollection;
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    this.subscriptions.push(
      this.subHeaderButtonService.buttonCollectionChanged.subscribe((item) =>
        this.selectedNavItem(item)
      )
    );
  }

  selectedNavItem(item: any) {
    this.buttonCollection = [...this.subHeaderButtonService.buttonCollection];
  }

  /**
   * After view init
   */
  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.subheaderService.title$.subscribe((bt) => {
        // breadcrumbs title sometimes can be undefined
        if (bt) {
          Promise.resolve(null).then(() => {
            this.title = bt.title;
            this.desc = bt.desc;
          });
        }
      })
    );

    this.subscriptions.push(
      this.subheaderService.breadcrumbs$.subscribe((bc) => {
        Promise.resolve(null).then(() => {
          this.breadcrumbs = bc;
        });
      })
    );

    this.subscriptions.push(
      this.subheaderService.url$.subscribe((bc) => {
        Promise.resolve(null).then(() => {
          this.url = bc.url;
        });
      })
    );
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  goBack() {
    if (this.url) {
      this.router.navigate([this.url]);
    } else {
      this._location.back();
    }
  }
  eventClickBtn(btn) {
    this.subHeaderButtonService.eventBtnClick(btn);
  }
}
