import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  Inject,
} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {map, debounceTime} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {environment} from 'environments/environment';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DynamicDashboardComponent} from '../dynamic-dashboard.component';
import {DbdBoardService} from '@app/api/dbdBoard.service';
import {DbdWidgetCollectionService} from '@app/api/dbdWidgetCollection.service';

@Component({
  selector: 'meu-modal-add-widget',
  templateUrl: './modal-add-widget.component.html',
  styleUrls: ['./modal-add-widget.component.scss'],
})
export class ModalAddWidgetComponent implements OnInit {
  public keyUp = new Subject<KeyboardEvent>();
  dataSearchBasic: any = '';
  filter = '';
  indexBtnSeleted = -1;
  btnSelected;
  listWidget = [];
  listBtn = [];

  paging: any = {
    pageNo: 1,
    pageSize: 2,
    recordCount: 0,
  };
  boardId;
  sort_order;
  board_section_id;
  category;
  @ViewChild('widgetsContent', {read: ElementRef})
  public widgetsContent: ElementRef<any>;

  constructor(
    public dialogRef: MatDialogRef<DynamicDashboardComponent>,
    @Inject(MAT_DIALOG_DATA) public dataInject,
    public ref: ChangeDetectorRef,
    public boardService: DbdBoardService,
    public widgetService: DbdWidgetCollectionService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.boardId = this.dataInject.boardId;
    this.sort_order = this.dataInject.sort_order;
    this.board_section_id = this.dataInject.board_section_id;
    this.category = this.dataInject.category;
    this.keyUp
      .pipe(
        map((event) => event),
        debounceTime(500)
      )
      .subscribe((value) => {
        this.dataSearchBasic = value;
        this.filter = 'widget_name|description@=*' + this.dataSearchBasic;
        this.paging.pageNo = 1;
        this.getWidgetCollection();
      });
    // Load lstBtn
    for (let i = 0; i < this.category.length; i++) {
      this.category[i].isChoose = false;
    }
    if (this.category.length > 0) {
      this.category[0].isChoose = true;
    }
    this.listBtn = this.category;
    // avoid conflict with post spinner
    setTimeout(() => this.getWidgetCollection(), 300);
  }

  save() {
    const widget = this.listWidget.find((p) => p.isChoose === true);
    delete widget.isChoose;
    const body = {
      board_section_id: this.board_section_id,
      sort_order: this.sort_order,
      widget_name: widget.widget_name,
      widget_code: widget.code,
      configuration: widget.configuration,
      name: widget.name,
    };
    this.boardService
      .apiMyBoardsSectionsIdWidgetsPost(this.board_section_id, body)
      .subscribe((result) => {
        if (result.success) {
          this.toastr.success('Thao tác lưu thành công.');
          const data = {
            boardWidget: result.data,
            boardId: this.boardId,
            widgetCollection: widget,
          };
          this.closeModalClick(data);
        } else {
          this.toastr.error('Thao tác lưu không thành công.');
        }
      });
  }

  closeModalClick(data) {
    this.dialogRef.close(data);
  }

  onChangeTab() {
    this.getWidgetCollection();
  }

  getCurrentBtn(index) {
    if (index === 0) {
      this.listBtn = this.listBtn.map((x) => ({
        ...x,
        isChoose: false,
      }));
      this.listBtn[index].isChoose = true;
    } else {
      this.listBtn[0].isChoose = false;
      this.listBtn[index].isChoose = this.listBtn[index].isChoose
        ? false
        : true;
    }
    this.getWidgetCollection();
  }

  getWidgetCollection() {
    const codeArr = [];
    this.listBtn.forEach((element) => {
      if (element.isChoose) {
        codeArr.push(element.code);
      }
    });
    this.widgetService
      .apiDbdWidgetCollectionPost(
        codeArr,
        this.filter,
        '',
        this.paging.pageNo,
        this.paging.pageSize
      )
      .subscribe((res) => {
        if (res.success) {
          this.listWidget = res.data.collection;
          this.sortWidget();
          for (let i = 0; i < this.listWidget.length; i++) {
            this.listWidget[i].image =
              environment.backendhost + this.listWidget[i].image;
            i === 0
              ? (this.listWidget[0].isChoose = true)
              : (this.listWidget[i].isChoose = false);
          }
          this.paging.pageNo = res.data.pageIndex;
          this.paging.recordCount = res.data.total;
          this.paging.pageSize = res.data.pagesize;
          this.paging = Object.assign({}, this.paging);
        }
      });
  }
  chooseWidget(index) {
    this.listWidget.forEach((element) => {
      element.isChoose = false;
    });
    this.listWidget[index].isChoose = this.listWidget[index].isChoose
      ? false
      : true;
  }
  paginatorChange(event?) {
    this.paging.pageNo = event.pageIndex + 1;
    this.paging.pageSize = event.pageSize;
    //this.paging = {...this.paging};
    this.getWidgetCollection();
  }
  scrollRight() {
    this.widgetsContent.nativeElement.scrollTo({
      left: this.widgetsContent.nativeElement.scrollLeft + 150,
      behavior: 'smooth',
    });
  }
  sortWidget() {
    this.listWidget.sort((a, b) => {
      return a.sort_order - b.sort_order;
    });
  }
  scrollLeft() {
    this.widgetsContent.nativeElement.scrollTo({
      left: this.widgetsContent.nativeElement.scrollLeft - 150,
      behavior: 'smooth',
    });
  }
}
