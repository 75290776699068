<!-- <kt-splash-screen *ngIf="htmlClassService.loader$ | async"></kt-splash-screen> -->
<router-outlet></router-outlet>
<meu-modal-confirm
  *ngIf="objectModal"
  [contents]="objectModal"
></meu-modal-confirm>
<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
>
  <p style="color: white">Đang tải...</p>
</ngx-spinner>
