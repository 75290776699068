<div class="mx-auto w-20px h-20px">
  <div class="container flex justify-center mx-auto px-0">
    <div class="relative w-20px h-20px">
      <div class="absolute" (clickOutside)="onClickedOutside()">
        <div class="cursor-pointer text-gray-600" data-menu>
          <img
            [src]="
              iconUrl
                ? iconUrl
                : isVertical
                ? isWhiteIcon
                  ? './assets/icons/svg/fi_more-vertical.svg'
                  : './assets/icons/svg/more-vertical.svg'
                : './assets/icons/svg/more-horizontal.svg'
            "
            alt="more-vertical"
            class="w-20px h-20px cursor-pointer"
            (click)="openMenu($event)"
          />
        </div>
        <ul
          id="dropdown-options"
          [ngClass]="
            isOpenedList === 1
              ? 'visible z-10 transition pl-0 duration-300 opacity-100 bg-white rounded-4px border border-solid border-lightest-grey py-8px w-200px absolute'
              : 'hidden'
          "
          [ngStyle]="{left: isLeftPosition ? '-185px' : ''}"
        >
          <li
            *ngFor="let item of options"
            class="cursor-pointer py-10px px-16px hover:bg-tlr-lightest-blue flex items-center"
            [value]="item.id"
            (click)="emitValue(item.id, $event)"
            [ngClass]="item.divide ? 'divider' : ''"
          >
            <img
              *ngIf="item.iconUrl"
              class="w-20px h-20px mr-10px"
              alt="u_edit-alt"
              [src]="item.iconUrl"
            />
            <div class="text-gray text-14px font-roboto-regular">
              {{ item.label }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
