import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatButtonModule} from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {InfoDialogComponent} from './month-picker-component/dialog/info-dialog/info-dialog.component';
import {MultidatepickerComponent} from './multidatepicker.component';
import {YearPickerComponentComponent} from './year-picker-component/year-picker-component.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    MatButtonModule,
    MatIconModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatMomentDateModule,
  ],
  declarations: [
    InfoDialogComponent,
    MultidatepickerComponent,
    YearPickerComponentComponent,
  ],
  entryComponents: [InfoDialogComponent],
  exports: [MultidatepickerComponent],
})
export class MultidatepickerModule {}
