/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { WorkflowItemContractResult } from '../model/workflowItemContractResult';
import { WorkflowItemContractResultDelivedProd } from '../model/workflowItemContractResultDelivedProd';
import { WorkflowItemListParticipant } from '../model/workflowItemListParticipant';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class WorkflowItemContractResultService {

    protected basePath = 'https://gateway.dev.meu-solutions.com/sciencetechnology';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param contractID 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractResultContractIDCompletedPut(contractID: string, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractResultContractIDCompletedPut(contractID: string, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractResultContractIDCompletedPut(contractID: string, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractResultContractIDCompletedPut(contractID: string, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (contractID === null || contractID === undefined) {
            throw new Error('Required parameter contractID was null or undefined when calling apiWorkflowItemContractResultContractIDCompletedPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractResult/${encodeURIComponent(String(contractID))}/completed`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param contractID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractResultContractIDConfirmPut(contractID: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractResultContractIDConfirmPut(contractID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractResultContractIDConfirmPut(contractID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractResultContractIDConfirmPut(contractID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (contractID === null || contractID === undefined) {
            throw new Error('Required parameter contractID was null or undefined when calling apiWorkflowItemContractResultContractIDConfirmPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractResult/${encodeURIComponent(String(contractID))}/confirm`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param contractID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractResultContractIDDownloadCertificateGet(contractID: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowItemContractResultContractIDDownloadCertificateGet(contractID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowItemContractResultContractIDDownloadCertificateGet(contractID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowItemContractResultContractIDDownloadCertificateGet(contractID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (contractID === null || contractID === undefined) {
            throw new Error('Required parameter contractID was null or undefined when calling apiWorkflowItemContractResultContractIDDownloadCertificateGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflowItemContractResult/${encodeURIComponent(String(contractID))}/downloadCertificate`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
		responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param contractID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractResultContractIDGetAllAttachemnetGet(contractID: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractResultContractIDGetAllAttachemnetGet(contractID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractResultContractIDGetAllAttachemnetGet(contractID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractResultContractIDGetAllAttachemnetGet(contractID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (contractID === null || contractID === undefined) {
            throw new Error('Required parameter contractID was null or undefined when calling apiWorkflowItemContractResultContractIDGetAllAttachemnetGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItemContractResult/${encodeURIComponent(String(contractID))}/getAllAttachemnet`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param contractID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractResultContractIDGetAllResultsGet(contractID: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractResultContractIDGetAllResultsGet(contractID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractResultContractIDGetAllResultsGet(contractID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractResultContractIDGetAllResultsGet(contractID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (contractID === null || contractID === undefined) {
            throw new Error('Required parameter contractID was null or undefined when calling apiWorkflowItemContractResultContractIDGetAllResultsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItemContractResult/${encodeURIComponent(String(contractID))}/getAllResults`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param contractID 
     * @param reason 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractResultContractIDNeedUpdatePut(contractID: string, reason?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractResultContractIDNeedUpdatePut(contractID: string, reason?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractResultContractIDNeedUpdatePut(contractID: string, reason?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractResultContractIDNeedUpdatePut(contractID: string, reason?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (contractID === null || contractID === undefined) {
            throw new Error('Required parameter contractID was null or undefined when calling apiWorkflowItemContractResultContractIDNeedUpdatePut.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (reason !== undefined && reason !== null) {
            queryParameters = queryParameters.set('reason', <any>reason);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractResult/${encodeURIComponent(String(contractID))}/needUpdate`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param contractID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractResultContractIDSendPut(contractID: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractResultContractIDSendPut(contractID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractResultContractIDSendPut(contractID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractResultContractIDSendPut(contractID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (contractID === null || contractID === undefined) {
            throw new Error('Required parameter contractID was null or undefined when calling apiWorkflowItemContractResultContractIDSendPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractResult/${encodeURIComponent(String(contractID))}/send`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param contractID 
     * @param file 
     * @param title 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractResultContractIDUploadAttachmentPost(contractID: string, file?: Blob, title?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractResultContractIDUploadAttachmentPost(contractID: string, file?: Blob, title?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractResultContractIDUploadAttachmentPost(contractID: string, file?: Blob, title?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractResultContractIDUploadAttachmentPost(contractID: string, file?: Blob, title?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (contractID === null || contractID === undefined) {
            throw new Error('Required parameter contractID was null or undefined when calling apiWorkflowItemContractResultContractIDUploadAttachmentPost.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (title !== undefined && title !== null) {
            queryParameters = queryParameters.set('title', <any>title);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflowItemContractResult/${encodeURIComponent(String(contractID))}/uploadAttachment`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param contractID 
     * @param workflowItemID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractResultContractIDWorkflowItemIDGetMyActionGet(contractID: string, workflowItemID: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractResultContractIDWorkflowItemIDGetMyActionGet(contractID: string, workflowItemID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractResultContractIDWorkflowItemIDGetMyActionGet(contractID: string, workflowItemID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractResultContractIDWorkflowItemIDGetMyActionGet(contractID: string, workflowItemID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (contractID === null || contractID === undefined) {
            throw new Error('Required parameter contractID was null or undefined when calling apiWorkflowItemContractResultContractIDWorkflowItemIDGetMyActionGet.');
        }

        if (workflowItemID === null || workflowItemID === undefined) {
            throw new Error('Required parameter workflowItemID was null or undefined when calling apiWorkflowItemContractResultContractIDWorkflowItemIDGetMyActionGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItemContractResult/${encodeURIComponent(String(contractID))}/${encodeURIComponent(String(workflowItemID))}/getMyAction`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param contractResultID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractResultContractResultIDGetAllDelivedProdGet(contractResultID: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractResultContractResultIDGetAllDelivedProdGet(contractResultID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractResultContractResultIDGetAllDelivedProdGet(contractResultID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractResultContractResultIDGetAllDelivedProdGet(contractResultID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (contractResultID === null || contractResultID === undefined) {
            throw new Error('Required parameter contractResultID was null or undefined when calling apiWorkflowItemContractResultContractResultIDGetAllDelivedProdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItemContractResult/${encodeURIComponent(String(contractResultID))}/getAllDelivedProd`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractResultIdDeleteTableUserJoinDelete(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractResultIdDeleteTableUserJoinDelete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractResultIdDeleteTableUserJoinDelete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractResultIdDeleteTableUserJoinDelete(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractResultIdDeleteTableUserJoinDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/workflowItemContractResult/${encodeURIComponent(String(id))}/deleteTableUserJoin`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractResultIdDownloadAttachmentGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowItemContractResultIdDownloadAttachmentGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowItemContractResultIdDownloadAttachmentGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowItemContractResultIdDownloadAttachmentGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractResultIdDownloadAttachmentGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflowItemContractResult/${encodeURIComponent(String(id))}/downloadAttachment`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
		responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractResultIdEditPut(id: string, body?: WorkflowItemContractResult, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractResultIdEditPut(id: string, body?: WorkflowItemContractResult, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractResultIdEditPut(id: string, body?: WorkflowItemContractResult, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractResultIdEditPut(id: string, body?: WorkflowItemContractResult, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractResultIdEditPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractResult/${encodeURIComponent(String(id))}/edit`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractResultIdGetResultByIDGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractResultIdGetResultByIDGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractResultIdGetResultByIDGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractResultIdGetResultByIDGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractResultIdGetResultByIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItemContractResult/${encodeURIComponent(String(id))}/getResultByID`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractResultIdGetTableUserJoinByIDGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractResultIdGetTableUserJoinByIDGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractResultIdGetTableUserJoinByIDGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractResultIdGetTableUserJoinByIDGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractResultIdGetTableUserJoinByIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItemContractResult/${encodeURIComponent(String(id))}/getTableUserJoinByID`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param getDocx 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractResultIdPrintGet(id: string, getDocx?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowItemContractResultIdPrintGet(id: string, getDocx?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowItemContractResultIdPrintGet(id: string, getDocx?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowItemContractResultIdPrintGet(id: string, getDocx?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractResultIdPrintGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (getDocx !== undefined && getDocx !== null) {
            queryParameters = queryParameters.set('getDocx', <any>getDocx);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflowItemContractResult/${encodeURIComponent(String(id))}/print`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
		responseType: 'blob' as 'json',
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param getDocx 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractResultIdPrintPMTCNGet(id: string, getDocx?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowItemContractResultIdPrintPMTCNGet(id: string, getDocx?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowItemContractResultIdPrintPMTCNGet(id: string, getDocx?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowItemContractResultIdPrintPMTCNGet(id: string, getDocx?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractResultIdPrintPMTCNGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (getDocx !== undefined && getDocx !== null) {
            queryParameters = queryParameters.set('getDocx', <any>getDocx);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflowItemContractResult/${encodeURIComponent(String(id))}/print/PMTCN`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
		responseType: 'blob' as 'json',
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractResultIdPutTableUserJoinPut(id: string, body?: WorkflowItemListParticipant, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractResultIdPutTableUserJoinPut(id: string, body?: WorkflowItemListParticipant, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractResultIdPutTableUserJoinPut(id: string, body?: WorkflowItemListParticipant, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractResultIdPutTableUserJoinPut(id: string, body?: WorkflowItemListParticipant, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractResultIdPutTableUserJoinPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractResult/${encodeURIComponent(String(id))}/putTableUserJoin`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractResultIdRemoveAttachmentDelete(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractResultIdRemoveAttachmentDelete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractResultIdRemoveAttachmentDelete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractResultIdRemoveAttachmentDelete(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractResultIdRemoveAttachmentDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/workflowItemContractResult/${encodeURIComponent(String(id))}/removeAttachment`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractResultIdTableUserHistoryApprovePut(id: string, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractResultIdTableUserHistoryApprovePut(id: string, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractResultIdTableUserHistoryApprovePut(id: string, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractResultIdTableUserHistoryApprovePut(id: string, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractResultIdTableUserHistoryApprovePut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractResult/${encodeURIComponent(String(id))}/tableUserHistory/approve`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param type 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractResultIdTableUserHistoryDownloadAttachmentGet(id: string, type?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowItemContractResultIdTableUserHistoryDownloadAttachmentGet(id: string, type?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowItemContractResultIdTableUserHistoryDownloadAttachmentGet(id: string, type?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowItemContractResultIdTableUserHistoryDownloadAttachmentGet(id: string, type?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractResultIdTableUserHistoryDownloadAttachmentGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflowItemContractResult/${encodeURIComponent(String(id))}/tableUserHistory/downloadAttachment`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
		responseType: 'blob' as 'json',
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractResultIdTableUserJoinDownloadAttachmentGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowItemContractResultIdTableUserJoinDownloadAttachmentGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowItemContractResultIdTableUserJoinDownloadAttachmentGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowItemContractResultIdTableUserJoinDownloadAttachmentGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractResultIdTableUserJoinDownloadAttachmentGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflowItemContractResult/${encodeURIComponent(String(id))}/tableUserJoin/downloadAttachment`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
		responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractResultIdUpdateDelivedProdPut(id: string, body?: WorkflowItemContractResultDelivedProd, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractResultIdUpdateDelivedProdPut(id: string, body?: WorkflowItemContractResultDelivedProd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractResultIdUpdateDelivedProdPut(id: string, body?: WorkflowItemContractResultDelivedProd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractResultIdUpdateDelivedProdPut(id: string, body?: WorkflowItemContractResultDelivedProd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractResultIdUpdateDelivedProdPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractResult/${encodeURIComponent(String(id))}/updateDelivedProd`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param file 
     * @param title 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractResultIdUploadAttachmentPut(id: string, file?: Blob, title?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractResultIdUploadAttachmentPut(id: string, file?: Blob, title?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractResultIdUploadAttachmentPut(id: string, file?: Blob, title?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractResultIdUploadAttachmentPut(id: string, file?: Blob, title?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractResultIdUploadAttachmentPut.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (title !== undefined && title !== null) {
            queryParameters = queryParameters.set('title', <any>title);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractResult/${encodeURIComponent(String(id))}/uploadAttachment`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param totalManager 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractResultPrintTemplateIdGet(id: number, totalManager?: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowItemContractResultPrintTemplateIdGet(id: number, totalManager?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowItemContractResultPrintTemplateIdGet(id: number, totalManager?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowItemContractResultPrintTemplateIdGet(id: number, totalManager?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractResultPrintTemplateIdGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (totalManager !== undefined && totalManager !== null) {
            queryParameters = queryParameters.set('totalManager', <any>totalManager);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflowItemContractResult/printTemplate/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
		responseType: 'blob' as 'json',
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractResultPrintTemplatePMTCNGet(observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowItemContractResultPrintTemplatePMTCNGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowItemContractResultPrintTemplatePMTCNGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowItemContractResultPrintTemplatePMTCNGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflowItemContractResult/printTemplatePMTCN`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
		responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractResultWorkflowItemIDGetAllTableUserJoinGet(workflowItemID: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractResultWorkflowItemIDGetAllTableUserJoinGet(workflowItemID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractResultWorkflowItemIDGetAllTableUserJoinGet(workflowItemID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractResultWorkflowItemIDGetAllTableUserJoinGet(workflowItemID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemID === null || workflowItemID === undefined) {
            throw new Error('Required parameter workflowItemID was null or undefined when calling apiWorkflowItemContractResultWorkflowItemIDGetAllTableUserJoinGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItemContractResult/${encodeURIComponent(String(workflowItemID))}/getAllTableUserJoin`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemID 
     * @param id 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractResultWorkflowItemIDIdPutPrimaryTableUserJoinPut(workflowItemID: string, id: string, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractResultWorkflowItemIDIdPutPrimaryTableUserJoinPut(workflowItemID: string, id: string, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractResultWorkflowItemIDIdPutPrimaryTableUserJoinPut(workflowItemID: string, id: string, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractResultWorkflowItemIDIdPutPrimaryTableUserJoinPut(workflowItemID: string, id: string, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemID === null || workflowItemID === undefined) {
            throw new Error('Required parameter workflowItemID was null or undefined when calling apiWorkflowItemContractResultWorkflowItemIDIdPutPrimaryTableUserJoinPut.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemContractResultWorkflowItemIDIdPutPrimaryTableUserJoinPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItemContractResult/${encodeURIComponent(String(workflowItemID))}/${encodeURIComponent(String(id))}/putPrimaryTableUserJoin`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemID 
     * @param participantID 
     * @param file 
     * @param contents 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractResultWorkflowItemIDParticipantIDTableUserHistoryRequestPost(workflowItemID: string, participantID: string, file?: Blob, contents?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractResultWorkflowItemIDParticipantIDTableUserHistoryRequestPost(workflowItemID: string, participantID: string, file?: Blob, contents?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractResultWorkflowItemIDParticipantIDTableUserHistoryRequestPost(workflowItemID: string, participantID: string, file?: Blob, contents?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractResultWorkflowItemIDParticipantIDTableUserHistoryRequestPost(workflowItemID: string, participantID: string, file?: Blob, contents?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemID === null || workflowItemID === undefined) {
            throw new Error('Required parameter workflowItemID was null or undefined when calling apiWorkflowItemContractResultWorkflowItemIDParticipantIDTableUserHistoryRequestPost.');
        }

        if (participantID === null || participantID === undefined) {
            throw new Error('Required parameter participantID was null or undefined when calling apiWorkflowItemContractResultWorkflowItemIDParticipantIDTableUserHistoryRequestPost.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (contents !== undefined && contents !== null) {
            queryParameters = queryParameters.set('contents', <any>contents);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflowItemContractResult/${encodeURIComponent(String(workflowItemID))}/${encodeURIComponent(String(participantID))}/tableUserHistory/request`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemID 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractResultWorkflowItemIDPostTableUserJoinPost(workflowItemID: string, body?: WorkflowItemListParticipant, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractResultWorkflowItemIDPostTableUserJoinPost(workflowItemID: string, body?: WorkflowItemListParticipant, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractResultWorkflowItemIDPostTableUserJoinPost(workflowItemID: string, body?: WorkflowItemListParticipant, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractResultWorkflowItemIDPostTableUserJoinPost(workflowItemID: string, body?: WorkflowItemListParticipant, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemID === null || workflowItemID === undefined) {
            throw new Error('Required parameter workflowItemID was null or undefined when calling apiWorkflowItemContractResultWorkflowItemIDPostTableUserJoinPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflowItemContractResult/${encodeURIComponent(String(workflowItemID))}/postTableUserJoin`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemContractResultWorkflowItemIDTableUserHistoryGetAllGet(workflowItemID: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemContractResultWorkflowItemIDTableUserHistoryGetAllGet(workflowItemID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemContractResultWorkflowItemIDTableUserHistoryGetAllGet(workflowItemID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemContractResultWorkflowItemIDTableUserHistoryGetAllGet(workflowItemID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemID === null || workflowItemID === undefined) {
            throw new Error('Required parameter workflowItemID was null or undefined when calling apiWorkflowItemContractResultWorkflowItemIDTableUserHistoryGetAllGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItemContractResult/${encodeURIComponent(String(workflowItemID))}/tableUserHistory/getAll`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
