/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class WorkflowItemAttachmentDataService {

    protected basePath = 'https://gateway.dev.meu-solutions.com/sciencetechnology';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param workflowItemId 
     * @param activityId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdActivitiesActivityIdAttachmentsGet(workflowItemId: string, activityId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdActivitiesActivityIdAttachmentsGet(workflowItemId: string, activityId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdActivitiesActivityIdAttachmentsGet(workflowItemId: string, activityId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdActivitiesActivityIdAttachmentsGet(workflowItemId: string, activityId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdActivitiesActivityIdAttachmentsGet.');
        }

        if (activityId === null || activityId === undefined) {
            throw new Error('Required parameter activityId was null or undefined when calling apiWorkflowItemsWorkflowItemIdActivitiesActivityIdAttachmentsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/activities/${encodeURIComponent(String(activityId))}/attachments`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param attachmentDataId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdAttachmentsAttachmentDataIdDelete(workflowItemId: string, attachmentDataId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdAttachmentsAttachmentDataIdDelete(workflowItemId: string, attachmentDataId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdAttachmentsAttachmentDataIdDelete(workflowItemId: string, attachmentDataId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdAttachmentsAttachmentDataIdDelete(workflowItemId: string, attachmentDataId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdAttachmentsAttachmentDataIdDelete.');
        }

        if (attachmentDataId === null || attachmentDataId === undefined) {
            throw new Error('Required parameter attachmentDataId was null or undefined when calling apiWorkflowItemsWorkflowItemIdAttachmentsAttachmentDataIdDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/attachments/${encodeURIComponent(String(attachmentDataId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param attachmentDataId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdAttachmentsAttachmentDataIdDownloadGet(workflowItemId: string, attachmentDataId: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowItemsWorkflowItemIdAttachmentsAttachmentDataIdDownloadGet(workflowItemId: string, attachmentDataId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowItemsWorkflowItemIdAttachmentsAttachmentDataIdDownloadGet(workflowItemId: string, attachmentDataId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowItemsWorkflowItemIdAttachmentsAttachmentDataIdDownloadGet(workflowItemId: string, attachmentDataId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdAttachmentsAttachmentDataIdDownloadGet.');
        }

        if (attachmentDataId === null || attachmentDataId === undefined) {
            throw new Error('Required parameter attachmentDataId was null or undefined when calling apiWorkflowItemsWorkflowItemIdAttachmentsAttachmentDataIdDownloadGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/attachments/${encodeURIComponent(String(attachmentDataId))}/download`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
		responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param attachmentId 
     * @param file 
     * @param attachmentDataId 
     * @param attachmentName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdAttachmentsAttachmentIdUploadPut(workflowItemId: string, attachmentId: string, file?: Blob, attachmentDataId?: string, attachmentName?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdAttachmentsAttachmentIdUploadPut(workflowItemId: string, attachmentId: string, file?: Blob, attachmentDataId?: string, attachmentName?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdAttachmentsAttachmentIdUploadPut(workflowItemId: string, attachmentId: string, file?: Blob, attachmentDataId?: string, attachmentName?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdAttachmentsAttachmentIdUploadPut(workflowItemId: string, attachmentId: string, file?: Blob, attachmentDataId?: string, attachmentName?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdAttachmentsAttachmentIdUploadPut.');
        }

        if (attachmentId === null || attachmentId === undefined) {
            throw new Error('Required parameter attachmentId was null or undefined when calling apiWorkflowItemsWorkflowItemIdAttachmentsAttachmentIdUploadPut.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (attachmentDataId !== undefined && attachmentDataId !== null) {
            queryParameters = queryParameters.set('attachmentDataId', <any>attachmentDataId);
        }
        if (attachmentName !== undefined && attachmentName !== null) {
            queryParameters = queryParameters.set('attachmentName', <any>attachmentName);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/attachments/${encodeURIComponent(String(attachmentId))}/upload`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
