import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  QueryList,
  ViewChildren,
} from '@angular/core';

@Component({
  selector: 'meu-ellipsis-cell',
  templateUrl: './ellipsis-cell.component.html',
  styleUrls: ['./ellipsis-cell.component.scss'],
})
export class EllipsisCellComponent implements AfterViewInit {
  @Input() content: string = '';
  showMore = true;
  isShowMore = false;
  @ViewChildren('spanElement') spanElements: QueryList<ElementRef>;

  constructor() {}

  ngAfterViewInit() {
    this.checkContentLength();
  }

  toggleEllipsis() {
    this.showMore = !this.showMore;
  }

  private checkContentLength() {
    if (this.spanElements.first) {
      const spanElement = this.spanElements.first.nativeElement;
      this.isShowMore = spanElement.scrollHeight > spanElement.clientHeight;
    }
  }
}
