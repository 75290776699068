<h1 mat-dialog-title>Invalid semester</h1>

<mat-dialog-content>
  Options:
  <ul class="dashed">
    <li>First Semester: <strong>January</strong></li>
    <li>Second Semester: <strong>July</strong></li>
  </ul>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close color="warn">Ok</button>
</mat-dialog-actions>
