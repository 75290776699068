<div class="select-search-more">
  <label class="label-for-select {{ titleClass }}" *ngIf="titleNotShowLabel">{{
    titleNotShowLabel
  }}</label>
  <mat-form-field
    floatLabel="{{ floatLabel ? 'auto' : 'never' }}"
    class="full-width-field custom-label {{ class }}"
    [ngClass]="[invalid == true ? 'ng-invalid mat-form-field-invalid' : '']"
  >
    <mat-label *ngIf="showLabel">{{ placeholderLabel }}</mat-label>
    <div
      class="input-custom pr-10px disable-center"
      [ngClass]="{'input-disabled': disabled, disableOnMobile: disableOnMobile}"
    >
      <mat-select
        placeholder="Chọn"
        [disabled]="disabled"
        [formControl]="selectCtrl"
        [ngModel]="defaultID"
        *ngIf="disableCenter"
        disableOptionCentering
        panelClass="my-panel-class"
      >
        <mat-option>
          <ngx-mat-select-search
            [placeholderLabel]="placeholderLabel"
            [noEntriesFoundLabel]="noEntriesFoundLabel"
            [formControl]="searchControl"
          >
          </ngx-mat-select-search>
        </mat-option>
        <mat-option *ngIf="allowUnSelect && !required" (click)="unselect()"
          >-- Bỏ chọn --</mat-option
        >
        <ng-container *ngIf="groupOpt; else option">
          <mat-optgroup
            *ngFor="let group of arrayInput"
            [label]="group.name"
            [disabled]="group.disabled"
          >
            <mat-option
              *ngFor="let item of group.options"
              [value]="item[columnValue]"
              >{{ item[columnName] }}</mat-option
            >
          </mat-optgroup>
        </ng-container>
        <ng-template #option>
          <mat-option
            *ngFor="let item of fiteredInput"
            [value]="item[columnValue]"
            >{{ item[columnName] }}
          </mat-option>
        </ng-template>
      </mat-select>
      <mat-select
        placeholder="Chọn"
        [disabled]="disabled"
        [formControl]="selectCtrl"
        [ngModel]="defaultID"
        *ngIf="!disableCenter"
        #myInputRef
      >
        <mat-select-trigger *ngIf="columnStatusCompany && selectedItem">
          {{ selectedItem[columnName] }}
          <span class="status-company">
            {{
              selectedItem.is_approve == null ? ' - Đơn vị chờ duyệt' : ''
            }}</span
          >
        </mat-select-trigger>
        <mat-option>
          <ngx-mat-select-search
            [placeholderLabel]="placeholderLabel"
            [noEntriesFoundLabel]="noEntriesFoundLabel"
            [formControl]="searchControl"
          >
          </ngx-mat-select-search>
        </mat-option>
        <mat-option *ngIf="allowUnSelect && !required" (click)="unselect()"
          >-- Bỏ chọn --</mat-option
        >
        <ng-container *ngIf="groupOpt; else option">
          <mat-optgroup
            *ngFor="let group of arrayInput"
            [label]="group.name"
            [disabled]="group.disabled"
          >
            <mat-option
              *ngFor="let item of group.options"
              [value]="item[columnValue]"
              >{{ item[columnName] }}</mat-option
            >
          </mat-optgroup>
        </ng-container>
        <ng-template #option>
          <mat-option
            *ngFor="let item of fiteredInput"
            [value]="item[columnValue]"
            >{{ item[columnName] }}
            <span class="status-company" *ngIf="columnStatusCompany">
              {{
                item.is_approve == null || item.is_approve == false
                  ? ' - Đơn vị chờ duyệt'
                  : ''
              }}</span
            ></mat-option
          >
          <div *ngIf="addNew" class="add-new">
            <button (click)="AddNew()" type="button" mat-button class="btn-add">
              <div class="d-flex justify-content-between" style="width: 100%">
                <span>
                  <img src="../../../../assets/icons/svg/add-blue.svg" />{{
                    titleAddNew
                  }}</span
                >
                <img
                  *ngIf="isWarning"
                  [matTooltip]="
                    'Đơn vị được chọn sẽ tự động được thêm vào danh sách đơn vị chủ trì của chủ nhiệm'
                  "
                  src="../../../../assets/icons/svg/info.svg"
                />
              </div>
            </button>
          </div>
        </ng-template>
      </mat-select>
    </div>

    <!-- <button mat-button *ngIf="selectCtrl.value != undefined" matSuffix mat-icon-button aria-label="Clear"
	(click)="unselect()">
		<mat-icon class="icon-close">close</mat-icon>
	</button> -->
  </mat-form-field>
</div>
