import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'kt-action-entity-dialog',
  templateUrl: './action-entity-dialog.component.html',
  styleUrls: ['./action-entity-dialog.component.scss'],
})
export class ActionEntityDialogComponent implements OnInit {
  // Public properties
  viewLoading = false;

  /**
   * Component constructor
   *
   * @param dialogRef: MatDialogRef<ActionEntityDialogComponent>
   * @param data: any
   */
  constructor(
    public dialogRef: MatDialogRef<ActionEntityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {}

  /**
   * Close dialog with false result
   */
  onNoClick(): void {
    this.dialogRef.close();
  }

  /**
   * Close dialog with true result
   */
  onYesClick(): void {
    /* Server loading imitation. Remove this */
    this.viewLoading = true;
    setTimeout(() => {
      this.dialogRef.close(true); // Keep only this row
    }, 2500);
  }
}
