import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import moment, {Moment} from 'moment';

export const DATETIME_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'meu-month-picker-component',
  templateUrl: './month-picker-component.component.html',
  styleUrls: ['./month-picker-component.component.scss'],
  providers: [{provide: MAT_DATE_FORMATS, useValue: DATETIME_FORMATS}],
})

export class MonthPickerComponentComponent implements OnInit, AfterViewInit {
  @Input() formControl: FormControl;
  @Input() title: string;
  @Input() placeholderText: string;
  @Input() invalidState: boolean;
  @Input() name: string;

  isRequiredField: boolean = false;

  constructor() {}

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(()=>{
      const validator = this.formControl?.validator({} as AbstractControl);
      this.isRequiredField = validator && validator?.required;
    },500)
  }

  setMonthAndYear(
    formControl: AbstractControl,
    normalizedMonthAndYear: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = moment();
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    formControl.setValue(ctrlValue.toDate());
    datepicker.close();
  }
}
