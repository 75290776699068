<div class="kt-portlet" style="overflow: hidden; margin-bottom: 0px">
  <div class="kt-portlet__head kt-portlet__head__custom meu-title-bg">
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title title-color font-roboto-medium">
        Cài đặt bố cục
      </h3>
    </div>
    <div mat-dialog-close class="close_modal_btn meu-item-center">
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div class="meu-p-4 create-flow-body">
    <div class="mb-8px" [ngClass]="{invalid: err}">
      <p class="form-label">Chiều cao bố cục (px)</p>
      <input
        class="form-control"
        placeholder="Nhập độ cao"
        [(ngModel)]="height"
        (ngModelChange)="onChangeHeight()"
      />
      <div class="text-error" *ngIf="err">
        <span *ngIf="!height">Bắt buộc nhập</span>
        <span *ngIf="height">Chỉ được nhập số</span>
      </div>
    </div>
  </div>
  <div
    class="kt-portlet__foot kt-portlet__foot--fit kt-portlet__no-border"
    id="modal-footer"
  >
    <div class="kt-form__actions kt-form__actions--solid">
      <div class="meu-p-2-5 text-right">
        <button
          class="meu-basic-button"
          (click)="closeModalClick(null)"
          mat-raised-button
        >
          Hủy
        </button>
        <button
          class="meu-primary-button ml-2"
          (click)="save()"
          mat-raised-button
          color="primary"
        >
          Lưu
        </button>
      </div>
    </div>
  </div>
</div>
