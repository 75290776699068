import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {DbdBoardShareService} from '@app/api/dbdBoardShare.service';
import {UsersService} from '@app/api/users.service';
import {ToastrService} from 'ngx-toastr';
import {BehaviorSubject} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
@Component({
  selector: 'meu-hash-tag-dashboard',
  templateUrl: './hash-tag-dashboard.component.html',
  styleUrls: ['./hash-tag-dashboard.component.scss'],
})
export class HashTagDashboardComponent implements OnInit {
  @Input() isEdit: boolean = false;
  @Input() isQuickview: boolean = false;
  @Input() boardId = null;
  @Output() emitLstUser = new EventEmitter();

  constructor(
    private toastr: ToastrService,
    private userService: UsersService,
    private bdBoardShareService: DbdBoardShareService
  ) {}

  arrHashtag = [];
  strTag = '';

  //
  lstUser = [];
  pageNo = 1;
  pageSize = 20;
  comboBoxLoading = false;
  uFilter = new BehaviorSubject<string>('');
  filter = 'extend_user_full_name|email@=';

  userChoose = null;

  ngOnInit(): void {
    this.getShare();

    //todo fix lỗi filter
    this.uFilter
      .pipe(
        map((event) => event),
        debounceTime(300)
      )
      .subscribe((value) => {
        this.lstUser = [];
        this.filter = 'extend_user_full_name|email@=' + value;
        this.getListUser(this.filter);
      });
  }

  getShare() {
    this.arrHashtag = [];
    // this.bdBoardShareService.apiDbdBoardShareOfBoardBoardIdGet(this.boardId).subscribe(
    //   res => {
    //     if (res.success) {
    //       let data = res.data.collection;
    //       data.forEach(element => {
    //         element.id = element.user_id
    //       });
    //       this.arrHashtag = data;
    //     } else {
    //       this.toastr.error(res.message, "Lỗi");
    //     }
    //   }
    // )
  }

  changeLeagueOwner(event) {
    if (event) {
      this.onEnterTag(event);
      this.emitLstUser.emit(this.arrHashtag);
    }
  }

  getListUser(filter = '', pageNo = this.pageNo) {
    this.pageNo = pageNo;
    this.comboBoxLoading = true;
    this.userService
      .apiUsersGet(filter, '', this.pageNo, this.pageSize)
      .pipe(
        map((event) => event),
        debounceTime(1000)
      )
      .subscribe((res) => {
        if (res.success) {
          this.lstUser = this.lstUser.concat(res.data.collection);
        } else {
          this.toastr.error(res.message, 'Thao tác không thành công');
        }
        this.comboBoxLoading = false;
      });
  }

  showListHashtag(str) {
    let lst = str ? str.split(',') : [];
    if (this.isQuickview && lst.length === 0) {
      return '- -';
    }
    return lst.join(', ');
  }

  ngOnChanges(changes: SimpleChanges) {}

  removeAll() {
    this.arrHashtag = [];
    this.strTag = '';
    this.emitLstUser.emit(this.arrHashtag);
  }

  removeTag(index) {
    this.arrHashtag.splice(index, 1);
    this.emitLstUser.emit(this.arrHashtag);
  }

  onEnterTag(value) {
    if (value) {
      this.strTag = '';
      const checkTagExist = this.arrHashtag
        .map((val) => val)
        .some((el) => el.id === value.id);
      if (checkTagExist) {
        this.toastr.warning('Người dùng đã tồn tại!');
      } else {
        this.arrHashtag.push(value);
      }
    }
  }
}
