// Angular
import {Injectable} from '@angular/core';
// RxJS
import {BehaviorSubject} from 'rxjs';
// Object path
import * as objectPath from 'object-path';
import {MenuConfigService} from './menu-config.service';
// Services

@Injectable()
export class MenuHorizontalService {
  // Public properties
  menuList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  menuItems: any;
  /**
   * Service constructor
   *
   * @param menuConfigService: MenuConfigService
   */
  constructor(private menuConfigService: MenuConfigService) {
    this.loadMenu();
  }

  /**
   * Load menu list
   */
  loadMenu() {
    // get menu list
    this.menuItems = objectPath.get(
      this.menuConfigService.getMenus(),
      'header.items'
    );
    this.menuList$.next(this.menuItems);
  }
}
