<div
  ktHeader
  #meuHeader
  class="kt-header meu-header kt-grid__item"
  [ngClass]="htmlClassService.getClasses('header', true)"
  id="kt_header"
>
  <ngb-progressbar
    class="kt-loading-bar"
    *ngIf="(loader.progress$ | async) > 0"
    [value]="loader.progress$ | async"
    height="3px"
  ></ngb-progressbar>
  <div class="kt-header__top">
    <div class="kt-container" [ngClass]="{'kt-container--fluid': fluid}">
      <kt-brand></kt-brand>
      <kt-topbar></kt-topbar>
    </div>
  </div>
  <!-- kt-header__bottom-meu kt-header__bottom -->
  <div class="meu-header" id="meu-header">
    <div
      class="kt-container meu-header-container"
      [ngClass]="{'kt-container--fluid': fluid}"
    >
      <div class="col-md-12 row left-view">
        <!-- <a href="javascript:;" href="/dashboard" class="hide-on-mobile">
          <img
            alt="logo"
            [src]="htmlClassService.logoPathSubject$ | async"
            class="kt-header__brand-logo-sticky meu-logo"
          />
        </a> -->
        <kt-menu-horizontal
          *ngIf="menuHeaderDisplay"
          class="menu-horizontal"
        ></kt-menu-horizontal>
      </div>
      <!-- <div class="col-md-2 right-view">
         <kt-topbar></kt-topbar> 
      </div> -->
    </div>
  </div>
</div>
