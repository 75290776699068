<label
  *ngIf="title"
  for="{{ name }}"
  attr.data-name="{{ name }}"
  class="input-label"
  [class.font-roboto-medium]="adjustFont"
  >{{ title }}
  <span class="required-input-label" *ngIf="isRequiredField">*</span></label
>
<ng-container *ngIf="!iconClass">
  <div>
    <input
      #focusName
      [readonly]="readonly"
      *ngIf="currency; else inputNotCurrency"
      id="{{ name }}"
      [type]="type"
      minlength="{{ minimumLength }}"
      maxlength="{{ maximumLength }}"
      class="input-box {{ classInput }}"
      placeholder="{{ placeholderText }}"
      [formControl]="formControl"
      attr.data-name="{{ name }}"
      pattern="{{ pattern }}"
      attr.inputmode="{{ inputType }}"
      attr.disabled="{{ disabled }}"
      appMeuCurrency
      [value]="formControl?.value?.toString().split('.').join('') | meuCurrency"
    />
  </div>
  <ng-template #inputNotCurrency>
    <input
      *ngIf="!textarea; else textareaTempl"
      #focusName
      [readonly]="readonly"
      id="{{ name }}"
      [type]="type"
      minlength="{{ minimumLength }}"
      maxlength="{{ maximumLength }}"
      class="input-box {{ classInput }}"
      placeholder="{{ placeholderText }}"
      [formControl]="formControl"
      attr.data-name="{{ name }}"
      pattern="{{ pattern }}"
      attr.inputmode="{{ inputType }}"
      attr.disabled="{{ disabled }}"
    />
    <ng-template #textareaTempl>
      <textarea
        [readonly]="readonly"
        #focusName
        id="{{ name }}"
        class="input-box {{ classInput }}"
        placeholder="{{ placeholderText }}"
        [formControl]="formControl"
        pattern="{{ pattern }}"
        rows="{{ minRows }}"
      >
      </textarea>
    </ng-template>
  </ng-template>
</ng-container>
<ng-container *ngIf="iconClass">
  <div class="input-container">
    <div>
      <input
        #focusName
        [readonly]="readonly"
        *ngIf="currency; else inputNotCurrencyIcon"
        id="{{ name }}"
        [type]="type"
        minlength="{{ minimumLength }}"
        maxlength="{{ maximumLength }}"
        class="input-box {{ classInput }}"
        placeholder="{{ placeholderText }}"
        [formControl]="formControl"
        attr.data-name="{{ name }}"
        pattern="{{ pattern }}"
        attr.inputmode="{{ inputType }}"
        attr.disabled="{{ disabled }}"
        [ngClass]="{'input-with-icon-padding': iconClass.length}"
        appMeuCurrency
        [value]="
          formControl?.value?.toString().split('.').join('') | meuCurrency
        "
      />
    </div>
    <ng-template #inputNotCurrencyIcon>
      <input
        #focusName
        [readonly]="readonly"
        id="{{ name }}"
        [type]="type"
        minlength="{{ minimumLength }}"
        maxlength="{{ maximumLength }}"
        class="input-box {{ classInput }}"
        placeholder="{{ placeholderText }}"
        [formControl]="formControl"
        attr.data-name="{{ name }}"
        pattern="{{ pattern }}"
        attr.inputmode="{{ inputType }}"
        attr.disabled="{{ disabled }}"
        [ngClass]="{'input-with-icon-padding': iconClass.length}"
      />
    </ng-template>
    <mat-icon matSuffix class="input-icon-style" (click)="iconClick.emit()">
      {{ iconClass }}
    </mat-icon>
    <!-- <em class="{{ iconClass }}" (click)="iconClick.emit()"></em> -->
  </div>
</ng-container>
<div *ngIf="invalidState" class="input-error-label mt-[4px]">
  <ng-content select="[errorMessage]"></ng-content>
</div>
