import {
  Component,
  OnInit,
  Output,
  Input,
  EventEmitter,
  Inject,
} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from 'environments/environment';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DynamicDashboardComponent} from '../dynamic-dashboard.component';
import {DbdBoardService} from '@app/api/dbdBoard.service';
import {FormValidatorsService} from '@app/shared/services/form-validators.service';

@Component({
  selector: 'meu-modal-create-board',
  templateUrl: './modal-create-board.component.html',
  styleUrls: ['./modal-create-board.component.scss'],
})
export class ModalCreateBoardComponent implements OnInit {
  isEdit = true;
  hasFormErrors = false;
  createBoardForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
  });
  nameControl = this.createBoardForm.get('name') as FormControl;

  currentUser = new BehaviorSubject<any>({});

  constructor(
    public dialogRef: MatDialogRef<DynamicDashboardComponent>,
    @Inject(MAT_DIALOG_DATA) public dataInject,
    private toastr: ToastrService,
    private dbdBoardService: DbdBoardService,
    private formValidators: FormValidatorsService
  ) {}

  ngOnInit(): void {
    this.getInfoUserLogged();
    if (this.dataInject.boardId) {
      this.isEdit = true;
      this.getBoardByID();
    } else {
      this.isEdit = false;
    }
  }
  getInfoUserLogged() {
    this.currentUser.next(
      JSON.parse(localStorage.getItem(environment.userInfo))
    );
  }

  getBoardByID() {
    this.dbdBoardService
      .apiMyBoardsIdGet(this.dataInject.boardId)
      .subscribe((res) => {
        if (res.success) {
          this.createBoardForm.patchValue({
            name: res.data.name,
          });
        }
      });
  }
  save() {
    if (this.createBoardForm.invalid) {
      this.formValidators.makeAsTouched(this.createBoardForm);
      return;
    }
    if (!this.isEdit) {
      const objCreateBoard = {
        name: this.createBoardForm.value.name,
        code: this.dataInject.code,
        sort_order: this.dataInject.sort_order,
        is_public: this.dataInject.is_public,
      };
      this.dbdBoardService.apiMyBoardsPost(objCreateBoard).subscribe((res) => {
        if (res.success) {
          this.toastr.success('Thêm mới thành công');
          const data = {
            isEdit: false,
            response: res.data,
          };
          this.closeModalClick(data);
        } else {
          this.toastr.error(res.message, 'Thêm mới không thành công');
        }
      });
    } else {
      const objEditBoard = {
        name: this.createBoardForm.value.name,
      };
      this.dbdBoardService
        .apiMyBoardsIdPut(this.dataInject.boardId, objEditBoard)
        .subscribe((res) => {
          if (res.success) {
            this.toastr.success('Chỉnh sửa thành công');
            const data = {
              isEdit: true,
              response: res.data,
            };
            this.closeModalClick(data);
          } else {
            this.toastr.error(res.message, 'Chỉnh sửa không thành công');
          }
        });
    }
  }

  closeModalClick(data) {
    this.dialogRef.close(data);
  }

  isShowError(formGroup: FormGroup, formControlName: string) {
    return this.formValidators.isShowError(formGroup, formControlName);
  }

  errors(
    formGroup: FormGroup,
    formControlName: string,
    formControlLabel: string
  ) {
    return this.formValidators.errors(
      formGroup,
      formControlName,
      formControlLabel
    );
  }
}
