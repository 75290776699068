import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DbdBoardShareService} from '@app/api/dbdBoardShare.service';
import {ToastrService} from 'ngx-toastr';
import {DynamicDashboardComponent} from '../dynamic-dashboard.component';

@Component({
  selector: 'meu-modal-share',
  templateUrl: './modal-share.component.html',
  styleUrls: ['./modal-share.component.scss'],
})
export class ModalShareComponent implements OnInit {
  oldData = [];
  lstUser = [];

  role = [
    {id: false, name: 'Chỉ xem'},
    {id: true, name: 'Được chỉnh sửa'},
  ];

  selectedRole = false;
  constructor(
    public dialogRef: MatDialogRef<DynamicDashboardComponent>,
    @Inject(MAT_DIALOG_DATA) public dataInject,
    private toastr: ToastrService,
    private bdBoardShareService: DbdBoardShareService
  ) {}

  ngOnInit(): void {
    this.getShare();
  }

  enabledButton() {
    return false;
  }

  closeModalClick(data) {
    this.dialogRef.close(data);
  }

  getShare() {
    this.bdBoardShareService
      .apiDbdBoardShareOfBoardBoardIdGet(this.dataInject.boardId)
      .subscribe((res) => {
        if (res.success) {
          this.oldData = res.data.collection;
        } else {
          this.toastr.error(res.message, 'Lỗi');
        }
      });
  }

  async save() {
    let arr = [];
    this.lstUser.forEach((element) => {
      const checkTagExist = this.oldData
        .map((val) => val)
        .some((el) => el.user_id === element.id);
      if (!checkTagExist) {
        arr.push({
          board_id: this.dataInject.boardId,
          user_id: element.id,
          accepted: false,
          editable: this.selectedRole,
        });
      }
    });
    let arrDelete = [];
    this.oldData.forEach((element) => {
      const checkTagExist = this.lstUser
        .map((val) => val)
        .some((el) => el.id === element.user_id);
      if (!checkTagExist) {
        arrDelete.push(element.id);
      }
    });
    var _this = this;
    for (let index = 0; index < arrDelete.length; index++) {
      const element = arrDelete[index];
      let prom = new Promise((res, rej) => {
        _this.bdBoardShareService
          .apiDbdBoardShareIdDelete(element)
          .subscribe((response) => {
            res('Success');
          });
      });
      prom.then((val) => {
        console.log('asynchronously executed: ' + val);
      });
    }
    //Update role
    let arrUpdate = [];
    this.oldData.forEach((element) => {
      const checkTagExist = this.lstUser
        .map((val) => val)
        .some((el) => el.id === element.user_id);
      if (checkTagExist) {
        arrUpdate.push(element.id);
      }
    });
    for (let index = 0; index < arrUpdate.length; index++) {
      const element = arrUpdate[index];
      if (element.editable != this.selectedRole) {
        let obj = {
          editable: this.selectedRole,
        };
        let prom = new Promise((res, rej) => {
          _this.bdBoardShareService
            .apiDbdBoardShareIdPut(element.id, obj)
            .subscribe((response) => {
              res('Success');
            });
        });
        prom.then((val) => {
          console.log('asynchronously executed: ' + val);
        });
      }
    }
    //end
    this.bdBoardShareService
      .apiDbdBoardSharePost(arr, this.dataInject.boardId)
      .subscribe((res) => {
        if (res.success) {
          this.toastr.success('Chia sẻ thành công', 'Thành công');
          this.closeModalClick(null);
        } else {
          this.toastr.error(res.message, 'Lỗi');
        }
      });
  }
}
