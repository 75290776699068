<div
  ngbDropdown
  placement="bottom-right"
  *ngIf="userProfile != null"
  class="kt-header__topbar-item kt-header__topbar-item--user"
>
  <div ngbDropdownToggle class="kt-header__topbar-wrapper">
    <span class="kt-header__topbar-welcome" *ngIf="greeting">Hi,</span>
    <span class="kt-header__topbar-username" *ngIf="greeting">{{
      userProfile.last_name
    }}</span>
    <!-- <span *ngIf="badge" class="kt-header__topbar-icon"><b>{{_user.fullname|firstLetter}}</b></span> -->
    <img
      *ngIf="avatar"
      alt="Pic"
      onerror="this.src='./assets/media/users/default.jpg';"
      [attr.src]="userProfile.avatar"
    />
  </div>
  <div
    ngbDropdownMenu
    style="width: 250px"
    class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl"
  >
    <!--begin: Head -->
    <!-- style="background-image: url(./assets/media/misc/bg-1.jpg)" -->
    <div
      class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
    >
      <div class="kt-user-card__avatar">
        <img
          alt="Pic"
          [attr.src]="userProfile.avatar"
          onerror="this.src='./assets/media/users/default.jpg';"
          [routerLink]="'/hr/user-management/edit/' + userProfile.id"
        />
        <span
          [hidden]="true"
          class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success"
        >
          {{ userProfile.extend_user_full_name | firstLetter }}
        </span>
      </div>
      <div class="kt-user-card__name">
        {{ userProfile.extend_user_full_name }}
      </div>
      <!-- <div class="kt-user-card__badge">
        <span class="btn btn-success btn-sm btn-bold btn-font-md">23 messages</span>
      </div> -->
    </div>
    <!--end: Head -->
    <div class="kt-dropdown-menu">
      <a
        style="color: #464c51"
        [href]="
          ssoDomain +
          urlProfile +
          userProfile.id +
          '/0000000000000000000#user-profile'
        "
      >
        <i class="fa fa-user mr-2"></i>
        Chỉnh sửa thông tin
      </a>
    </div>

    <div class="kt-dropdown-menu" (click)="logout()">
      <i class="fa fa-sign-out mr-2"> </i>
      Đăng xuất
    </div>
    <!--begin: Navigation -->
    <!-- <div class="kt-notification">
      <a
        ngbDropdownItem
        [routerLink]="linkProfile"
        href="javascript:;"
        class="kt-notification__item"
      >
        <div class="kt-notification__item-icon">
          <i class="flaticon2-calendar-3 kt-font-success"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">
            My Profile
          </div>
          <div class="kt-notification__item-time">
            Account settings and more
          </div>
        </div>
      </a>
      <a
        ngbDropdownItem
        [routerLink]=""
        href="javascript:;"
        class="kt-notification__item"
      >
        <div class="kt-notification__item-icon">
          <i class="flaticon2-mail kt-font-warning"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">
            My Messages
          </div>
          <div class="kt-notification__item-time">
            Inbox and tasks
          </div>
        </div>
      </a>
      <a
        ngbDropdownItem
        routerLink="profile"
        href="javascript:;"
        class="kt-notification__item"
      >
        <div class="kt-notification__item-icon">
          <i class="flaticon2-rocket-1 kt-font-danger"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">
            My Activities
          </div>
          <div class="kt-notification__item-time">
            Logs and notifications
          </div>
        </div>
      </a>
      <a
        ngbDropdownItem
        routerLink="profile"
        href="javascript:;"
        class="kt-notification__item"
      >
        <div class="kt-notification__item-icon">
          <i class="flaticon2-hourglass kt-font-brand"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">
            My Tasks
          </div>
          <div class="kt-notification__item-time">
            latest tasks and projects
          </div>
        </div>
      </a>
      <div class="kt-notification__custom">
        <a
          href="javascript:;"
          (click)="logout()"
          class="btn btn-outline-brand btn-upper btn-sm btn-bold"
          >Sign Out</a
        >
      </div>
    </div> -->
    <!--end: Navigation -->

    <!--begin: Navigation(alternative) -->
    <!-- <ul class="kt-nav kt-margin-b-10 kt-hidden">
      <li class="kt-nav__item">
        <a ngbDropdownItem routerLink="profile" class="kt-nav__link">
          <span class="kt-nav__link-icon"
            ><i class="flaticon2-calendar-3"></i
          ></span>
          <span class="kt-nav__link-text">My Profile</span>
        </a>
      </li>
      <li class="kt-nav__item">
        <a ngbDropdownItem routerLink="profile" class="kt-nav__link">
          <span class="kt-nav__link-icon"
            ><i class="flaticon2-browser-2"></i
          ></span>
          <span class="kt-nav__link-text">My Tasks</span>
        </a>
      </li>
      <li class="kt-nav__item">
        <a ngbDropdownItem routerLink="profile" class="kt-nav__link">
          <span class="kt-nav__link-icon"><i class="flaticon2-mail"></i></span>
          <span class="kt-nav__link-text">Messages</span>
        </a>
      </li>
      <li class="kt-nav__item">
        <a ngbDropdownItem routerLink="profile" class="kt-nav__link">
          <span class="kt-nav__link-icon"><i class="flaticon2-gear"></i></span>
          <span class="kt-nav__link-text">Settings</span>
        </a>
      </li>
      <li class="kt-nav__item kt-nav__item--custom kt-margin-t-15">
        <a (click)="logout()" class="btn btn-label-brand btn-sm btn-bold"
          >Sign Out</a
        >
      </li>
    </ul> -->
    <!--end: Navigation(alternative) -->
  </div>
</div>
