import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import moment from 'moment';
import {debounceTime, map} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'meu-control-search',
  templateUrl: './control-search.component.html',
  styleUrls: ['./control-search.component.scss'],
})
export class ControlSearchComponent implements OnInit {
  @Output() result = new EventEmitter<string>();

  @Input() search_field: SEARCH_CONFIG[];
  @Input() is_advance_support: boolean = false;
  @Input() search_by_unaccent = false;

  searchText: string = '';
  advanceMode: boolean = false;
  filterRow = [
    {
      type: '',
      operator: '',
      value: '',
      break: '',
      typeSelected: type_selected.TEXT,
      optionCustoms: [],
    },
  ];

  compare = [];

  VietnameseSigns = [
    'aAeEoOuUiIdDyY',

    'áàạảãâấầậẩẫăắằặẳẵ',

    'ÁÀẠẢÃÂẤẦẬẨẪĂẮẰẶẲẴ',

    'éèẹẻẽêếềệểễ',

    'ÉÈẸẺẼÊẾỀỆỂỄ',

    'óòọỏõôốồộổỗơớờợởỡ',

    'ÓÒỌỎÕÔỐỒỘỔỖƠỚỜỢỞỠ',

    'úùụủũưứừựửữ',

    'ÚÙỤỦŨƯỨỪỰỬỮ',

    'íìịỉĩ',

    'ÍÌỊỈĨ',

    'đ',

    'Đ',

    'ýỳỵỷỹ',

    'ÝỲỴỶỸ',
  ];

  public keyUp = new Subject<KeyboardEvent>();
  constructor() {}

  ngOnInit(): void {
    this.keyUpInit();
  }

  keyUpInit() {
    this.keyUp
      .pipe(
        map((event) => event),
        debounceTime(500)
      )
      .subscribe((value) => {
        if (this.advanceMode) {
          this.onSubmitFilter();
        } else {
          this.onSubmit();
        }
      });
  }

  showNormalSearch() {
    this.advanceMode = !this.advanceMode;
    this.searchText = '';
    this.onSubmit();
  }

  showAdvance() {
    this.advanceMode = !this.advanceMode;
    this.searchText = '';
    this.onSubmitFilter();
  }

  addFilter() {
    if (this.filterRow.length - 1 >= 0) {
      this.filterRow[this.filterRow.length - 1].break = ',';
    }
    this.filterRow.push({
      type: '',
      operator: '',
      value: '',
      break: '',
      typeSelected: type_selected.TEXT,
      optionCustoms: [],
    });
  }
  removeFilter(index) {
    if (this.filterRow.length > 1) {
      this.filterRow = this.filterRow.filter((x, i) => {
        return i != index;
      });
      this.compare = this.compare.filter((x, i) => {
        return i != index;
      });
      if (this.filterRow.length == 1) {
        this.filterRow[0].break = '';
      }
      this.onSubmitFilter();
    }
  }
  checkDuplicate(type, operator, array) {
    let result = false;
    let position = -1;
    array.forEach((item, index) => {
      if (item.includes(type + operator)) {
        result = true;
        position = index;
      }
    });
    return {result: result, position: position};
  }

  onSubmitFilter() {
    let arrayString = [];
    this.filterRow.forEach((x, i, arr) => {
      if (x.value) {
        if (
          typeof x.value != 'string' &&
          typeof x.value != 'boolean' &&
          typeof x.value != 'number'
        ) {
          x.value = moment(moment.utc(x.value).toDate()).format(
            'YYYY-MM-DDT00:00:00+00:00'
          );
        }
        let checkDuplicate = this.checkDuplicate(
          x.type,
          x.operator,
          arrayString
        );
        if (checkDuplicate.result) {
          if (arr[i - 1]?.break === '|') {
            let positionInsert = checkDuplicate.position;
            arrayString[positionInsert] = arrayString[positionInsert]
              .concat(x.value)
              .concat(x.break);
          } else {
            arrayString.push(
              x.type.concat(x.operator.concat(x.value.concat(x.break)))
            );
          }
        } else {
          arrayString.push(
            x.type.concat(x.operator.concat(x.value.concat(x.break)))
          );
        }
      }
    });
    this.searchText = arrayString.join('');
    if (this.searchText) {
      this.result.emit(this.searchText);
    }
  }
  submit() {
    if (this.advanceMode) {
      this.onSubmitFilter();
    } else {
      this.onSubmit();
    }
  }

  RemoveSignAndLowerCase4VietnameseString(str) {
    str = str.toLowerCase();
    for (let i = 1; i < this.VietnameseSigns.length; i++) {
      for (let j = 0; j < this.VietnameseSigns[i].length; j++) {
        str = str.replace(
          this.VietnameseSigns[i][j],
          this.VietnameseSigns[0][i - 1]
        );
      }
    }
    return str;
  }

  onSubmit() {
    if (this.searchText != '') {
      let str = this.searchText;
      let newString = '@=';
      let newArr = [];
      this.search_field.forEach((x) => {
        if (x.isSimpleSearch) {
          newArr.push(x.ColumnName);
          if (x.ColumnName === 'full_name_unaccent') {
            str = this.RemoveSignAndLowerCase4VietnameseString(this.searchText);
          }
        }
      });
      newArr.forEach((x, i) => {
        if (i != 0) {
          newString = x.concat('|').concat(newString);
        } else {
          newString = x.concat(newString);
        }
      });

      this.result.emit(newString.concat(str));
    } else {
      this.result.emit('');
    }
  }
  resetSearch() {
    this.filterRow = [];
    this.searchText = '';
    this.addFilter();
    this.onSubmitFilter();
  }

  typeChange(event, indexFilterRow = null) {
    if (indexFilterRow != null) {
      this.compare[indexFilterRow] = [];
      this.filterRow[indexFilterRow].value = '';
      let position = -1;
      position = this.search_field.findIndex((x) => x.ColumnName == event);
      this.filterRow[indexFilterRow].typeSelected = this.search_field[
        position
      ].Right_Format;
      this.search_field[position].Operators.forEach((x) => {
        this.compare[indexFilterRow].push(x);
      });
      if (
        this.filterRow[indexFilterRow].typeSelected == 'SELECT' &&
        this.search_field[position].Right_Options != undefined
      ) {
        this.filterRow[indexFilterRow].optionCustoms = [];
        this.search_field[position].Right_Options.forEach((x) => {
          this.filterRow[indexFilterRow].optionCustoms.push(x);
        });
      }
    }
    this.submit();
  }

  // @HostListener('window:keydown.enter', ['$event']) enterEvent() {
  //   this.submit();
  // }

  hideSearchIcon(condition) {
    let searchIcon = document.getElementById('search-icon');
    if (!condition && !this.searchText) {
      searchIcon.style.display = 'inherit';
    } else {
      searchIcon.style.display = 'none';
    }
  }

  clearSearch() {
    this.searchText = '';
    this.onSubmit();
  }
}

export enum type_selected {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  SELECT = 'SELECT',
}

export enum operator_type {
  equal = '==',
  notEqual = '!=',
  lessThan = '<',
  greaterThan = '>',
  lessThanOrEqual = '<=',
  greaterThanOrEqual = '>=',
  contain = '@=',
  startWith = '_=',
  notStartWith = '!_=',
  caseInsensitiveStringContain = '@=*',
  caseInsensitiveStringNotContain = '!@=*',
  caseInsensitiveStartWith = '_=*',
  caseInsensitiveNotStartWith = '!_=*',
  caseInsensitiveEqual = '==*',
  caseInsensitiveNotEqual = '!=*',
}

export interface RIGHT_OPTION {
  Label: string;
  Value: string | boolean | number;
}

export interface OPERATOR {
  Operator: operator_type;
  Label: string;
}
export interface SEARCH_CONFIG {
  Label: string;
  ColumnName: string;
  isSimpleSearch: boolean;
  Operators: OPERATOR[];
  Right_Format: type_selected;
  Right_Options?: RIGHT_OPTION[];
}
