import {BreakpointObserver} from '@angular/cdk/layout';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import {environment} from '@app/../environments/environment';
import {UsersService} from '@app/api/api';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
@Component({
  selector: 'meu-avatar-card',
  templateUrl: './avatar-card.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./avatar-card.component.scss'],
})
export class AvatarCardComponent implements OnInit {
  @Input() userId: string = '';
  @Input() path: string = '';
  @Input() width: string = '';
  @Input() type: string = 'hover';
  @Input() cardPosition: string = 'top-left';

  userInfo: any;
  modalRef: BsModalRef;
  eventType: any;
  popPlacement = [
    'top-right',
    'left-top',
    'top',
    'top-left',
    'bottom',
    'bottom-left',
    'bottom-right',
    'left',
    'left-bottom',
    'right',
    'right-top',
    'right-bottom',
  ];

  constructor(
    private usersService: UsersService,
    private modalService: BsModalService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.initAttribute();
  }
  setPositionDefault() {
    var temp = this.popPlacement.indexOf(this.cardPosition);
    this.popPlacement[temp] = this.popPlacement[0];
    this.popPlacement[0] = this.cardPosition;
  }
  initAttribute() {
    if (!this.path) {
      this.path = environment.backendhost + 'assets/media/users/default.jpg';
    } else {
      this.path = environment.backendhost + this.path;
    }
    if (this.width == '') {
      this.width = '30px';
    }
    if (this.type == 'hover') {
      this.setPositionDefault();
    }
  }

  popoverOpen(popover) {
    var isSmallScreen = this.breakpointObserver.isMatched(
      '(max-width: 1024px)'
    );
    if (this.type == 'hover') {
      this.usersService.apiUsersIdGet(this.userId).subscribe((res) => {
        if (res.success) {
          this.userInfo = res.data;
        }
      });
      if (!isSmallScreen) popover.open();
    }
  }
  closePopover(popover) {
    popover.close();
  }

  openCard(template, popover) {
    if (this.type == 'click') {
      this.usersService.apiUsersIdGet(this.userId).subscribe((res) => {
        if (res.success) {
          this.userInfo = res.data;
          this.openModalCard(template);
        }
      });
    } else {
      if (popover.isOpen()) {
        popover.close();
      } else {
        popover.open();
      }
    }
  }

  openModalCard(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-sm',
    });
  }

  closeModalCard() {
    this.modalRef.hide();
  }
}
