import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonElementService {

  constructor() { }
  getIconClass(name) {
    let typeName = name.split(".")[1];
    if (typeName.includes("png") || typeName.includes("jpg") || typeName.includes("jpeg")) {
      return "fa fa-file-image-o"
    }
    if (name.includes("doc") || name.includes("docx")) {
      return "fa fa-file-word-o"
    }
    if (name.includes("xlsx")) {
      return "fa fa-file-excel-o"
    }
    if (name.includes("pdf")) {
      return "fa fa-file-pdf-o"
    }
    if (name.includes("pptx")) {
      return "fa fa-file-pdf-o"
    }
    if (name.includes("rar") || name.includes("zip")) {
      return "fa fa-file-archive-o"
    }
    else {
      return "fa fa-paperclip"
    }
  }
}
