// Angular
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
// RxJS
import {Observable, Subject} from 'rxjs';
import {finalize, takeUntil, tap} from 'rxjs/operators';
// Store
import {Store} from '@ngrx/store';
import {AppState} from '../../../../core/reducers';
// Auth
import {AuthNoticeService, AuthService, Login} from '../../../../core/auth';
import {AuthenticateService} from '@app/api/authenticate.service';

import {CookieService} from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';
import {environment} from 'environments/environment';
import {HtmlClassService} from '@app/views/theme/html-class.service';
import {FormErrorService} from '@app/shared/services/form-error.service';
const asymmetricCrypto = require('asymmetric-crypto');

const DEMO_PARAMS = {
  EMAIL: '',
  PASSWORD: '',
};

@Component({
  selector: 'meu-login-qlts',
  templateUrl: './login-qlts.component.html',
  styleUrls: ['./login-qlts.component.scss'],
})
export class LoginQltsComponent implements OnInit {
  // Public params
  loginForm: FormGroup;
  isLoggedIn$: Observable<boolean>;
  errors: any = [];
  authenticateError: boolean = false;
  showPass: boolean = false;
  emailPattern = '^[A-z0-9._%+-]+@[a-z0-9.-]+[.]+[a-z]{2,4}$';
  emailEncrypt: string;
  encryptPassword = '@123RAPTOR!@#&^';
  private unsubscribe: Subject<any>;

  private returnUrl: any;
  private channel: any;
  private publicKey: any;
  formErrorServie: any;

  // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  /**
   * Component constructor
   *
   * @param router: Router
   * @param auth: AuthService
   * @param authNoticeService: AuthNoticeService
   * @param store: Store<AppState>
   * @param fb: FormBuilder
   * @param cdr
   * @param route
   */
  constructor(
    private router: Router,
    private auth: AuthService,
    private authNoticeService: AuthNoticeService,
    private store: Store<AppState>,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private authenticateService: AuthenticateService,
    public htmlClassService: HtmlClassService
  ) {
    this.unsubscribe = new Subject();
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.returnUrl = params.returnUrl || '/';
      this.channel = params.channel;
      this.publicKey = atob(params.publicKey);
    });

    if (localStorage.getItem(environment.authTokenKey)) {
      if (!this.channel) {
        this.router.navigate(['/hr/user-management']);
      }
    }
    this.authNoticeService.setNotice(null);
    this.initLoginForm();
    // redirect back to the returnUrl before login

    this.formErrorServie = new FormErrorService();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.authNoticeService.setNotice(null);
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit(): void {
    $('input').attr('autocomplete', 'off');
  }

  /**
   * Form initialization
   * Default params, validators
   */
  initLoginForm() {
    // demo message to show
    if (!this.authNoticeService.onNoticeChanged$.getValue()) {
      const initialNotice = `Use account
     <strong>${DEMO_PARAMS.EMAIL}</strong> and password
     <strong>${DEMO_PARAMS.PASSWORD}</strong> to continue.`;
      this.authNoticeService.setNotice(initialNotice, 'info');
    }

    this.loginForm = this.fb.group({
      email: [
        DEMO_PARAMS.EMAIL,
        Validators.compose([
          Validators.required,
          Validators.pattern(this.emailPattern),
          Validators.minLength(3),
          Validators.maxLength(320),
          // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
        ]),
      ],
      password: [
        DEMO_PARAMS.PASSWORD,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ]),
      ],
    });
  }

  /**
   * Form Submit
   */
  submit() {
    const controls = this.loginForm.controls;
    /** check form */
    if (this.loginForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      return;
    }
    const authData = {
      email: controls.email.value,
      password: controls.password.value,
    };
    this.htmlClassService.loader$.next(true);
    this.authenticateService
      .apiAuthenticateAuthenticatePost(authData.email, authData.password)
      .pipe(
        tap((res) => {
          if (res.token) {
            if (!this.channel) {
              const data = new Login({authToken: res.token});
              this.store.dispatch(data);
              this.emailEncrypt = CryptoJS.AES.encrypt(
                JSON.stringify(authData.email),
                this.encryptPassword
              ).toString();
              localStorage.setItem(
                'logged-in',
                JSON.stringify(this.emailEncrypt)
              );
              //Get user permissions
              this.auth.getMyPermissions(true).subscribe((result) => {
                if (result) {
                  window.location.href = this.returnUrl + '?token=' + res.token;
                  //  this.router.navigateByUrl(this.returnUrl + '?token=' + res.token);
                }

                this.htmlClassService.loader$.next(false);
              });
            } else {
              const myKeyPair = asymmetricCrypto.keyPair();
              const keyCode = asymmetricCrypto.encrypt(
                res.token,
                this.publicKey,
                myKeyPair.secretKey
              );
              keyCode.SSOServerPublicKey = myKeyPair.publicKey;
              const encToken = btoa(JSON.stringify(keyCode));
              window.location.href = this.returnUrl + '?token=' + encToken;
            }
          } else {
            this.authNoticeService.setNotice(res.message, 'danger');
          }
        }),
        takeUntil(this.unsubscribe),
        finalize(() => {
          this.cdr.markForCheck();
        })
      )
      .subscribe();
  }
}
