<div class="modal-content-container custom-dashboard">
  <div class="flex flex-col py-8px px-16px border-b border-tlr-lightest-blue">
    <div class="text-16px text-dark-grey font-roboto-medium">
      Danh sách thẻ được chia sẻ với tôi
      <img
        src="/assets/icons/u_times.svg"
        alt=""
        height="20px"
        (click)="closeModalClick(null)"
        class="cursor-pointer lg:block inline float-right"
      />
    </div>
  </div>

  <div class="grid grid-cols-2 gap-8px pt-16px px-16px">
    <div class="col-span-1 mr-4px md:mr-8px">
      <div class="row padding-input-search">
        <div
          class="right-inner-addon input-container pointer input-search-event full-width-field"
        >
          <div class="input-group border-search">
            <div class="input-group-prepend">
              <div class="input-group-text icon-search none-border">
                <img
                  class="flex justify-content-center flex-column input-icon-search ico-wd"
                  src="./assets/icons/icon_search.svg"
                />
              </div>
            </div>
            <input
              type="text"
              class="m-h-40px form-control input-search none-border"
              id="inlineFormInputGroupUsername2"
              placeholder="Tìm kiếm"
              autocomplete="off"
              (keyup)="keyUp.next($event.target.value)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-1 flex justify-end items-center">
      <span class="pr-8px text-14px">Chọn tất cả </span>
      <input
        (click)="chooseAll($event)"
        [(ngModel)]="checkboxAll"
        class="meu-checkbox form-control"
        type="checkbox"
      />
    </div>
    <div
      class="col-span-2 mt-8px rounded p-8px flex justify-center"
      *ngIf="listShare.length == 0"
    >
      <span>Không có dữ liệu</span>
    </div>
    <div
      class="col-span-2 pr-10px md:pr-0px grid grid-cols-2 overflow-mobile-dcl gap-16px"
    >
      <div
        class="col-span-2 md:col-span-1 mt-8px -mb-8px rounded border border-tlr-lightest-blue p-8px"
        [ngClass]="item.extend_is_new ? 'active-badge pl-7px' : 'pl-12px'"
        *ngFor="let item of listShare"
      >
        <div class="grid grid-cols-10">
          <div class="col-span-1 flex items-center">
            <img
              class="rounded-full w-32px h-32px border border-2px border-tlr-lightest-blue"
              [src]="item.extend_receiver_avatar"
              onerror="this.src = null;this.src='/assets/images/default.jpg'"
            />
          </div>
          <div class="col-span-8 pl-8px">
            <div
              class="font-medium text-14px truncate w-full"
              [title]="item.extend_board_name"
            >
              Thẻ ‘{{ item.extend_board_name }}’
            </div>
            <div
              class="text-12px truncate"
              [title]="
                'mới được chia sẻ với bạn bởi ' + item.extend_creator_full_name
              "
            >
              mới được chia sẻ với bạn bởi {{ item.extend_creator_full_name }}
            </div>
            <div class="text-12px text-tlr-datetime-color">
              {{ item.created_at }}
            </div>
          </div>
          <div class="col-span-1 flex items-end justify-end">
            <input
              [title]="
                !item.isaccepted
                  ? 'Hiển thị lên trang của tôi'
                  : 'Ẩn khỏi trang của tôi'
              "
              class="meu-checkbox form-control"
              [(ngModel)]="item.isaccepted"
              type="checkbox"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="col-span-2 flex rounded-5px mt-3 justify-end">
      <div class="mr-0 flex">
        <mat-paginator
          class=""
          (page)="paginatorChange($event)"
          [pageSize]="paging.pageSize"
          [length]="paging.recordCount"
          [hidePageSize]="false"
          [pageSizeOptions]="[5, 10, 20, 100]"
          [showFirstLastButtons]="true"
        >
        </mat-paginator>
      </div>
    </div>
  </div>

  <div class="flex justify-end p-16px">
    <button
      class="btn-text-sm mr-8px input-h-40px"
      (click)="closeModalClick(null)"
    >
      Hủy
    </button>
    <button class="btn-primary-sm input-h-40px" (click)="save()">Lưu</button>
  </div>
</div>
