/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { WorkflowCouncilApprover } from '../model/workflowCouncilApprover';
import { WorkflowCouncilworkflowCouncilApproverArrayTuple } from '../model/workflowCouncilworkflowCouncilApproverArrayTuple';
import { WorkflowCouncilworkflowCouncilApproverListTuple } from '../model/workflowCouncilworkflowCouncilApproverListTuple';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class WorkflowCouncilService {

    protected basePath = 'https://gateway.dev.meu-solutions.com/sciencetechnology';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWofkflowsCouncilCheckUserInApprovedCouncilsGet(userId?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWofkflowsCouncilCheckUserInApprovedCouncilsGet(userId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWofkflowsCouncilCheckUserInApprovedCouncilsGet(userId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWofkflowsCouncilCheckUserInApprovedCouncilsGet(userId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/wofkflows/council/checkUserInApprovedCouncils`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param councilID 
     * @param file 
     * @param isPrimary 
     * @param id 
     * @param description 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsCouncilAttachmentCouncilIDUploadPut(councilID: string, file?: Blob, isPrimary?: boolean, id?: string, description?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsCouncilAttachmentCouncilIDUploadPut(councilID: string, file?: Blob, isPrimary?: boolean, id?: string, description?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsCouncilAttachmentCouncilIDUploadPut(councilID: string, file?: Blob, isPrimary?: boolean, id?: string, description?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsCouncilAttachmentCouncilIDUploadPut(councilID: string, file?: Blob, isPrimary?: boolean, id?: string, description?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (councilID === null || councilID === undefined) {
            throw new Error('Required parameter councilID was null or undefined when calling apiWorkflowsCouncilAttachmentCouncilIDUploadPut.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (isPrimary !== undefined && isPrimary !== null) {
            queryParameters = queryParameters.set('isPrimary', <any>isPrimary);
        }
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (description !== undefined && description !== null) {
            queryParameters = queryParameters.set('description', <any>description);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflows/council/attachment/${encodeURIComponent(String(councilID))}/upload`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsCouncilAttachmentIdDeleteDelete(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsCouncilAttachmentIdDeleteDelete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsCouncilAttachmentIdDeleteDelete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsCouncilAttachmentIdDeleteDelete(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowsCouncilAttachmentIdDeleteDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/workflows/council/attachment/${encodeURIComponent(String(id))}/delete`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsCouncilAttachmentIdDownloadGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowsCouncilAttachmentIdDownloadGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowsCouncilAttachmentIdDownloadGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowsCouncilAttachmentIdDownloadGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowsCouncilAttachmentIdDownloadGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflows/council/attachment/${encodeURIComponent(String(id))}/download`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
		responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param councilID 
     * @param file 
     * @param id 
     * @param description 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsCouncilCouncilIDSubmitSignPost(councilID: string, file?: Blob, id?: string, description?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsCouncilCouncilIDSubmitSignPost(councilID: string, file?: Blob, id?: string, description?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsCouncilCouncilIDSubmitSignPost(councilID: string, file?: Blob, id?: string, description?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsCouncilCouncilIDSubmitSignPost(councilID: string, file?: Blob, id?: string, description?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (councilID === null || councilID === undefined) {
            throw new Error('Required parameter councilID was null or undefined when calling apiWorkflowsCouncilCouncilIDSubmitSignPost.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (description !== undefined && description !== null) {
            queryParameters = queryParameters.set('description', <any>description);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflows/council/${encodeURIComponent(String(councilID))}/submitSign`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param Filters 
     * @param Sorts 
     * @param Page 
     * @param PageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsCouncilGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsCouncilGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsCouncilGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsCouncilGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (Filters !== undefined && Filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>Filters);
        }
        if (Sorts !== undefined && Sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>Sorts);
        }
        if (Page !== undefined && Page !== null) {
            queryParameters = queryParameters.set('Page', <any>Page);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflows/council`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsCouncilIdApprovePost(id: string, body?: WorkflowCouncilApprover, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsCouncilIdApprovePost(id: string, body?: WorkflowCouncilApprover, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsCouncilIdApprovePost(id: string, body?: WorkflowCouncilApprover, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsCouncilIdApprovePost(id: string, body?: WorkflowCouncilApprover, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowsCouncilIdApprovePost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflows/council/${encodeURIComponent(String(id))}/approve`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsCouncilIdApproversPut(id: string, body?: Array<WorkflowCouncilApprover>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsCouncilIdApproversPut(id: string, body?: Array<WorkflowCouncilApprover>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsCouncilIdApproversPut(id: string, body?: Array<WorkflowCouncilApprover>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsCouncilIdApproversPut(id: string, body?: Array<WorkflowCouncilApprover>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowsCouncilIdApproversPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflows/council/${encodeURIComponent(String(id))}/approvers`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsCouncilIdCompletePut(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsCouncilIdCompletePut(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsCouncilIdCompletePut(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsCouncilIdCompletePut(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowsCouncilIdCompletePut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflows/council/${encodeURIComponent(String(id))}/complete`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsCouncilIdDelete(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsCouncilIdDelete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsCouncilIdDelete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsCouncilIdDelete(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowsCouncilIdDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/workflows/council/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsCouncilIdDownloadInviteTemplateGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowsCouncilIdDownloadInviteTemplateGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowsCouncilIdDownloadInviteTemplateGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowsCouncilIdDownloadInviteTemplateGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowsCouncilIdDownloadInviteTemplateGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflows/council/${encodeURIComponent(String(id))}/downloadInviteTemplate`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
		responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsCouncilIdDownloadSubmissionSheetTemplateGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowsCouncilIdDownloadSubmissionSheetTemplateGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowsCouncilIdDownloadSubmissionSheetTemplateGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowsCouncilIdDownloadSubmissionSheetTemplateGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowsCouncilIdDownloadSubmissionSheetTemplateGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflows/council/${encodeURIComponent(String(id))}/downloadSubmissionSheetTemplate`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
		responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsCouncilIdDownloadTemplateGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowsCouncilIdDownloadTemplateGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowsCouncilIdDownloadTemplateGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowsCouncilIdDownloadTemplateGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowsCouncilIdDownloadTemplateGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflows/council/${encodeURIComponent(String(id))}/downloadTemplate`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
		responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsCouncilIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsCouncilIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsCouncilIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsCouncilIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowsCouncilIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflows/council/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param getAsWordFile 
     * @param time 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsCouncilIdPrintInviteGet(id: string, getAsWordFile?: boolean, time?: Date, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowsCouncilIdPrintInviteGet(id: string, getAsWordFile?: boolean, time?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowsCouncilIdPrintInviteGet(id: string, getAsWordFile?: boolean, time?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowsCouncilIdPrintInviteGet(id: string, getAsWordFile?: boolean, time?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowsCouncilIdPrintInviteGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (getAsWordFile !== undefined && getAsWordFile !== null) {
            queryParameters = queryParameters.set('getAsWordFile', <any>getAsWordFile);
        }
        if (time !== undefined && time !== null) {
            queryParameters = queryParameters.set('time', <any>time.toISOString());
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflows/council/${encodeURIComponent(String(id))}/printInvite`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
		responseType: 'blob' as 'json',
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param getAsWordFile 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsCouncilIdPrintTemplateGet(id: string, getAsWordFile?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowsCouncilIdPrintTemplateGet(id: string, getAsWordFile?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowsCouncilIdPrintTemplateGet(id: string, getAsWordFile?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowsCouncilIdPrintTemplateGet(id: string, getAsWordFile?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowsCouncilIdPrintTemplateGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (getAsWordFile !== undefined && getAsWordFile !== null) {
            queryParameters = queryParameters.set('getAsWordFile', <any>getAsWordFile);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflows/council/${encodeURIComponent(String(id))}/printTemplate`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
		responseType: 'blob' as 'json',
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsCouncilIdPut(id: string, body?: WorkflowCouncilworkflowCouncilApproverArrayTuple, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsCouncilIdPut(id: string, body?: WorkflowCouncilworkflowCouncilApproverArrayTuple, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsCouncilIdPut(id: string, body?: WorkflowCouncilworkflowCouncilApproverArrayTuple, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsCouncilIdPut(id: string, body?: WorkflowCouncilworkflowCouncilApproverArrayTuple, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowsCouncilIdPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflows/council/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsCouncilIdRejectPost(id: string, body?: WorkflowCouncilApprover, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsCouncilIdRejectPost(id: string, body?: WorkflowCouncilApprover, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsCouncilIdRejectPost(id: string, body?: WorkflowCouncilApprover, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsCouncilIdRejectPost(id: string, body?: WorkflowCouncilApprover, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowsCouncilIdRejectPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflows/council/${encodeURIComponent(String(id))}/reject`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsCouncilPost(body?: WorkflowCouncilworkflowCouncilApproverListTuple, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsCouncilPost(body?: WorkflowCouncilworkflowCouncilApproverListTuple, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsCouncilPost(body?: WorkflowCouncilworkflowCouncilApproverListTuple, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsCouncilPost(body?: WorkflowCouncilworkflowCouncilApproverListTuple, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflows/council`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param Filters 
     * @param Sorts 
     * @param Page 
     * @param PageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsCouncilsWorkitemIdGet(id: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsCouncilsWorkitemIdGet(id: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsCouncilsWorkitemIdGet(id: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsCouncilsWorkitemIdGet(id: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowsCouncilsWorkitemIdGet.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (Filters !== undefined && Filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>Filters);
        }
        if (Sorts !== undefined && Sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>Sorts);
        }
        if (Page !== undefined && Page !== null) {
            queryParameters = queryParameters.set('Page', <any>Page);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflows/councils/workitem/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
