// Angular
import {Component, Input, OnInit} from '@angular/core';
// RxJS
import {Observable} from 'rxjs';
// NGRX
import {select, Store} from '@ngrx/store';
// State
import {AppState} from '../../../../../core/reducers';
import {currentUser, Logout, User} from '../../../../../core/auth';
import {AuthenticateService} from '@app/api/authenticate.service';
//environment
import {environment} from '@app/../environments/environment';
import {Router} from '@angular/router';
@Component({
  selector: 'kt-user-profile2',
  templateUrl: './user-profile2.component.html',
})
export class UserProfile2Component implements OnInit {
  // Public properties
  user$: Observable<any>;
  userProfile: any = null;

  @Input() avatar = true;
  @Input() greeting = true;
  @Input() badge: boolean;
  @Input() icon: boolean;
  ssoDomain = environment.ssoFeUrl;
  urlProfile = '/portal/hr/user-management/edit/';

  linkProfile: string;
  /**
   * Component constructor
   *
   * @param store: Store<AppState>
   */
  constructor(
    private store: Store<AppState>,
    private router: Router,
    public Authenticate: AuthenticateService
  ) {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    this.user$ = this.store.pipe(select(currentUser));
    this.getData();
  }

  getData() {
    this.Authenticate.apiAuthenticateGetMyInfoGet().subscribe((res) => {
      if (res.success) {
        this.userProfile = res.user;
        this.linkProfile = '/hr/user-management/edit/' + this.userProfile.id;
        this.userProfile.avatar =
          environment.backendhost + this.userProfile.avatar;
      } else {
        this.userProfile = null;
      }
    });
  }

  /**
   * Log out
   */
  logout() {
    this.router.navigate(['/auth/logout']);
  }
}
