<!-- splash screen -->
<div #splashScreen class="kt-splash-screen">
  <span *ngIf="loaderType === 'spinner-message'">{{ loaderMessage }}</span>
  <img
    class="aw-spinner"
    *ngIf="
      loaderType === 'spinner-logo' &&
      (htmlClassService.logoPathSubject$ | async)
    "
    src="/assets/icons/svg/Double-Ring.svg"
    alt="Logo"
  />
  <!-- <span class="text-blue">Loading...</span> -->
</div>
