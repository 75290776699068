import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  forwardRef,
  AfterViewInit,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
} from '@angular/forms';
import {MatSelect} from '@angular/material/select';
import {Configuration} from '@app/configuration';
import {CustomHttpUrlEncodingCodec} from '@app/encoder';
import {environment} from 'environments/environment';

import * as _ from 'lodash';

@Component({
  selector: 'meu-control-select-search',
  templateUrl: './control-select-search.component.html',
  styleUrls: ['./control-select-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ControlSelectSearchComponent),
      multi: true,
    },
  ],
})
export class ControlSelectSearchComponent
  implements ControlValueAccessor, AfterViewInit
{
  @Input() placeholderLabel: string;
  @Input() noEntriesFoundLabel: string;
  @Input() arrayInput: any[];
  @Input() defaultID;
  @Input() disabled: boolean = false;
  @Input() invalid: boolean = false;
  @Output() change = new EventEmitter();
  @Output() dataChange = new EventEmitter();
  @Input() class: string;
  @Input() allowUnSelect = true;
  @Input() groupOpt = false;
  @Input() showLabel = true;
  @Input() disableCenter = false;
  @Input() option: boolean = false;
  @Input() nameAPI: string;
  @Input() panelclass: string;
  @Input() required: boolean;
  @Input() columnName: string = 'name';
  @Input() columnValue: string = 'id';
  @Input() errorLabel: string = 'Vui lòng chọn dữ liệu thích hợp';
  @Input() titleNotShowLabel: string;
  @Input() addNew: boolean = false;
  @Input() titleAddNew: string = '';
  @Input() columnStatusCompany: boolean = false;
  @Input() isWarning: boolean = false;
  @Input() disableOnMobile: boolean = false;
  @Input() titleClass = '';
  @Output() eventAdd = new EventEmitter();
  @ViewChild('myInputRef', {static: false}) myInputRef: MatSelect;
  selectedItem;

  /* @floatLabel:
   * true => show label in normally
   * false => hide label when input has value
   */
  @Input() floatLabel = true;

  ngOnChanges(changes: SimpleChanges) {
    if (this.arrayInput != undefined) {
      this.fiteredInput = this.arrayInput;
      if (this.addNew && this.defaultID) {
        this.selectedItem = this.fiteredInput?.find(
          (x) => x.id === this.defaultID
        );
        this.ChangeDetectorRef.detectChanges();
      }
    }
    if (
      changes.nameAPI &&
      changes.nameAPI.currentValue &&
      !changes.nameAPI.currentValue.includes('{{')
    ) {
      this.runAPI(changes.nameAPI.currentValue);
    }
    // You can also use categoryId.previousValue and
    // categoryId.firstChange for comparing old and new values
  }

  public fiteredInput: any[];
  public selectCtrl: FormControl = new FormControl();
  public searchControl: FormControl = new FormControl();

  private _value: any;
  get value() {
    return this._value;
  }
  set value(val) {
    this._value = val;
    this.propagateChange(this._value);
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
    }
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    //throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
    //throw new Error("Method not implemented.");
  }

  private propagateChange = (_: any) => {};
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  constructor(
    protected httpClient: HttpClient,
    private ChangeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {}
  ngAfterViewInit() {
    //this.fiteredInput = [...this.arrayInput];
    this.selectCtrl.valueChanges.subscribe(() => {
      this.selectedItem = this.arrayInput?.find(
        (x) => x.id == this.selectCtrl.value
      );
      this.emitChangeValue(this.selectCtrl.value);
    });
    this.searchControl.valueChanges.subscribe(() => {
      this.filterData(this.searchControl.value);
    });
  }
  emitChangeValue(event) {
    if (event !== this.defaultID) {
      this.value = event;
      this.change.emit(this.value);
      this.dataChange.emit(this.value);
      this.defaultID = event;
    }
  }

  filterData(search) {
    if (!search) {
      this.fiteredInput = [...this.arrayInput];
      return;
    }
    search = search?.toLowerCase();
    this.fiteredInput = _.filter(this.arrayInput, function (obj) {
      return obj.name.toLowerCase().indexOf(search) !== -1;
    });
  }

  unselect(): void {
    this.defaultID = null;
  }

  runAPI(nameAPI) {
    if (nameAPI == null || nameAPI.trim() == '') {
      return;
    }
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    let headers = this.defaultHeaders;
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    this.httpClient
      .request<any>('get', `${environment.backendhost + nameAPI}`, {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
      })
      .subscribe((res) => {
        this.arrayInput = res.data.collection || res.data || [];
        if (
          this.arrayInput.length &&
          !this.arrayInput[0].hasOwnProperty(this.columnName)
        ) {
          this.arrayInput = this.arrayInput.map((x) => ({
            [this.columnName]: x,
            [this.columnValue]: x,
          }));
        }
        if (this.addNew && this.defaultID) {
          this.selectedItem = this.arrayInput.find(
            (x) => x.id == this.defaultID
          );
        }
        this.filterData(null);
        this.ChangeDetectorRef.detectChanges();
      });
  }

  AddNew() {
    this.myInputRef.close();
    this.eventAdd.emit(true);
  }
}
