import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Input,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import {SubHeaderButtonService} from '@app/services/sub-header-button.service';
import {LayoutUtilsService} from '@app/core/_base/crud';
import {ToastrService} from 'ngx-toastr';
import {CommentService} from './../../../api/comment.service';
import {Subscription, Observable} from 'rxjs';
import {currentUser, User} from './../../../core/auth';
import {Store, select} from '@ngrx/store';
import {AppState} from '@app/core/reducers';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import * as _moment from 'moment';
import {FormGroup, FormControl} from '@angular/forms';
import {saveAs} from 'file-saver';
import {environment} from '@app/../environments/environment';
const moment = _moment;

@Component({
  selector: 'meu-control-comment',
  templateUrl: './control-comment.component.html',
  styleUrls: ['./control-comment.component.scss'],
})
export class ControlCommentComponent implements OnInit, AfterViewInit {
  @Input() code: string;
  @Input() title: string;
  constructor(
    public subHeaderButtonService: SubHeaderButtonService,
    private toastr: ToastrService,
    private layoutUtilsService: LayoutUtilsService,
    private changeDetectorRef: ChangeDetectorRef,
    private commentService: CommentService,
    private store: Store<AppState>,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private elementRef: ElementRef
  ) {
    iconRegistry.addSvgIcon(
      'thumbs-up',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/icons/svg/thumps-up.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'thumbs-up-solid',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/icons/svg/thumbs-up-solid.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'text-editor',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/icons/svg/text-editor.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'emotion',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/icons/svg/emotion.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'paperclip',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/icons/svg/paperclip.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'send',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/icons/svg/send.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'file',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/icons/svg/file.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'trash',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/icons/svg/trash.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'times',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/icons/svg/times.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'reply',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/icons/svg/reply.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'edit',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/icons/svg/edit.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'cancel',
      sanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/icons/svg/cancel.svg'
      )
    );
  }
  toggleemotionNewCmt = false;
  total = 0;
  pageIndex = 1;
  pagesize = 10;
  lstParentComments = [];
  user$: Observable<User>;
  curUser: any = {};
  newCmtForm = new FormGroup({
    content: new FormControl(''),
    code: new FormControl(),
    reply_for: new FormControl(null),
    number_of_like: new FormControl(0),
  });
  replyCmtForm = new FormGroup({
    content: new FormControl(''),
    code: new FormControl(),
    reply_for: new FormControl(null),
    number_of_like: new FormControl(0),
  });
  fileList = [];
  replyFileList = [];
  config = {
    placeholder: 'Nhập bình luận',
    tabsize: 2,
    height: 'auto',
    maxHeight: '5rem',
    focus: true,
    disableDragAndDrop: true,
    toolbar: [
      [
        'font',
        [
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'superscript',
          'subscript',
          'clear',
        ],
      ],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['ul', 'ol', 'paragraph', 'height']],
    ],
    fontNames: [
      'Helvetica',
      'Arial',
      'Arial Black',
      'Comic Sans MS',
      'Courier New',
      'Roboto',
      'Times',
    ],
  };
  showSummerNoteNewCmt = false;
  previousKeyCode;
  isEmojiModalOpening: boolean = false;

  ngOnInit() {
    this.getAllCommentsByCode();
    this.user$ = this.store.pipe(select(currentUser));
    this.user$.subscribe((user) => {
      this.curUser = Object.assign(this.curUser, user);
      this.curUser.avatar = this.curUser.avatar
        ? environment.backendhost + this.curUser.avatar
        : './assets/media/users/default.jpg';
    });
  }

  ngAfterViewInit() {
    let emoji = this.elementRef.nativeElement.querySelector('#hidenTarget');
    emoji.parentNode.removeChild(emoji);
  }

  changeFocusedColor(isSendBtn, isFocused) {
    if (isSendBtn) {
      if (isFocused) {
        return '#5d78ff';
      } else {
        return '';
      }
    } else {
      if (isFocused) {
        return '#dc3545';
      } else {
        return '';
      }
    }
  }

  bindKeyupToSummernote(location, status, comment_id) {
    setTimeout(() => {
      if (
        this.elementRef.nativeElement.querySelector(
          '#ngxSummernote + div.note-frame div.note-editable'
        )
      ) {
        if (location == 'newField' && status) {
          this.elementRef.nativeElement
            .querySelector('#ngxSummernote + div.note-frame div.note-editable')
            .addEventListener('keydown', this.postNewComment.bind(this));
        } else {
          this.elementRef.nativeElement
            .querySelector('#ngxSummernote + div.note-frame div.note-editable')
            .addEventListener('keydown', this.postNewComment.bind(this), false);
        }
      }

      if (
        this.elementRef.nativeElement.querySelector(
          '#replySummernote' +
            comment_id +
            ' + div.note-frame div.note-editable'
        )
      ) {
        if (location == 'replyField' && status) {
          this.elementRef.nativeElement
            .querySelector(
              '#replySummernote' +
                comment_id +
                ' + div.note-frame div.note-editable'
            )
            .addEventListener(
              'keydown',
              this.replyComment.bind(this, comment_id)
            );
        } else {
          this.elementRef.nativeElement
            .querySelector(
              '#replySummernote' +
                comment_id +
                ' + div.note-frame div.note-editable'
            )
            .addEventListener('keydown', this.replyComment.bind(this), false);
        }
      }

      if (
        this.elementRef.nativeElement.querySelector(
          '#parentCommentSummernote' +
            comment_id +
            ' + div.note-frame div.note-editable'
        )
      ) {
        if (location == 'parentComment' && status) {
          this.elementRef.nativeElement
            .querySelector(
              '#parentCommentSummernote' +
                comment_id +
                ' + div.note-frame div.note-editable'
            )
            .addEventListener(
              'keydown',
              this.editComment.bind(this, comment_id)
            );
        } else {
          this.elementRef.nativeElement
            .querySelector(
              '#parentCommentSummernote' +
                comment_id +
                ' + div.note-frame div.note-editable'
            )
            .addEventListener('keydown', this.editComment.bind(this), false);
        }
      }

      if (
        this.elementRef.nativeElement.querySelector(
          '#childCommentSummernote' +
            comment_id +
            ' + div.note-frame div.note-editable'
        )
      ) {
        if (location == 'childComment' && status) {
          this.elementRef.nativeElement
            .querySelector(
              '#childCommentSummernote' +
                comment_id +
                ' + div.note-frame div.note-editable'
            )
            .addEventListener(
              'keydown',
              this.editComment.bind(this, comment_id)
            );
        } else {
          this.elementRef.nativeElement
            .querySelector(
              '#childCommentSummernote' +
                comment_id +
                ' + div.note-frame div.note-editable'
            )
            .addEventListener('keydown', this.editComment.bind(this), false);
        }
      }
    }, 200);
  }

  handleEmojiModal(event) {
    if (event) {
      let emojiModalList = document.getElementsByClassName('emoji-mart');
      let path = event.path;

      for (let i = 0; i < emojiModalList.length; i++) {
        for (let j = 0; j < 10; j++) {
          if (
            typeof path[j].className === 'string' &&
            (path[j].className.includes('emoji-mart') ||
              path[j].className.includes('emoji-icon'))
          ) {
            break;
          }
          if (i === emojiModalList.length - 1 && j === 9) {
            this.isEmojiModalOpening = true;
          }
        }
      }

      if (this.isEmojiModalOpening) {
        for (let i = 0; i < emojiModalList.length; i++) {
          (<any>emojiModalList[i]).style.display = 'none';
        }
        this.isEmojiModalOpening = false;
      }
    }
  }

  handleEmojiSelection(event, form) {
    const data = (form.controls.content.value += event.emoji.native);
    form.patchValue({
      content: data,
    });
  }

  handleEmojiSelectionInComment(event, comment) {
    comment.content += event.emoji.native;
  }

  getAllCommentsByCode() {
    if (this.code) {
      this.commentService
        .apiCommentCodeGet(this.code, '', '', this.pageIndex, this.pagesize)
        .subscribe((res) => {
          this.lstParentComments = res.result.sort(function (a, b) {
            let aDate = new Date(a.created_at).valueOf();
            let bDate = new Date(b.created_at).valueOf();
            return bDate - aDate;
          });
          this.total = res.total;
          this.pageIndex = res.pageIndex;
          for (let i = 0; i < this.lstParentComments.length; i++) {
            this.lstParentComments[i].index =
              (this.pageIndex - 1) * this.pagesize + i;
            this.lstParentComments[i].showReplies = false;
            this.lstParentComments[i].showReplyInputField = false;
            this.lstParentComments[i].showSummernoteReplyInputField = false;
            this.lstParentComments[i].isEditing = false;
            this.lstParentComments[i].showSummernoteField = false;
            this.lstParentComments[i].toggleemotion = false;
            this.lstParentComments[i].toggleEmotionAtReplyfield = false;
            this.lstParentComments[i].extend_staff_avatar = this
              .lstParentComments[i].extend_staff_avatar
              ? environment.backendhost +
                this.lstParentComments[i].extend_staff_avatar
              : './assets/media/users/default.jpg';
            this.lstParentComments[i].tmpAttachmentsLst = [
              ...this.lstParentComments[i].extend_attachments,
            ];
            if (this.lstParentComments[i].extend_repply_comments.length > 0) {
              this.lstParentComments[i].extend_repply_comments.sort(function (
                a,
                b
              ) {
                let aDate = new Date(a.created_at).valueOf();
                let bDate = new Date(b.created_at).valueOf();
                return aDate - bDate;
              });
              for (
                let j = 0;
                j < this.lstParentComments[i].extend_repply_comments.length;
                j++
              ) {
                this.lstParentComments[i].extend_repply_comments[
                  j
                ].showReplyInputField = false;
                this.lstParentComments[i].extend_repply_comments[
                  j
                ].showSummernoteField = false;
                this.lstParentComments[i].extend_repply_comments[j].isEditing =
                  false;
                this.lstParentComments[i].extend_repply_comments[
                  j
                ].toggleemotion = false;
                this.lstParentComments[i].extend_repply_comments[
                  j
                ].extend_staff_avatar =
                  environment.backendhost +
                  this.lstParentComments[i].extend_repply_comments[j]
                    .extend_staff_avatar;
                this.lstParentComments[i].extend_repply_comments[
                  j
                ].tmpAttachmentsLst = [
                  ...this.lstParentComments[i].extend_repply_comments[j]
                    .extend_attachments,
                ];
              }
            }
          }
          this.changeDetectorRef.detectChanges();
        });
    }
  }

  postNewComment(keydownEvent) {
    if (keydownEvent && keydownEvent.keyCode !== 13) {
      this.previousKeyCode = keydownEvent.keyCode;
      return;
    }

    if (keydownEvent && keydownEvent.shiftKey && keydownEvent.keyCode === 13) {
      return;
    }

    if (
      (keydownEvent && !keydownEvent.shiftKey && keydownEvent.keyCode === 13) ||
      !keydownEvent
    ) {
      keydownEvent?.preventDefault();
      this.startSubmitComment();
    }
  }
  startSubmitComment() {
    let content = this.newCmtForm.controls.content.value.trim();
    while (content.startsWith('<p><br></p>')) {
      content = content.substring(11);
    }
    while (content.startsWith('<br>')) {
      content = content.substring(4);
    }
    while (content.endsWith('<p><br></p>')) {
      content = content.substring(0, content.Length - 11);
    }
    while (content.endsWith('<br>')) {
      content = content.substring(0, content.Length - 4);
    }

    if (content === '' && this.fileList.length === 0) {
      return;
    }

    this.newCmtForm.patchValue({
      code: this.code,
      content: content,
    });
    this.newCmtForm.markAllAsTouched();
    this.commentService
      .apiCommentsPost(this.newCmtForm.value)
      .subscribe((res) => {
        if (res.success) {
          this.newCmtForm.patchValue({
            content: '',
          });

          let newComment = res.data;
          newComment.extend_staff_avatar = this.curUser.avatar;
          newComment.extend_staff_full_name =
            this.curUser.extend_user_full_name;
          newComment.extend_attachments = [];
          newComment.tmpAttachmentsLst = [];

          for (let i = 0; i < this.fileList.length; i++) {
            this.commentService
              .apiCommentsIdAttachmentsPost(res.data.id, this.fileList[i])
              .subscribe((res) => {
                newComment.extend_attachments.push(res.data);
                newComment.tmpAttachmentsLst.push(res.data);
                if (i === this.fileList.length - 1) {
                  this.fileList = [];
                }
              });
          }

          this.previousKeyCode = null;
          this.showSummerNoteNewCmt = false;
          this.lstParentComments.unshift(newComment);
        } else {
          this.toastr.error(res.message, 'Xảy ra lỗi');
        }
      });
  }

  replyComment(parent_comment, keydownEvent) {
    if (keydownEvent && keydownEvent.keyCode !== 13) {
      this.previousKeyCode = keydownEvent.keyCode;
      return;
    }

    if (keydownEvent && keydownEvent.shiftKey && keydownEvent.keyCode === 13) {
      return;
    }

    if (
      (keydownEvent && !keydownEvent.shiftKey && keydownEvent.keyCode === 13) ||
      !keydownEvent
    ) {
      keydownEvent?.preventDefault();
      this.startReplyComment(parent_comment);
    }
  }

  startReplyComment(parent_comment) {
    let content = this.replyCmtForm.controls.content.value.trim();
    while (content.startsWith('<p><br></p>')) {
      content = content.substring(11);
    }
    while (content.startsWith('<br>')) {
      content = content.substring(4);
    }
    while (content.endsWith('<p><br></p>')) {
      content = content.substring(0, content.Length - 11);
    }
    while (content.endsWith('<br>')) {
      content = content.substring(0, content.Length - 4);
    }

    if (content === '' && this.replyFileList.length === 0) {
      return;
    }

    this.replyCmtForm.patchValue({
      code: this.code,
      reply_for: parent_comment.id,
      content: content,
    });
    this.replyCmtForm.markAllAsTouched();
    this.commentService
      .apiCommentsPost(this.replyCmtForm.value)
      .subscribe((res) => {
        if (res.success) {
          this.replyCmtForm.patchValue({
            content: '',
            reply_for: null,
          });

          let newComment = res.data;
          newComment.extend_staff_avatar = this.curUser.avatar;
          newComment.extend_staff_full_name =
            this.curUser.extend_user_full_name;
          newComment.extend_attachments = [];
          newComment.tmpAttachmentsLst = [];

          for (let i = 0; i < this.replyFileList.length; i++) {
            this.commentService
              .apiCommentsIdAttachmentsPost(res.data.id, this.replyFileList[i])
              .subscribe(
                (res) => {
                  if (res.success) {
                    newComment.extend_attachments.push(res.data);
                    newComment.tmpAttachmentsLst.push(res.data);
                    if (i === this.replyFileList.length - 1) {
                      this.replyFileList = [];
                    }
                  }
                },
                (err) => {
                  this.toastr.error('Xuất hiện lỗi', err);
                }
              );
          }

          parent_comment.showReplies = true;
          parent_comment.showSummernoteReplyInputField = false;
          parent_comment.toggleEmotionAtReplyfield = false;
          parent_comment.extend_repply_comments.push(newComment);
        } else {
          this.toastr.error(res.message, 'Xảy ra lỗi');
        }
      });
  }

  editComment(comment, keydownEvent) {
    if (keydownEvent && keydownEvent.keyCode === 27) {
      //esc to stop editing
      this.stopEditingComment(comment);
      return;
    }

    if (keydownEvent && keydownEvent.keyCode !== 13) {
      this.previousKeyCode = keydownEvent.keyCode;
      return;
    }

    if (keydownEvent && keydownEvent.shiftKey && keydownEvent.keyCode === 13) {
      return;
    }

    if (
      (keydownEvent && !keydownEvent.shiftKey && keydownEvent.keyCode === 13) ||
      !keydownEvent
    ) {
      keydownEvent?.preventDefault();
      this.startEditingComment(comment);
    }
  }

  startEditingComment(comment) {
    if (comment) {
      if (comment.content === '' && comment.tmpAttachmentsLst.length === 0) {
        return;
      }

      while (comment.content.startsWith('<p><br></p>')) {
        comment.content = comment.content.substring(11);
      }
      while (comment.content.startsWith('<br>')) {
        comment.content = comment.content.substring(4);
      }
      while (comment.content.endsWith('<p><br></p>')) {
        comment.content = comment.content.substring(
          0,
          comment.content.Length - 11
        );
      }
      while (comment.content.endsWith('<br>')) {
        comment.content = comment.content.substring(
          0,
          comment.content.Length - 4
        );
      }

      // compare 2 attachment lists
      if (comment.tmpAttachmentsLst.length === 0) {
        for (let i = 0; i < comment.extend_attachments.length; i++) {
          this.commentService
            .apiCommentsIdAttachmentsAttachmentIdDelete(
              comment.id,
              comment.extend_attachments[i].id
            )
            .subscribe(
              (res) => {},
              (err) => {
                this.toastr.error('Xuất hiện lỗi', err);
              }
            );
          if (i === comment.extend_attachments.length - 1) {
            comment.extend_attachments = [];
            comment.tmpAttachmentsLst = [];
          }
        }
      } else {
        let lstDifferencesToRemove = [];
        let lstDifferencesToAdd = [];

        if (comment.extend_attachments.length === 0) {
          for (let i = 0; i < comment.tmpAttachmentsLst.length; i++) {
            this.commentService
              .apiCommentsIdAttachmentsPost(
                comment.id,
                comment.tmpAttachmentsLst[i]
              )
              .subscribe(
                (res) => {
                  comment.extend_attachments.push(res.data);
                  comment.tmpAttachmentsLst.push(res.data);
                },
                (err) => {
                  this.toastr.error('Xuất hiện lỗi', err);
                }
              );
          }
        } else {
          let belongsToNewArr = null; // to check if an el in curLst belongs to newArr
          let count = 0;

          for (let i = 0; i < comment.extend_attachments.length; i++) {
            // Add elements which are new, remove them from newArr, runs 1time only
            belongsToNewArr = null;

            if (count < 1) {
              comment.tmpAttachmentsLst.forEach((element) => {
                const belongsToCurrentArr =
                  comment.extend_attachments.indexOf(element);
                if (belongsToCurrentArr == -1) {
                  lstDifferencesToAdd.push(element);
                }
              });

              if (lstDifferencesToAdd.length > 0) {
                lstDifferencesToAdd.forEach((el) => {
                  this.commentService
                    .apiCommentsIdAttachmentsPost(comment.id, el)
                    .subscribe((res) => {});
                });

                for (let j = 0; j < lstDifferencesToAdd.length; j++) {
                  if (
                    comment.tmpAttachmentsLst.indexOf(lstDifferencesToAdd[j]) !=
                    -1
                  ) {
                    const index = comment.tmpAttachmentsLst.indexOf(
                      lstDifferencesToAdd[j]
                    );
                    comment.tmpAttachmentsLst.splice(index, 1);
                  }
                }
              }
              count++;
            }

            // if current el belongs to newArr, we'll not remove it
            belongsToNewArr = comment.tmpAttachmentsLst.indexOf(
              comment.extend_attachments[i]
            );
            if (belongsToNewArr != -1) {
              continue;
            }
            lstDifferencesToRemove.push(comment.extend_attachments[i]);
          }

          if (lstDifferencesToRemove.length > 0) {
            for (let j = 0; j < lstDifferencesToRemove.length; j++) {
              this.commentService
                .apiCommentsIdAttachmentsAttachmentIdDelete(
                  comment.id,
                  lstDifferencesToRemove[j].id
                )
                .subscribe((res) => {});
            }
          }
        }
        comment.extend_attachments = [
          ...comment.tmpAttachmentsLst,
          ...lstDifferencesToAdd,
        ];
        comment.tmpAttachmentsLst = [...comment.extend_attachments];
      }

      this.commentService
        .apiCommentsIdPut(comment.id, comment)
        .subscribe((res) => {
          if (res.success) {
            comment.content = res.data.content;
            comment.isEditing = false;
            comment.showSummernoteField = false;
          }
        });
    }
  }

  stopEditingComment(comment) {
    comment.tmpAttachmentsLst = [...comment.extend_attachments];
    this.commentService.apiCommentsIdGet(comment.id).subscribe((res) => {
      if (res.success) {
        comment.content = res.data.content;
        this.changeDetectorRef.detectChanges();
      }
    });
    if (!comment.reply_for) {
      comment.toggleEmotionAtReplyfield = false;
    }
    comment.toggleemotion = false;
    comment.showSummernoteField = false;
    comment.isEditing = false;
  }

  deleteComment(cmt_id) {
    const title = 'Xóa bình luận';
    const description = 'Bạn có chắc chắn muốn xóa ?';
    const waitDesciption = 'Đang xử lý...';
    const dialogRef = this.layoutUtilsService.deleteElement(
      title,
      description,
      waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.commentService.apiCommentsIdDelete(cmt_id).subscribe((res) => {
        if (res.success) {
          for (let i = 0; i < this.lstParentComments.length; i++) {
            if (cmt_id === this.lstParentComments[i].id) {
              this.lstParentComments.splice(i, 1);
              break;
            } else if (
              this.lstParentComments[i].extend_repply_comments.length > 0
            ) {
              for (
                let j = 0;
                j < this.lstParentComments[i].extend_repply_comments.length;
                j++
              ) {
                if (
                  cmt_id ===
                  this.lstParentComments[i].extend_repply_comments[j].id
                ) {
                  this.lstParentComments[i].extend_repply_comments.splice(j, 1);
                  if (
                    this.lstParentComments[i].extend_repply_comments.length ===
                    0
                  ) {
                    this.lstParentComments[i].showReplies = false;
                  }
                  break;
                }
              }
            }
          }
        }
      });
    });
  }

  likeComment(comment) {
    this.commentService.apiCommentsIdLikesPost(comment.id).subscribe((res) => {
      if (res.success) {
        comment.extend_liked = true;
        comment.number_of_like++;
      }
    });
  }

  unlikeComment(comment) {
    this.commentService
      .apiCommentsIdUnLikesPost(comment.id)
      .subscribe((res) => {
        if (res.success) {
          comment.extend_liked = false;
          comment.number_of_like--;
        }
      });
  }

  onFileChange(event, fileArray) {
    for (let i = 0; i < event.target.files.length; i++) {
      fileArray.push(event.target.files[i]);
    }
  }

  downloadAttachment(commentID, attachment) {
    this.commentService
      .apiCommentsIdAttachmentsAttachmentIdGet(commentID, attachment.id)
      .subscribe(
        (res) => {
          const blob = new Blob([res]);
          saveAs(blob, attachment.name);
        },
        (err) => {
          this.toastr.error('File không tồn tại', err);
        }
      );
  }

  downloadAllAttachments(cmt_id, attachment_lst) {
    for (let i = 0; i < attachment_lst.length; i++) {
      this.commentService
        .apiCommentsIdAttachmentsAttachmentIdGet(cmt_id, attachment_lst[i].id)
        .subscribe(
          (res) => {
            const blob = new Blob([res]);
            saveAs(blob, attachment_lst[i].name);
          },
          (err) => {
            this.toastr.error('File không tồn tại', err);
          }
        );
    }
  }

  removeEditingTempFile(index, file_arr) {
    file_arr.splice(index, 1);
  }

  removeTempFile(index) {
    this.fileList.splice(index, 1);
  }

  removeReplyTempFile(index) {
    this.replyFileList.splice(index, 1);
  }

  momentDMY(date) {
    return moment(date).format('DD/MM/YYYY HH:mm');
  }

  paginatorChange(event) {
    this.pagesize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    this.getAllCommentsByCode();
  }
}
