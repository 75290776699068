<div
  class="modal-bg-overlay"
  [ngStyle]="{'z-index': option.zIndex}"
  (click)="close()"
></div>
<div
  class="modal-container {{ option.styleClass }} custom-dashboard"
  [ngStyle]="{
    top: option.top,
    'animation-name':
      option.top === '50%' ? 'centerModalAnimation' : 'modalAnimation'
  }"
  [ngClass]="{'top-center': option.top === '50%'}"
>
  <div class="modal-content">
    <div class="grid grid-col-12 pt-8px px-16px">
      <div class="header">
        <div class="title">
          {{ contents.title }}
        </div>
        <div *ngIf="!option.hideCloseIcon">
          <span
            (click)="close()"
            class="cursor-pointer"
            ><img
              [src]="'/assets/icons/svg/u_close_modal_confirm.svg'"
              alt="icons gray cross"
          /></span>
        </div>
      </div>
    </div>
    <div class="cls-content">
      
      <div class="d-flex align-items-start gap-8px">
        <img src="/assets/icons/svg/report.svg" />
        <div [innerHTML]="contents.content"
          class="content"
          ></div
        >
      </div>
    </div>
    
    <div
      class="d-flex justify-content-end p-3 footer"
    >
      <!-- [disabled]="!formcreate.valid" -->
      <button class="btn btn-outline-primary" *ngIf="!option.hideCloseButton" type="button" (click)="Del(null)">
        Hủy
      </button>
      <button class="btn {{!option.actionBtnClass ? 'btn-danger' : option.actionBtnClass}}" type="button" (click)="Del(true)">
        {{ contents.confirmOption == true ? 'Xóa' : option?.actionBtnLabel ? option?.actionBtnLabel :'Xác nhận' }}
      </button>
    </div>
  </div>
</div>
