<!--begin: My Cart -->
<div ngbDropdown placement="bottom" autoClose="outside" class="kt-header__topbar-item">
	<div ngbDropdownToggle class="kt-header__topbar-wrapper">
		<span class="kt-header__topbar-icon"
			[ngClass]="{'kt-header__topbar-icon--brand':  iconType === 'brand'}">
			<i *ngIf="!useSVG" [ngClass]="icon"></i>
			<span *ngIf="useSVG"
				class="kt-svg-icon"
				[inlineSVG]="icon"
				[ngClass]="{'kt-svg-icon--brand':  iconType === 'brand'}"></span>
		</span>
	</div>
	<div ngbDropdownMenu class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
		<form>
			<!-- begin:: Mycart -->
			<div class="kt-mycart">
				<div class="kt-mycart__head kt-head" [ngStyle]="{'background-image': 'url('+bgImage+')'}">
					<div class="kt-mycart__info">
						<span class="kt-mycart__icon"><i class="flaticon2-shopping-cart-1 kt-font-success"></i></span>
						<h3 class="kt-mycart__title">My Cart</h3>
					</div>
					<div class="kt-mycart__button">
						<button type="button" class="btn btn-success btn-sm">2 Items</button>
					</div>
				</div>

				<div [perfectScrollbar]="{wheelPropagation: false}" [ngStyle]="{'max-height': '28vh', 'position': 'relative'}" class="kt-mycart__body">
					<div class="kt-mycart__item">
						<div class="kt-mycart__container">
							<div class="kt-mycart__info">
								<a href="javascript:;" class="kt-mycart__title">
									Samsung
								</a>
								<span class="kt-mycart__desc">
			                        Profile info, Timeline etc
			                    </span>

								<div class="kt-mycart__action">
									<span class="kt-mycart__price">$ 450</span>
									<span class="kt-mycart__text">for</span>
									<span class="kt-mycart__quantity">7</span>
									<a href="javascript:;" class="btn btn-label-success btn-icon">−</a>
									<a href="javascript:;" class="btn btn-label-success btn-icon">+</a>
								</div>
							</div>

							<a href="javascript:;" class="kt-mycart__pic">
								<img src="./assets/media/products/product9.jpg" title="">
							</a>
						</div>
					</div>

					<div class="kt-mycart__item">
						<div class="kt-mycart__container">
							<div class="kt-mycart__info">
								<a href="javascript:;" class="kt-mycart__title">
									Panasonic
								</a>

								<span class="kt-mycart__desc">
			                        For PHoto &amp; Others
			                    </span>

								<div class="kt-mycart__action">
									<span class="kt-mycart__price">$ 329</span>
									<span class="kt-mycart__text">for</span>
									<span class="kt-mycart__quantity">1</span>
									<a href="javascript:;" class="btn btn-label-success btn-icon">−</a>
									<a href="javascript:;" class="btn btn-label-success btn-icon">+</a>
								</div>
							</div>

							<a href="javascript:;" class="kt-mycart__pic">
								<img src="./assets/media/products/product13.jpg" title="">
							</a>
						</div>
					</div>

					<div class="kt-mycart__item">
						<div class="kt-mycart__container">
							<div class="kt-mycart__info">
								<a href="javascript:;" class="kt-mycart__title">
									Fujifilm
								</a>
								<span class="kt-mycart__desc">
			                        Profile info, Timeline etc
			                    </span>

								<div class="kt-mycart__action">
									<span class="kt-mycart__price">$ 520</span>
									<span class="kt-mycart__text">for</span>
									<span class="kt-mycart__quantity">6</span>
									<a href="javascript:;" class="btn btn-label-success btn-icon">−</a>
									<a href="javascript:;" class="btn btn-label-success btn-icon">+</a>
								</div>
							</div>

							<a href="javascript:;" class="kt-mycart__pic">
								<img src="./assets/media/products/product16.jpg" title="">
							</a>
						</div>
					</div>

					<div class="kt-mycart__item">
						<div class="kt-mycart__container">
							<div class="kt-mycart__info">
								<a href="javascript:;" class="kt-mycart__title">
									Candy Machine
								</a>

								<span class="kt-mycart__desc">
			                        For PHoto &amp; Others
			                    </span>

								<div class="kt-mycart__action">
									<span class="kt-mycart__price">$ 784</span>
									<span class="kt-mycart__text">for</span>
									<span class="kt-mycart__quantity">4</span>
									<a href="javascript:;" class="btn btn-label-success btn-icon">−</a>
									<a href="javascript:;" class="btn btn-label-success btn-icon">+</a>
								</div>
							</div>

							<a href="javascript:;" class="kt-mycart__pic">
								<img src="./assets/media/products/product15.jpg" title="" alt="">
							</a>
						</div>
					</div>
				</div>

				<div class="kt-mycart__footer">
					<div class="kt-mycart__section">
						<div class="kt-mycart__subtitel">
							<span>Sub Total</span>
							<span>Taxes</span>
							<span>Total</span>
						</div>

						<div class="kt-mycart__prices">
							<span>$ 840.00</span>
							<span>$ 72.00</span>
							<span class="kt-font-brand">$ 912.00</span>
						</div>
					</div>
					<div class="kt-mycart__button kt-align-right">
						<button type="button" class="btn btn-primary btn-sm">Place Order</button>
					</div>
				</div>
			</div>
			<!-- end:: Mycart -->
		</form>
	</div>
</div>
<!--end: My Cart -->
