<div class="container-fluid mb-3 mt-3">
  <div class="row">
    <div class="col-lg-5 col-12 p-0" *ngIf="!advanceMode">
      <div class="custom-select-search-advance non-advance-search">
        <button
          mat-flat-button
          class="btn-action-non-advance"
          (click)="showAdvance()"
        >
          <mat-icon class="pt-3px">tune</mat-icon>
          <span class="only-show-on-desktop"> Tìm nâng cao </span>
        </button>
        <button
          *ngIf="searchText"
          class="delete-all-input-btn"
          (click)="clearSearch()"
        >
          <mat-icon>close</mat-icon>
        </button>
        <mat-form-field appearance="outline" class="mat-form-field-full-width">
          <mat-label>
            <i id="search-icon" class="fas fa-search mr-2"></i>
            Nhập tìm kiếm
          </mat-label>
          <input
            class="input-width-70"
            matInput
            [(ngModel)]="searchText"
            autocomplete="off"
            (keyup)="keyUp.next($event.target.value)"
            (focus)="hideSearchIcon(true)"
            (blur)="hideSearchIcon(false)"
          />
        </mat-form-field>
      </div>
    </div>
    <!-- <div class="col-lg-12 col-12 p-0 mb-4" *ngIf="advanceMode">
      <div
        class="search-bar only-show-on-desktop d-flex justify-content-between pt-2 pb-2"
      >
        <div class="d-flex col-lg-10 p-0">
          <img src="../../../../assets/icons/svg/code-line.svg" />
          <span class="result-search">{{ searchText }}</span>
        </div>
        <div>
          <button mat-flat-button (click)="showNormalSearch()">
            Ẩn tìm nâng cao
          </button>
        </div>
      </div>
    </div> -->
    <div
      class="spawn-filter col-lg-12 col-12 p-0"
      *ngFor="let item of filterRow; index as i"
    >
      <div class="row item-filter" *ngIf="advanceMode">
        <div class="col-lg-2 col-6 p-0 pr-2 mb-3">
          <div class="custom-select-search-advance">
            <mat-form-field
              appearance="outline"
              class="mat-form-field-full-width"
            >
              <mat-label>Chọn tên cột</mat-label>
              <mat-select
                [(ngModel)]="item.type"
                (selectionChange)="typeChange($event.value, i)"
              >
                <mat-option
                  *ngFor="let filter of search_field"
                  value="{{ filter.ColumnName }}"
                >
                  {{ filter.Label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="col-lg-2 col-6 p-0 pr-2 mb-3">
          <div class="custom-select-search-advance">
            <mat-form-field
              appearance="outline"
              class="mat-form-field-full-width"
            >
              <mat-label>So sánh</mat-label>
              <mat-select
                [(ngModel)]="item.operator"
                [disabled]="item.type == ''"
                (selectionChange)="typeChange($event.value)"
              >
                <mat-option
                  *ngFor="let option of compare[i]"
                  value="{{ option.Operator }}"
                >
                  {{ option.Label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="col-lg-5 col-12 p-0 pr-2 mb-3">
          <div class="custom-select-search-advance">
            <mat-form-field
              appearance="outline"
              class="mat-form-field-full-width"
              *ngIf="item.typeSelected == 'TEXT'"
            >
              <mat-label>Nhập giá trị</mat-label>
              <input
                [disabled]="item.operator == ''"
                (keyup)="keyUp.next($event.target.value)"
                autocomplete="off"
                matInput
                placeholder="Nhập giá trị"
                [(ngModel)]="item.value"
              />
            </mat-form-field>
          </div>

          <div class="custom-select-search-advance">
            <mat-form-field
              *ngIf="item.typeSelected == 'DATE'"
              appearance="outline"
              class="mat-form-field-full-width"
            >
              <mat-label>Chọn ngày</mat-label>
              <input
                [disabled]="item.operator == ''"
                autocomplete="off"
                matInput
                [matDatepicker]="date"
                [(ngModel)]="item.value"
                (dateChange)="typeChange($event.value)"
                (keyup)="keyUp.next($event.target.value)"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="date"
              ></mat-datepicker-toggle>
              <mat-datepicker #date></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="custom-select-search-advance">
            <mat-form-field
              *ngIf="item.typeSelected == 'SELECT'"
              appearance="outline"
              class="mat-form-field-full-width"
            >
              <mat-label>Lựa chọn</mat-label>
              <mat-select
                [disabled]="item.operator == ''"
                [(ngModel)]="item.value"
                (selectionChange)="typeChange($event.value)"
              >
                <mat-option
                  *ngFor="let option of item.optionCustoms"
                  value="{{ option.Value }}"
                >
                  {{ option.Label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="custom-select-search-advance">
            <mat-form-field
              *ngIf="item.typeSelected == 'NUMBER'"
              appearance="outline"
              class="mat-form-field-full-width"
            >
              <mat-label>Nhập giá trị</mat-label>
              <input
                [disabled]="item.operator == ''"
                (keyup)="keyUp.next($event.target.value)"
                autocomplete="off"
                type="number"
                matInput
                placeholder="Nhập giá trị"
                [(ngModel)]="item.value"
              />
            </mat-form-field>
          </div>
        </div>
        <div
          class="col-lg-2 col-6 p-0 pr-2 mb-3 only-show-on-mobile"
          *ngIf="filterRow.length - i > 1"
        >
          <div class="custom-select-search-advance">
            <mat-form-field
              appearance="outline"
              class="mat-form-field-full-width"
            >
              <mat-select
                [(ngModel)]="item.break"
                (selectionChange)="typeChange($event.value)"
              >
                <mat-option value=",">Và</mat-option>
                <mat-option value="|">Hoặc</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="col-lg-2 col-6 p-0 pr-2 mb-3 only-show-on-desktop">
          <div class="custom-select-search-advance">
            <mat-form-field
              *ngIf="filterRow.length - i > 1"
              appearance="outline"
              class="mat-form-field-full-width"
            >
              <mat-select
                [(ngModel)]="item.break"
                (selectionChange)="typeChange($event.value)"
              >
                <mat-option value=",">Và</mat-option>
                <mat-option value="|">Hoặc</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div
          class="col-lg-1 col-6 d-flex justify-content-center only-show-on-desktop"
          *ngIf="filterRow.length > 1"
        >
          <button
            mat-icon-button
            class="mt-5px"
            (click)="removeFilter(i)"
            matTooltip="Xóa dòng"
          >
            <mat-icon class="iconColor">delete</mat-icon>
          </button>
        </div>
        <div
          class="col-lg-1 col-6 only-show-on-mobile"
          *ngIf="filterRow.length > 1"
        >
          <button
            class="color-text-blue hide-search-advance-btn"
            mat-flat-button
            (click)="removeFilter(i)"
          >
            Xóa dòng này
          </button>
        </div>
      </div>
    </div>
    <div
      class="col-lg-12 col-12 p-0 d-flex justify-content-between"
      *ngIf="advanceMode"
    >
      <div class="col-lg-12 col-12 p-0">
        <button
          mat-flat-button
          class="btn-add btn-res-mobile"
          (click)="addFilter()"
        >
          <mat-icon class="mt-3px ml-n5px">add</mat-icon>
          Thêm bộ lọc
        </button>

        <button
          *ngIf="searchText.length > 0 || filterRow.length > 1"
          mat-flat-button
          class="reset-search-btn mr-2 ml-2 btn-res-mobile"
          (click)="resetSearch()"
        >
          <mat-icon class="mt-4px">replay</mat-icon>
          Đặt lại
        </button>
        <button
          *ngIf="searchText.length > 0 || filterRow.length > 1"
          mat-flat-button
          class="btn-action btn-res-mobile"
          (click)="onSubmitFilter()"
        >
          Tìm kiếm
        </button>
        <button
          class="ml-2 only-show-on-desktop"
          mat-flat-button
          (click)="showNormalSearch()"
        >
          Ẩn tìm nâng cao
        </button>
      </div>
    </div>
    <div
      class="col-12 justify-content-center display-flex-mobile"
      *ngIf="advanceMode"
    >
      <button
        class="color-text-blue mt-4"
        mat-flat-button
        (click)="showNormalSearch()"
      >
        <mat-icon class="pt-3px">tune</mat-icon>
        Ẩn tìm nâng cao
      </button>
    </div>
  </div>
</div>
