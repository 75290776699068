<div class="w-full relative" clickOutside (clickOutside)="openDropdown = false">
  <button
    #drdbtn
    [disabled]="isDisabled"
    (click)="openDropdown = !openDropdown"
    (focusout)="markAsTouched()"
    [ngClass]="{'bg-lightest-grey': isDisabled}"
    class="w-full bg-white flex justify-between border border-blue h-30px hover:border-primary-light focus:border-dark-blue px-8px rounded inline-flex items-center focus:outline-none h-28px"
  >
    <div class="flex w-full">
      <img
        *ngIf="selectedItem?.icon && !isActionMenu"
        [src]="selectedItem?.icon"
        alt=""
        width="16px"
        height="16px"
        class="mr-5px"
      />
      <input
        *ngIf="canInput; else showText"
        [value]="value"
        (change)="onSelectItem($event.target.value)"
        [placeholder]="placeholder"
        [type]="inputType"
        class="w-full"
      />
      <ng-template #showText>
        <span
          class="text-14px selected-label"
          [title]="
            selectedItem && selectedItem.tooltip ? selectedItem.tooltip : ''
          "
          [ngClass]="{placeholder: !selectedItem?.name}"
          >{{
            selectedItem && selectedItem.name && !isActionMenu
              ? selectedItem.name
              : placeholder
          }}</span
        >
      </ng-template>
    </div>
    <div>
      <div class="mt-2px">
        <img
          [src]="
            openDropdown
              ? './assets/icons/svg/keyboard_arrow_down.svg'
              : './assets/icons/svg/keyboard_arrow_down.svg'
          "
        />
      </div>
    </div>
  </button>
  <div
    class="absolute w-full z-index top-full mt-2px shadow-md rounded-md border-t-0"
    [ngClass]="{hidden: !openDropdown}"
    *ngIf="!isActionMenu; else actionMenu"
  >
    <div class="py-8px bg-white box-shadow-custom float-right w-full">
      <li
        *ngFor="let item of options"
        class="bg-white hover:bg-tlr-lightest-blue py-10px px-16px rounded inline-flex items-center focus:outline-none mr-8px w-full"
        [title]="item.tooltip ? item.tooltip : ''"
        (click)="onSelectItem(item)"
        [value]="item.id"
      >
        <img
          *ngIf="item.icon"
          [src]="item.icon"
          alt=""
          width="20px"
          height="20px"
          class="mr-5px"
        />
        <span class="{{ classFont }} truncate">
          {{ item.name }}
        </span>
      </li>
    </div>
  </div>
  <ng-template #actionMenu>
    <ul
      id="dropdown-options"
      class="{{
        openDropdown
          ? 'visible z-10 pl-0 transition duration-300 opacity-100 bg-white rounded-4px border border-solid border-lightest-grey py-8px w-200px absolute'
          : 'hidden'
      }} {{ align + '-0' }}"
    >
      <li
        *ngFor="let item of options"
        class="cursor-pointer py-10px px-16px hover:bg-tlr-lightest-blue flex items-center"
        [value]="item.id"
        (click)="onSelectItem(item)"
      >
        <img
          *ngIf="item.icon"
          class="w-20px h-20px mr-10px"
          alt="u_edit-alt"
          [src]="item.icon"
        />
        <div class="text-gray text-14px font-roboto-regular">
          {{ item.name }}
        </div>
      </li>
    </ul>
  </ng-template>
</div>
