<!-- begin:: Header Topbar -->
<div class="kt-header__topbar">
  <!--begin: My Cart -->
  <!-- <kt-cart [bgImage]="'./assets/media/misc/bg-1.jpg'" [icon]="'./assets/media/icons/svg/Shopping/Cart3.svg'"
    [useSVG]="true"></kt-cart> -->
  <!--end: My Cart -->
  <!--begin: Notifications -->
  <kt-notification
    [bgImage]="'./assets/media/misc/bg-1.jpg'"
    [pulse]="true"
    [skin]="'dark'"
    [icon]="'./assets/icons/svg/bell_2.svg'"
    [useSVG]="true"
  ></kt-notification>
  <!--end: Notifications -->
  <!--begin: Quick actions -->
  <kt-quick-action
    [bgImage]="'./assets/media/misc/bg-2.jpg'"
    [skin]="'dark'"
    [icon]="'./assets/media/icons/svg/Layout/Layout-4-blocks.svg'"
    [useSVG]="true"
  ></kt-quick-action>
  <!--end: Quick actions -->
  <!--begin: Quick panel toggler -->
  <!-- <div class="kt-header__topbar-item kt-header__topbar-item--quick-panel" data-placement="left"
    ngbTooltip="Quick panel">
    <div class="kt-header__topbar-wrapper">
      <span class="kt-header__topbar-icon" id="kt_quick_panel_toggler_btn">
        <span class="kt-svg-icon" [inlineSVG]="'./assets/media/icons/svg/Layout/Layout-4-blocks.svg'"></span>
      </span>
    </div>
  </div> -->
  <!--end: Quick panel toggler -->
  <!--begin: Language bar -->
  <!-- <kt-language-selector></kt-language-selector> -->
  <!--end: Language bar -->
  <!--begin: User bar -->
  <kt-user-profile2></kt-user-profile2>
  <!--end: User bar -->
</div>
<!-- end:: Header Topbar -->
