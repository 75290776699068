<div class="modal-content-container custom-dashboard">
  <div class="flex flex-col py-8px px-16px border-b border-tlr-lightest-blue">
    <div class="text-16px text-dark-grey font-roboto-medium">
      Chọn widget
      <img
        src="/assets/icons/svg/u_times.svg"
        alt=""
        height="20px"
        (click)="closeModalClick(null)"
        class="cursor-pointer lg:block inline float-right"
      />
    </div>
  </div>

  <div class="px-16px pt-16px" style="overflow-y: auto; height: 72vh">
    <div class="mb-16px">
      <div class="flex h-32px">
        <button
          class="w-35px md:w-auto outline-none py-5px rounded-sm flex-grow"
          title="Trước"
          (click)="scrollLeft()"
        >
          <img
            class="icon-arrow flex flex-column"
            src="/assets/icons/svg/u_angle-left.svg"
            alt=""
          />
        </button>
        <div
          #widgetsContent
          class="flex overflow-auto overflow-x-hidden overflow-y-hidden p-0 choose-pannel"
        >
          <button
            *ngFor="let item of listBtn; index as i"
            class="outline-none flex items-center px-16px py-6px text-14px ml-8px rounded"
            (click)="getCurrentBtn(i)"
            [ngClass]="
              item.isChoose
                ? 'bg-blue text-white border border-blue'
                : 'bg-white text-blue border border-blue'
            "
          >
            <span class="w-max">
              {{ item.name }}
            </span>
          </button>
        </div>

        <button
          class="w-35px md:w-auto outline-none py-5px rounded-sm flex-grow"
          title="Sau"
          (click)="scrollRight()"
        >
          <img
            class="icon-arrow flex flex-column"
            src="/assets/icons/svg/u_angle-right.svg"
            alt=""
          />
        </button>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-8px mb-16px">
      <div class="col-span-2">
        <div class="padding-input-search">
          <div
            class="right-inner-addon input-container pointer input-search-event full-width-field"
          >
            <div class="input-group border-search">
              <div class="input-group-prepend">
                <div class="icon-search none-border">
                  <img
                    class="flex justify-content-center flex-column input-icon-search ico-wd"
                    src="./assets/icons/svg/icon_search.svg"
                  />
                </div>
              </div>
              <input
                type="text"
                class="m-h-40px form-control input-search none-border"
                id="inlineFormInputGroupUsername2"
                placeholder="Tìm kiếm..."
                autocomplete="off"
                (keyup)="keyUp.next($event.target.value)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-8px overflow-mobile-dcl">
      <div
        *ngFor="let item of listWidget; index as i"
        class="col-span-2 md:col-span-1 card cursor-pointer border rounded"
        [class]="'br-4 card-widget'"
        [ngClass]="
          item.isChoose ? 'border-blue' : 'border-grayscale-light-grey'
        "
        (click)="chooseWidget(i)"
      >
        <div
          class="h-16 self-stretch flex-grow-0 flex flex-col justify-center items-start p-2 bg-tlr-lightest-blue"
        >
          <div
            class="flex-grow-0 text-base font-medium leading-normal text-left text-14px truncate w-full"
          >
            {{ item.name }}
          </div>
          <span class="flex-grow-0 text-sm font-normal not-italic text-grey">
            {{ item.description }}
          </span>
        </div>
        <div class="card-body p-0">
          <img class="img-widget" [src]="item.image" />
        </div>
      </div>
      <div
        class="flex justify-center items-center col-span-2"
        *ngIf="listWidget.length == 0"
      >
        <span>Không có dữ liệu</span>
      </div>
    </div>
    <div class="flex rounded-5px justify-end">
      <div class="mr-0 flex">
        <mat-paginator
          class=""
          (page)="paginatorChange($event)"
          [pageSize]="paging.pageSize"
          [length]="paging.recordCount"
          [hidePageSize]="false"
          [pageSizeOptions]="[4, 8, 16, 32]"
          [showFirstLastButtons]="true"
        >
        </mat-paginator>
      </div>
    </div>
  </div>

  <div class="flex justify-end p-16px">
    <button
      class="btn btn-outline-primary mr-2"
      (click)="closeModalClick(null)"
    >
      Hủy
    </button>
    <button class="btn btn-primary" (click)="save()">Thêm</button>
  </div>
</div>
