<div class="custom-dashboard">
  <div class="col-span-9 hidden"></div>
  <div class="content-custom-dashboard">
    <div class="lg:bg-white lg:border-b lg:border-tlr-lightest-blue">
      <div class="grid grid-cols-12">
        <div
          class="lg:col-span-9 col-span-12 pt-16px border-b border-tlr-lightest-blue bg-white pl-19px pr-22px lg:p-0 lg:border-b-0 lg:bg-transparent"
        >
          <div class="flex">
            <button
              class="outline-none py-5px px-0 rounded-sm lg:hidden"
              title="Trước"
              (click)="scrollLeft()"
              *ngIf="listMyBoard.length"
            >
              <img
                class="icon-arrow flex flex-column"
                src="/assets/icons/svg/u_angle-left.svg"
                alt=""
              />
            </button>
            <div
              #widgetsContent
              class="flex overflow-x-hidden lg:overflow-auto overflow-y-hidden p-0 choose-pannel"
            >
              <div
                class="pl-10px lg:pl-16px py-16px text-14px cursor-pointer inline-block whitespace-nowrap {{
                  boardSelected != null && indexSelect === i
                    ? 'font-roboto-medium activated-board'
                    : 'text-grey font-medium '
                }}"
                [ngClass]="{
                  'pr-10px lg:pr-16px':
                    i !== listMyBoard.length - 1 || indexSelect === i
                }"
                *ngFor="let item of listMyBoard; index as i"
                (click)="getCurrentBoard(item, i)"
              >
                <span
                  [ngClass]="{
                    'pr-10px md:pr-16px':
                      i === listMyBoard.length - 1 &&
                      listMyBoard.length > 1 &&
                      indexSelect !== i &&
                      !isEdit
                  }"
                  >{{ item.name }}</span
                >
              </div>
            </div>

            <button
              class="outline-none py-5px rounded-sm px-0 lg:hidden"
              title="Sau"
              *ngIf="listMyBoard.length"
              (click)="scrollRight()"
            >
              <img
                class="icon-arrow flex flex-column"
                src="/assets/icons/svg/u_angle-right.svg"
                alt=""
              />
            </button>
            <div
              class="pl-8px pr-16px md:px-16px py-16px text-14px text-blue inline-block font-roboto-medium cursor-pointer whitespace-nowrap"
              (click)="openModalCreateBoard(false)"
              *ngIf="!isEdit"
            >
              <img
                class="inline -mt-3px mr-2px"
                src="/assets/icons/svg/add-blue.svg"
              /><span class="md:inline-block">Thêm thẻ</span>
            </div>
          </div>
        </div>
        <div
          class="lg:col-span-3 flex justify-end items-center col-span-12 text-right action pr-10px mt-mobile-10"
        >
          <ng-container *ngIf="!isEdit; else completeUpdate">
            <div>
              <meu-dropdown
                *ngIf="boardSelected?.extend_editable !== false"
                [isActionMenu]="true"
                [align]="'right'"
                (change)="onSelectAction($event)"
                [options]="options"
                placeholder="Thao tác"
              ></meu-dropdown>
            </div>
          </ng-container>

          <ng-template #completeUpdate>
            <button
              class="hidden lg:inline-block btn btn-primary"
              (click)="isEdit = !isEdit"
            >
              Xong
            </button>
            <div class="cursor-pointer lg:hidden inline-block mt-6px -mb-5px">
              <img
                (click)="isEdit = !isEdit"
                title="Xong"
                src="/assets/icons/svg/save.svg"
              />
            </div>
          </ng-template>
        </div>
      </div>
    </div>

    <div
      class="text-center text-14px text-warm-grey mt-27px"
      *ngIf="!isEdit && checkBoardEmpty()"
    >
      Không có widget
    </div>

    <div class="mt-16px">
      <ng-container *ngFor="let item of boardContent; let indexSection = index">
        <div
          *ngIf="isEdit || !checkBoardEmpty()"
          class="p-0 rounded {{ isEdit ? 'mb-8px shadow' : 'mb-16px' }}"
        >
          <div
            class="pt-10px bg-white"
            *ngIf="isEdit && boardSelected?.extend_editable !== false"
          >
            <div class="text-right flex justify-end">
              <img
                (click)="openModalSettingSection(item)"
                class="mr-3 cursor-pointer"
                title="Cài đặt bố cục"
                src="./assets/icons/svg/u_setting.svg"
              />
              <img
                (click)="deleteSection(item.board_section_id)"
                class="mr-2 cursor-pointer"
                title="Xóa"
                src="/assets/icons/svg/u_trash-alt.svg"
              />
              <img
                (click)="moveUpSection(item, indexSection)"
                class="mr-2 cursor-pointer"
                [title]="indexSection != 0 ? 'Chuyển lên' : ''"
                [src]="
                  '/assets/icons/svg/' +
                  (indexSection == 0
                    ? 'u_arrow-up-lightgrey.svg'
                    : 'u_arrow-up-grey.svg')
                "
              />
              <img
                (click)="moveDownSection(item, indexSection)"
                class="mr-2 cursor-pointer"
                [title]="
                  indexSection + 1 != boardContent.length ? 'Chuyển xuống' : ''
                "
                [src]="
                  '/assets/icons/svg/' +
                  (indexSection + 1 == boardContent.length
                    ? 'u_arrow-down-lightgrey.svg'
                    : 'u_arrow-down-grey.svg')
                "
              />
            </div>
          </div>
          <div
            class="grid grid-cols-12"
            [ngClass]="{
              'p-16px pt-10px bg-white': isEdit
            }"
          >
            <div
              *ngFor="let subItem of item.array_section_layout; index as i"
              class="p-0 pb-1 bg-white rounded-sm border-tlr-lightest-blue item-choose-widget mr-0 md:mb-0 {{
                !mobileScreen ? 'col-span-' + subItem : 'col-span-12'
              }}"
              [ngClass]="{
                'md:mr-16px mb-16px ': i < item.array_section_layout.length - 1
              }"
              [style.height.px]="
                item.height && item.height !== 0 ? item.height : '250'
              "
            >
              <div
                *ngIf="
                  !isEdit &&
                  (checkBoardWidgetEmpty(indexSection) ||
                    checkObjEmpty(item.board_widget[i]))
                "
                [style.height.px]="
                  item.height && item.height !== 0 ? item.height : '250'
                "
                class="w-full item-choose-widget flex justify-center items-center text-14px text-grey"
              >
                Không có widget
              </div>
              <div
                class="flex justify-center items-center cursor-pointer text-14px font-roboto-medium text-blue border border-dashed rounded-sm border-tlr-lightest-blue item-choose-widget"
                [style.height.px]="
                  item.height && item.height !== 0 ? item.height : '250'
                "
                *ngIf="
                  checkObjEmpty(item.board_widget[i]) &&
                  isEdit &&
                  boardSelected?.extend_editable !== false
                "
                (click)="openModalChooseWidget(item.board_section_id, i)"
              >
                <div class="text-center">
                  <img class="inline" src="/assets/icons/svg/add-blue.svg" />
                  <p class="mb-0">Chọn widget</p>
                </div>
              </div>
              <div class="p-16px" *ngIf="!checkObjEmpty(item.board_widget[i])">
                <div class="relative">
                  <div class="text-14px text-black font-roboto-medium pr-20px">
                    {{ item.board_widget[i].name }}
                  </div>
                  <div
                    class="toolbar block absolute top-0 right-0"
                    *ngIf="boardSelected?.extend_editable !== false"
                  >
                    <meu-dropdown-with-dots
                      [options]="optionsEditWidget"
                      [isLeftPosition]="true"
                      (idSelected)="
                        onSelectWidgetAction(
                          $event,
                          item.board_widget[i],
                          indexSection,
                          i
                        )
                      "
                    ></meu-dropdown-with-dots>
                  </div>
                </div>
                <div
                  [ngClass]="{
                    'body-card-widget-bar-chart':
                      item.board_widget[i].widget_name == 'bar-chart'
                  }"
                >
                  <div
                    [ngClass]="{
                      'body-card-widget': true,
                      'body-card-widget-bar-chart-widget':
                        item.board_widget[i].widget_name == 'bar-chart'
                    }"
                    [id]="item.board_widget[i].id"
                    [style.height.px]="
                      item.height && item.height !== 0
                        ? item.height -
                          (item.board_widget[i].widget_code ===
                          'TABLE_TASK_DEADLINE'
                            ? 55
                            : 35)
                        : '225'
                    "
                  >
                    <div #pluginReference [id]="item.board_widget[i].id">
                      <!--Nội dung widget-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div
      class="text-center bg-white text-14px font-roboto-medium text-blue py-8px cursor-pointer shadow"
      *ngIf="isEdit"
      (click)="openModalChooseLayout()"
    >
      <div
        class="border border-dashed border-tlr-lighter-blue py-25px mx-8px mt-0"
      >
        <img class="inline" src="/assets/icons/svg/add-blue.svg" />
        <p class="color-grey">Thêm mới bố cục</p>
      </div>
    </div>
  </div>
</div>
