// Angular
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// Components
import {BaseComponent} from './views/theme/base/base.component';
import {WorkflowLayoutComponent} from './views/theme/workflow/workflow.component';

import {ErrorPageComponent} from './views/theme/content/error-page/error-page.component';
// Auth
import {MeuBaseComponent} from './views/theme/meu-base/meu-base.component';
import {WorkitemStatusLayoutComponent} from './views/theme/workitem-status/workitem-status.component';
import {AuthGuard} from './core/auth';
import {environment} from 'environments/environment';

const routes: Routes = [
  {
    matcher: (url)=>{
      //@ts-ignore
      let _url = url.toString().replaceAll(",", "/");
      if (_url.startsWith("hr")){
        window.location.href = environment.ssoFeUrl + "/portal/" + _url.toString()
      }
      return null;
    }, 
    redirectTo: ''
  },
  {
    path: 'error/404',
    component: ErrorPageComponent,
    data: {
      type: 'error-v6',
      code: 404,
      title: '404... Not found',
    },
  },
  {
    path: 'error/somethingwrong',
    component: ErrorPageComponent,
    data: {
      type: 'error-v6',
      code: 404,
      title: 'Oops...',
      desc: ' Có lỗi xảy ra, vui lòng thử lại!',
    },
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('app/views/modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'workflow',
    component: WorkflowLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/views/modules/workflow/workflow.module').then(
            (m) => m.WorkflowModule
          ),
      },
    ],
  },
  {
    path: 'workflow/workitem/:itemCode/status',
    component: WorkitemStatusLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/views/modules/workflow/workflow.module').then(
            (m) => m.WorkflowModule
          ),
      },
    ],
  },
  {path: '', redirectTo: 'workflow/dashboard', pathMatch: 'full'},
  {
    path: 'error/:type',
    component: ErrorPageComponent,
  },

  //{path: '**', redirectTo: 'error/404', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
