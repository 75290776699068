import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DatePipe} from './pipe/date.pipe';
import {GenderPipe} from './pipe/gender.pipe';
import {FormErrorService} from './services/form-error.service';
import {FullNameService} from './services/full-name.service';
import {Title} from '@angular/platform-browser';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {MY_FORMATS} from '@app/shared/constants/constants';
import {TimePipe} from './pipe/time';
import {ControlSelectSearchComponent} from './common_elements/control-select-search/control-select-search.component';
import {ControlSelectSearchLoadMoreComponent} from './common_elements/control-select-search-load-more/control-select-search-load-more.component';
import {AppCommonElementComponent} from './common_elements/app-common-element/app-common-element.component';
import {ControlDatepickerComponent} from './common_elements/control-datepicker/control-datepicker.component';
import {ControlTimepickerComponent} from './common_elements/control-timepicker/control-timepicker.component';
import {DateRangePickerComponent} from './common_elements/date-range-picker/date-range-picker.component';
import {ControlUploadFileComponent} from './common_elements/control-upload-file/control-upload-file.component';
import {ControlCommentComponent} from './common_elements/control-comment/control-comment.component';
import {ControlUploadFileDropzoneComponent} from './common_elements/control-upload-file-dropzone/control-upload-file-dropzone.component';
import {ControlSearchComponent} from './common_elements/control-search/control-search.component';
import {DynamicDashboardComponent} from './common_elements/dynamic-dashboard/dynamic-dashboard.component';
import {ModalChooseLayoutComponent} from './common_elements/dynamic-dashboard/modal-choose-layout/modal-choose-layout.component';
import {ModalAddWidgetComponent} from './common_elements/dynamic-dashboard/modal-add-widget/modal-add-widget.component';
import {ModalCreateBoardComponent} from './common_elements/dynamic-dashboard/modal-create-board/modal-create-board.component';
import {ModalEditWidgetNameComponent} from './common_elements/dynamic-dashboard/modal-edit-widget-name/modal-edit-widget-name.component';
import {SelectLoadMoreDynamicDashboardComponent} from './common_elements/dynamic-dashboard/select-load-more-dynamic-dashboard/select-load-more-dynamic-dashboard.component';
import {AvatarCardComponent} from './common_elements/avatar-card/avatar-card.component';
import {MaterialFileInputModule} from 'ngx-material-file-input';
import {MaterialModule} from 'mat-modules';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {RouterModule} from '@angular/router';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {NgxSummernoteModule} from 'ngx-summernote';
import {PickerModule} from '@ctrl/ngx-emoji-mart';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {PortletModule} from '@app/views/partials/content/general/portlet/portlet.module';
import {HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import {InterceptService} from '@app/core/_base/crud';
import {InputComponent} from './common_elements/input/input.component';
import {RadioButtonComponent} from './common_elements/radio-button/radio-button.component';
import {DatepickerComponent} from './common_elements/datepicker/datepicker.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {ModalSettingSectionComponent} from './common_elements/dynamic-dashboard/modal-setting-section/modal-setting-section.component';
import {ModalConfigWidgetComponent} from './common_elements/dynamic-dashboard/modal-config-widget/modal-config-widget.component';
import {ModalConfirmComponent} from './common_elements/modal-confirm/modal-confirm.component';
import {DropdownWithDotsComponent} from './common_elements/dropdown-with-dots/dropdown-with-dots.component';
import {DropdownComponent} from './common_elements/dropdown/dropdown.component';
import {FormValidatorsService} from './services/form-validators.service';
import {ModalShareWidgetComponent} from './common_elements/dynamic-dashboard/modal-share-widget/modal-share-widget.component';
import {ShareListComponent} from './common_elements/dynamic-dashboard/share-list/share-list.component';
import {ModalShareComponent} from './common_elements/dynamic-dashboard/modal-share/modal-share.component';
import {HashTagDashboardComponent} from './common_elements/dynamic-dashboard/modal-share/hash-tag-dashboard/hash-tag-dashboard.component';

import {SelectContainerComponent} from './common_elements/select-container/select-container.component';
import {MultidatepickerModule} from './common_elements/multidatepicker/multidatepicker.module';
import {TreegridComponent} from './common_elements/treegrid/treegrid.component';
import {TreegridAddDialogComponent} from './common_elements/treegrid/treegrid-add-dialog/treegrid-add-dialog.component';
import {MeuCurrencyPipe} from './pipe/meu-currency.pipe';
import {MeuCurrencyDirective} from '@app/core/_base/layout/directives/meu-currency.directive';
import {CurrencyModule} from './pipe/meuCurrency.module';
import {MonthPickerComponentComponent} from './common_elements/multidatepicker/month-picker-component/month-picker-component.component';
import {EllipsisCellComponent} from './common_elements/ellipsis-cell/ellipsis-cell.component';
@NgModule({
  declarations: [
    DatePipe,
    GenderPipe,
    TimePipe,
    ControlSelectSearchComponent,
    ControlSelectSearchLoadMoreComponent,
    AppCommonElementComponent,
    ControlDatepickerComponent,
    ControlTimepickerComponent,
    DateRangePickerComponent,
    ControlUploadFileComponent,
    ControlCommentComponent,
    ControlUploadFileDropzoneComponent,
    ControlSearchComponent,
    DynamicDashboardComponent,
    ModalChooseLayoutComponent,
    ModalAddWidgetComponent,
    ModalCreateBoardComponent,
    ModalEditWidgetNameComponent,
    SelectLoadMoreDynamicDashboardComponent,
    ModalConfigWidgetComponent,
    ModalSettingSectionComponent,
    ModalShareWidgetComponent,
    ModalShareComponent,
    ShareListComponent,
    HashTagDashboardComponent,
    AvatarCardComponent,
    InputComponent,
    RadioButtonComponent,
    DatepickerComponent,
    MonthPickerComponentComponent,
    ModalConfirmComponent,
    DropdownWithDotsComponent,
    DropdownComponent,
    SelectContainerComponent,
    TreegridComponent,
    TreegridAddDialogComponent,
    MeuCurrencyDirective,
    EllipsisCellComponent,
  ],
  imports: [
    CommonModule,
    // material modules
    MaterialFileInputModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    RouterModule.forChild([
      {
        path: 'appcommonelement',
        component: AppCommonElementComponent,
      },
    ]),
    NgxDaterangepickerMd.forRoot(),
    PickerModule,
    NgxSummernoteModule,
    NgxDropzoneModule,
    PortletModule,
    NgSelectModule,
    MultidatepickerModule,
    CurrencyModule,
  ],
  providers: [
    FormErrorService,
    FullNameService,
    Title,
    DatePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    TimePipe,
    {provide: HTTP_INTERCEPTORS, useClass: InterceptService, multi: true},
    FormValidatorsService,
  ],
  exports: [
    DatePipe,
    GenderPipe,
    TimePipe,
    ControlSelectSearchComponent,
    ControlSelectSearchLoadMoreComponent,
    ControlDatepickerComponent,
    ControlUploadFileComponent,
    ControlCommentComponent,
    ControlUploadFileDropzoneComponent,
    ControlSearchComponent,
    DynamicDashboardComponent,
    AvatarCardComponent,
    InputComponent,
    RadioButtonComponent,
    DatepickerComponent,
    MonthPickerComponentComponent,
    ModalConfirmComponent,
    DropdownWithDotsComponent,
    DropdownComponent,
    TreegridComponent,
    EllipsisCellComponent,
  ],
})
export class SharedModule {
  //static emod = ɵɵdefineNgModule({ type: SharedModule });
}
