import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DbdBoardService} from '@app/api/dbdBoard.service';
import {ToastrService} from 'ngx-toastr';
import {DynamicDashboardComponent} from '../dynamic-dashboard.component';

@Component({
  selector: 'meu-modal-setting-section',
  templateUrl: './modal-setting-section.component.html',
  styleUrls: ['./modal-setting-section.component.scss'],
})
export class ModalSettingSectionComponent implements OnInit {
  height = 0;
  err = false;
  heightOptions = [
    {id: '150', name: '150'},
    {id: '170', name: '170'},
    {id: '190', name: '190'},
    {id: '210', name: '210'},
    {id: '230', name: '230'},
    {id: '250', name: '250'},
  ];
  constructor(
    public dialogRef: MatDialogRef<DynamicDashboardComponent>,
    @Inject(MAT_DIALOG_DATA) public dataInject,
    private toastr: ToastrService,
    private dbdBoardService: DbdBoardService
  ) {}

  ngOnInit(): void {
    this.height = this.dataInject.section.height;
  }

  onChangeHeight() {
    this.err = false;
    if (!this.height || isNaN(this.height)) {
      this.err = true;
      return;
    }
  }

  save() {
    if (!this.height || isNaN(this.height)) {
      return;
    }
    this.dataInject.section.height = this.height;
    this.dbdBoardService
      .apiMyBoardsSectionsIdPut(
        this.dataInject.section.board_section_id,
        this.dataInject.section
      )
      .subscribe((result) => {
        if (result.success) {
          this.toastr.success('Cài đặt bộ cục thành công.');
          this.closeModalClick(result.data);
        } else {
          this.toastr.error('Cài đặt bộ cục thất bại.');
          this.closeModalClick(null);
        }
      });
  }

  closeModalClick(data) {
    this.dialogRef.close(data);
  }
}
