<div
  class="meu-container login-img row"
  [ngStyle]="{
    'background-image': 'url(' + pathImg + 'login-connection-img.png)'
  }"
>
  <div
    class="left-view login-img"
    [ngStyle]="{
      'background-image': 'url(' + pathImg + 'login-page.png)'
    }"
  ></div>
  <div class="right-view row">
    <div
      class="col-12 rc-1"
      [ngStyle]="{
        'background-image': 'url(' + pathImg + 'top-right-reg.png)'
      }"
    >
      <img
        class="login-logo-img"
        [src]="htmlClassService.logoPathSubject$ | async"
      />
    </div>
    <div class="col-12 rc-2">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="col-12 footer-login">
    <div class="kt-footer__copyright text-gray">
      {{ today | date: 'yyyy' }}&nbsp;©&nbsp;
      <a href="https://meu-solutions.com/" target="_blank" rel="noreferrer" class="text-gray">
        MeU Solutions, Inc
      </a>
    </div>
  </div>
</div>
