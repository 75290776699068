import {Component, OnInit, Output, EventEmitter, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DbdSectionService} from '@app/api/dbdSection.service';
import {DynamicDashboardComponent} from '../dynamic-dashboard.component';

@Component({
  selector: 'meu-modal-choose-layout',
  templateUrl: './modal-choose-layout.component.html',
  styleUrls: ['./modal-choose-layout.component.scss'],
})
export class ModalChooseLayoutComponent implements OnInit {
  layoutSelected;
  indexSelect = 0;
  templateLayout = [];
  constructor(
    public dialogRef: MatDialogRef<DynamicDashboardComponent>,
    @Inject(MAT_DIALOG_DATA) public dataInject,
    private dbdSectionService: DbdSectionService
  ) {}

  ngOnInit(): void {
    this.dbdSectionService.apiDbdSectionGet().subscribe((res) => {
      if (res.success) {
        for (let i = 0; i < res.data.length; i++) {
          this.templateLayout.push({
            col_layout: res.data[i].col_layout.split(':'),
            section_layout: res.data[i].col_layout,
          });
        }
        this.layoutSelected =
          this.templateLayout.length > 0 ? this.templateLayout[0] : null;
      }
    });
  }

  save() {
    this.closeModalClick(this.layoutSelected);
  }

  closeModalClick(data) {
    this.dialogRef.close(data);
  }

  getCurrentLayout(item, index) {
    this.indexSelect = index;
    this.layoutSelected = item;
  }
}
