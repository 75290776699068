/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class WorkflowStatisticsService {

    protected basePath = 'https://gateway.dev.meu-solutions.com/sciencetechnology';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param start 
     * @param finish 
     * @param industryID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsAllStatisticsWorkitemGet(start?: Date, finish?: Date, industryID?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsAllStatisticsWorkitemGet(start?: Date, finish?: Date, industryID?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsAllStatisticsWorkitemGet(start?: Date, finish?: Date, industryID?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsAllStatisticsWorkitemGet(start?: Date, finish?: Date, industryID?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (start !== undefined && start !== null) {
            queryParameters = queryParameters.set('start', <any>start.toISOString());
        }
        if (finish !== undefined && finish !== null) {
            queryParameters = queryParameters.set('finish', <any>finish.toISOString());
        }
        if (industryID !== undefined && industryID !== null) {
            queryParameters = queryParameters.set('industryID', <any>industryID);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflows/all/statistics/workitem`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param yearStart 
     * @param yearEnd 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsStatisticsBudgetGet(yearStart?: number, yearEnd?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsStatisticsBudgetGet(yearStart?: number, yearEnd?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsStatisticsBudgetGet(yearStart?: number, yearEnd?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsStatisticsBudgetGet(yearStart?: number, yearEnd?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (yearStart !== undefined && yearStart !== null) {
            queryParameters = queryParameters.set('yearStart', <any>yearStart);
        }
        if (yearEnd !== undefined && yearEnd !== null) {
            queryParameters = queryParameters.set('yearEnd', <any>yearEnd);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflows/statistics/budget`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsStatisticsContractAllStatusGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsStatisticsContractAllStatusGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsStatisticsContractAllStatusGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsStatisticsContractAllStatusGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflows/statistics/contract/allStatus`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowID 
     * @param programOrAreaID 
     * @param start 
     * @param finish 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsStatisticsContractGet(workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsStatisticsContractGet(workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsStatisticsContractGet(workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsStatisticsContractGet(workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workflowID !== undefined && workflowID !== null) {
            queryParameters = queryParameters.set('workflowID', <any>workflowID);
        }
        if (programOrAreaID !== undefined && programOrAreaID !== null) {
            queryParameters = queryParameters.set('programOrAreaID', <any>programOrAreaID);
        }
        if (start !== undefined && start !== null) {
            queryParameters = queryParameters.set('start', <any>start.toISOString());
        }
        if (finish !== undefined && finish !== null) {
            queryParameters = queryParameters.set('finish', <any>finish.toISOString());
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflows/statistics/contract`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param yearStart 
     * @param yearEnd 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsStatisticsCostGet(yearStart?: number, yearEnd?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsStatisticsCostGet(yearStart?: number, yearEnd?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsStatisticsCostGet(yearStart?: number, yearEnd?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsStatisticsCostGet(yearStart?: number, yearEnd?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (yearStart !== undefined && yearStart !== null) {
            queryParameters = queryParameters.set('yearStart', <any>yearStart);
        }
        if (yearEnd !== undefined && yearEnd !== null) {
            queryParameters = queryParameters.set('yearEnd', <any>yearEnd);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflows/statistics/cost`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsStatisticsGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsStatisticsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsStatisticsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsStatisticsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflows/statistics`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param startDate 
     * @param endDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsStatisticsMajorGet(startDate?: Date, endDate?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsStatisticsMajorGet(startDate?: Date, endDate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsStatisticsMajorGet(startDate?: Date, endDate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsStatisticsMajorGet(startDate?: Date, endDate?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflows/statistics/major`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param productTypeID 
     * @param workflowID 
     * @param start 
     * @param end 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsStatisticsProductTypeIDWorkItemsProductGet(productTypeID: string, workflowID?: string, start?: Date, end?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsStatisticsProductTypeIDWorkItemsProductGet(productTypeID: string, workflowID?: string, start?: Date, end?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsStatisticsProductTypeIDWorkItemsProductGet(productTypeID: string, workflowID?: string, start?: Date, end?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsStatisticsProductTypeIDWorkItemsProductGet(productTypeID: string, workflowID?: string, start?: Date, end?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productTypeID === null || productTypeID === undefined) {
            throw new Error('Required parameter productTypeID was null or undefined when calling apiWorkflowsStatisticsProductTypeIDWorkItemsProductGet.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workflowID !== undefined && workflowID !== null) {
            queryParameters = queryParameters.set('workflowID', <any>workflowID);
        }
        if (start !== undefined && start !== null) {
            queryParameters = queryParameters.set('start', <any>start.toISOString());
        }
        if (end !== undefined && end !== null) {
            queryParameters = queryParameters.set('end', <any>end.toISOString());
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflows/statistics/${encodeURIComponent(String(productTypeID))}/workItems/product`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowID 
     * @param start 
     * @param finish 
     * @param companyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsStatisticsStakeholderGet(workflowID?: string, start?: Date, finish?: Date, companyID?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsStatisticsStakeholderGet(workflowID?: string, start?: Date, finish?: Date, companyID?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsStatisticsStakeholderGet(workflowID?: string, start?: Date, finish?: Date, companyID?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsStatisticsStakeholderGet(workflowID?: string, start?: Date, finish?: Date, companyID?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workflowID !== undefined && workflowID !== null) {
            queryParameters = queryParameters.set('workflowID', <any>workflowID);
        }
        if (start !== undefined && start !== null) {
            queryParameters = queryParameters.set('start', <any>start.toISOString());
        }
        if (finish !== undefined && finish !== null) {
            queryParameters = queryParameters.set('finish', <any>finish.toISOString());
        }
        if (companyID !== undefined && companyID !== null) {
            queryParameters = queryParameters.set('companyID', <any>companyID);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflows/statistics/stakeholder`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowID 
     * @param companyID 
     * @param start 
     * @param finish 
     * @param Filters 
     * @param Sorts 
     * @param Page 
     * @param PageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsStatisticsStakeholderWorkItemsExportGet(workflowID?: string, companyID?: string, start?: Date, finish?: Date, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowsStatisticsStakeholderWorkItemsExportGet(workflowID?: string, companyID?: string, start?: Date, finish?: Date, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowsStatisticsStakeholderWorkItemsExportGet(workflowID?: string, companyID?: string, start?: Date, finish?: Date, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowsStatisticsStakeholderWorkItemsExportGet(workflowID?: string, companyID?: string, start?: Date, finish?: Date, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workflowID !== undefined && workflowID !== null) {
            queryParameters = queryParameters.set('workflowID', <any>workflowID);
        }
        if (companyID !== undefined && companyID !== null) {
            queryParameters = queryParameters.set('companyID', <any>companyID);
        }
        if (start !== undefined && start !== null) {
            queryParameters = queryParameters.set('start', <any>start.toISOString());
        }
        if (finish !== undefined && finish !== null) {
            queryParameters = queryParameters.set('finish', <any>finish.toISOString());
        }
        if (Filters !== undefined && Filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>Filters);
        }
        if (Sorts !== undefined && Sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>Sorts);
        }
        if (Page !== undefined && Page !== null) {
            queryParameters = queryParameters.set('Page', <any>Page);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflows/statistics/stakeholder/workItems/export`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
		responseType: 'blob' as 'json',
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowID 
     * @param companyID 
     * @param start 
     * @param finish 
     * @param Filters 
     * @param Sorts 
     * @param Page 
     * @param PageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsStatisticsStakeholderWorkItemsGet(workflowID?: string, companyID?: string, start?: Date, finish?: Date, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsStatisticsStakeholderWorkItemsGet(workflowID?: string, companyID?: string, start?: Date, finish?: Date, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsStatisticsStakeholderWorkItemsGet(workflowID?: string, companyID?: string, start?: Date, finish?: Date, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsStatisticsStakeholderWorkItemsGet(workflowID?: string, companyID?: string, start?: Date, finish?: Date, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workflowID !== undefined && workflowID !== null) {
            queryParameters = queryParameters.set('workflowID', <any>workflowID);
        }
        if (companyID !== undefined && companyID !== null) {
            queryParameters = queryParameters.set('companyID', <any>companyID);
        }
        if (start !== undefined && start !== null) {
            queryParameters = queryParameters.set('start', <any>start.toISOString());
        }
        if (finish !== undefined && finish !== null) {
            queryParameters = queryParameters.set('finish', <any>finish.toISOString());
        }
        if (Filters !== undefined && Filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>Filters);
        }
        if (Sorts !== undefined && Sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>Sorts);
        }
        if (Page !== undefined && Page !== null) {
            queryParameters = queryParameters.set('Page', <any>Page);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflows/statistics/stakeholder/workItems`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param Filters 
     * @param Sorts 
     * @param Page 
     * @param PageSize 
     * @param startDate 
     * @param endDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsStatisticsUserMajorGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, startDate?: Date, endDate?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsStatisticsUserMajorGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, startDate?: Date, endDate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsStatisticsUserMajorGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, startDate?: Date, endDate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsStatisticsUserMajorGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, startDate?: Date, endDate?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (Filters !== undefined && Filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>Filters);
        }
        if (Sorts !== undefined && Sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>Sorts);
        }
        if (Page !== undefined && Page !== null) {
            queryParameters = queryParameters.set('Page', <any>Page);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflows/statistics/userMajor`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param userID 
     * @param startDate 
     * @param endDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsStatisticsUserMajorUserIDWorkflowItemGet(userID: string, startDate?: Date, endDate?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsStatisticsUserMajorUserIDWorkflowItemGet(userID: string, startDate?: Date, endDate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsStatisticsUserMajorUserIDWorkflowItemGet(userID: string, startDate?: Date, endDate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsStatisticsUserMajorUserIDWorkflowItemGet(userID: string, startDate?: Date, endDate?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userID === null || userID === undefined) {
            throw new Error('Required parameter userID was null or undefined when calling apiWorkflowsStatisticsUserMajorUserIDWorkflowItemGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDate !== undefined && startDate !== null) {
            queryParameters = queryParameters.set('startDate', <any>startDate.toISOString());
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('endDate', <any>endDate.toISOString());
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflows/statistics/userMajor/${encodeURIComponent(String(userID))}/workflowItem`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param workflowID 
     * @param programOrAreaID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsStatisticsV2BudgetCountPost(body?: Array<number>, workflowID?: string, programOrAreaID?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsStatisticsV2BudgetCountPost(body?: Array<number>, workflowID?: string, programOrAreaID?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsStatisticsV2BudgetCountPost(body?: Array<number>, workflowID?: string, programOrAreaID?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsStatisticsV2BudgetCountPost(body?: Array<number>, workflowID?: string, programOrAreaID?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workflowID !== undefined && workflowID !== null) {
            queryParameters = queryParameters.set('workflowID', <any>workflowID);
        }
        if (programOrAreaID !== undefined && programOrAreaID !== null) {
            queryParameters = queryParameters.set('programOrAreaID', <any>programOrAreaID);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflows/statistics-v2/budgetCount`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowID 
     * @param searchCompanyName 
     * @param Filters 
     * @param Sorts 
     * @param Page 
     * @param PageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsStatisticsV2CompanyCountGet(workflowID?: string, searchCompanyName?: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsStatisticsV2CompanyCountGet(workflowID?: string, searchCompanyName?: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsStatisticsV2CompanyCountGet(workflowID?: string, searchCompanyName?: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsStatisticsV2CompanyCountGet(workflowID?: string, searchCompanyName?: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workflowID !== undefined && workflowID !== null) {
            queryParameters = queryParameters.set('workflowID', <any>workflowID);
        }
        if (searchCompanyName !== undefined && searchCompanyName !== null) {
            queryParameters = queryParameters.set('searchCompanyName', <any>searchCompanyName);
        }
        if (Filters !== undefined && Filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>Filters);
        }
        if (Sorts !== undefined && Sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>Sorts);
        }
        if (Page !== undefined && Page !== null) {
            queryParameters = queryParameters.set('Page', <any>Page);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflows/statistics-v2/companyCount`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowId 
     * @param year 
     * @param programID 
     * @param areaID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsStatisticsV2ContractAssessmentCountGet(workflowId?: string, year?: number, programID?: string, areaID?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsStatisticsV2ContractAssessmentCountGet(workflowId?: string, year?: number, programID?: string, areaID?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsStatisticsV2ContractAssessmentCountGet(workflowId?: string, year?: number, programID?: string, areaID?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsStatisticsV2ContractAssessmentCountGet(workflowId?: string, year?: number, programID?: string, areaID?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workflowId !== undefined && workflowId !== null) {
            queryParameters = queryParameters.set('workflowId', <any>workflowId);
        }
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('year', <any>year);
        }
        if (programID !== undefined && programID !== null) {
            queryParameters = queryParameters.set('programID', <any>programID);
        }
        if (areaID !== undefined && areaID !== null) {
            queryParameters = queryParameters.set('areaID', <any>areaID);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflows/statistics-v2/contractAssessmentCount`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param workflowID 
     * @param programOrAreaID 
     * @param start 
     * @param finish 
     * @param approvedStart 
     * @param approvedFinish 
     * @param search 
     * @param statusId 
     * @param activityId 
     * @param contractMonthFilter 
     * @param contractReport_trueGD_falseNT 
     * @param bugdetYear 
     * @param hostCompanyId 
     * @param managerId 
     * @param expertId 
     * @param isExpired 
     * @param isWaitHandle 
     * @param withContractTrue_withoutContractFalse_elseNull 
     * @param Filters 
     * @param Sorts 
     * @param Page 
     * @param PageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsStatisticsV2ExportWorkItemsPost(body?: Array<string>, workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, approvedStart?: Date, approvedFinish?: Date, search?: string, statusId?: string, activityId?: string, contractMonthFilter?: Date, contractReport_trueGD_falseNT?: boolean, bugdetYear?: Date, hostCompanyId?: string, managerId?: string, expertId?: string, isExpired?: boolean, isWaitHandle?: boolean, withContractTrue_withoutContractFalse_elseNull?: boolean, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowsStatisticsV2ExportWorkItemsPost(body?: Array<string>, workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, approvedStart?: Date, approvedFinish?: Date, search?: string, statusId?: string, activityId?: string, contractMonthFilter?: Date, contractReport_trueGD_falseNT?: boolean, bugdetYear?: Date, hostCompanyId?: string, managerId?: string, expertId?: string, isExpired?: boolean, isWaitHandle?: boolean, withContractTrue_withoutContractFalse_elseNull?: boolean, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowsStatisticsV2ExportWorkItemsPost(body?: Array<string>, workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, approvedStart?: Date, approvedFinish?: Date, search?: string, statusId?: string, activityId?: string, contractMonthFilter?: Date, contractReport_trueGD_falseNT?: boolean, bugdetYear?: Date, hostCompanyId?: string, managerId?: string, expertId?: string, isExpired?: boolean, isWaitHandle?: boolean, withContractTrue_withoutContractFalse_elseNull?: boolean, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowsStatisticsV2ExportWorkItemsPost(body?: Array<string>, workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, approvedStart?: Date, approvedFinish?: Date, search?: string, statusId?: string, activityId?: string, contractMonthFilter?: Date, contractReport_trueGD_falseNT?: boolean, bugdetYear?: Date, hostCompanyId?: string, managerId?: string, expertId?: string, isExpired?: boolean, isWaitHandle?: boolean, withContractTrue_withoutContractFalse_elseNull?: boolean, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
























        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workflowID !== undefined && workflowID !== null) {
            queryParameters = queryParameters.set('workflowID', <any>workflowID);
        }
        if (programOrAreaID !== undefined && programOrAreaID !== null) {
            queryParameters = queryParameters.set('programOrAreaID', <any>programOrAreaID);
        }
        if (start !== undefined && start !== null) {
            queryParameters = queryParameters.set('start', <any>start.toISOString());
        }
        if (finish !== undefined && finish !== null) {
            queryParameters = queryParameters.set('finish', <any>finish.toISOString());
        }
        if (approvedStart !== undefined && approvedStart !== null) {
            queryParameters = queryParameters.set('approvedStart', <any>approvedStart.toISOString());
        }
        if (approvedFinish !== undefined && approvedFinish !== null) {
            queryParameters = queryParameters.set('approvedFinish', <any>approvedFinish.toISOString());
        }
        if (search !== undefined && search !== null) {
            queryParameters = queryParameters.set('search', <any>search);
        }
        if (statusId !== undefined && statusId !== null) {
            queryParameters = queryParameters.set('statusId', <any>statusId);
        }
        if (activityId !== undefined && activityId !== null) {
            queryParameters = queryParameters.set('activityId', <any>activityId);
        }
        if (contractMonthFilter !== undefined && contractMonthFilter !== null) {
            queryParameters = queryParameters.set('contractMonthFilter', <any>contractMonthFilter.toISOString());
        }
        if (contractReport_trueGD_falseNT !== undefined && contractReport_trueGD_falseNT !== null) {
            queryParameters = queryParameters.set('contractReport_trueGD_falseNT', <any>contractReport_trueGD_falseNT);
        }
        if (bugdetYear !== undefined && bugdetYear !== null) {
            queryParameters = queryParameters.set('bugdetYear', <any>bugdetYear.toISOString());
        }
        if (hostCompanyId !== undefined && hostCompanyId !== null) {
            queryParameters = queryParameters.set('hostCompanyId', <any>hostCompanyId);
        }
        if (managerId !== undefined && managerId !== null) {
            queryParameters = queryParameters.set('managerId', <any>managerId);
        }
        if (expertId !== undefined && expertId !== null) {
            queryParameters = queryParameters.set('expertId', <any>expertId);
        }
        if (isExpired !== undefined && isExpired !== null) {
            queryParameters = queryParameters.set('isExpired', <any>isExpired);
        }
        if (isWaitHandle !== undefined && isWaitHandle !== null) {
            queryParameters = queryParameters.set('isWaitHandle', <any>isWaitHandle);
        }
        if (withContractTrue_withoutContractFalse_elseNull !== undefined && withContractTrue_withoutContractFalse_elseNull !== null) {
            queryParameters = queryParameters.set('withContractTrue_withoutContractFalse_elseNull', <any>withContractTrue_withoutContractFalse_elseNull);
        }
        if (Filters !== undefined && Filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>Filters);
        }
        if (Sorts !== undefined && Sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>Sorts);
        }
        if (Page !== undefined && Page !== null) {
            queryParameters = queryParameters.set('Page', <any>Page);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/workflows/statistics-v2/exportWorkItems`,
            {
                body: body,
                params: queryParameters,
		responseType: 'blob' as 'json',
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param workflowID 
     * @param programOrAreaID 
     * @param start 
     * @param finish 
     * @param approvedStart 
     * @param approvedFinish 
     * @param search 
     * @param statusId 
     * @param activityId 
     * @param contractMonthFilter 
     * @param contractReport_trueGD_falseNT 
     * @param bugdetYear 
     * @param hostCompanyId 
     * @param managerId 
     * @param expertId 
     * @param isExpired 
     * @param isWaitHandle 
     * @param withContractTrue_withoutContractFalse_elseNull 
     * @param Filters 
     * @param Sorts 
     * @param Page 
     * @param PageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsStatisticsV2ExportWorkItemsWithCostPost(body?: Array<number>, workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, approvedStart?: Date, approvedFinish?: Date, search?: string, statusId?: string, activityId?: string, contractMonthFilter?: Date, contractReport_trueGD_falseNT?: boolean, bugdetYear?: Date, hostCompanyId?: string, managerId?: string, expertId?: string, isExpired?: boolean, isWaitHandle?: boolean, withContractTrue_withoutContractFalse_elseNull?: boolean, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowsStatisticsV2ExportWorkItemsWithCostPost(body?: Array<number>, workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, approvedStart?: Date, approvedFinish?: Date, search?: string, statusId?: string, activityId?: string, contractMonthFilter?: Date, contractReport_trueGD_falseNT?: boolean, bugdetYear?: Date, hostCompanyId?: string, managerId?: string, expertId?: string, isExpired?: boolean, isWaitHandle?: boolean, withContractTrue_withoutContractFalse_elseNull?: boolean, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowsStatisticsV2ExportWorkItemsWithCostPost(body?: Array<number>, workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, approvedStart?: Date, approvedFinish?: Date, search?: string, statusId?: string, activityId?: string, contractMonthFilter?: Date, contractReport_trueGD_falseNT?: boolean, bugdetYear?: Date, hostCompanyId?: string, managerId?: string, expertId?: string, isExpired?: boolean, isWaitHandle?: boolean, withContractTrue_withoutContractFalse_elseNull?: boolean, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowsStatisticsV2ExportWorkItemsWithCostPost(body?: Array<number>, workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, approvedStart?: Date, approvedFinish?: Date, search?: string, statusId?: string, activityId?: string, contractMonthFilter?: Date, contractReport_trueGD_falseNT?: boolean, bugdetYear?: Date, hostCompanyId?: string, managerId?: string, expertId?: string, isExpired?: boolean, isWaitHandle?: boolean, withContractTrue_withoutContractFalse_elseNull?: boolean, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
























        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workflowID !== undefined && workflowID !== null) {
            queryParameters = queryParameters.set('workflowID', <any>workflowID);
        }
        if (programOrAreaID !== undefined && programOrAreaID !== null) {
            queryParameters = queryParameters.set('programOrAreaID', <any>programOrAreaID);
        }
        if (start !== undefined && start !== null) {
            queryParameters = queryParameters.set('start', <any>start.toISOString());
        }
        if (finish !== undefined && finish !== null) {
            queryParameters = queryParameters.set('finish', <any>finish.toISOString());
        }
        if (approvedStart !== undefined && approvedStart !== null) {
            queryParameters = queryParameters.set('approvedStart', <any>approvedStart.toISOString());
        }
        if (approvedFinish !== undefined && approvedFinish !== null) {
            queryParameters = queryParameters.set('approvedFinish', <any>approvedFinish.toISOString());
        }
        if (search !== undefined && search !== null) {
            queryParameters = queryParameters.set('search', <any>search);
        }
        if (statusId !== undefined && statusId !== null) {
            queryParameters = queryParameters.set('statusId', <any>statusId);
        }
        if (activityId !== undefined && activityId !== null) {
            queryParameters = queryParameters.set('activityId', <any>activityId);
        }
        if (contractMonthFilter !== undefined && contractMonthFilter !== null) {
            queryParameters = queryParameters.set('contractMonthFilter', <any>contractMonthFilter.toISOString());
        }
        if (contractReport_trueGD_falseNT !== undefined && contractReport_trueGD_falseNT !== null) {
            queryParameters = queryParameters.set('contractReport_trueGD_falseNT', <any>contractReport_trueGD_falseNT);
        }
        if (bugdetYear !== undefined && bugdetYear !== null) {
            queryParameters = queryParameters.set('bugdetYear', <any>bugdetYear.toISOString());
        }
        if (hostCompanyId !== undefined && hostCompanyId !== null) {
            queryParameters = queryParameters.set('hostCompanyId', <any>hostCompanyId);
        }
        if (managerId !== undefined && managerId !== null) {
            queryParameters = queryParameters.set('managerId', <any>managerId);
        }
        if (expertId !== undefined && expertId !== null) {
            queryParameters = queryParameters.set('expertId', <any>expertId);
        }
        if (isExpired !== undefined && isExpired !== null) {
            queryParameters = queryParameters.set('isExpired', <any>isExpired);
        }
        if (isWaitHandle !== undefined && isWaitHandle !== null) {
            queryParameters = queryParameters.set('isWaitHandle', <any>isWaitHandle);
        }
        if (withContractTrue_withoutContractFalse_elseNull !== undefined && withContractTrue_withoutContractFalse_elseNull !== null) {
            queryParameters = queryParameters.set('withContractTrue_withoutContractFalse_elseNull', <any>withContractTrue_withoutContractFalse_elseNull);
        }
        if (Filters !== undefined && Filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>Filters);
        }
        if (Sorts !== undefined && Sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>Sorts);
        }
        if (Page !== undefined && Page !== null) {
            queryParameters = queryParameters.set('Page', <any>Page);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/workflows/statistics-v2/exportWorkItemsWithCost`,
            {
                body: body,
                params: queryParameters,
		responseType: 'blob' as 'json',
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param workflowID 
     * @param programOrAreaID 
     * @param start 
     * @param finish 
     * @param approvedStart 
     * @param approvedFinish 
     * @param search 
     * @param statusId 
     * @param activityId 
     * @param contractMonthFilter 
     * @param contractReport_trueGD_falseNT 
     * @param bugdetYear 
     * @param hostCompanyId 
     * @param managerId 
     * @param expertId 
     * @param isExpired 
     * @param isWaitHandle 
     * @param withContractTrue_withoutContractFalse_elseNull 
     * @param Filters 
     * @param Sorts 
     * @param Page 
     * @param PageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsStatisticsV2GetWorkItemsPost(body?: Array<string>, workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, approvedStart?: Date, approvedFinish?: Date, search?: string, statusId?: string, activityId?: string, contractMonthFilter?: Date, contractReport_trueGD_falseNT?: boolean, bugdetYear?: Date, hostCompanyId?: string, managerId?: string, expertId?: string, isExpired?: boolean, isWaitHandle?: boolean, withContractTrue_withoutContractFalse_elseNull?: boolean, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsStatisticsV2GetWorkItemsPost(body?: Array<string>, workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, approvedStart?: Date, approvedFinish?: Date, search?: string, statusId?: string, activityId?: string, contractMonthFilter?: Date, contractReport_trueGD_falseNT?: boolean, bugdetYear?: Date, hostCompanyId?: string, managerId?: string, expertId?: string, isExpired?: boolean, isWaitHandle?: boolean, withContractTrue_withoutContractFalse_elseNull?: boolean, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsStatisticsV2GetWorkItemsPost(body?: Array<string>, workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, approvedStart?: Date, approvedFinish?: Date, search?: string, statusId?: string, activityId?: string, contractMonthFilter?: Date, contractReport_trueGD_falseNT?: boolean, bugdetYear?: Date, hostCompanyId?: string, managerId?: string, expertId?: string, isExpired?: boolean, isWaitHandle?: boolean, withContractTrue_withoutContractFalse_elseNull?: boolean, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsStatisticsV2GetWorkItemsPost(body?: Array<string>, workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, approvedStart?: Date, approvedFinish?: Date, search?: string, statusId?: string, activityId?: string, contractMonthFilter?: Date, contractReport_trueGD_falseNT?: boolean, bugdetYear?: Date, hostCompanyId?: string, managerId?: string, expertId?: string, isExpired?: boolean, isWaitHandle?: boolean, withContractTrue_withoutContractFalse_elseNull?: boolean, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
























        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workflowID !== undefined && workflowID !== null) {
            queryParameters = queryParameters.set('workflowID', <any>workflowID);
        }
        if (programOrAreaID !== undefined && programOrAreaID !== null) {
            queryParameters = queryParameters.set('programOrAreaID', <any>programOrAreaID);
        }
        if (start !== undefined && start !== null) {
            queryParameters = queryParameters.set('start', <any>start.toISOString());
        }
        if (finish !== undefined && finish !== null) {
            queryParameters = queryParameters.set('finish', <any>finish.toISOString());
        }
        if (approvedStart !== undefined && approvedStart !== null) {
            queryParameters = queryParameters.set('approvedStart', <any>approvedStart.toISOString());
        }
        if (approvedFinish !== undefined && approvedFinish !== null) {
            queryParameters = queryParameters.set('approvedFinish', <any>approvedFinish.toISOString());
        }
        if (search !== undefined && search !== null) {
            queryParameters = queryParameters.set('search', <any>search);
        }
        if (statusId !== undefined && statusId !== null) {
            queryParameters = queryParameters.set('statusId', <any>statusId);
        }
        if (activityId !== undefined && activityId !== null) {
            queryParameters = queryParameters.set('activityId', <any>activityId);
        }
        if (contractMonthFilter !== undefined && contractMonthFilter !== null) {
            queryParameters = queryParameters.set('contractMonthFilter', <any>contractMonthFilter.toISOString());
        }
        if (contractReport_trueGD_falseNT !== undefined && contractReport_trueGD_falseNT !== null) {
            queryParameters = queryParameters.set('contractReport_trueGD_falseNT', <any>contractReport_trueGD_falseNT);
        }
        if (bugdetYear !== undefined && bugdetYear !== null) {
            queryParameters = queryParameters.set('bugdetYear', <any>bugdetYear.toISOString());
        }
        if (hostCompanyId !== undefined && hostCompanyId !== null) {
            queryParameters = queryParameters.set('hostCompanyId', <any>hostCompanyId);
        }
        if (managerId !== undefined && managerId !== null) {
            queryParameters = queryParameters.set('managerId', <any>managerId);
        }
        if (expertId !== undefined && expertId !== null) {
            queryParameters = queryParameters.set('expertId', <any>expertId);
        }
        if (isExpired !== undefined && isExpired !== null) {
            queryParameters = queryParameters.set('isExpired', <any>isExpired);
        }
        if (isWaitHandle !== undefined && isWaitHandle !== null) {
            queryParameters = queryParameters.set('isWaitHandle', <any>isWaitHandle);
        }
        if (withContractTrue_withoutContractFalse_elseNull !== undefined && withContractTrue_withoutContractFalse_elseNull !== null) {
            queryParameters = queryParameters.set('withContractTrue_withoutContractFalse_elseNull', <any>withContractTrue_withoutContractFalse_elseNull);
        }
        if (Filters !== undefined && Filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>Filters);
        }
        if (Sorts !== undefined && Sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>Sorts);
        }
        if (Page !== undefined && Page !== null) {
            queryParameters = queryParameters.set('Page', <any>Page);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflows/statistics-v2/getWorkItems`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param workflowID 
     * @param programOrAreaID 
     * @param start 
     * @param finish 
     * @param approvedStart 
     * @param approvedFinish 
     * @param search 
     * @param statusId 
     * @param activityId 
     * @param contractMonthFilter 
     * @param contractReport_trueGD_falseNT 
     * @param bugdetYear 
     * @param hostCompanyId 
     * @param managerId 
     * @param expertId 
     * @param isExpired 
     * @param isWaitHandle 
     * @param withContractTrue_withoutContractFalse_elseNull 
     * @param Filters 
     * @param Sorts 
     * @param Page 
     * @param PageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsStatisticsV2GetWorkItemsWithCostPost(body?: Array<number>, workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, approvedStart?: Date, approvedFinish?: Date, search?: string, statusId?: string, activityId?: string, contractMonthFilter?: Date, contractReport_trueGD_falseNT?: boolean, bugdetYear?: Date, hostCompanyId?: string, managerId?: string, expertId?: string, isExpired?: boolean, isWaitHandle?: boolean, withContractTrue_withoutContractFalse_elseNull?: boolean, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsStatisticsV2GetWorkItemsWithCostPost(body?: Array<number>, workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, approvedStart?: Date, approvedFinish?: Date, search?: string, statusId?: string, activityId?: string, contractMonthFilter?: Date, contractReport_trueGD_falseNT?: boolean, bugdetYear?: Date, hostCompanyId?: string, managerId?: string, expertId?: string, isExpired?: boolean, isWaitHandle?: boolean, withContractTrue_withoutContractFalse_elseNull?: boolean, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsStatisticsV2GetWorkItemsWithCostPost(body?: Array<number>, workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, approvedStart?: Date, approvedFinish?: Date, search?: string, statusId?: string, activityId?: string, contractMonthFilter?: Date, contractReport_trueGD_falseNT?: boolean, bugdetYear?: Date, hostCompanyId?: string, managerId?: string, expertId?: string, isExpired?: boolean, isWaitHandle?: boolean, withContractTrue_withoutContractFalse_elseNull?: boolean, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsStatisticsV2GetWorkItemsWithCostPost(body?: Array<number>, workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, approvedStart?: Date, approvedFinish?: Date, search?: string, statusId?: string, activityId?: string, contractMonthFilter?: Date, contractReport_trueGD_falseNT?: boolean, bugdetYear?: Date, hostCompanyId?: string, managerId?: string, expertId?: string, isExpired?: boolean, isWaitHandle?: boolean, withContractTrue_withoutContractFalse_elseNull?: boolean, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
























        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workflowID !== undefined && workflowID !== null) {
            queryParameters = queryParameters.set('workflowID', <any>workflowID);
        }
        if (programOrAreaID !== undefined && programOrAreaID !== null) {
            queryParameters = queryParameters.set('programOrAreaID', <any>programOrAreaID);
        }
        if (start !== undefined && start !== null) {
            queryParameters = queryParameters.set('start', <any>start.toISOString());
        }
        if (finish !== undefined && finish !== null) {
            queryParameters = queryParameters.set('finish', <any>finish.toISOString());
        }
        if (approvedStart !== undefined && approvedStart !== null) {
            queryParameters = queryParameters.set('approvedStart', <any>approvedStart.toISOString());
        }
        if (approvedFinish !== undefined && approvedFinish !== null) {
            queryParameters = queryParameters.set('approvedFinish', <any>approvedFinish.toISOString());
        }
        if (search !== undefined && search !== null) {
            queryParameters = queryParameters.set('search', <any>search);
        }
        if (statusId !== undefined && statusId !== null) {
            queryParameters = queryParameters.set('statusId', <any>statusId);
        }
        if (activityId !== undefined && activityId !== null) {
            queryParameters = queryParameters.set('activityId', <any>activityId);
        }
        if (contractMonthFilter !== undefined && contractMonthFilter !== null) {
            queryParameters = queryParameters.set('contractMonthFilter', <any>contractMonthFilter.toISOString());
        }
        if (contractReport_trueGD_falseNT !== undefined && contractReport_trueGD_falseNT !== null) {
            queryParameters = queryParameters.set('contractReport_trueGD_falseNT', <any>contractReport_trueGD_falseNT);
        }
        if (bugdetYear !== undefined && bugdetYear !== null) {
            queryParameters = queryParameters.set('bugdetYear', <any>bugdetYear.toISOString());
        }
        if (hostCompanyId !== undefined && hostCompanyId !== null) {
            queryParameters = queryParameters.set('hostCompanyId', <any>hostCompanyId);
        }
        if (managerId !== undefined && managerId !== null) {
            queryParameters = queryParameters.set('managerId', <any>managerId);
        }
        if (expertId !== undefined && expertId !== null) {
            queryParameters = queryParameters.set('expertId', <any>expertId);
        }
        if (isExpired !== undefined && isExpired !== null) {
            queryParameters = queryParameters.set('isExpired', <any>isExpired);
        }
        if (isWaitHandle !== undefined && isWaitHandle !== null) {
            queryParameters = queryParameters.set('isWaitHandle', <any>isWaitHandle);
        }
        if (withContractTrue_withoutContractFalse_elseNull !== undefined && withContractTrue_withoutContractFalse_elseNull !== null) {
            queryParameters = queryParameters.set('withContractTrue_withoutContractFalse_elseNull', <any>withContractTrue_withoutContractFalse_elseNull);
        }
        if (Filters !== undefined && Filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>Filters);
        }
        if (Sorts !== undefined && Sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>Sorts);
        }
        if (Page !== undefined && Page !== null) {
            queryParameters = queryParameters.set('Page', <any>Page);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflows/statistics-v2/getWorkItemsWithCost`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowID 
     * @param searchCompanyName 
     * @param Filters 
     * @param Sorts 
     * @param Page 
     * @param PageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsStatisticsV2ManagerCountGet(workflowID?: string, searchCompanyName?: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsStatisticsV2ManagerCountGet(workflowID?: string, searchCompanyName?: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsStatisticsV2ManagerCountGet(workflowID?: string, searchCompanyName?: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsStatisticsV2ManagerCountGet(workflowID?: string, searchCompanyName?: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workflowID !== undefined && workflowID !== null) {
            queryParameters = queryParameters.set('workflowID', <any>workflowID);
        }
        if (searchCompanyName !== undefined && searchCompanyName !== null) {
            queryParameters = queryParameters.set('searchCompanyName', <any>searchCompanyName);
        }
        if (Filters !== undefined && Filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>Filters);
        }
        if (Sorts !== undefined && Sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>Sorts);
        }
        if (Page !== undefined && Page !== null) {
            queryParameters = queryParameters.set('Page', <any>Page);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflows/statistics-v2/managerCount`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsStatisticsV2ProductCountGet(workflowId?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsStatisticsV2ProductCountGet(workflowId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsStatisticsV2ProductCountGet(workflowId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsStatisticsV2ProductCountGet(workflowId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workflowId !== undefined && workflowId !== null) {
            queryParameters = queryParameters.set('workflowId', <any>workflowId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflows/statistics-v2/productCount`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowFilterId 
     * @param template 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsStatisticsWorkflowFilterWorkflowFilterIdWorkItemsExportGet(workflowFilterId: string, template?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowsStatisticsWorkflowFilterWorkflowFilterIdWorkItemsExportGet(workflowFilterId: string, template?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowsStatisticsWorkflowFilterWorkflowFilterIdWorkItemsExportGet(workflowFilterId: string, template?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowsStatisticsWorkflowFilterWorkflowFilterIdWorkItemsExportGet(workflowFilterId: string, template?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowFilterId === null || workflowFilterId === undefined) {
            throw new Error('Required parameter workflowFilterId was null or undefined when calling apiWorkflowsStatisticsWorkflowFilterWorkflowFilterIdWorkItemsExportGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (template !== undefined && template !== null) {
            queryParameters = queryParameters.set('template', <any>template);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflows/statistics/workflowFilter/${encodeURIComponent(String(workflowFilterId))}/workItems/export`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
		responseType: 'blob' as 'json',
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowID 
     * @param programOrAreaID 
     * @param yearStart 
     * @param yearEnd 
     * @param industryID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsStatisticsWorkflowTreeBudgetFollowProgramGet(workflowID?: string, programOrAreaID?: string, yearStart?: number, yearEnd?: number, industryID?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsStatisticsWorkflowTreeBudgetFollowProgramGet(workflowID?: string, programOrAreaID?: string, yearStart?: number, yearEnd?: number, industryID?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsStatisticsWorkflowTreeBudgetFollowProgramGet(workflowID?: string, programOrAreaID?: string, yearStart?: number, yearEnd?: number, industryID?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsStatisticsWorkflowTreeBudgetFollowProgramGet(workflowID?: string, programOrAreaID?: string, yearStart?: number, yearEnd?: number, industryID?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workflowID !== undefined && workflowID !== null) {
            queryParameters = queryParameters.set('workflowID', <any>workflowID);
        }
        if (programOrAreaID !== undefined && programOrAreaID !== null) {
            queryParameters = queryParameters.set('programOrAreaID', <any>programOrAreaID);
        }
        if (yearStart !== undefined && yearStart !== null) {
            queryParameters = queryParameters.set('yearStart', <any>yearStart);
        }
        if (yearEnd !== undefined && yearEnd !== null) {
            queryParameters = queryParameters.set('yearEnd', <any>yearEnd);
        }
        if (industryID !== undefined && industryID !== null) {
            queryParameters = queryParameters.set('industryID', <any>industryID);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflows/statistics/workflowTree/budgetFollowProgram`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowID 
     * @param programOrAreaID 
     * @param yearStart 
     * @param yearEnd 
     * @param industryID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsStatisticsWorkflowTreeBudgetGet(workflowID?: string, programOrAreaID?: string, yearStart?: number, yearEnd?: number, industryID?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsStatisticsWorkflowTreeBudgetGet(workflowID?: string, programOrAreaID?: string, yearStart?: number, yearEnd?: number, industryID?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsStatisticsWorkflowTreeBudgetGet(workflowID?: string, programOrAreaID?: string, yearStart?: number, yearEnd?: number, industryID?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsStatisticsWorkflowTreeBudgetGet(workflowID?: string, programOrAreaID?: string, yearStart?: number, yearEnd?: number, industryID?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workflowID !== undefined && workflowID !== null) {
            queryParameters = queryParameters.set('workflowID', <any>workflowID);
        }
        if (programOrAreaID !== undefined && programOrAreaID !== null) {
            queryParameters = queryParameters.set('programOrAreaID', <any>programOrAreaID);
        }
        if (yearStart !== undefined && yearStart !== null) {
            queryParameters = queryParameters.set('yearStart', <any>yearStart);
        }
        if (yearEnd !== undefined && yearEnd !== null) {
            queryParameters = queryParameters.set('yearEnd', <any>yearEnd);
        }
        if (industryID !== undefined && industryID !== null) {
            queryParameters = queryParameters.set('industryID', <any>industryID);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflows/statistics/workflowTree/budget`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowID 
     * @param programOrAreaID 
     * @param start 
     * @param finish 
     * @param submitTye 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsStatisticsWorkflowTreeContractStatisticGet(workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, submitTye?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsStatisticsWorkflowTreeContractStatisticGet(workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, submitTye?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsStatisticsWorkflowTreeContractStatisticGet(workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, submitTye?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsStatisticsWorkflowTreeContractStatisticGet(workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, submitTye?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workflowID !== undefined && workflowID !== null) {
            queryParameters = queryParameters.set('workflowID', <any>workflowID);
        }
        if (programOrAreaID !== undefined && programOrAreaID !== null) {
            queryParameters = queryParameters.set('programOrAreaID', <any>programOrAreaID);
        }
        if (start !== undefined && start !== null) {
            queryParameters = queryParameters.set('start', <any>start.toISOString());
        }
        if (finish !== undefined && finish !== null) {
            queryParameters = queryParameters.set('finish', <any>finish.toISOString());
        }
        if (submitTye !== undefined && submitTye !== null) {
            queryParameters = queryParameters.set('submitTye', <any>submitTye);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflows/statistics/workflowTree/contract/statistic`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowID 
     * @param programOrAreaID 
     * @param yearStart 
     * @param yearEnd 
     * @param industryID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsStatisticsWorkflowTreeCostGet(workflowID?: string, programOrAreaID?: string, yearStart?: number, yearEnd?: number, industryID?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsStatisticsWorkflowTreeCostGet(workflowID?: string, programOrAreaID?: string, yearStart?: number, yearEnd?: number, industryID?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsStatisticsWorkflowTreeCostGet(workflowID?: string, programOrAreaID?: string, yearStart?: number, yearEnd?: number, industryID?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsStatisticsWorkflowTreeCostGet(workflowID?: string, programOrAreaID?: string, yearStart?: number, yearEnd?: number, industryID?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workflowID !== undefined && workflowID !== null) {
            queryParameters = queryParameters.set('workflowID', <any>workflowID);
        }
        if (programOrAreaID !== undefined && programOrAreaID !== null) {
            queryParameters = queryParameters.set('programOrAreaID', <any>programOrAreaID);
        }
        if (yearStart !== undefined && yearStart !== null) {
            queryParameters = queryParameters.set('yearStart', <any>yearStart);
        }
        if (yearEnd !== undefined && yearEnd !== null) {
            queryParameters = queryParameters.set('yearEnd', <any>yearEnd);
        }
        if (industryID !== undefined && industryID !== null) {
            queryParameters = queryParameters.set('industryID', <any>industryID);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflows/statistics/workflowTree/cost`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowID 
     * @param areaOrProgramID 
     * @param start 
     * @param end 
     * @param Filters 
     * @param Sorts 
     * @param Page 
     * @param PageSize 
     * @param hostCompanyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsStatisticsWorkflowTreeWorkItemsContractExportGet(workflowID?: string, areaOrProgramID?: string, start?: Date, end?: Date, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, hostCompanyId?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowsStatisticsWorkflowTreeWorkItemsContractExportGet(workflowID?: string, areaOrProgramID?: string, start?: Date, end?: Date, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, hostCompanyId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowsStatisticsWorkflowTreeWorkItemsContractExportGet(workflowID?: string, areaOrProgramID?: string, start?: Date, end?: Date, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, hostCompanyId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowsStatisticsWorkflowTreeWorkItemsContractExportGet(workflowID?: string, areaOrProgramID?: string, start?: Date, end?: Date, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, hostCompanyId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {










        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workflowID !== undefined && workflowID !== null) {
            queryParameters = queryParameters.set('workflowID', <any>workflowID);
        }
        if (areaOrProgramID !== undefined && areaOrProgramID !== null) {
            queryParameters = queryParameters.set('areaOrProgramID', <any>areaOrProgramID);
        }
        if (start !== undefined && start !== null) {
            queryParameters = queryParameters.set('start', <any>start.toISOString());
        }
        if (end !== undefined && end !== null) {
            queryParameters = queryParameters.set('end', <any>end.toISOString());
        }
        if (Filters !== undefined && Filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>Filters);
        }
        if (Sorts !== undefined && Sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>Sorts);
        }
        if (Page !== undefined && Page !== null) {
            queryParameters = queryParameters.set('Page', <any>Page);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }
        if (hostCompanyId !== undefined && hostCompanyId !== null) {
            queryParameters = queryParameters.set('hostCompanyId', <any>hostCompanyId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflows/statistics/workflowTree/workItems/contract/export`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
		responseType: 'blob' as 'json',
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowID 
     * @param areaOrProgramID 
     * @param start 
     * @param end 
     * @param Filters 
     * @param Sorts 
     * @param Page 
     * @param PageSize 
     * @param hostCompanyId 
     * @param submitTye 
     * @param getItemExpiredOtherwiseOnTime 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsStatisticsWorkflowTreeWorkItemsContractGet(workflowID?: string, areaOrProgramID?: string, start?: Date, end?: Date, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, hostCompanyId?: string, submitTye?: string, getItemExpiredOtherwiseOnTime?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsStatisticsWorkflowTreeWorkItemsContractGet(workflowID?: string, areaOrProgramID?: string, start?: Date, end?: Date, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, hostCompanyId?: string, submitTye?: string, getItemExpiredOtherwiseOnTime?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsStatisticsWorkflowTreeWorkItemsContractGet(workflowID?: string, areaOrProgramID?: string, start?: Date, end?: Date, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, hostCompanyId?: string, submitTye?: string, getItemExpiredOtherwiseOnTime?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsStatisticsWorkflowTreeWorkItemsContractGet(workflowID?: string, areaOrProgramID?: string, start?: Date, end?: Date, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, hostCompanyId?: string, submitTye?: string, getItemExpiredOtherwiseOnTime?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {












        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workflowID !== undefined && workflowID !== null) {
            queryParameters = queryParameters.set('workflowID', <any>workflowID);
        }
        if (areaOrProgramID !== undefined && areaOrProgramID !== null) {
            queryParameters = queryParameters.set('areaOrProgramID', <any>areaOrProgramID);
        }
        if (start !== undefined && start !== null) {
            queryParameters = queryParameters.set('start', <any>start.toISOString());
        }
        if (end !== undefined && end !== null) {
            queryParameters = queryParameters.set('end', <any>end.toISOString());
        }
        if (Filters !== undefined && Filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>Filters);
        }
        if (Sorts !== undefined && Sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>Sorts);
        }
        if (Page !== undefined && Page !== null) {
            queryParameters = queryParameters.set('Page', <any>Page);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }
        if (hostCompanyId !== undefined && hostCompanyId !== null) {
            queryParameters = queryParameters.set('hostCompanyId', <any>hostCompanyId);
        }
        if (submitTye !== undefined && submitTye !== null) {
            queryParameters = queryParameters.set('submitTye', <any>submitTye);
        }
        if (getItemExpiredOtherwiseOnTime !== undefined && getItemExpiredOtherwiseOnTime !== null) {
            queryParameters = queryParameters.set('getItemExpiredOtherwiseOnTime', <any>getItemExpiredOtherwiseOnTime);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflows/statistics/workflowTree/workItems/contract`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowID 
     * @param programOrAreaID 
     * @param start 
     * @param finish 
     * @param Filters 
     * @param Sorts 
     * @param Page 
     * @param PageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsStatisticsWorkflowTreeWorkItemsExportGet(workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowsStatisticsWorkflowTreeWorkItemsExportGet(workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowsStatisticsWorkflowTreeWorkItemsExportGet(workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowsStatisticsWorkflowTreeWorkItemsExportGet(workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workflowID !== undefined && workflowID !== null) {
            queryParameters = queryParameters.set('workflowID', <any>workflowID);
        }
        if (programOrAreaID !== undefined && programOrAreaID !== null) {
            queryParameters = queryParameters.set('programOrAreaID', <any>programOrAreaID);
        }
        if (start !== undefined && start !== null) {
            queryParameters = queryParameters.set('start', <any>start.toISOString());
        }
        if (finish !== undefined && finish !== null) {
            queryParameters = queryParameters.set('finish', <any>finish.toISOString());
        }
        if (Filters !== undefined && Filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>Filters);
        }
        if (Sorts !== undefined && Sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>Sorts);
        }
        if (Page !== undefined && Page !== null) {
            queryParameters = queryParameters.set('Page', <any>Page);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflows/statistics/workflowTree/workItems/export`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
		responseType: 'blob' as 'json',
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowID 
     * @param programOrAreaID 
     * @param start 
     * @param finish 
     * @param Filters 
     * @param Sorts 
     * @param Page 
     * @param PageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsStatisticsWorkflowTreeWorkItemsGet(workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsStatisticsWorkflowTreeWorkItemsGet(workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsStatisticsWorkflowTreeWorkItemsGet(workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsStatisticsWorkflowTreeWorkItemsGet(workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workflowID !== undefined && workflowID !== null) {
            queryParameters = queryParameters.set('workflowID', <any>workflowID);
        }
        if (programOrAreaID !== undefined && programOrAreaID !== null) {
            queryParameters = queryParameters.set('programOrAreaID', <any>programOrAreaID);
        }
        if (start !== undefined && start !== null) {
            queryParameters = queryParameters.set('start', <any>start.toISOString());
        }
        if (finish !== undefined && finish !== null) {
            queryParameters = queryParameters.set('finish', <any>finish.toISOString());
        }
        if (Filters !== undefined && Filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>Filters);
        }
        if (Sorts !== undefined && Sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>Sorts);
        }
        if (Page !== undefined && Page !== null) {
            queryParameters = queryParameters.set('Page', <any>Page);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflows/statistics/workflowTree/workItems`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowID 
     * @param programOrAreaID 
     * @param start 
     * @param finish 
     * @param industryID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsStatisticsWorkflowTreeWorkItemsStatisticGet(workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, industryID?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsStatisticsWorkflowTreeWorkItemsStatisticGet(workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, industryID?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsStatisticsWorkflowTreeWorkItemsStatisticGet(workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, industryID?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsStatisticsWorkflowTreeWorkItemsStatisticGet(workflowID?: string, programOrAreaID?: string, start?: Date, finish?: Date, industryID?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workflowID !== undefined && workflowID !== null) {
            queryParameters = queryParameters.set('workflowID', <any>workflowID);
        }
        if (programOrAreaID !== undefined && programOrAreaID !== null) {
            queryParameters = queryParameters.set('programOrAreaID', <any>programOrAreaID);
        }
        if (start !== undefined && start !== null) {
            queryParameters = queryParameters.set('start', <any>start.toISOString());
        }
        if (finish !== undefined && finish !== null) {
            queryParameters = queryParameters.set('finish', <any>finish.toISOString());
        }
        if (industryID !== undefined && industryID !== null) {
            queryParameters = queryParameters.set('industryID', <any>industryID);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflows/statistics/workflowTree/workItems/statistic`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param start 
     * @param finish 
     * @param industryID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsStatisticsWorkitemGet(start?: Date, finish?: Date, industryID?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsStatisticsWorkitemGet(start?: Date, finish?: Date, industryID?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsStatisticsWorkitemGet(start?: Date, finish?: Date, industryID?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsStatisticsWorkitemGet(start?: Date, finish?: Date, industryID?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (start !== undefined && start !== null) {
            queryParameters = queryParameters.set('start', <any>start.toISOString());
        }
        if (finish !== undefined && finish !== null) {
            queryParameters = queryParameters.set('finish', <any>finish.toISOString());
        }
        if (industryID !== undefined && industryID !== null) {
            queryParameters = queryParameters.set('industryID', <any>industryID);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflows/statistics/workitem`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowId 
     * @param start 
     * @param finish 
     * @param industryID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowsWorkflowIdStatisticsWorkitemGet(workflowId: string, start?: Date, finish?: Date, industryID?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowsWorkflowIdStatisticsWorkitemGet(workflowId: string, start?: Date, finish?: Date, industryID?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowsWorkflowIdStatisticsWorkitemGet(workflowId: string, start?: Date, finish?: Date, industryID?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowsWorkflowIdStatisticsWorkitemGet(workflowId: string, start?: Date, finish?: Date, industryID?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowId === null || workflowId === undefined) {
            throw new Error('Required parameter workflowId was null or undefined when calling apiWorkflowsWorkflowIdStatisticsWorkitemGet.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (start !== undefined && start !== null) {
            queryParameters = queryParameters.set('start', <any>start.toISOString());
        }
        if (finish !== undefined && finish !== null) {
            queryParameters = queryParameters.set('finish', <any>finish.toISOString());
        }
        if (industryID !== undefined && industryID !== null) {
            queryParameters = queryParameters.set('industryID', <any>industryID);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflows/${encodeURIComponent(String(workflowId))}/statistics/workitem`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
