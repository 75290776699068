<div
  #drop
  ngbDropdown
  placement="bottom"
  autoClose="outside"
  class="kt-header__topbar-item"
>
  <div
    ngbDropdownToggle
    class="kt-header__topbar-wrapper"
    (click)="getNotificationGroup(drop)"
  >
    <span
      class="kt-header__topbar-icon"
      [ngClass]="{
        'kt-pulse kt-pulse--brand': pulse,
        'kt-pulse--light': pulseLight,
        'kt-header__topbar-icon--success': iconType === 'success'
      }"
    >
      <ng-container *ngIf="notificationCount$ | async as notificationCount">
        <span
          [hidden]="notificationCount == 0"
          class="color-white notifi-number"
        >
          <div>{{ notificationCount < 100 ? notificationCount : '99+' }}</div>
        </span>
      </ng-container>

      <span
        *ngIf="useSVG"
        class="kt-svg-icon"
        [ngClass]="{'kt-svg-icon--success': iconType === 'success'}"
        [inlineSVG]="icon"
      ></span>
      <span class="kt-pulse__ring" [hidden]="!pulse"></span>
    </span>
  </div>

  <div
    ngbDropdownMenu
    class="notification-width dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg"
  >
    <form>
      <!-- [ngStyle]="{'background-image': backGroundStyle()}" -->
      <div
        class="notifi-head kt-head kt-head--skin-{{
          skin
        }} kt-head--fit-x kt-head--fit-b"
      >
        <h3 class="kt-head__title text-left color-white">
          {{ selectedGroup != null ? selectedGroup.name : 'Thông báo' }}&nbsp;
          <span class="float-r">
            <img src="./assets/icons/svg/icon-setting.svg" />
          </span>
        </h3>
      </div>
      <div class="row kt-margin-0 notify-padding">
        <div class="col-12">
          <span *ngFor="let item of groupNotification$ | async; index as i">
            <span
              class="kt-padding-10 nav-item group-icon-border cursor-pointer"
              (click)="getNotify(item.type, i)"
              [ngClass]="selectedGroupIndex == i ? 'group-icon-bg' : ''"
            >
              <img
                [ngClass]="
                  item.unread > 0 ? 'filter-blue-color' : 'filter-grey-color'
                "
                class="icon-margin"
                [src]="item.icon_group"
                alt="..."
                width="18"
                height="18"
              />
              <span *ngIf="item.unread != 0" class="position-relative"
                ><span
                  [hidden]="item.unread == 0"
                  class="color-white notifi-group-number"
                >
                  <div>{{ item.unread }}</div>
                </span></span
              >
            </span>
          </span>
        </div>
      </div>
      <div
        [perfectScrollbar]="{wheelPropagation: false}"
        [ngStyle]="{'max-height': '50vh', position: 'relative'}"
        class="kt-notification kt-margin-b-10 mt-1"
      >
        <div
          class="row"
          *ngFor="let item of notify$ | async; index as i"
          href="javascript:;"
          class="hide-icon kt-notification__item cursor-pointer"
          (click)="readNotify(item.id, i, $event, true)"
          (mouseover)="item.hover = true"
          (mouseout)="item.hover = false"
        >
          <div class="col-12">
            <div class="row">
              <div class="col-11 kt-margin-b-5 kt-padding-0">
                <div class="fs-11 ellipsis" [title]="item.summary">
                  <div class="row m-0">
                    <div class="col-12 ellipsis p-0">
                      <img
                        class="kt-font-success notify-item-icon"
                        [src]="item.icon"
                      />
                      <span class="text-color fw-4"> {{ item.summary }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-1 kt-padding-0">
                <div class="row kt-margin-0">
                  <div class="w-50">
                    <img
                      [hidden]="item.hover ? false : true"
                      (click)="deleteNotify(item.id, i, $event)"
                      class="cursor-pointer kt-margin-b-5"
                      matTooltip="Xóa"
                      src="./assets/icons/svg/icon_trash.svg"
                    />
                  </div>
                  <div class="w-50 text-right">
                    <div
                      [hidden]="item.read && !item.hover ? true : false"
                      [ngStyle]="
                        item.read
                          ? {'background-color': 'grey'}
                          : {'background-color': '#5d78ff'}
                      "
                      class="notify-stt kt-margin-t-5 kt-margin-l-5"
                      [matTooltip]="
                        item.read ? 'Đánh dấu chưa đọc' : 'Đánh dấu đã đọc'
                      "
                      (click)="
                        item.read
                          ? unreadNotify(item.id, i, $event)
                          : readNotify(item.id, i, $event, false)
                      "
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-11 kt-padding-0 kt-margin-b-5">
                <div class="kt-notification__item-details">
                  <div class="notify-item-detail" [title]="item.details">
                    {{ item.details }}
                  </div>
                </div>
              </div>
              <div
                *ngIf="item.group_count > 1"
                class="col-1 text-right text-right group-number"
              >
                +{{ item.group_count - 1 }}
              </div>
            </div>
            <div class="row">
              <div class="col-3 p-0">
                <div class="fs-11">
                  <span class="notify-item-date">{{ item.created_at }}</span>
                </div>
              </div>
              <div class="col-9 p-0">
                <div class="row">
                  <div class="fs-11 ellipsis" *ngIf="item.expired != null">
                    <span class="notify-item-date"
                      >Thông báo sẽ được xóa sau {{ item.expired }}p nữa
                    </span>
                    <span
                      class="notify-item-date cursor-pointer text-underline"
                      (click)="cancelDeleteNotify(item.id, i, $event)"
                      >Hủy xóa</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            *ngIf="notify && notify.length < notifyTotal"
            class="col-12 loadmore kt-padding-t-10 kt-padding-b-10 cursor-pointer"
            (click)="loadMoreNotify()"
          >
            <div class="text-center">Xem thêm</div>
          </div>
        </div>
        <div class="ps__rail-y" style="top: 0px; right: 0px">
          <div
            class="ps__thumb-y"
            tabindex="0"
            style="top: 0px; height: 0px"
          ></div>
        </div>
      </div>
    </form>
  </div>
</div>
