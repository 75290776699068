<ng-select
  class="custom form-control"
  [formControl]="selectCtrl"
  [(ngModel)]="defaultID"
  [ngClass]="invalid ? 'ng-invalid mat-form-field-invalid' : ''"
  (change)="emitChangeValue($event)"
  (search)="search($event.term)"
  dropdownPosition="bottom"
  [virtualScroll]="true"
  [loading]="comboBoxLoading"
  [placeholder]="placeholderLabel"
  [multiple]="multiple"
  (scrollToEnd)="loadMore()"
>
  <ng-option [id]="item.id" *ngFor="let item of fiteredInput" [value]="item[columnValue]">{{
    item[columnName]
  }}</ng-option>
</ng-select>
