// Angular
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {HtmlClassService} from '@app/views/theme/html-class.service';
// Object-Path
import * as objectPath from 'object-path';
// Layout
import {
  LayoutConfigService,
  SplashScreenService,
} from '../../../../core/_base/layout';

@Component({
  selector: 'kt-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent implements OnInit, OnDestroy {
  // Public proprties
  loaderLogo: string;
  loaderType: string;
  loaderMessage: string;

  @ViewChild('splashScreen', {static: true}) splashScreen: ElementRef;

  /**
   * Component constructor
   *
   * @param layoutConfigService: LayoutConfigService
   * @param splashScreenService: SplachScreenService
   */
  constructor(
    private layoutConfigService: LayoutConfigService,
    private splashScreenService: SplashScreenService,
    public htmlClassService: HtmlClassService
  ) {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    // init splash screen, see loader option in layout.config.ts
    const loaderConfig = this.layoutConfigService.getConfig('loader');
    this.loaderType = objectPath.get(loaderConfig, 'type');
    this.loaderMessage = objectPath.get(loaderConfig, 'message');
    this.splashScreenService.init(this.splashScreen);
    $('body').css({overflow: 'hidden'});
  }

  ngOnDestroy(): void {
    $('body').css({overflow: 'unset'});
  }
}
