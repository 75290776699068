import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
export interface dropdownOptions {
  id: string;
  iconUrl?: string;
  label: string;
  divide?: boolean;
}

@Component({
  selector: 'meu-dropdown-with-dots',
  templateUrl: './dropdown-with-dots.component.html',
  styleUrls: ['./dropdown-with-dots.component.scss'],
})
export class DropdownWithDotsComponent implements OnInit {
  isOpenedList: any;
  @Input() iconUrl: string;
  @Input() options: dropdownOptions[];
  @Input() isVertical: boolean = true;
  @Input() isLeftPosition: boolean = false;
  @Input() isWhiteIcon: boolean = false;
  @Output() idSelected = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onClickedOutside() {
    this.isOpenedList = 0;
  }

  openMenu(e) {
    e.stopPropagation();
    this.isOpenedList === 1 ? (this.isOpenedList = 0) : (this.isOpenedList = 1);
  }
  emitValue(idSelected, e) {
    e.stopPropagation();
    this.idSelected.emit(idSelected);
    this.onClickedOutside();
  }
}
